import { DevKGProps } from "./model"

export function Logo({ isDark }: DevKGProps): JSX.Element {
  return (
    <svg
      className="h-5"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 2429 700"
    >
      <g transform="translate(-2670 -930)">
        <g transform="matrix(3.10217 0 0 1 -5567.98 930)">
          <g>
            <path
              fill={isDark ? "#212121" : "#fff"}
              d="M2670 930H5099V1630H2670z"
              transform="matrix(.32236 0 0 1 1794.86 -930)"
            />
            <path
              fill={isDark ? "#fff" : "#212121"}
              d="M2855 100c110.38 0 200 89.617 200 200s-89.62 200-200 200h-128V100h128zm0 80c66.23 0 120 53.77 120 120 0 66.23-53.77 120-120 120h-48V180h48z"
              transform="matrix(.40294 0 0 1.25 1601.96 -25)"
            />
            <path
              fill={isDark ? "#fff" : "#212121"}
              d="M3787.16 100H3895l160 400 160-400h107.84L4123 600h-136l-199.84-500z"
              transform="matrix(.32236 0 0 1 1760.43 0)"
            />
            <path
              fill={isDark ? "#fff" : "#212121"}
              d="M3155 100h360v100h-260v100h260v100h-260v100h260v100h-360V100z"
              transform="matrix(.32236 0 0 1 1832.04 0)"
            />
            <path
              fill={isDark ? "#fff" : "#1565C0"}
              d="M0 0H700V700H0z"
              transform="matrix(.36058 0 0 1 3186.15 0)"
            />
            <path
              fill={isDark ? "#212121" : "#fff"}
              d="M200 100H100v200l300 300h283V300.001H483l-50 50 50 50h99.982L583 500H441.5L200 258V100zM200 600H100V400l35.824-35.823L206.501 435 200 441.75V600z"
              transform="matrix(.32236 0 0 1 3186.15 0)"
            />
            <path
              fill={isDark ? "#212121" : "#fff"}
              d="M683 100H400L235.197 264.805l70.655 70.844L441.531 200H683V100z"
              transform="matrix(.32236 0 0 1 3186.15 0)"
            />
            <path
              fill={isDark ? "#fff" : "#212121"}
              d="M4316 930v40H2710v620h1606v40H2670V930h1646z"
              transform="matrix(.32236 0 0 1 1794.86 -930)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
