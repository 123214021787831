import React from "react"

const fr = (
  <>
    <h2>Politique de confidentialité</h2>
    <p>
      Le terme utilisé "Avarta Auto" dans cette politique de confidentialité fait référence à notre site web https://avartasolutions.com
      à moins qu'il ne soit définit autrement dans la présente
      politique de confidentialité.
    </p>
    <p>
      ce site web est construit et est la propriété de Avarta Auto solutions inc. Ce SERVICE est fourni par
      Avarta Auto solutions inc. et est destiné à être
      être utilisé tel quel.
    </p>
    <p>
      Cette page est utilisée pour informer les visiteurs concernant
      nos politiques en matière de collecte, d'utilisation et de
      divulgation d'informations personnelles si quelqu'un décidait d'utiliser
      notre service.
    </p>
    <p>
      Si vous décidez d'utiliser nos services, vous acceptez alors
      à la collecte et à l'utilisation des informations en relation avec cette
      politique. Les informations personnelles que nous recueillons sont
      utilisées pour fournir et améliorer le service.
      En utilisant Avarta Auto, nous ne partagerons pas
      les informations avec quiconque, sauf dans les cas décrits dans la présente
      politique de confidentialité.
    </p>
    <p><strong>Collecte et utilisation de l'information</strong></p>
    <p>
      Pour une meilleure expérience, lors de l'utilisation de notre Service,
      nous pouvons vous demander de nous fournir certaines
      informations personnellement identifiables. Les
      informations que nous demandons seront
      conservées sur votre appareil et certaines sont stockées dans une base de données.
    </p>
    <p>
      L'application utilise des services tiers qui peuvent collecter des
      des informations utilisées pour vous identifier.
    </p>
    <p><strong>Cookies et stockage local</strong></p>
    <p>
      Les cookies et le stockage local sont des fichiers contenant une petite quantité de données qui sont
      communément utilisés comme identifiants uniques anonymes. Ils sont
      envoyés à votre navigateur depuis les sites web que vous visitez et
      sont stockés sur la mémoire interne de votre appareil.
    </p>
    <p>
      Ces cookies et ce stockage local sont nécessaires au bon fonctionnement de avartasolutions.com      .
      Vous pouvez choisir de ne pas les utiliser en n'utilisant pas les services de avartasolutions.com      .
    </p>
    <p><strong>Fournisseurs de services</strong></p>
    <p>
      Nous pouvons employer des sociétés tierces
      et des individus pour les raisons suivantes :
    </p>
    <ul>
      <li>Pour faciliter notre service;</li>
      <li>Pour fournir le Service en notre nom;</li>
      <li>Pour exécuter des services liés au Service ; ou</li>
      <li>Pour nous aider à analyser la façon dont notre Service est utilisé.</li>
    </ul>
    <p>
      Nous souhaitons informer les utilisateurs de ce
      Service que ces tiers ont accès à vos
      informations personnelles. La raison en est l'exécution des tâches
      qui leur sont assignées en notre nom. Cependant, ils sont obligés
      de ne pas divulguer ou utiliser les informations à toute autre
      fins.
    </p>
    <p><strong>Sécurité</strong></p>
    <p>
      Nous apprécions la confiance que vous nous accordez en nous fournissant
      vos informations personnelles, aussi nous efforçons-nous d'utiliser des
      moyens commercialement acceptables pour les protéger. Mais n'oubliez pas
      qu'aucune méthode de transmission sur Internet, ni aucune méthode de stockage
      de stockage électronique n'est sûre et fiable à 100 %.
      Nous ne pouvons pas garantir sa sécurité absolue.
    </p>
    <p><strong>Liens vers d'autres sites</strong></p>
    <p>
      Ce service peut contenir des liens vers d'autres sites. Si vous cliquez
      sur un lien tiers, vous serez dirigé vers ce site.
      Notez que ces sites externes ne sont pas exploités par
      nous. Par conséquent, nous vous conseillons vivement de
      de consulter la politique de confidentialité de ces sites.
      Nous n'avons aucun contrôle et n'assumons aucune
      responsabilité du contenu, des politiques de confidentialité ou des
      pratiques de tout site ou service tiers.
    </p>
    <p><strong>Modifications de la présente politique de confidentialité</strong></p>
    <p>
      Nous pouvons mettre à jour notre politique de confidentialité de
      temps en temps. Ainsi, il vous est conseillé de consulter cette page
      périodiquement pour prendre connaissance de tout changement. Nous allons
      vous informer de tout changement en publiant la nouvelle politique de confidentialité
      sur cette page. Ces modifications entrent en vigueur immédiatement après
      leur publication sur cette page.
    </p>
    <p><strong>Nous contacter</strong></p>
    <p>
      Si vous avez des questions ou des suggestions concernant
      notre politique de confidentialité, n'hésitez pas à nous contacter par les moyens proposés dans notre pied de page de notre site web.
    </p>
    <p>
      Cette page de politique de confidentialité a été créée à l'adresse
      <a rel="noopener noreferrer" href="https://privacypolicytemplate.net" target="_blank"> privacypolicytemplate.net
      </a>
      et modifié / généré par
      <a rel="noopener noreferrer" href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank">
        Générateur de règles de confidentialité des applications
      </a>
    </p>
  </>
)

export default fr
