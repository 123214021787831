import { css } from "styled-components"

export const SpecStyledOverride = () => css`
  display: flex;
  align-items: center;
  .fa-icon {
    height: 20px;
    margin-right: 15px;
    color: ${window.theme.secondary900};
  }
  span {
    font-size: 14px;
    width: max-content;
  }
  h4 {
    display: none;
  }
`