import { TextArea } from "../TextArea"

import {
  OutputStyled,
} from "./OutputStyled"
import { OutputProps } from "./model"


export function Output({ label, value, isTextArea, isError }: OutputProps): JSX.Element {
  return (
    <OutputStyled isTextArea={!!isTextArea} className={isError ? "error output-box" : "output-box"}>
      <div className="label">{label}</div>
      {!isTextArea && <div className="output">{value}</div>}
      {isTextArea && <TextArea value={value as string} isReadonly={true} />}
    </OutputStyled>
  )
}
