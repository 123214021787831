import { useRef } from "react"
import { tw } from "@/functions"

import { TableCellProps } from "./model"

export function TableCell({ children, isError, onDoubleClick }: TableCellProps): JSX.Element {
  const buttonRef = useRef<HTMLDivElement>(null)
  return (
    <div className="h-10 overflow-auto border-b border-r border-solid w-max border-grey-800 ">
      <div
        tabIndex={1}
        ref={buttonRef}
        onBlur={() => {
          if (buttonRef.current) {
            buttonRef.current.scrollLeft = 0
          }
        }}
        onDoubleClick={onDoubleClick}
        className={tw`
        flex
        items-center
        justify-center
        w-full
        h-full
        px-2
        border
        font-light
        border-solid
        text-grey-500
        ${isError ? "border-error-500" : "border-trans"}
        overflow-hidden
        focus:border-primary-300
        focus:!overflow-auto
        whitespace-nowrap
      `}>
        {children}
      </div>
    </div >
  )
}