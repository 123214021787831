import { shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const PageTopBarStyled = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    padding: 0 20px;
    h4 {
      font-weight: 700;
      color: ${() => window.theme.secondary800};
    }
    .fa-icon {
      position: absolute;
      left: 20px;
      height: 30px;
      margin-right: 15px;
      ${() => shadeEffect("primary500", "color")};
    }
  `