import { center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const BugReportStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  .bug-item {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: calc(100% - 40px);
    padding: 20px;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 0 5px 10px ${() => window.theme.grey200};
    & > .fa-icon {
      ${center};
      align-self: flex-end;
      height: 40px;
      width: 40px;
      border-radius: 9999px;
      color: white;
      ${() => shadeEffect("primary500", "background")}
      svg {
        height: 20px;
      }
    }
  }
  .padding {
    height: 20px;
    width: 100%;
    flex-shrink: 0;
  }
  .textarea {
    background: ${() => window.theme.grey100};
    padding: 20px;
    margin-bottom: 20px;
  }

  .scrollbar-box > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

`
