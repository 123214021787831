import { PublicUser } from "@/shared"
import { formatString } from "@/shared"
import { ReduxState } from "@/redux/model"

import { UsersState } from "../model"


export function filterUsers(
  newState: UsersState,
  globalState: ReduxState
): PublicUser[] {
  const {
    searchField,
    searchPrecision,
  } = newState
  const {
    languages: {
      say
    }
  } = globalState
  return newState.userList.filter(item => {
    const activeFilterCount = [
      searchField.trim(),
    ].filter(val => !!val).length
    let noMatchCount = 0

    if (searchField.trim()) {
      if (
        formatString("flat", item.email).includes(formatString("flat", searchField))
        || formatString("flat", say[item.role] || "").includes(formatString("flat", searchField))

      ) {
        return true
      } else {
        if (searchPrecision === "high") {
          return false
        } else {
          noMatchCount++
        }
      }
    }

    if (searchPrecision === "high" && noMatchCount) {
      return false
    }
    if (searchPrecision === "medium" && noMatchCount > activeFilterCount / 2) {
      return false
    } if (searchPrecision === "low" && noMatchCount >= activeFilterCount) {
      return false
    }
    return true
  })
}