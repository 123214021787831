
import { COLORS } from "../constants"
import { AllColors, ColorItem } from "../model"

export function getColor(
  color: keyof AllColors,
): any {
  const primaryObj: any = {}
  Object.keys(COLORS[color]).forEach((key: string) => {
    primaryObj[`${color}${key}`] = COLORS[color][key as keyof ColorItem]
  })
  return primaryObj
}
