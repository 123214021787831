import { tw } from "@/functions"

export function SliderIndexes({
  index,
  childrenArray,
  onChangeIndex
}: {
  index: number,
  childrenArray: (React.ReactChild | React.ReactFragment | React.ReactPortal)[]
  onChangeIndex?: (index: number) => void
}): JSX.Element {
  return (
    <div
      className="absolute left-0 flex justify-center w-full pl-4 slider-indexes bottom-5">
      {childrenArray.map((_: any, newIndex: number) => (
        <div className={tw`
          z-[200]
          w-10
          h-1
          rounded-sm
          center
          cursor-pointer
          not-last:mr-2
          ${index === newIndex ? `
            bg-white
          ` : `
            bg-white/40
            mouse:hover:bg-white/60
            mouse:active:bg-white/80
            touch:active:bg-white/60
          `}
        `}
          key={newIndex}
          onClick={() => onChangeIndex && onChangeIndex(newIndex)}
        />

      ))}
    </div>
  )
}