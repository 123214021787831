import { Vehicle, Say, VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND } from "@/shared"
import moment from "moment"

import { VEHICLE_TABLE_HEAD_LIST } from "../constants/VEHICULE_TABLE_HEAD_LIST"
import { AllVehicleHeadColumns, VehiclesState } from "../model"

export function getOutputMapByVehicleId(vehicleList: Vehicle[], say: Say, isInitView: boolean): VehiclesState["outputMapByVehicleId"] {
  const outputMapByVehicleId: VehiclesState["outputMapByVehicleId"] = {}
  vehicleList.forEach(vehicle => {
    const initMap: { [key in AllVehicleHeadColumns]: any } = {
      brand: vehicle.init.brand === "other" ? `${say.other}: ${vehicle.init.otherBrand}` : say[vehicle.init.brand],
      model: VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[vehicle.init.brand][vehicle.init.model],
      year: vehicle.init.year,
      estimate: vehicle.init.estimate ? `${vehicle.init.estimate.price}${vehicle.init.estimate.isMsrp ? ` (${say.msrp})` : ""}` : "",
      creationDate: null,
      lastEditDate: null,
      comment: null,
      status: null,
      bodyStyle: say[vehicle.init.bodyStyle],
      exteriorColor: vehicle.init.exteriorColor,
      interiorColor: vehicle.init.interiorColor,
      doors: vehicle.init.doorCount,
      seatingCount: vehicle.init.seatingCount,
      vinId: vehicle.init.vinId,
      equipments: vehicle.init.equipmentList.map(item => say[item]).join(", "),
      condition: say[vehicle.init.condition],
      distance: `${vehicle.init.distance} ${vehicle.init.isKmDistanceUnit ? say.km : say.miles}`,
      fuelType: say[vehicle.init.fuelType],
      cityFuelConsumption: vehicle.init.cityFuelConsumption,
      highwayFuelConsumption: vehicle.init.highwayFuelConsumption,
      transmission: vehicle.init.isManualTransmission ? say.manual : say.automatic,
      cylinderCount: vehicle.init.cylinderCount,
      driveTrain: say[vehicle.init.driveTrain],
      price: vehicle.init.price,
      audience: null,
      ownership: vehicle.init.customerParams ? vehicle.init.customerParams.isFullOwnership ? say.ownedVehicle : say.leasedVehicle : "",
      firstName: vehicle.init.customerParams ? vehicle.init.customerParams.firstName : "",
      lastName: vehicle.init.customerParams ? vehicle.init.customerParams.lastName : "",
      phone: vehicle.init.customerParams ? vehicle.init.customerParams.phone : "",
      email: vehicle.init.customerParams ? vehicle.init.customerParams.email : "",
      address: vehicle.init.customerParams ? vehicle.init.customerParams.address.address : "",
    }
    const currentMap: { [key in AllVehicleHeadColumns]: any } = {
      brand: vehicle.brand === "other" ? `${say.other}: ${vehicle.otherBrand}` : say[vehicle.brand],
      model: VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[vehicle.brand][vehicle.model],
      year: vehicle.year,
      estimate: vehicle.estimate ? `${vehicle.estimate.price}${vehicle.estimate.isMsrp ? ` (${say.msrp})` : ""}` : "",
      creationDate: moment(vehicle.creationDate).format(say.yearDateShort),
      lastEditDate: moment(vehicle.lastEditDate).format(say.yearDateShort),
      comment: vehicle.comment?.plainText,
      status: say[vehicle.status],
      bodyStyle: say[vehicle.bodyStyle],
      exteriorColor: vehicle.exteriorColor,
      interiorColor: vehicle.interiorColor,
      doors: vehicle.doorCount,
      seatingCount: vehicle.seatingCount,
      vinId: vehicle.vinId,
      equipments: vehicle.equipmentList.map(item => say[item]).join(", "),
      condition: say[vehicle.condition],
      distance: `${vehicle.distance} ${vehicle.isKmDistanceUnit ? say.km : say.miles}`,
      fuelType: say[vehicle.fuelType],
      cityFuelConsumption: vehicle.cityFuelConsumption,
      highwayFuelConsumption: vehicle.highwayFuelConsumption,
      transmission: vehicle.isManualTransmission ? say.manual : say.automatic,
      cylinderCount: vehicle.cylinderCount,
      driveTrain: say[vehicle.driveTrain],
      price: vehicle.price,
      audience: vehicle.isPublic ? say.public : say.private,
      ownership: vehicle.customerParams ? vehicle.customerParams.isFullOwnership ? say.ownedVehicle : say.leasedVehicle : "",
      firstName: vehicle.customerParams ? vehicle.customerParams.firstName : "",
      lastName: vehicle.customerParams ? vehicle.customerParams.lastName : "",
      phone: vehicle.customerParams ? vehicle.customerParams.phone : "",
      email: vehicle.customerParams ? vehicle.customerParams.email : "",
      address: vehicle.customerParams ? vehicle.customerParams.address.address : "",
    }
    const mixedMap: { [key in AllVehicleHeadColumns]: any } = [...VEHICLE_TABLE_HEAD_LIST].reduce((obj, key) => {
      return {
        ...obj,
        [key]: isInitView &&
          currentMap[key] !== initMap[key]
          && initMap[key] !== null
          ? `${say.old}: ${initMap[key] || say.undefined} - ${say.current}: ${currentMap[key]}`
          : currentMap[key]
      }
    }, {} as { [key in AllVehicleHeadColumns]: any })
    outputMapByVehicleId[vehicle._id] = mixedMap
  })
  return outputMapByVehicleId
}