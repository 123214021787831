import { SetAction } from "@/redux/model"

import { HomeState } from "./model"


export const home = (currentState: HomeState, action: SetAction<"home">) => {
  const data = action.data
  return {
    ...currentState,
    ...data
  }
}