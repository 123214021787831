import { OptionalToUndefined } from "@/shared"
import { getStorageItem } from "@/localStorage"

import { VehiclesState } from "../model"


export function getVehiclesInitState(): OptionalToUndefined<VehiclesState> {
  return {
    tableVehicleIdList: [],
    outputMapByVehicleId: {},
    vehicleMap: {},
    vehicleList: [],
    vehicleIdList: [],
    editedTableCell: undefined,
    editedStatus: undefined,
    editedComment: { html: "", plainText: "" },
    ...getStorageItem("vehicles")
  }
}
