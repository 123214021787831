import { Button, Fieldset, TextArea, ToggleList } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { AllBrokerageSchedules, BROKERAGE_SCHEDULE_LIST } from "@/shared"

import { incrementCreateBrokerageViewIndex } from "../functions/incrementCreateBrokerageViewIndex"

import { FormFooter } from "./FormFooter"

export function FirstForm(): JSX.Element {
  const {
    say,
    generalQuestionField,
    isInvalidField,
    scheduleType
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    generalQuestionField: state.createBrokerage.generalQuestionField,
    scheduleType: state.createBrokerage.scheduleType,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))

  return (
    <div>
      <Fieldset label={say.brokerageFirstQuestion} isRequired={true}>
        <TextArea
          isError={!generalQuestionField.plainText.trim() && isInvalidField}
          value={generalQuestionField.html}
          isUtilsDisabled={true}
          placeholder={say.brokerageFirstPlaceholder}
          onChange={(html, plainText) => setReduxState("createBrokerage", { generalQuestionField: { html, plainText } })}
        />
      </Fieldset>
      <Fieldset label={say.askSchedule} isRequired={true}>
        <ToggleList
          isError={isInvalidField && !scheduleType}
          optionList={[...BROKERAGE_SCHEDULE_LIST]}
          outputMap={(val: AllBrokerageSchedules) => say[val]}
          active={scheduleType}
          onChange={(val: AllBrokerageSchedules) => setReduxState("createBrokerage", { scheduleType: val })}
        />
      </Fieldset>
      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(false)}
        >
          {say.previous}
        </Button>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(true)}
        >
          {say.next}
        </Button>
      </FormFooter>
    </div >
  )
}
