import { AllStringFormats } from ".."

export function formatString(
  type: AllStringFormats,
  value: string,
): string {
  const numberString = value.split(/[^0-9]/).join("")
  if (type === "url") {
    value = value.split(" ").join("")
    if (value.startsWith("https://") || value.startsWith("http://")) {
      return value
    } else {
      return `https://${value}`
    }
  }
  if (type === "dashedDate") {
    const inputVal = value.split(/[^0-9]/).join("")
    const year = inputVal.slice(0, 4)
    const month = inputVal.slice(4, 6)
    const day = inputVal.slice(6, 8)
    if (day) {
      return `${year}-${month}-${day}`
    } else if (month) {
      return `${year}-${month}`
    } else {
      return year
    }
  }
  if (type === "nas") {
    const nasNum = value.split(/[^0-9]/).join("")
    if (nasNum.length !== 9) {
      return ""
    }
    const match = value.match(/^(\d{3})(\d{3})(\d{3})$/)

    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`
    }
    return ""
  }
  if (type === "phone") {
    const [phoneNum, ext] = value.split("#").map(val => val.replace(/\D/g, ""))
    if (!phoneNum) {
      return ""
    }
    const match = phoneNum.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}${ext ? ` #${ext}` : ""}`
    }

    return ""
  }
  if (type === "flat") {
    return value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim()
  }
  if (type === "percent" || type === "positivePercent") {
    const newVal = Number(numberString) > 100 ? "100" : numberString
    if (type === "percent" && value.startsWith("-")) {
      return `-${newVal}`
    }
    return newVal
  } else if (type === "positiveInteger" || type === "integer") {
    if (type === "integer" && value.startsWith("-")) {
      return `-${numberString}`
    }
    return numberString
  } else if (type === "positiveDecimalPercent" || type === "decimalPercent") {
    let decimalPercent = value.split(/[^0-9.]/).join("")
    decimalPercent = Number(decimalPercent) > 100 ? "100" : decimalPercent
    const decimalPointList = decimalPercent.split(".").length - 1
    if (decimalPointList > 1) {
      decimalPercent = decimalPercent.split(".").slice(0, 2).join(".")
    }
    if (type === "decimalPercent" && value.startsWith("-")) {
      return `-${decimalPercent}`
    } else {
      return decimalPercent
    }
  } else if (type === "positiveDecimal" || type === "decimal") {
    let decimal = value.split(/[^0-9.]/).join("")
    const decimalPointList = decimal.split(".").length - 1
    if (decimalPointList > 1) {
      decimal = decimal.split(".").slice(0, 2).join(".")
    }
    if (type === "decimal" && value.startsWith("-")) {
      return `-${decimal}`
    } else {
      return decimal
    }
  } else if (type === "price" || type === "positivePrice") {
    let decimal = value.split(/[^0-9.]/).join("")
    const decimalPointList = decimal.split(".").length - 1
    if (decimalPointList > 1) {
      decimal = decimal.split(".").slice(0, 2).join(".")
    } else if (decimalPointList === 1) {
      const [num, decimals] = decimal.split(".") as [string, string]
      decimal = `${num}.${decimals.slice(0, 2)}`
    }
    if (type === "price" && value.startsWith("-")) {
      return `-${decimal}`
    } else {
      return decimal
    }
  } else if (type === "trim") {
    return value.split(" ").join("")
  } else {
    return value
  }
}