import { AutoComplete, Button, Fieldset, Input, KeywordList } from "@/components"
import { simplifyString } from "@/functions"
import { getVehicleFieldClassName } from "@/pages/Vehicles/functions/getVehicleFieldClassName"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import * as shared from "@/shared"
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core"

import { incrementCreateVehicleViewIndex } from "../functions/incrementCreateVehicleViewIndex"

import { FormFooter } from "./FormFooter"

export function SpecsForm(): JSX.Element {
  const {
    say,
    distanceField,
    isKmDistanceUnit,
    condition,
    conditionField,
    isInvalidField,
    cityFuelConsumptionField,
    highwayFuelConsumptionField,
    fuelType,
    fuelTypeField,
    cylinderCountField,
    isManualTransmission,
    driveTrain,
    driveTrainField,
    firstPath,
    equipmentField,
    equipmentList
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    firstPath: state.routes.firstPath,
    imageList: state.createVehicle.imageList,
    distanceField: state.createVehicle.distanceField,
    isKmDistanceUnit: state.createVehicle.isKmDistanceUnit,
    condition: state.createVehicle.condition,
    conditionField: state.createVehicle.conditionField,
    cityFuelConsumptionField: state.createVehicle.cityFuelConsumptionField,
    highwayFuelConsumptionField: state.createVehicle.highwayFuelConsumptionField,
    fuelType: state.createVehicle.fuelType,
    fuelTypeField: state.createVehicle.fuelTypeField,
    cylinderCountField: state.createVehicle.cylinderCountField,
    isManualTransmission: state.createVehicle.isManualTransmission,
    driveTrain: state.createVehicle.driveTrain,
    driveTrainField: state.createVehicle.driveTrainField,
    equipmentField: state.createVehicle.equipmentField,
    equipmentList: state.createVehicle.equipmentList,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  return (
    <div>
      <div className="horizontal-box">
        <Fieldset className={getVehicleFieldClassName("condition")} label={say.selectCondition} isRequired={true}>
          <AutoComplete
            filter={(value: shared.AllVehicleConditions) => simplifyString(say[value]).includes(simplifyString(conditionField))}
            inputProps={{
              mobileFocusLabel: say.selectCondition,
              iconName: "clipboard-list-check",
              isError: isInvalidField && !condition,
              value: conditionField,
              placeholder: say[shared.VEHICLE_CONDITION_LIST[0]!],
              onChange: evt => setReduxState("createVehicle", { conditionField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: condition,
              optionList: shared.VEHICLE_CONDITION_LIST,
              outputMap: (value: shared.AllVehicleConditions) => say[value],
              onChange: (value: shared.AllVehicleConditions) => setReduxState("createVehicle", {
                condition: value,
                conditionField: say[value],
              })
            }}
          />
        </Fieldset>
        <Fieldset className={getVehicleFieldClassName("distance")} label={say.selectDistance} isRequired={true}>
          <Input
            mobileFocusLabel={say.selectDistance}
            iconName="tachometer-alt"
            isError={isInvalidField && !distanceField}
            value={distanceField}
            inputMode="numeric"
            placeholder="100000"
            onChange={evt => setReduxState("createVehicle", { distanceField: evt.target.value.split(/[^0-9]/).join("") })} />
        </Fieldset>
        <Fieldset className={getVehicleFieldClassName("distance")} label={say.selectDistanceType} isRequired={true}>
          <RadioGroup
            className="radio-group"
            value={isKmDistanceUnit ? "km" : "miles"}
            onChange={evt => setReduxState("createVehicle", { isKmDistanceUnit: evt.target.value === "km" ? true : false })}
          >
            <FormControlLabel value="km" control={<Radio color="primary" />} label={say.km} />
            <FormControlLabel value="miles" control={<Radio color="primary" />} label={say.miles} />
          </RadioGroup>
        </Fieldset>
      </div>
      <div className="horizontal-box">
        <Fieldset className={getVehicleFieldClassName("fuelType")} label={say.selectFuelType} isRequired={true}>
          <AutoComplete
            filter={(value: shared.AllVehicleFuelTypes) => simplifyString(say[value]).includes(simplifyString(fuelTypeField))}
            inputProps={{
              mobileFocusLabel: say.selectFuelType,
              iconName: "gas-pump",
              isError: isInvalidField && !fuelType,
              value: fuelTypeField,
              placeholder: say[shared.VEHICLE_FUEL_TYPE_LIST[0]!],
              onChange: evt => setReduxState("createVehicle", { fuelTypeField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: fuelType,
              optionList: shared.VEHICLE_FUEL_TYPE_LIST,
              sort: (
                val1: shared.AllVehicleFuelTypes,
                val2: shared.AllVehicleFuelTypes,
              ) => say[val1].localeCompare(say[val2]),
              outputMap: (value: shared.AllVehicleFuelTypes) => say[value],
              onChange: (value: shared.AllVehicleFuelTypes) => setReduxState("createVehicle", {
                fuelType: value,
                fuelTypeField: say[value],
              })
            }}
          />
        </Fieldset>
        {fuelType !== "electric" && firstPath !== "sell-vehicle" && (
          <>
            <Fieldset className={getVehicleFieldClassName("fuelType")} label={say.selectCityFuelConsumption}>
              <Input
                mobileFocusLabel={say.selectCityFuelConsumption}
                iconName="gas-pump"
                value={cityFuelConsumptionField}
                inputMode="decimal"
                placeholder="8.1"
                onChange={evt => setReduxState("createVehicle", { cityFuelConsumptionField: evt.target.value.split(/[^0-9.]/).join("") })} />
            </Fieldset>
            <Fieldset className={getVehicleFieldClassName("fuelType")} label={say.selectHighwayFuelConsumption}>
              <Input
                mobileFocusLabel={say.selectHighwayFuelConsumption}
                iconName="gas-pump"
                value={highwayFuelConsumptionField}
                inputMode="decimal"
                placeholder="7.8"
                onChange={evt => setReduxState("createVehicle", { highwayFuelConsumptionField: evt.target.value.split(/[^0-9.]/).join("") })} />
            </Fieldset>
          </>
        )}
      </div>
      {fuelType !== "electric" && firstPath !== "sell-vehicle" && (
        <div className="horizontal-box">
          <Fieldset className={getVehicleFieldClassName("fuelType")} label={say.selectCylinderCount} isRequired={true}>
            <Input
              mobileFocusLabel={say.selectCylinderCount}
              iconName="cogs"
              isError={isInvalidField && !cylinderCountField}
              value={cylinderCountField}
              inputMode="numeric"
              placeholder="4"
              onChange={evt => setReduxState("createVehicle", { cylinderCountField: evt.target.value.split(/[^0-9]/).join("") })} />
          </Fieldset>
          <Fieldset className={getVehicleFieldClassName("fuelType")} label={say.selectTransmission} isRequired={true}>
            <RadioGroup
              className="radio-group"
              value={isManualTransmission ? "manual" : "automatic"}
              onChange={evt => setReduxState("createVehicle", { isManualTransmission: evt.target.value === "manual" ? true : false })}
            >
              <FormControlLabel value="manual" control={<Radio color="primary" />} label={say.manual} />
              <FormControlLabel value="automatic" control={<Radio color="primary" />} label={say.automatic} />
            </RadioGroup>
          </Fieldset>
        </div>
      )}

      <Fieldset className={getVehicleFieldClassName("driveTrain")} label={say.selectDriveTrain} isRequired={true}>
        <AutoComplete
          filter={(value: shared.AllVehicleDriveTrains) => simplifyString(say[value]).includes(simplifyString(driveTrainField))}
          inputProps={{
            mobileFocusLabel: say.selectDriveTrain,
            iconName: "tire",
            isError: isInvalidField && !driveTrain,
            value: driveTrainField,
            placeholder: say[shared.VEHICLE_DRIVE_TRAIN_LIST[0]!],
            onChange: evt => setReduxState("createVehicle", { driveTrainField: evt.target.value })
          }}
          dropdownProps={{
            selectedValue: driveTrain,
            optionList: shared.VEHICLE_DRIVE_TRAIN_LIST,
            sort: (
              val1: shared.AllVehicleDriveTrains,
              val2: shared.AllVehicleDriveTrains,
            ) => say[val1].localeCompare(say[val2]),
            outputMap: (value: shared.AllVehicleDriveTrains) => say[value],
            onChange: (value: shared.AllVehicleDriveTrains) => setReduxState("createVehicle", {
              driveTrain: value,
              driveTrainField: say[value],
            })
          }}
        />
      </Fieldset>
      <div className="horizontal-box">
        <Fieldset className={getVehicleFieldClassName("equipments")} label={say.selectEquipments} isRequired={true}>
          <AutoComplete
            filter={(value: shared.AllVehicleEquipments) => simplifyString(say[value]).includes(simplifyString(equipmentField))}
            inputProps={{
              mobileFocusLabel: say.selectEquipments,
              iconName: "list",
              value: equipmentField,
              placeholder: say[shared.VEHICLE_EQUIPMENT_LIST[0]!],
              onChange: evt => setReduxState("createVehicle", { equipmentField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: "",
              optionList: shared.VEHICLE_EQUIPMENT_LIST,
              sort: (
                val1: shared.AllVehicleEquipments,
                val2: shared.AllVehicleEquipments,
              ) => say[val1].localeCompare(say[val2]),
              outputMap: (value: shared.AllVehicleEquipments) => say[value],
              onChange: (value: shared.AllVehicleEquipments) => value && setReduxState("createVehicle", {
                equipmentList: equipmentList.includes(value) ? equipmentList.filter(key => key !== value) : [...equipmentList, value],
                equipmentField: "",
              })
            }}
          />
        </Fieldset>
      </div>
      <div className={`horizontal-box ${getVehicleFieldClassName("equipments")}`}>
        <KeywordList
          optionList={[...equipmentList]}
          outputMap={(key: shared.AllVehicleEquipments) => say[key]}
          onChange={(newVal: shared.AllVehicleEquipments[]) => setReduxState("createVehicle", {
            equipmentList: newVal,
          })}
        />
      </div>
      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateVehicleViewIndex(false)}
        >
          {say.previous}
        </Button>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateVehicleViewIndex(true)}
        >
          {say.next}
        </Button>
      </FormFooter>
    </div >
  )
}
