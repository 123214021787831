import { BottomRightButton } from "./BottomRightButton"
import { VehiclesTable } from "./VehicleTable"
import { VehiclesFilters } from "./VehiclesFilters"


export function Vehicles(): JSX.Element {
  return (
    <div className="row">
      <VehiclesFilters />
      <VehiclesTable />
      <BottomRightButton />
    </div>
  )

}