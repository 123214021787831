import { useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"

import { Icon, SafeFixed } from ".."

import { LoadingStyled } from "./LoadingStyled"


// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")

export function Loading(): JSX.Element | null {
  const { isLoading } = useReduxState((state: ReduxState) => ({
    isLoading: state.ui.loadingCount > 0
  }))
  if (!isLoading) {
    return null
  }
  return (
    <SafeFixed>
      <LoadingStyled>
        <Fade up={true} duration={500}>
          <Icon name="spinner" />
        </Fade>
      </LoadingStyled>
    </SafeFixed>
  )
}
