import { IS_INVENTORY } from "@/constants"

import { HomeSlider } from "./HomeSlider"
import { HomeStyled } from "./HomeStyled"
import { HotCategories } from "./HotCategories"
import { OurServices } from "./OurServices"
import { Statistics } from "./Statistics"
import { Quotes } from "./Quotes"

export function Home(): JSX.Element {
  return (
    <HomeStyled>
      <HomeSlider />
      {IS_INVENTORY && (
        <HotCategories />
      )}
      <OurServices />
      <Statistics />
      <Quotes />
    </HomeStyled>
  )
}