import { listToMap } from "@/shared"
import { ReduxState, SetAction } from "@/redux/model"
import { setStorageItem } from "@/localStorage"

import { getOutputMapByUserId } from "./functions/getOutputMapByUserId"
import { getTableUserIdList } from "./functions/getTableUserIdList"
import { UsersState } from "./model"


export const users = (currentState: UsersState, action: SetAction<"users">, globalState?: ReduxState) => {
  const data = { ...action.data }
  if (data.currentUser) {
    setStorageItem("currentUser", data.currentUser)
  }
  if (data.userList && globalState) {
    if (!data.userList) {
      data.userList = currentState.userList
    }
    data.userMap = listToMap(data.userList)
    data.userIdList = data.userList.map(item => item._id)
    data.outputMapByUserId = getOutputMapByUserId(
      data.userList,
      globalState.languages.say,
    )
  }
  if (globalState) {
    data.tableUserIdList = getTableUserIdList(currentState, data, globalState)
  }

  return {
    ...currentState,
    ...data
  }
}