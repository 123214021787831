import { handleHttpRequest } from "@/api/functions"
import { setReduxState } from "@/redux"
import { FileFindOneResult } from "@/shared"
import axios, { CancelTokenSource } from "axios"

export function getOneFile(query: { _id: string, isThumbnail?: boolean }, callback: (file: FileFindOneResult) => void): CancelTokenSource["cancel"] {
  const tokenSource = axios.CancelToken.source()
  handleHttpRequest(
    "files",
    "getOne",
    {
      ...query,
      isLoadingHidden: true,
      cancelToken: tokenSource.token
    },
    file => {
      callback(file)
    }, err => {
      setReduxState("ui", {
        snackbar: {
          message: err,
          severity: "error"
        }
      })
    }
  ).catch(() => null)
  return tokenSource.cancel
}