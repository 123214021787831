import { Snackbar, Footer, Loading, ShowTermsWarning, Nav } from "@/components"
import { ReduxState } from "@/redux/model"
import { ThemeProvider } from "styled-components"
import { Switch } from "react-router-dom"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core"
import { muiTheme } from "@/ui/theme"
import * as pages from "@/pages"
import { ActivePopup } from "@/popups"
import { useReduxState } from "@/redux"

import { RoutesStyled } from "./RoutesStyled"
import { PublicRoute } from "./PublicRoute"
import { PrivateRoute } from "./PrivateRoute"
import { ScrollToTop } from "./ScrollToTop"
import { AnyRoute } from "./AnyRoute"
import { Redirect } from "./Redirect"

export function Routes(): JSX.Element {
  useReduxState((state: ReduxState) => ({
    themeColor: state.ui.themeColor,
    isDarkMode: state.ui.isDarkMode,
  }))

  return (
    <ThemeProvider theme={window.theme}>
      <MuiThemeProvider theme={muiTheme}>
        <RoutesStyled className="app-routes col">
          <Snackbar />
          <Loading />
          <ShowTermsWarning />
          <ActivePopup />
          <ScrollToTop />
          <Nav />
          <div className="page-box">
            <Switch>
              <AnyRoute isExact={true} path="/home" component={pages.Home} />
              <AnyRoute isExact={true} path="/about-us" component={pages.AboutUs} />
              <AnyRoute isExact={true} path="/not-found" component={pages.NotFound} />
              <AnyRoute isExact={true} path="/join-us" component={pages.JoinUs} />
              <AnyRoute isExact={true} path="/privacy" component={pages.Privacy} />
              <AnyRoute isExact={true} path="/terms" component={pages.Terms} />
              <AnyRoute path="/search" component={pages.Search} />
              <AnyRoute path="/vehicle" component={pages.Vehicle} />
              <AnyRoute path="/brokerage" component={pages.CreateBrokerage} />
              <PublicRoute isExact={true} path="/forgot-password" component={pages.ForgotPassword} />
              <PublicRoute isExact={true} path="/login" component={pages.Login} />
              <PrivateRoute isExact={true} path="/signin" component={pages.SignIn} />
              <PrivateRoute path="/bug-report" component={pages.BugReport} />
              <PrivateRoute isExact={true} path="/create-vehicle" component={pages.CreateVehicle} />
              <PrivateRoute isExact={true} path="/edit-vehicle" component={pages.CreateVehicle} />
              <AnyRoute isExact={true} path="/sell-vehicle" component={pages.CreateVehicle} />
              <PrivateRoute path="/dashboard" component={pages.Dashboard} />
              <Redirect isExact={true} from="/" to="/home" />
              <Redirect isExact={true} from="/*" to="/not-found" />
            </Switch>
          </div>
          <Footer />
        </RoutesStyled>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}
