import VisibilitySensor from "react-visibility-sensor"

import { TableCell } from "./TableCell"
import { TableRowProps } from "./model"

export function TableRow<ColId extends string | number>({
  colList,
  errorMap,
  colIdList,
  trueIndexList,
  adjustTableWidthRef,
  onDoubleClick
}: TableRowProps<ColId>): JSX.Element {
  return (
    <VisibilitySensor
      partialVisibility={true}
      intervalCheck={true}
      intervalDelay={30}
    >
      {({ isVisible }) => (
        isVisible ? (
          <div className="row shrink-0" ref={ref => (
            ref && adjustTableWidthRef.current.setOne(ref)
          )}>
            {colList.map((item, colIndex) => (!trueIndexList.length || trueIndexList.includes(colIndex)) ? (
              <TableCell
                isError={errorMap(colIdList[colIndex]!, colIndex, item)}
                key={colIdList[colIndex]}
                onDoubleClick={() => onDoubleClick && onDoubleClick(colIdList[colIndex]!)}
              >{item}</TableCell>
            ) : null)}
          </div>
        ) : (
          <div
            className="w-full h-10"
          />
        )
      )}
    </VisibilitySensor>
  )
}