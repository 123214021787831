import { getBrokeragesInitFilters } from "@/pages/Brokerages/functions/getBrokerageInitFilters"
import { getUsersInitFilters } from "@/pages/Users/functions/getUsersInitFilters"
import { getVehiclesInitFilters } from "@/pages/Vehicles/functions/getVehiclesInitFilters"
import { generateId, toAES } from "@/shared"


import { LocalStorageData } from "./model"

export function initStorage(): void {
  const storageId = generateId()
  const data: LocalStorageData = {
    selectedLanguage: undefined,
    sessionId: undefined,
    currentUser: undefined,
    deviceId: generateId(),
    isShowTermsWarning: true,
    apparence: { isAuto: false, isDark: true },
    vehicles: getVehiclesInitFilters(),
    brokerages: getBrokeragesInitFilters(),
    users: getUsersInitFilters()
  }
  const finalData = toAES(data, storageId)!
  window.localStorage.setItem("q", `${storageId}${finalData}`)
}