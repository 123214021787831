import { IS_DESKTOP } from "@/constants"
import { clickable, center } from "@/ui/mixins"
import styled, { css } from "styled-components"

import { NavItemStyledProps } from "./model"

export const NavItemStyled = styled.div<NavItemStyledProps>`
  ${clickable};
  ${center};
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 400;
  padding: 0 16px;
  text-transform: uppercase;
  height: 35px;
  border-radius: 9999px;
  @media screen and (min-width: 800px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 799px) {
    margin-bottom: 10px;
  }
  ${(props: NavItemStyledProps) => props.isActive && css`
    color: ${() => window.theme.secondary900};
  `}
  ${(props: NavItemStyledProps) => {
    if (props.isActive) {
      return ""
    }
    if (IS_DESKTOP) {
      return css`
        &:hover {
          background: ${() => window.theme.secondary900}22;
        }
        &:active {
          background: ${() => window.theme.secondary900}44;
        }
      `
    } else {
      return css`
        &:active {
          background: ${() => window.theme.secondary900}44;
        }
      `
    }
  }}
`