import { breakpoints, center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const FooterStyled = styled.div`
.top-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

   a, p,.email, .popup-button {
    ${center}
    line-height: 200%;
    font-weight: 200;
    padding: 0 10px;
    text-align: center !important;
    color: ${() => window.theme.grey300};
  }
  p {
    max-width: 75%;
    line-height: 150%;
  }
  a:hover, .popup-button:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .download {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    flex: 1;
    & > div {
      margin-left: 10px;
      margin-right: 10px;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .padding {
    width: 100%;
    height: 1px;
    background: ${() => window.theme.grey300};
  }
  .bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    margin-top: 10px;

    span {
      color: ${() => window.theme.grey300};
      flex: 1;
      font-weight: 200;
      margin-bottom: 15px;
    }
    .icons {
      display: flex;
    }
    .fa-icon {
      ${center}
      width: 40px;
      height: 40px;
      border-radius: 9999px;
      ${shadeEffect("secondary900", "background")}
      color: hsl(42,37%,20%);
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    svg {

      height: 20px;
    }
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    align-self: center;
    width: calc(100% - 40px);
    padding: 0 20px;
    .top-content {
      margin-bottom: initial;
    }
    .bottom-content {
      flex-direction: row;
      span {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (min-width: ${breakpoints.md}) {
    .top-content {
      margin: 20px 40px 20px 0;
      align-items: flex-start;
      justify-content: space-between;
      height: auto;
    }
    .bottom-content {
      border-top: 1px solid ${() => window.theme.grey300};
    }
    .top-box {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

    a, p, .email, .popup-button {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      line-height: 125% !important;
    }
    section {
      align-items: flex-start;
    }
    h3, a, p, .popup-button {
      text-align: left;
    }
    .download {
      flex-direction: row;
      flex: none;
      & > div {
      margin-left: 0;
      margin-right: 0;
      &:first-child {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
    }
    h3 {
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: calc(100% - 40px);
    max-width: 1140px;
    justify-self: center;
    margin: auto;
  }
  @media screen and (min-width: 1240px) {
    padding: 0 !important;
  }
`