import { setReduxState, useReduxState } from "@/redux"
import { AutoComplete, Fieldset, Icon, Input, KeywordList, Scrollbar, ToggleList } from "@/components"
import { simplifyString } from "@/functions"
import * as shared from "@/shared"
import moment from "moment"

import { SearchState } from "../model"
import { searchVehicles } from "../functions/searchVehicles"
import { getSearchInitState } from "../functions"

import { SearchFiltersStyled } from "./SearchFiltersStyled"

export function SearchFilters(): JSX.Element | null {
  const {
    say,
    brandField,
    brand,
    modelField,
    model,
    isInvalidField,
    minYearField,
    maxYearField,
    bodyStyleList,
    doorCountList,
    seatingCountList,
    equipmentList,
    isManualTransmission,
    fuelTypeList,
    driveTrainList,
    isFilters,
    minDistanceField,
    maxDistanceField,
    minPriceField,
    maxPriceField,
    equipmentField
  } = useReduxState(state => ({
    say: state.languages.say,
    brandField: state.search.brandField,
    brand: state.search.brand,
    modelField: state.search.modelField,
    model: state.search.model,
    minYearField: state.search.minYearField,
    maxYearField: state.search.maxYearField,
    bodyStyleList: state.search.bodyStyleList,
    fuelTypeList: state.search.fuelTypeList,
    doorCountList: state.search.doorCountList,
    minDistanceField: state.search.minDistanceField,
    maxDistanceField: state.search.maxDistanceField,
    minPriceField: state.search.minPriceField,
    maxPriceField: state.search.maxPriceField,
    seatingCountList: state.search.seatingCountList,
    equipmentList: state.search.equipmentList,
    driveTrainList: state.search.driveTrainList,
    isManualTransmission: state.search.isManualTransmission,
    equipmentField: state.search.equipmentField,
    isFilters: state.search.isFilters,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  const setState = (newState: Partial<SearchState>) => {
    setReduxState("search", newState, () => {
      searchVehicles()
    })
  }
  return (
    <SearchFiltersStyled className={isFilters ? "active" : ""}>
      <Icon name="trash-alt" onClick={() => setState({ ...getSearchInitState(), isFilters: true })}>{say.clearFilters}</Icon>
      <Icon name="times" onClick={() => setReduxState("search", { isFilters: false })} />
      <Scrollbar>
        <div className="horizontal-box">
          <Fieldset label={say.minPrice}>
            <Input
              mobileFocusLabel={say.minPrice}
              value={minPriceField}
              inputMode="numeric"
              placeholder="2 000 $"
              onBlur={() => setState({})}
              onChange={evt => setReduxState("search", { minPriceField: evt.target.value.split(/[^0-9]/).join("") })}
            />
          </Fieldset>
          <Fieldset label={say.maxPrice}>
            <Input
              mobileFocusLabel={say.maxPrice}
              value={maxPriceField}
              inputMode="numeric"
              placeholder="5 000 $"
              onBlur={() => setState({})}
              onChange={evt => setReduxState("search", { maxPriceField: evt.target.value.split(/[^0-9]/).join("") })}
            />
          </Fieldset>
        </div>
        <div className="horizontal-box">
          <Fieldset label={say.minYear}>
            <Input
              mobileFocusLabel={say.minYear}
              value={minYearField}
              inputMode="numeric"
              placeholder={moment().subtract(5, "years").format("YYYY")}
              onBlur={() => setState({})}
              onChange={evt => setReduxState("search", { minYearField: evt.target.value.split(/[^0-9]/).join("").slice(0, 4) })}
            />
          </Fieldset>
          <Fieldset label={say.maxYear}>
            <Input
              mobileFocusLabel={say.maxYear}
              value={maxYearField}
              inputMode="numeric"
              placeholder={moment().subtract(2, "years").format("YYYY")}
              onBlur={() => setState({})}
              onChange={evt => setReduxState("search", { maxYearField: evt.target.value.split(/[^0-9]/).join("").slice(0, 4) })}
            />
          </Fieldset>
        </div>
        <div className="horizontal-box">
          <Fieldset label={say.minDistance}>
            <Input
              mobileFocusLabel={say.minDistance}
              value={minDistanceField}
              inputMode="numeric"
              placeholder="20 000 km"
              onBlur={() => setState({})}
              onChange={evt => setReduxState("search", { minDistanceField: evt.target.value.split(/[^0-9]/).join("") })}
            />
          </Fieldset>
          <Fieldset label={say.maxDistance}>
            <Input
              mobileFocusLabel={say.maxDistance}
              value={maxDistanceField}
              inputMode="numeric"
              placeholder="100 000 km"
              onBlur={() => setState({})}
              onChange={evt => setReduxState("search", { maxDistanceField: evt.target.value.split(/[^0-9]/).join("") })}
            />
          </Fieldset>
        </div>
        <Fieldset label={say.selectEquipments}>
          <AutoComplete
            filter={(value: shared.AllVehicleEquipments) => simplifyString(say[value]).includes(simplifyString(equipmentField))}
            inputProps={{
              mobileFocusLabel: say.selectEquipments,
              isError: isInvalidField && !equipmentList.length,
              value: equipmentField,
              placeholder: say[shared.VEHICLE_EQUIPMENT_LIST[0]!],
              onChange: evt => setState({ equipmentField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: "",
              optionList: shared.VEHICLE_EQUIPMENT_LIST,
              sort: (
                val1: shared.AllVehicleEquipments,
                val2: shared.AllVehicleEquipments,
              ) => say[val1].localeCompare(say[val2]),
              outputMap: (value: shared.AllVehicleEquipments) => say[value],
              onChange: (value: shared.AllVehicleEquipments) => value && setState({
                equipmentList: equipmentList.includes(value) ? equipmentList.filter(key => key !== value) : [...equipmentList, value],
                equipmentField: "",
              })
            }}
          />
        </Fieldset>
        <KeywordList
          optionList={[...equipmentList]}
          outputMap={(key: shared.AllVehicleEquipments) => say[key]}
          onChange={(newVal: shared.AllVehicleEquipments[]) => setState({
            equipmentList: newVal,
          })}
        />
        <Fieldset label={say.brand}>
          <AutoComplete
            filter={(value: shared.AllVehicleBrands) => simplifyString(say[value]).includes(simplifyString(brandField))}
            inputProps={{
              mobileFocusLabel: say.brand,
              isError: isInvalidField && !brandField,
              value: brandField,
              placeholder: say[shared.VEHICLE_BRAND_LIST[0]!],
              onChange: evt => setReduxState("search", { brandField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: brand,
              optionList: shared.VEHICLE_BRAND_LIST,
              sort: (
                val1: shared.AllVehicleBrands,
                val2: shared.AllVehicleBrands,
              ) => val1 === "other" ? -1 : val2 === "other" ? 1 : say[val1].localeCompare(say[val2]),
              outputMap: (value: shared.AllVehicleBrands) => say[value],
              onChange: (value: shared.AllVehicleBrands) => value === brand ? setState({
                brand: value,
                brandField: say[value],
              }) : setState({
                brand: value,
                brandField: say[value],
                model: undefined,
                modelField: ""
              })
            }}
          />
        </Fieldset>
        {brand && (
          <Fieldset label={say.model}>
            <AutoComplete
              filter={value => simplifyString(value).includes(simplifyString(modelField))}
              inputProps={{
                mobileFocusLabel: say.model,
                isError: isInvalidField && !modelField,
                value: modelField,
                placeholder: shared.VEHICLE_MODEL_LIST_BY_VEHICLE_BRAND[brand][0],
                onChange: evt => setReduxState("search", { modelField: evt.target.value })
              }}
              dropdownProps={{
                selectedValue: model,
                sort: (
                  val1: string,
                  val2: string,
                ) => shared.VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][val1]!.localeCompare(shared.VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][val2]!),
                optionList: shared.VEHICLE_MODEL_LIST_BY_VEHICLE_BRAND[brand!] || [],
                outputMap: (value: string) => shared.VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][value],
                onChange: (value: string) => (
                  setState({
                    model: value,
                    modelField: shared.VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][value]
                  })
                )
              }}
            />
          </Fieldset>
        )}
        <Fieldset label={say.transmission}>
          <ToggleList
            optionList={["manual", "automatic"]}
            outputMap={(val: "manual" | "automatic") => say[val]}
            active={isManualTransmission === true ? "manual" : isManualTransmission === false ? "automatic" : undefined}
            onChange={(val: "manual" | "automatic") => setState({
              isManualTransmission: val === "manual" ? true : val === "automatic" ? false : undefined
            })}
          />
        </Fieldset>
        {/* <Fieldset label={say.condition}>
          <ToggleList
          mobileFocusLabel={say.condition}
            optionList={[...shared.VEHICLE_CONDITION_LIST]}
            outputMap={(val: shared.AllVehicleConditions) => say[val]}
            activeList={conditionList}
            onChange={(val: shared.AllVehicleConditions[]) => setState({ conditionList: val })}
          />
        </Fieldset> */}
        <Fieldset label={say.bodyStyle}>
          <ToggleList
            optionList={[...shared.VEHICLE_BODY_STYLE_LIST]}
            outputMap={(val: shared.AllVehicleBodyStyles) => (
              <>
                {say[val]}<img alt={val} src={"./images/" + val + ".svg"} />
              </>
            )}
            activeList={bodyStyleList}
            onChange={(val: shared.AllVehicleBodyStyles[]) => setState({ bodyStyleList: val })}
          />
        </Fieldset>
        <Fieldset label={say.fuelType}>
          <ToggleList
            optionList={[...shared.VEHICLE_FUEL_TYPE_LIST]}
            outputMap={(val: shared.AllVehicleFuelTypes) => say[val]}
            activeList={fuelTypeList}
            onChange={(val: shared.AllVehicleFuelTypes[]) => setState({ fuelTypeList: val })}
          />
        </Fieldset>
        <Fieldset label={say.doors}>
          <ToggleList
            optionList={[2, 4, 5]}
            outputMap={(val: number) => `${val} ${say.doorsShort}`}
            activeList={doorCountList}
            onChange={(val: number[]) => setState({ doorCountList: val })}
          />
        </Fieldset>
        <Fieldset label={say.seatingCount}>
          <ToggleList
            optionList={[2, 4, 5, 6, 7, 8, 9]}
            outputMap={(val: number) => `${val} ${say.seats}`}
            activeList={seatingCountList}
            onChange={(val: number[]) => setState({ seatingCountList: val })}
          />
        </Fieldset>
        <Fieldset label={say.driveTrain}>
          <ToggleList
            optionList={[...shared.VEHICLE_DRIVE_TRAIN_LIST]}
            outputMap={(val: shared.AllVehicleDriveTrains) => say[val]}
            activeList={driveTrainList}
            onChange={(val: shared.AllVehicleDriveTrains[]) => setState({ driveTrainList: val })}
          />
        </Fieldset>
      </Scrollbar>
    </SearchFiltersStyled>
  )
}