import { AllSeverityColors } from "@/shared"
import { IS_ANY_IOS, IS_CORDOVA } from "@/constants"
import { tw } from "@/functions"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { getNotch } from "@/ui/mixins"

import { Button, Icon } from ".."
import { Class } from "../Class"
import { SafeFixed } from "../SafeFixed"

// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
let timeoutId = -1
export function Snackbar(): JSX.Element | null {
  const { snackbar, say } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    snackbar: state.ui.snackbar
  }))
  if (!snackbar) {
    return null
  }
  const { severity, message, after } = snackbar
  const iconMap: { [color in AllSeverityColors]: string } = {
    success: "check-circle",
    warning: "exclamation-triangle",
    error: "exclamation-circle",
    info: "info-circle"
  }
  const bgMap: { [color in AllSeverityColors]: string } = {
    success: tw`border-success-300`,
    warning: tw`border-warning-300`,
    error: tw`border-error-300`,
    info: tw`border-info-300`,
  }
  const iconColorMap: { [color in AllSeverityColors]: string } = {
    success: tw`!text-success-300`,
    warning: tw`!text-warning-300`,
    error: tw`!text-error-300`,
    info: tw`!text-info-300`,
  }
  return (
    <Class
      props={{ snackbar }}
      didMount={() => {
        timeoutId = window.setTimeout(() => setReduxState("ui", { snackbar: undefined }), snackbar.severity === "error" ? 3000 : 3000)
      }}
      willUnmout={() => {
        window.clearTimeout(timeoutId)
      }}
      didUpdate={oldProps => {
        if (oldProps.snackbar && snackbar && oldProps.snackbar._id !== snackbar._id) {
          setReduxState("ui", { snackbar: undefined }, () => {
            setReduxState("ui", { snackbar })
          })
        }
      }}
    >
      <SafeFixed>
        <div
          className={tw`
          snackbar
          fixed
          w-full
          flex
          justify-center
          z-[1000000000]
          pointer-events-none touch-none
          ${IS_CORDOVA ? "top-5" : "bottom-5"}
          `}
          style={{
            marginTop: IS_CORDOVA && IS_ANY_IOS && IS_CORDOVA ? getNotch("top") : undefined,
          }}
        >
          <Fade duration={200} top={!!IS_CORDOVA} bottom={!IS_CORDOVA} >
            <div
              className={tw`${bgMap[severity]} pointer-events-auto touch-auto  relative bg-grey-900 p-4 flex rounded-lg shadow-lg border-solid text-grey-500 border font-light max-w-screen`}
            >
              <Icon name={iconMap[severity]} className={tw`${iconColorMap[severity]} h-5 mr-3 text-parent`} />
              {`${say[message] || message}${after ? `: ${after}` : ""}`}
              <Button
                onClick={() => setReduxState("ui", { snackbar: undefined })}
                isCircle={true}
                size="xs"
                shade={300}
                color="error"
                variant="outlined"
                iconName="times"
                className="!absolute -top-2 -right-2 !bg-grey-850"
              />
            </div>
          </Fade>
        </div>
      </SafeFixed>
    </Class>
  )
}