import { Button } from ".."

import { TabItemProps } from "./model"

export function TabItem({ value, onChange }: TabItemProps): JSX.Element {
  return (
    <Button
      shade={800}
      color="grey"
      className="!w-max flex-1 rounded-none"
      onClick={() => onChange()}
    >
      {value}
    </Button>
  )
}