import { center } from "@/ui/mixins"
import styled, { css } from "styled-components"

import { VehicleSpecPropsStyled } from "../model"

export const VehicleSpecStyled = styled.div<VehicleSpecPropsStyled>`
  ${(props: VehicleSpecPropsStyled) => props.styleOverride ? props.styleOverride : css`
    ${center}
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 160px;
    background: ${() => window.theme.grey850};
    padding: 20px;
    border-radius: 20px;

    svg {
      color: ${() => window.theme.secondary900};
      height: 40px;
      margin-bottom: 20px;
    }
    h4 {
      text-align: center;
      color: ${() => window.theme.grey300};
      font-weight: 500;
      margin-bottom: 10px;

    }
    span {
      text-align: center;
      color: ${() => window.theme.grey300};
      line-height: 125%;
      font-weight: 200;
    }
  `}
`