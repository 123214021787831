import { tw } from "@/shared"

import { useReduxState } from "@/redux"

export const Palestine = () => {
  const say = useReduxState(state => state.languages.say)
  const lineTw = tw`w-full h-1/3`
  return (
    <div
      className="top-0 z-[1000] fixed bg-no-repeat center !h-[30px] w-full"
    >
      <div className="full-absolute col">
        <div className={tw`${lineTw} bg-[#000000]`} />
        <div className={tw`${lineTw} bg-[#fff]`} />
        <div className={tw`${lineTw} bg-[#009639]`} />
      </div>
      <svg
        className="absolute left-0 h-[30px]"
        viewBox="0 0 6 3"
      >
        <path fill="#009639" d="M0 0H6V3H0z" />
        <path fill="#FFF" d="M0 0H6V2H0z" />
        <path d="M0 0H6V1H0z" />
        <path fill="#ED2E38" d="M0 0l2 1.5L0 3z" />
      </svg>
      <div
        className="h-5 px-[9px] text-sm font-[400] z-[5] in-b:text-success-700 in-b:ml-1 in-b:font-bold  bg-white rounded-full shadow-md text-grey-900 center w-max"
        dangerouslySetInnerHTML={{ __html: say.weSupportPalestine }}
      />
    </div>
  )
}