export const PHONES_WITH_NOTCH = [
  "iPhone10,3",
  "iPhone10,6",
  "iPhone11,8	",
  "iPhone11,2",
  "iPhone11,6",
  "iPhone11,4",
  "iPhone12,1",
  "iPhone12,3",
  "iPhone12,5",
  "iPhone13,1",
  "iPhone13,2",
  "iPhone13,3",
  "iPhone13,4"
] as const