
import { CreateBrokerageState } from "../model"

export function getCreateBrokerageInitState(): CreateBrokerageState {
  return {
    generalQuestionField: { html: "", plainText: "" },
    bodyStyle: undefined,
    bodyStyleField: "",
    scheduleType: undefined,
    price: undefined,
    brandField: "",
    brandList: [],
    otherBrand: "",
    fuelTypeList: [],
    driveTrainList: [],
    viewIndex: 0,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: undefined,
  }
}
