import { SayKeywordStrings } from "@/shared"
import { Button, Fieldset, Select, TextArea } from "@/components"
import { getReduxState, setReduxState, useReduxState } from "@/redux"
import { deleteOneBrokerage, updateOneBrokerage } from "@/api/requests"

import { LeftButtonListProps } from "../model"

export function LeftButtonList({ tableBrokerageIdList }: LeftButtonListProps): JSX.Element {
  const { say, brokerageMap, isFilters } = useReduxState(state => ({
    say: state.languages.say,
    brokerageMap: state.brokerages.brokerageMap,
    isFilters: state.ui.isFilters,
  }))
  return (
    <div
      className={`sticky  mt-[140px] col ${isFilters ? "left-[400px]" : "left-0"}`}
    >
      <div className="w-10 h-12 border-b border-solid bg-grey-850 border-grey-800" />
      {tableBrokerageIdList.map(_id => {
        const optionList: SayKeywordStrings[] = ["delete"]
        const brokerage = brokerageMap[_id]!
        optionList.unshift("details", "comment")
        return (
          <Select
            key={_id}
            isStyleDisabled={true}
            dropdownProps={{
              optionList,
              outputMap: (key: typeof optionList[number]) => say[key],
              onChange: (key: typeof optionList[number]) => {
                if (key === "comment") {

                  setReduxState({
                    brokerages: {
                      editedComment: brokerage.comment || { html: "", plainText: "" },
                    },
                    popups: {
                      name: "SafeAction",
                      props: {
                        title: say.comment,
                        buttonName: "save",
                        action: () => updateOneBrokerage(_id, getReduxState().brokerages.editedComment),
                        Component: () => {
                          const editedComment = useReduxState(state => state.brokerages.editedComment)
                          return (
                            <Fieldset label={say.comment}>
                              <TextArea
                                value={editedComment.html}
                                onChange={(html, plainText) => setReduxState("brokerages", { editedComment: { html, plainText } })}
                              />
                            </Fieldset>
                          )
                        }
                      }
                    }
                  })
                } else if (key === "delete") {
                  setReduxState("popups", {
                    name: "SafeAction",
                    props: {
                      title: say.askDeleteBrokerage(`${brokerage.firstName} ${brokerage.lastName}`),
                      action: () => deleteOneBrokerage(brokerage._id)
                    }
                  })
                }
              }
            }}
          >
            <Button
              iconName="chevron-right"
              size="xs"
              isCircle={true}
              color="success"
              className="w-10 h-10 border-b border-solid rounded-none border-success-300"
            />
          </Select>
        )
      })}
    </div>
  )
}
