import { handleSocketRequest } from "@/api/functions"
import { getReduxState, setReduxState } from "@/redux"

export function deleteOneVehicle(_id: string): void {
  handleSocketRequest(
    "vehicles",
    "deleteOne",
    {
      _id,
    }, () => {
      const { vehicleList } = getReduxState().vehicles
      const newVehicleList = vehicleList.filter(vehicle => vehicle._id !== _id)
      setReduxState("vehicles", { vehicleList: newVehicleList })
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    }
  )
}