import { ReduxState } from "@/redux/model"
import { setReduxState, useReduxState } from "@/redux"

import { Button } from ".."

import { ShowTermsWarningStyled } from "./ShowTermsWarningStyled"

export function ShowTermsWarning(): JSX.Element | null {
  const { say, isShowTermsWarning } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    isShowTermsWarning: state.ui.isShowTermsWarning,
  }))
  if (!isShowTermsWarning) {
    return null
  }
  return (
    <ShowTermsWarningStyled>
      <h4 dangerouslySetInnerHTML={{ __html: say.applyPolicies }} />
      <Button
        className="ml-5"
        variant="text"
        onClick={() => setReduxState("ui", { isShowTermsWarning: false })}
      >{say.ok}</Button>
    </ShowTermsWarningStyled>
  )
}