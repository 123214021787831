import { breakpoints, center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const SearchStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 100px;
  min-height: calc(100vh - 60px);

  .fal-sliders-h {
    ${center};
    height: 40px;
    padding: 0 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: white;
    ${() => shadeEffect("secondary900", "background")}
    box-shadow: 0 5px 10px black;
    svg {
      height: 20px;
      margin-right: 15px;
    }
  }
  .top-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .grid-box {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-items: center;
    width: calc(100% - 20px);
    max-width: 1140px;
    padding-bottom: 40px;
  }
  .scrollbar-box {
    .inner-scroll-box {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: ${breakpoints.md};
    }
    & > div:first-child {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    .grid-box {
      width: calc(100% - 80px);
      grid-template-columns: repeat(2, calc(${100 / 2}% - 5px)) !important;
    }

  }
  @media screen and (min-width: ${breakpoints.md}) {
    .grid-box {
      grid-template-columns: repeat(3, calc(${100 / 3}% - ${20 / 3}px)) !important;
    }

  }
  @media screen and (min-width: ${breakpoints.lg}) {
    .grid-box {
      grid-template-columns: repeat(4, calc(${100 / 4}% - ${30 / 4}px)) !important;
    }
  }
`