import { Slider } from "@/components"
import { useReduxState, setReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")

export function HomeSlider(): JSX.Element {
  const { say, index, vh } = useReduxState(state => ({
    say: state.languages.say,
    index: state.home.index,
    vh: state.ui.vh && state.ui.vw && state.ui.vh > state.ui.vw ? state.ui.vh : undefined
  }))
  const style = vh ? { height: `${vh}px` } : undefined
  return (
    <Slider
      index={index}
      onChangeIndex={newIndex => setReduxState("home", { index: newIndex })}
      delay={5200}
    >
      <div style={style} className="slider-item landscape:!h-[700px]">
        <div className="text-box text-box-1">
          {index === 0 && (
            <Fade left={true} delay={500}>
              <h1 className="text-grey-900">{say.headerTitle1}</h1>
              <h2 className="text-secondary-900">{say.headerSubTitle1}</h2>
            </Fade>
          )}
          {index === 0 && (
            <Fade bottom={true} delay={500}>
              <div className="header-button" onClick={() => goToExactPath("/brokerage")}>
                {say.seeMore}
              </div>
            </Fade>
          )}
        </div>
        <img alt="lambo" src="./images/header2.jpg" className="img-1" />
      </div>
      <div style={style} className="slider-item landscape:!h-[700px]">
        <div className="text-box">
          {index === 1 && (
            <Fade left={true} delay={500}>
              <h1 className="text-white ">{say.headerTitle2}</h1>
              <h2 className="text-secondary-900">{say.headerSubTitle2}</h2>
            </Fade>
          )}
          {index === 1 && (
            <Fade bottom={true} delay={500}>
              <div className="header-button" onClick={() => goToExactPath("/sell-vehicle")}>
                {say.seeMore}
              </div>
            </Fade>
          )}
        </div>
        <img alt="bugatti" src="./images/header3.jpg" />
      </div>

      <div style={style} className="slider-item landscape:!h-[700px]">
        <div className="text-box">
          {index === 2 && (
            <Fade left={true} delay={500}>
              <h1 className="text-secondary-900">{say.headerTitle3}</h1>
              <h2 className="text-white">{say.headerSubTitle3}</h2>
            </Fade>
          )}
          {index === 2 && (
            <Fade bottom={true} delay={500}>
              <div className="header-button" onClick={() => goToExactPath("/brokerage")}>
                {say.seeMore}
              </div>
            </Fade>
          )}

        </div>
        <img alt="bmw" src="./images/header1.jpg" />
      </div>
    </Slider>
  )
}