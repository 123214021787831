import { CheckboxListValueMap } from "./model"

export function getOptionsAndValues<Value>(data: CheckboxListValueMap<Value>): {
  optionList: Value[] | readonly Value[]
  valueList: Value[]
} {
  if (typeof data === "object" && !(data instanceof Array)) {
    return {
      optionList: Object.keys(data!),
      valueList: Object.keys(data!).filter(val => !!(data as any)[val])
    } as any
  } else {
    throw new Error()
  }
}