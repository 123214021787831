import { AllVehicleBrands } from "."

export const VEHICLE_MODEL_LIST_BY_VEHICLE_BRAND: {
  [brand in AllVehicleBrands]: string[]
} = {
  other: ["other"],
  "acura": ["clModels", "22cl", "23cl", "30cl", "32cl", "ilx", "integ", "legend", "mdx", "nsx", "rdx", "rlModels", "35rl", "rl", "rsx", "slx", "tlModels", "25tl", "32tl", "tl", "tsx", "vigor", "zdx", "acuoth"],
  "alfa": ["alfa164", "alfa8c", "alfagt", "mil", "spid", "alfaoth"],
  // "amc": ["amcallian", "con", "eagle", "amcenc", "amcspirit", "amcoth"],
  "aston": ["db7", "db9", "dbs", "lagonda", "rapide", "v12vant", "vantage", "vanquish", "virage", "unavailast"],
  "audi": ["audi100", "audi200", "4000", "5000", "80", "90", "a3", "a4", "a5", "a6", "a7", "a8", "allrdqua", "audicabri", "audicoupe", "q3", "q5", "q7", "quattr", "r8", "rs4", "rs5", "rs6", "s4", "s5", "s6", "s7", "s8", "tt", "ttrs", "tts", "v8", "audoth"],
  // "avanti": ["convert", "coupeavant", "sedan", "unavailava"],
  "bentl": ["arnage", "azure", "brooklands", "bencont", "corniche", "beneight", "benmul", "benturbo", "unavailben"],
  "bmw": ["1Series", "128i", "135i", "135is", "3Series", "318i", "318ic", "318is", "318ti", "320i", "323ci", "323i", "323is", "323it", "325ci", "325e", "325es", "325i", "325is", "325ix", "325xi", "328ci", "328i", "328is", "328xi", "330ci", "330i", "330xi", "335d", "335i", "335is", "335xi", "active3", "bmw325", "5Series", "524td", "525i", "525xi", "528e", "528i", "528it", "528xi", "530i", "530it", "530xi", "533i", "535i", "535igt", "535xi", "540i", "545i", "550i", "550igt", "active5", "6Series", "633csi", "635csi", "640i", "640igc", "645ci", "650i", "650igc", "l6", "7Series", "733i", "735i", "735il", "740i", "740il", "740li", "745i", "745li", "750i", "750il", "750li", "760i", "760li", "active7", "alpinab7", "8Series", "840ci", "850ci", "850csi", "850i", "lSeries", "l7", "mSeries", "1seriesm", "bmwmcoupe", "bmwroad", "m3", "m5", "m6", "x5m", "x6m", "xSeries", "activex6", "x1", "x3", "x5", "x6", "zSeries", "z3", "z4", "z8", "bmwoth"],
  "buick": ["cent", "elec", "enclave", "buienc", "lacrosse", "lesa", "lucerne", "park", "rainier", "reatta", "reg", "rendezvous", "riv", "buickroad", "skyh", "skyl", "somer", "terraza", "buverano", "buoth"],
  "cad": ["allant", "ats", "brough", "catera", "cima", "cts", "dev", "dts", "eldo", "escala", "escalaesv", "ext", "flee", "sev", "srx", "sts", "xlr", "xts", "cadoth"],
  "chev": ["astro", "avalnch", "aveo", "aveo5", "berett", "blazer", "cam", "cap", "checaps", "cav", "cele", "chevette", "citation", "cobalt", "colorado", "corsi", "corv", "cruze", "elcam", "equinox", "g15exp", "g10", "hhr", "chevimp", "kodc4500", "lumina", "lapv", "luv", "mali", "chvmetr", "chevmont", "nova", "chevprizm", "chvst", "s10pickup", "chev150", "chevc25", "ch3500pu", "sonic", "spark", "chevspec", "chsprint", "ssr", "chevsub", "tahoe", "tracke", "trailblz", "trailbzext", "traverse", "uplander", "ventur", "volt", "cheoth"],
  "chry": ["chrys200", "300", "chry300", "aspen", "caravan", "cirrus", "conc", "chryconq", "cordoba", "crossfire", "eclass", "fifth", "chrygrandv", "impe", "intrepid", "chrylas", "leba", "lhs", "chryneon", "ny", "newport", "pacifica", "chprowle", "ptcruis", "chryseb", "chrytc", "tandc", "voyager", "choth"],
  // "daew": ["lanos", "leganza", "nubira", "daeoth"],
  // "daihat": ["char", "rocky", "daihoth"],
  // "datsun": ["dat200sx", "dat210", "280z", "300zx", "310", "510", "720", "810", "datmax", "datpu", "pul", "datsent", "stan", "datoth"],
  // "delorean": ["dmc12"],
  "dodge": ["400", "dod600", "ari", "avengr", "caliber", "dodcara", "challenger", "dodchar", "dodcolt", "dodconq", "doddw", "dakota", "doddart", "day", "diploma", "durang", "doddyna", "grandcarav", "intre", "journey", "lancerdodg", "magnum", "mirada", "monaco", "dodneon", "nitro", "omni", "raider", "ram1504wd", "ram25002wd", "ram3502wd", "ram4500", "dodd50", "cv", "ramsrt10", "ramvanv8", "ramwagon", "ramcgr", "rampage", "dodshad", "dodspir", "sprinter", "srt4", "stregis", "steal", "stratu", "viper", "dooth"],
  // "eagle": ["eaglemed", "eagleprem", "summit", "talon", "vision", "eagoth"],
  "fer": ["308gtb", "308tbi", "308gts", "308tsi", "328gtb", "328gts", "348gtb", "348gts", "348spi", "348tb", "348ts", "360", "456gt", "456mgt", "458italia", "512bbi", "512m", "512tr", "550m", "575m", "599gtb", "599gto", "612scaglie", "fercalif", "enzo", "f355", "f40", "f430", "f50", "ferff", "mond", "test", "unavailfer"],
  "fiat": ["2000", "fiat500", "berton", "brava", "pinin", "strada", "fiatx19", "unavailfia"],
  // "fisk": ["karma"],
  "ford": ["aero", "aspire", "bron", "b2", "focmax", "fordclub", "contour", "courier", "crownvic", "e150econ", "e250econ", "e350econ", "edge", "escape", "esco", "excursion", "exp", "expedi", "expediel", "explor", "sporttrac", "f100", "f150pickup", "f250", "f350", "f450", "fairm", "festiv", "fiesta", "fivehundre", "flex", "focus", "freestar", "freestyle", "fusion", "granada", "gt", "ltd", "must", "probe", "ranger", "taurus", "taurusx", "tempo", "tbird", "transconn", "windst", "fordzx2", "footh"],
  // "freight": ["fresprint"],
  // "geoBrand": ["geometro", "geoprizm", "spect", "storm", "geotrack", "geooth"],
  "gmc": ["acadia", "caballero", "canyon", "envoy", "envoyxl", "envoyxuv", "jim", "rallywag", "gmcs15", "s15", "safarigmc", "gmcsavana",
    "15sipu4wd", "gmcc25pu", "gmc3500pu", "sonoma", "sub", "gmcsyclon", "terrain", "topc4500", "typh", "gmcvandur", "yukon", "yukonxl", "gmcoth"],
  "honda": ["accord", "civic", "crv", "crz", "crx", "crosstourModels", "crosstour", "honcross", "hondelsol", "element", "fit", "insight", "odyssey", "passpo", "pilot", "pre", "ridgeline", "s2000", "honoth"],
  "amgen": ["hummer", "h2", "h3", "h3t", "amgoth"],
  "hyund": ["accent", "azera", "elantr", "hyuelancpe", "elantour", "entourage", "equus", "hyuexcel", "genesis", "genesiscpe", "santafe", "scoupe", "sonata", "tiburo", "tucson", "veloster", "veracruz", "xg300", "xg350", "hyuoth"],
  "infin": ["exModels", "ex35", "ex37", "fxModels", "fx35", "fx37", "fx45", "fx50", "gModels", "g20", "g25", "g35", "g37", "iModels", "i30", "i35", "jModels", "j30", "jxModels", "jx35", "mModels", "m30", "m35", "m35hybrid", "m37", "m45", "m56", "qModels", "q45", "qxModels", "qx4", "qx56", "infoth"],
  // "isu": ["amigo", "ascender", "axiom", "hombre", "i280", "i290", "i350", "i370", "isumark", "isuimp", "oasis", "isupu", "rodeo", "stylus", "troop", "trp2", "vehicross", "isuoth"],
  "jag": ["stype", "xtype", "xf", "xjSeries", "jagxj12", "jagxj6", "jagxjr", "jagxjrs", "jagxjs", "vanden", "xj",
    "xj8", "xj8l", "xjsport", "xkSeries", "jagxk8", "xk", "xkr", "jagoth"],
  "jeep": ["cher", "jeepcj", "comanc", "commander", "compass", "jeepgrand", "grwag", "liberty", "patriot", "jeeppu", "scramble", "wagone", "wrangler", "jeoth"],
  "kia": ["amanti", "borrego", "forte", "fortekoup", "optima", "rio", "rio5", "rondo", "sedona", "sephia", "sorento", "soul", "spectra", "spectra5", "sporta", "kiaoth"],
  "lam": ["avent", "count", "diablo", "gallardo", "jalpa", "lm002", "murcielago", "unavaillam"],
  // "lan": ["beta", "zagato", "unavaillan"],
  "rov": ["defend", "discov", "frelndr", "lr2", "lr3", "lr4", "range", "evoque", "rangesport", "rovoth"],
  "lexus": ["ctModels", "ct200h", "esModels", "es250", "es300", "es300h", "es330", "es350", "gsModels", "gs300", "gs350", "gs400", "gs430", "gs450h", "gs460", "gxModels", "gx460", "gx470", "hsModels", "hs250h", "isModels", "is250", "is250c", "is300", "is350", "is350c", "isf", "lexlfa", "lsModels", "ls400", "ls430", "ls460", "ls600h", "lxModels", "lx450", "lx470", "lx570", "rxModels", "rx300", "rx330", "rx350", "rx400h", "rx450h", "scModels", "sc300", "sc400", "sc430", "lexoth"],
  "linc": ["aviator", "blkwood", "cont", "lslincoln", "marklt", "mark6", "mark7", "mark8", "mks", "mkt", "mkx", "mkz", "naviga", "navigal", "linctc", "zephyr", "linoth"],
  "lotus": ["elan", "lotelise", "esprit", "evora", "exige", "unavaillot"],
  "mas": ["430", "bitrbo", "coupemas", "gransport", "granturism", "qp", "spyder", "unavailmas"],
  "maybach": ["57maybach", "62maybach", "unavailmay"],
  "mazda": ["mazda323", "mazda626", "929", "bSeries", "cx5", "cx7", "cx9", "glc", "mazda2", "mazda3", "mazda5", "mazda6", "mazdaspd3", "mazdaspd6", "miata", "mill", "mpv", "mx3", "mx6", "navajo", "prote", "prote5", "rx7", "rx8", "tribute", "mazoth"],
  "mclaren": ["mp4"],
  "mb": ["190Class", "190d", "190e", "240Class", "240d", "300ClassEClass", "300cd", "300ce", "300d", "300e", "300td", "300te", "cClass", "c220", "c230", "c240", "c250", "c280", "c300", "c320", "c32amg", "c350", "c36amg", "c43amg", "c55amg", "c63amg", "clClass", "cl500", "cl550", "cl55amg", "cl600", "cl63amg", "cl65amg", "clkClass", "clk320", "clk350", "clk430", "clk500", "clk550", "clk55amg", "clk63amg", "clsClass", "cls500", "cls550", "cls55amg", "cls63amg", "eClass", "260e", "280ce", "280e", "400e", "500e", "e300", "e320", "e320blue", "e320cdi", "e350", "e350blue", "e400", "e420", "e430", "e500", "e550", "e55amg", "e63amg", "gClass", "g500", "g550", "g55amg", "g63amg", "glClass", "gl320blue", "gl320cdi", "gl350blue", "gl450", "gl550", "glkClass", "glk350", "mClass", "ml320", "ml320blue", "ml320cdi", "ml350", "ml350blue", "ml430", "ml450hy", "ml500", "ml550", "ml55amg", "ml63amg", "rClass", "r320blue", "r320cdi", "r350", "r350blue", "r500", "r63amg", "sClass", "300sd", "300sdl", "300se", "300sel", "350sd", "350sdl", "380se", "380sec", "380sel", "400se", "400sel", "420sel", "500sec", "500sel", "560sec", "560sel", "600sec", "600sel", "s320", "s350", "s350blue", "s400hy", "s420", "s430", "s500", "s550", "s55amg", "s600", "s63amg", "s65amg", "slClass", "300sl", "380sl", "380slc", "500sl", "560sl", "600sl", "sl320", "sl500", "sl550", "sl55amg", "sl600", "sl63amg", "sl65amg", "slkClass", "slk230", "slk250", "slk280", "slk300", "slk320", "slk32amg", "slk350", "slk55amg", "slrClass", "slr", "slsClass", "slsamg", "sprinterClass", "mbsprinter", "mboth"],
  // "merc": ["capri", "cougar", "mercgrand", "lynx", "marauder", "mariner", "marq",
  // "milan", "montego", "monterey", "mounta", "mystiq", "sable", "topaz", "tracer", "villa", "merczep", "meoth"],
  // "merkur": ["scorp", "xr4ti", "meroth"],
  "mini": ["cooprclubModels", "cooperclub", "cooprclubs", "coopcountryModels", "countryman", "countrymns", "coopcoupModels", "minicoupe", "miniscoupe", "cooperModels", "cooper", "coopers", "cooprroadModels", "cooperroad", "coopersrd"],
  "mit": ["3000gt", "cord", "diaman", "eclip", "endeavor", "mitexp", "galant", "miti", "lancermits", "lancerevo", "mitpu", "mirage", "mont", "montsport", "outlander", "outlandspt", "precis", "raidermits", "sigma", "mitstar", "tred", "mitvan", "mitoth"],
  "nissan": ["nis200sx", "240sx", "300zxturbo", "350z", "370z", "altima", "patharmada", "axxess", "cube", "fronti", "gtR", "juke", "leaf", "max", "murano", "muranocros", "nv", "nx", "path", "nispu", "pulsar", "quest", "rogue", "sentra", "stanza", "titan", "nisvan", "versa", "xterra", "nissoth"],
  // "olds": ["88", "achiev", "alero", "aurora", "brav", "cucr", "oldcus", "oldcalais", "ciera", "csupr", "oldsfir", "intrig", "98", "omeg", "regen", "silho", "toro", "oldoth"],
  // "peug": ["405", "504", "505", "604", "unavailpeu"],
  // "plym": ["acc", "arrow", "breeze", "carave", "champ", "colt", "plymconq", "granfury", "plymgranv", "hori", "laser", "neon", "prowle", "reli", "sapporoply", "scamp", "sundan", "traildust", "voya", "plyoth"],
  "pont": ["t1000", "6000", "aztek", "bon", "catalina", "fiero", "fbird", "g3", "g5", "g6", "g8", "grndam", "gp", "gto", "j2000", "lemans", "montana", "parisi", "phoenix", "safaripont", "solstice", "sunbir", "sunfir", "torrent", "ts", "vibe", "ponoth"],
  "por": ["911", "924", "928", "944", "968", "boxste", "carreragt", "cayenne", "cayman", "panamera", "poroth"],
  "ram": ["ram1504wd", "ram25002wd", "ram3502wd", "ram4500"],
  // "ren": ["18i", "fu", "lecar", "r18", "rensport", "unavailren"],
  "rr": ["camar", "corn", "ghost", "parkward", "phant", "dawn", "silseraph", "rrspir", "spur", "unavailrr"],
  // "saab": ["92x", "93", "94x", "95", "97x", "900", "9000", "saoth"],
  // "saturn": ["astra", "aura", "ion", "lSeries", "l100", "l200", "l300", "lssaturn", "lwSeries", "lw", "lw2", "lw200", "lw300", "outlook", "relay", "scSeries", "sc1", "sc2", "sky", "slSeries", "sl", "sl1", "sl2", "swSeries", "sw1", "sw2", "vue", "satoth"],
  "scion": ["scifrs", "iq", "tc", "xa", "xb", "xd"],
  "smart": ["fortwo", "smoth"],
  "srt": ["srtviper"],
  // "sterl": ["825", "827", "unavailste"],
  "sub": ["baja", "brat", "subbrz", "forest", "imprez", "impwrx", "justy", "subl", "legacy", "loyale", "suboutbk", "svx", "b9tribeca", "xt", "xvcrsstrek", "suboth"],
  "suzuki": ["aerio", "equator", "esteem", "forenza", "grandv", "kizashi", "reno", "samur", "side", "swift", "sx4", "verona", "vitara", "x90", "xl7", "suzoth"],
  "tesla": ["roadster"],
  "toyota": ["4run", "avalon", "camry", "celica", "corol", "corona", "cress", "echo", "fjcruis", "highlander", "lc", "matrix", "mr2", "mr2spydr", "paseo", "pickup", "previa", "prius", "priusc", "priusv", "rav4", "sequoia", "sienna", "solara", "starlet", "supra", "t100", "tacoma", "tercel", "tundra", "toyvan", "venza", "yaris", "toyoth"],
  // "tri": ["tr7", "tr8", "trioth"],
  "volks": ["beetle", "volkscab", "cab", "cc", "corr", "dasher", "eos", "eurovan", "volksfox", "gli", "golfr", "gti", "golfandrabbitmodels", "golf", "rabbit", "jet", "pass", "phaeton", "rabbitpu", "quan", "r32", "routan", "scir", "tiguan", "touareg", "vanag", "vwoth"],
  "volvo": ["240", "260", "740", "760", "780", "850", "940", "960", "c30", "c70", "s40", "s60", "s70", "s80", "s90", "v40", "v50", "v70", "v90", "xc60", "xc", "xc90", "voloth"],
  // "yugo": ["gv", "gvc", "gvl", "gvs", "gvx", "yuoth"]
}