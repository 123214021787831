import { OptionalToUndefined } from "@/shared"

import { VehiclesState } from "../model"


export function getVehiclesInitFilters(): OptionalToUndefined<Pick<VehiclesState,
  | "searchField"
  | "shownColumnList"
  | "searchPrecision"
  | "isPublic"
  | "ownership"
  | "status"
  | "isInitView"
>> {
  return {
    searchField: "",
    status: "all",
    isPublic: undefined,
    ownership: "all",
    isInitView: false,
    searchPrecision: "high",
    shownColumnList: [
      "brand",
      "model",
      "year",
      "estimate",
      "creationDate",
      "status",
      "bodyStyle",
      "exteriorColor",
      "interiorColor",
      "vinId",
      "equipments",
      "condition",
      "distance",
      "fuelType",
      "transmission",
      "cylinderCount",
      "driveTrain",
      "price",
      "ownership",
      "firstName",
      "lastName",
      "phone",
      "email",
    ]
  }
}