import { getReduxState, setReduxState, useReduxState, useWillMount } from "@/redux"
import { goToExactPath } from "@/routes"
import { FormContentStyled } from "@/styles/FormContentStyled"
import { FormParentStyled } from "@/styles/FormParentStyled"
import { Class } from "@/components"

import { getCreateVehicleInitState, validateVehicle } from "../functions"

import { ImagesForm } from "./ImagesForm"
import { VehicleForm } from "./VehicleForm"
import { SpecsForm } from "./SpecsForm"
import { FinalForm } from "./FinalForm"
import { WelcomeForm } from "./WelcomeForm"
import { VehicleInfoItem } from "./VehicleInfoItem"

export function CreateVehicle(): JSX.Element {
  const { viewIndex, say, firstPath, isInvalidField } = useReduxState(state => ({
    say: state.languages.say,
    firstPath: state.routes.firstPath,
    viewIndex: state.createVehicle.viewIndex,
    isInvalidField: state.ui.snackbar?.message === "invalidField"
  }))
  useWillMount(() => {
    const isEditing = !!getReduxState().createVehicle._id
    if (firstPath === "edit-vehicle" && !isEditing) {
      goToExactPath("/create-vehicle")
    } else if (firstPath === "create-vehicle" && isEditing) {
      setReduxState("createVehicle", getCreateVehicleInitState())
    }
  })


  const titleList = [
    say.describeVehicle,
    say.technicalSpecifications,
    say.enterYourInformations,
    say.uploadVehiclePhotos,
  ]
  const indexList = [
    <VehicleForm key={1} />,
    <SpecsForm key={2} />,
    <FinalForm key={3} />,
    <ImagesForm key={4} />,
  ]
  if (firstPath === "sell-vehicle") {
    titleList.unshift(say.fillForm)
    indexList.unshift(<WelcomeForm />)
  }

  return (
    <>
      <Class
        props={{ viewIndex }}
        didUpdate={(oldProps, newProps) => (
          oldProps.viewIndex !== newProps.viewIndex
          && !isInvalidField
          && newProps.viewIndex > oldProps.viewIndex
          && validateVehicle(oldProps.viewIndex)
          && setReduxState("createVehicle", { viewIndex: oldProps.viewIndex })
        )} />
      {firstPath === "sell-vehicle" && (
        <>
          <div className="relative center mt-[100px]">
            <div className="z-10 full-absolute bg-secondary-900/70" />
            <h1 className="absolute z-20 max-w-2xl text-3xl leading-snug text-center text-white uppercase sm:text-5xl ">{say.sellVehicleTitle}</h1>
            <img alt="sell-header" className="w-full object-cover object-center h-[500px]" src="/images/sell.jpg" />
          </div>
          <div className="relative py-20 bg-secondary-900 center col">
            <div className="z-10 full-absolute bg-black/75" />
            <img
              alt="car-hands"
              className="object-cover object-center full-absolute"
              src="/images/car-hands.jpg"
            />
            <h1 className="relative z-20 mb-10 text-2xl text-center text-white uppercase sm:text-4xl">{say.sellVehicleSectionTitle}</h1>
            <div className="z-20 relative flex flex-wrap w-[calc(100%-40px)] justify-center gap-10 max-w-[1000px]">
              <VehicleInfoItem
                text={say.sellVehicleSectionSubText1}
                iconName="money-bill"
                title={say.sellVehicleSectionSubTitle1}
              />
              <VehicleInfoItem
                iconName="clock"
                text={say.sellVehicleSectionSubText2}
                title={say.sellVehicleSectionSubTitle2}
              />

            </div>
          </div>
        </>
      )}
      <FormParentStyled id="form" className={firstPath === "sell-vehicle" ? "" : "!pt-[100px]"}>
        <div className="w-full pt-16 pb-16 text-center bg-gradient-to-b from-black/50 to-grey-900">
          <h1 className="pb-2 text-secondary-900">
            {firstPath === "sell-vehicle" ? say.sellVehicle : firstPath === "create-vehicle" ? say.publishVehicle : say.editVehicle}
          </h1>
          <h3 className="text-secondary-50/40">
            {titleList[viewIndex]}
          </h3>
        </div>
        <FormContentStyled>
          {indexList[viewIndex]}
        </FormContentStyled>
      </FormParentStyled>
    </>
  )
}
