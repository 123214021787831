import { getStorageItem, setStorageItem } from "@/localStorage"
import { getReduxState, setReduxState } from "@/redux"
import { User } from "@/shared"

import { SocketIo } from "../classes"
import { handleSocketRequest, launchAllRequests } from "../functions"

import { deleteOneSession } from "./deleteOneSession"

export function createOneSession(
  {
    email,
    password,
  }: Partial<Pick<User, "email" | "password">>,
  callback?: () => void
): void {
  const { deviceId } = getReduxState().settings
  const sessionId = getStorageItem("sessionId") || undefined
  const userId = getStorageItem("currentUser")?._id
  handleSocketRequest(
    "sessions",
    "createOne",
    email !== undefined ? { email, password, deviceId } : {
      sessionId,
      userId,
      deviceId,
    }, data => {
      SocketIo.launchCallbackArray()
      if (callback) {
        callback()
      }
      if (!data.sessionId) {
        setReduxState("users", {
          isSessionLoaded: true,
          currentUser: data.user
        }, () => {
          launchAllRequests()
        })
      } else {
        setStorageItem("sessionId", data.sessionId)
        setReduxState("users", { currentUser: data.user }, () => {
          launchAllRequests()
        })
      }
    },
    error => {
      SocketIo.launchCallbackArray()
      if (email !== undefined) {
        setReduxState("ui", { snackbar: { message: error, severity: "error" } })
      } else {
        deleteOneSession(true)
      }
    }
  )
}