
import { Button, CheckboxList, DelayedSearchInput, Fieldset, Icon } from "@/components"
import { setReduxState, useReduxState } from "@/redux"

import { BROKERAGE_TABLE_HEAD_LIST } from "../constants/BROKERAGE_TABLE_HEAD_LIST"
import { getBrokeragesInitFilters } from "../functions/getBrokerageInitFilters"

export function BrokeragesFilters(): JSX.Element | null {
  const {
    say,
    shownColumnList,
    isFilters
  } = useReduxState(state => ({
    say: state.languages.say,
    isFilters: state.ui.isFilters,
    shownColumnList: state.brokerages.shownColumnList,
  }))
  if (!isFilters) {
    return null
  }
  return (
    <div className="col w-[400px] max-w-[calc(100vw-40px)] top-[100px] h-full fixed backdrop-blur-sm border-r-2 bg-grey-900/90 border-solid border-primary-300 z-20">
      <div className="flex items-center justify-between shrink-0 px-5 h-[57px] w-full border-b border-solid border-primary-300 text-primary-300">
        <div className="flex items-center">
          <Icon name="filter" className="h-4 mr-2 text-primary-300" />
          {say.filters}
        </div>
        <Button
          iconName="trash-alt"
          isCircle={true}
          size="sm"
          variant="outlined"
          className="!bg-grey-850"
          shade={300}
          isTransparent={true}
          color="error"
          onClick={() => setReduxState("brokerages", { ...getBrokeragesInitFilters(), shownColumnList })}
        />
      </div>
      <div className="p-5 overflow-auto h-[calc(100%-157px)] in-h3:text-lg in-.fieldset:mb-4 in-.input:h-10 in-h3:mb-2">
        {/* <Fieldset label={say.searchPrecision}>
          <CheckboxList
            optionList={[...SEARCH_PRECISION_LIST]}
            valueList={[searchPrecision]}
            outputMap={value => say[value]}
            onChange={newList => setReduxState("brokerages", { searchPrecision: newList[0] || "high" })}
          />
        </Fieldset> */}
        <DelayedSearchInput
          onChange={searchField => setReduxState("brokerages", { searchField })}
          getValue={reduxState => reduxState.brokerages.searchField}
        />
        <Fieldset label={say.shownColumns}>
          <CheckboxList
            optionList={[...BROKERAGE_TABLE_HEAD_LIST]}
            valueList={shownColumnList}
            outputMap={value => say[value]}
            isMultiple={true}
            onChange={newList => setReduxState("brokerages", { shownColumnList: newList })}
          />
        </Fieldset>
      </div>
    </div>
  )
}