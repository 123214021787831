import { IS_DESKTOP } from "@/constants"
import { center, clickable, shadeEffect } from "@/ui/mixins"
import styled, { css } from "styled-components"

export const NavStyled = styled.div`
  position: fixed;
  ${center};
  z-index: 1000;
  height: 100px;
  top: 30px;
  padding: 0 20px;
  width: 100%;
  @media screen and (orientation: landscape) {
    background: var(--grey-900);
  }
  @media screen and (orientation: portrait) {
    background: linear-gradient(to bottom, var(--grey-900), hsla(0, 0%, 0%, 0));
  }
  .left-box {
    flex: 1;
    .fa-icon {
      height: 45px;
      color: white;
    }
  }
  .fal-bars {
    ${center};
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    border-radius: 9999px;
    ${shadeEffect("white", "background", { whiteShadeType: "secondary" })}
    svg{
      margin-left: 0;
      height: 25px;
      color: ${() => window.theme.secondary800};
    }
  }
  @media screen and (min-width: 800px) {
    .fal-bars {
      display: none;
    }
    .right-box {
      display: flex;
      align-items: center;
    }
    .nav-icon {
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 799px) {
    .right-box {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      right: 0;
      top: 50px;
      width: calc(100vw - 40px);
      border-radius: 10px;
      background: hsla(0, 0%, 0%, 0.65);
      backdrop-filter: blur(5px);
    }
  }
  .nav-icon {
    ${center};
    ${clickable};
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    z-index: 10;
    border-radius: 9999px;
    ${() => {
    if (IS_DESKTOP) {
      return css`
        &:hover {
          background: ${() => window.theme.secondary900}55;
        }
        &:active {
          background: ${() => window.theme.secondary900}77;
        }
      `
    } else {
      return css`
        &:active {
          background: ${() => window.theme.secondary900}55;
        }
      `
    }
  }}
    .fa-icon {
      margin-left: 0;
      height: 25px;
      color: white;
    }
  }
`