import { handleSocketRequest } from "@/api/functions"
import { getReduxState, setReduxState } from "@/redux"
import { HtmlString } from "@/shared"
export function updateOneBrokerage(_id: string, comment: HtmlString): void {
  handleSocketRequest(
    "brokerages",
    "updateOne",
    {
      _id,
      comment: comment.plainText.trim() ? comment : undefined
    }, data => {
      setReduxState("brokerages", { brokerageList: getReduxState().brokerages.brokerageList.map(item => item._id === _id ? data : item) })
    })
}