import { updateOneVehicle } from "@/api/requests"
import { validatePartialVehicle } from "@/pages/Vehicles/functions/validatePartialVehicle"
import { getReduxState, setReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { getCreateVehicleInitState, validateVehicle } from "."
export function editVehicle(): void {
  const {
    createVehicle: {
      brand,
      otherBrand,
      model,
      year,
      bodyStyle,
      exteriorColor,
      interiorColor,
      doorCountField,
      seatingCountField,
      equipmentList,
      distanceField,
      isKmDistanceUnit,
      condition,
      cityFuelConsumptionField,
      highwayFuelConsumptionField,
      cylinderCountField,
      driveTrain,
      isManualTransmission,
      fuelType,
      imageList,
      priceField,
      isFullOwnership,
      firstName,
      lastName,
      vinIdField,
      estimateField,
      isMsrp,
      _id,
      phone,
      email,
      address,
    },
    vehicles: {
      editedTableCell,
      editedComment
    }
  } = getReduxState()


  if (editedTableCell) {
    if (validatePartialVehicle(editedTableCell)) {
      return
    }
  } else if (validateVehicle()) {
    return
  }

  updateOneVehicle({
    _id: _id!,
    details: {
      brand: brand!,
      otherBrand: brand === "other" ? otherBrand : undefined,
      model: model!,
      year: year!,
      bodyStyle: bodyStyle!,
      exteriorColor,
      interiorColor,
      estimate: estimateField ? { price: Number(estimateField), isMsrp } : undefined,
      doorCount: doorCountField ? Number(doorCountField) : undefined,
      seatingCount: seatingCountField ? Number(seatingCountField) : undefined,
      vinId: vinIdField,
      equipmentList,
      distance: Number(distanceField),
      isKmDistanceUnit,
      condition: condition!,
      cityFuelConsumption: Number(cityFuelConsumptionField),
      highwayFuelConsumption: Number(highwayFuelConsumptionField),
      cylinderCount: cylinderCountField ? Number(cylinderCountField) : undefined,
      driveTrain: driveTrain!,
      isManualTransmission,
      fuelType: fuelType!,
      imageFileList: imageList,
      price: Number(priceField),
      ...(editedTableCell === "comment" ? { comment: editedComment } : {}),
      customerParams: {
        isFullOwnership,
        firstName,
        lastName,
        phone,
        email,
        address: address!,
      }
    }
  }, vehicle => {
    const newVehicleList = getReduxState().vehicles.vehicleList.map(item => item._id === _id ? vehicle : item)

    if (editedTableCell) {
      setReduxState({
        vehicles: { vehicleList: newVehicleList, editedTableCell: undefined },
        createVehicle: getCreateVehicleInitState(),
        popups: { name: undefined }
      })
    } else {
      setReduxState({
        vehicles: { vehicleList: newVehicleList, editedTableCell: undefined },
        createVehicle: getCreateVehicleInitState(),
      })
    }

    goToExactPath("/dashboard/vehicles")
  })
}