import { breakpoints } from "@/ui/mixins"
import styled from "styled-components"

export const VehicleContentStyled = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 80px;
& > .top-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1140px;
  padding: 20px 20px 10px 20px;
  @media screen and (min-width: ${breakpoints.sm}) {
    padding: 40px 0 10px 0;
  }
}

.grid-box {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: calc(100% - 30px);
  max-width: 1110px;
  margin-bottom: 20px;
}
h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 300;
  color: ${() => window.theme.secondary900};
  span {
    flex-shrink: 0;
  }
}
.equipment-box {
  grid-column-start: 1;
  grid-column-end: 3;
  span {
    display: flex;
    flex-wrap: wrap;
    div:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid ${() => window.theme.grey300};
    }
  }
}
@media screen and (min-width: ${breakpoints.sm}) {
  margin: 100px 40px 0 40px;

  .grid-box {
  width: calc(100% - 20px);
    grid-template-columns: repeat(3, ${100 / 3}%) !important;
  }
  .equipment-box {
    grid-column-end: 4;
  }
}
@media screen and (min-width: ${breakpoints.lg}) {
  .grid-box {
    width: calc(100% - 30px);
    grid-template-columns: repeat(4, 25%) !important;
  }
  .equipment-box {
    grid-column-end: 5;
  }
}
`