import { SetAction } from "../../redux/model"

import { CreateVehicleState } from "./model"



export const createVehicle = (currentState: CreateVehicleState, action: SetAction<"createVehicle">) => {
  const data = { ...action.data }
  return {
    ...currentState,
    ...data
  }
}
