import { Icon } from "@/components"

import { VehicleSpecProps } from "../model"

import { VehicleSpecStyled } from "./VehicleSpecStyled"

export function VehicleSpec({ label, value, iconName, styleOverride }: VehicleSpecProps): JSX.Element {
  return (
    <VehicleSpecStyled styleOverride={styleOverride}>
      <Icon name={iconName} />
      <h4>{label}</h4>
      <span>{value}</span>
    </VehicleSpecStyled>
  )
}