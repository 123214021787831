import { handleSocketRequest } from "@/api/functions"
import { setReduxState } from "@/redux"
import { AllRequests } from "@/shared"

export function forgotPassword({ forgotPasswordEmail, language }: AllRequests["users"]["updateOne"]["params"]): void {
  handleSocketRequest(
    "users",
    "updateOne",
    {
      forgotPasswordEmail,
      language
    }, () => {
      setReduxState("ui", { snackbar: { message: "passwordSent", severity: "success" } })

    }, error => {
      setReduxState("ui", { snackbar: { message: error, severity: "error" } })
    })
}