import { OptionalToUndefined } from "@/shared"

import { UsersState } from "../model"


export function getUsersInitFilters(): OptionalToUndefined<Pick<UsersState,
  | "searchField"
  | "shownColumnList"
  | "searchPrecision"
>> {
  return {
    searchField: "",
    searchPrecision: "high",
    shownColumnList: [
      "email",
      "role"
    ]
  }
}