import { Button, SafeFixed } from "@/components"
import { useReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

export function BottomRightButton(): JSX.Element {
  const say = useReduxState(state => state.languages.say)
  return (
    <SafeFixed>
      <div className="fixed bottom-5 right-5 not-first-children:mt-2 in-button:shadow-1">
        <Button
          iconName="plus"
          size="lg"
          shade={300}
          color="success"
          children={say.createForClient}
          onClick={() => goToExactPath("/sell-vehicle")}
        />
        <Button
          iconName="plus"
          size="lg"
          shade={300}
          color="success"
          children={say.createForAvarta}
          onClick={() => goToExactPath("/create-vehicle")}
        />
      </div>
    </SafeFixed>
  )
}