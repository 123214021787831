import { IS_DEV } from "@/constants"
import { getReduxState } from "@/redux"
import { Socket as SocketType } from "socket.io-client"

import { launchAllRequests } from "../functions"
import { createOneSession } from "../requests"


export class SocketIo {
  public static isReady: boolean = false
  private static Socket?: SocketType
  private static callbackArray: (() => void)[] = []

  public static launch(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.src = IS_DEV ? `http://localhost:${window.location.port}/socket.io/socket.io.js` : `https://${window.location.hostname}/socket.io/socket.io.js`
      document.body.appendChild(script)
      script.onerror = () => {
        reject()
      }
      script.onload = () => {
        resolve(undefined)
      }
    }).then(() => {
      if (this.Socket) {
        this.Socket.disconnect()
      }
      if (window.io) {
        const port = IS_DEV ? `ws://localhost:${window.location.port}` : `wss://${window.location.hostname}`
        this.Socket = window.io(port)

        this.Socket.on(`/api/sockets/getOne`, () => {
          this.isReady = true
          if (getReduxState().users.currentUser) {
            createOneSession({})
          } else {
            launchAllRequests()
            this.launchCallbackArray()
          }
        })
      }
    })
  }
  public static getSocket(): SocketType | undefined {
    return this.Socket
  }

  public static launchCallbackArray(): void {
    this.callbackArray.forEach(callback => callback())
    this.callbackArray = []
  }
  public static pushCallback(callback: () => void): void {
    this.callbackArray.push(callback)
  }
}



