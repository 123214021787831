import { tw } from "@/shared"

export const keywordItemTw = tw`
clickable
relative
h-10
px-4
mb-2
mr-2
border
border-solid
rounded-full
center
bg-grey-850
text-primary-300
shade-border-primary-300
cursor-grab
active:cursor-grabbing
mouse:hover-in-button:flex
touch:active-in-button:!flex
`