import React from "react"

const fr = (
  <>
    <h2> Termes &amp; Conditions </h2>
    <p>
      Le terme utilisé "Avarta Auto" dans cette politique de confidentialité fait référence à notre site web https://avartasolutions.com
      à moins qu'il ne soit définit autrement dans la présente termes et conditions
    </p>
    <p>
      En naviguant sur Avarta Auto, ces termes seront
      appliqués automatiquement à vous - vous devez donc vous assurer que
      que vous les lisiez attentivement avant d'utiliser le site web. Vous n'êtes
      pas autorisé à copier, ou modifier l'application, toute partie du site web,
      ou nos marques de quelque manière que ce soit. Vous n'êtes pas autorisé à tenter
      d'extraire le code source du site web, et vous n'avez pas le droit
      d'essayer de traduire le site web dans d'autres langues, ou
      faire des versions dérivées. Le site web lui-même et tout le commerce,
      les droits d'auteur, droits de base de données et autres droits de propriété intellectuelle,
      les droits de propriété qui y sont liés appartiennent tous à
      Avarta Auto solutions inc.
    </p>

    <p>
      Avarta Auto solutions inc. s’engage à faire en sorte que Avarta Auto
      est aussi utile et efficace que possible. Pour cette raison, nous
      nous réservons le droit d'apporter des modifications à Avarta Auto, à tout moment et pour n'importe quelle raison.
    </p>
    <p>
      Avarta Auto stocke et traite des données personnelles
      que vous nous avez fourni, afin de fournir
      notre service.
    </p>
    <p>
      Vous devez être conscient qu'il y a certaines choses que
      Avarta Auto solutions inc. n'assume aucune responsabilité.
      Certaines fonctions de Avarta Auto nécessiteront que Avarta Auto dispose d'une
      connexion Internet active. La connexion peut être Wi-Fi ou
      fournie par votre fournisseur de réseau mobile, mais
      Avarta Auto solutions inc. ne peut assumer la responsabilité que l'application ne fonctionne pas à pleine fonctionnalité si vous ne possédez pas
      d'accès au Wi-Fi.
    </p>
    <p />
    <p>
      Dans le même ordre d'idées, Avarta Auto solutions inc. ne peut pas toujours
      assumer la responsabilité de la manière dont vous utilisez Avarta Auto, c.-à-d.
      vous devez vous assurer que votre appareil reste chargé - s'il
      manque de batterie et vous ne pouvez pas l’allumer pour profiter du
      Service, Avarta Auto solutions inc. ne peut pas être tenu
      responsable.
    </p>
    <p>
      En ce qui concerne la responsabilité de Avarta Auto solutions inc. concernant
      votre utilisation de Avarta Auto, lorsque vous l'utilisez, il est
      important de garder à l’esprit que, bien que nous nous efforcions de
      veiller à ce qu'il soit mis à jour et correct en tout temps, nous le faisons
      compter sur des tiers pour nous fournir des informations afin que
      nous pouvons le mettre à votre disposition.
      Avarta Auto solutions inc. décline toute responsabilité pour toute perte,
      directe ou indirecte que vous rencontrez sur une fonctionnalité dépendant d'un tiers dans l'application.
    </p>
    <p>
      À un moment donné, nous souhaiterons peut-être mettre à jour Avarta Auto
      Avarta Auto solutions inc. ne promet pas que
      il mettra toujours à jour Avarta Auto pour qu'il soit pertinent pour vous.
      Nous pouvons également souhaiter cesser de fournir Avarta Auto, et peut mettre fin à
      l'utilisation à tout moment sans préavis. Sauf indication contraire de notre part, en cas de résiliation,
      les droits et licences qui vous sont accordés dans ces conditions
      finira;
    </p>
    <p> <strong>Cycle de vie des véhicules</strong> </p>
    <p>
      Les administrateurs de Avarta Auto se donnent la discrétion d'accepter ou refuser la publication,
      modification suppression et changement d'audience d'un véhicule.
    </p>

    <p> <strong> Modifications de ces termes et conditions </strong> </p>
    <p>
      Nous pouvons mettre à jour nos conditions générales
      de temps en temps. Ainsi, il est conseillé de consulter cette page
      périodiquement pour tout changement. Nous allons
      vous informer de tout changement en affichant les nouvelles conditions et
      Conditions sur cette page. Ces changements sont efficaces
      immédiatement après leur publication sur cette page.
    </p>
    <p> <strong> Contactez-nous </strong> </p>
    <p>
      Si vous avez des questions ou des suggestions sur
      nos Termes et Conditions, n'hésitez pas à nous contacter par les moyens offerts dans la section nous joindre de notre site web
    </p>
    <p>
      Cette page de termes et conditions a été générée par
      <a rel="noopener noreferrer" href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank">
        Générateur de règles de confidentialité des applications
      </a>
    </p>
  </>
)

export default fr
