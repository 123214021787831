import { createMuiTheme } from "@material-ui/core"
import { getStorageItem } from "@/localStorage"
import { COLORS, getColor } from "@/shared"

import { Theme } from "./model"



const theme: Theme = {
  apparence: getStorageItem("apparence") || { isDark: false, isAuto: true },
  ...getColor("primary"),
  ...getColor("secondary"),
  ...getColor("grey"),
  ...getColor("success"),
  ...getColor("info"),
  ...getColor("error"),
  ...getColor("warning"),
}
// IS_CORDOVA = true
window.theme = theme

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.primary[500],
      ...COLORS.primary
    },
    secondary: {
      main: COLORS.secondary[500],
      ...COLORS.secondary
    },
    grey: {
      ...COLORS.grey
    },
    info: {
      main: COLORS.info[500],
      ...COLORS.info
    },
    success: {
      main: COLORS.success[500],
      ...COLORS.success
    },
    warning: {
      main: COLORS.warning[500],
      ...COLORS.warning
    },
    error: {
      main: COLORS.error[500],
      ...COLORS.error
    },
  },
})
