import { getPopupsInitState } from "@/popups/functions/getPopupsInitState"
import { getLanguagesInitState } from "@/languages/getLanguagesInitState"
import { getCreateBrokerageInitState } from "@/pages/CreateBrokerage/functions"
import { getCreateVehicleInitState } from "@/pages/CreateVehicle/functions"
import { getHomeInitState } from "@/pages/Home/getHomeInitState"
import { getLoginInitState } from "@/pages/Login/getLoginInitState"
import { getSearchInitState } from "@/pages/Search/functions"
import { getSignInInitState } from "@/pages/SignIn/functions"
import { getVehicleInitState } from "@/pages/Vehicle/functions/getVehicleInitState"
import { getVehiclesInitState } from "@/pages/Vehicles/functions/getVehiclesInitState"
import { getRoutesInitState } from "@/routes"
import { getSettingsInitState } from "@/Settings/getSettingsInitState"
import { getUiInitState } from "@/ui/functions"
import { getBrokeragesInitState } from "@/pages/Brokerages/functions/getBrokerageInitState"
import { getUsersInitState } from "@/pages/Users/functions/getUsersInitState"

import { ReduxState } from "../model"

export function getReduxInitState(): ReduxState {
  return {
    popups: getPopupsInitState(),
    languages: getLanguagesInitState(),
    ui: getUiInitState(),
    routes: getRoutesInitState(),
    login: getLoginInitState(),
    search: getSearchInitState(),
    brokerages: getBrokeragesInitState(),
    createBrokerage: getCreateBrokerageInitState(),
    home: getHomeInitState(),
    vehicles: getVehiclesInitState(),
    users: getUsersInitState(),
    settings: getSettingsInitState(),
    vehicle: getVehicleInitState(),
    createVehicle: getCreateVehicleInitState(),
    signIn: getSignInInitState(),
  }
}