export function getScreenDimensions(): { width: number, height: number } {
  let height = window.innerHeight
  let width = window.innerWidth

  if (window.visualViewport && window.visualViewport.width && window.visualViewport.height) {
    height = window.visualViewport.height
    width = window.visualViewport.width
  }

  return { width, height }
}