import React, { useRef } from "react"
import { generateId } from "@/shared"
import { eventHandler } from "@/classes"
import { IS_DESKTOP } from "@/constants"

import { Dropdown, Input } from ".."

import { AutoCompleteProps } from "./model"

function outsideClick(evt: MouseEvent, id: string, handleBlur?: () => void): void {
  if (!isInside(evt.target as HTMLElement, id) && handleBlur) {
    handleBlur()
    eventHandler.delete(id)
  }
}
function isInside(elem: HTMLElement | null, id: string): boolean {
  if (!elem) {
    return false
  } else if (elem.id.startsWith(id)) {
    return true
  } else {
    return isInside(elem.parentElement, id)
  }
}
export function AutoComplete<Value>({ inputProps, dropdownProps, filter }: AutoCompleteProps<Value>): JSX.Element {
  const optionList = filter ? dropdownProps.optionList.filter(filter) : dropdownProps.optionList
  const blurRef = useRef<undefined | (() => void)>(undefined)
  const idRef = useRef(generateId())
  return (
    <Input
      id={idRef.current + "0"}
      {...inputProps}
      getBlurHandler={handler => blurRef.current = handler}
      isChildrenOnlyWhenFocus={true}
      onFocus={evt => {
        if (inputProps.onChange) {
          const fakeEvent: React.ChangeEvent<HTMLInputElement> = { target: { value: "" } } as any
          inputProps.onChange(fakeEvent)
        }
        if (IS_DESKTOP) {
          eventHandler.create(
            window,
            "click",
            event => outsideClick(event, idRef.current, blurRef.current),
            false,
            idRef.current
          )
        }
        if (inputProps.onFocus) {
          inputProps.onFocus(evt)
        }
      }}
    >
      <Dropdown
        {...dropdownProps}
        id={idRef.current + "1"}
        isFullWidth={true}
        optionList={optionList}
        onChange={evt => {
          dropdownProps.onChange(evt)
          if (blurRef.current) {
            blurRef.current()
            eventHandler.delete(idRef.current)
          }
        }}
      />
    </Input>
  )
}