import { Button, Icon } from "@/components"
import { useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"

import { incrementCreateBrokerageViewIndex } from "../functions/incrementCreateBrokerageViewIndex"

import { FormFooter } from "./FormFooter"

export function WelcomeForm(): JSX.Element {
  const {
    say,
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
  }))

  return (
    <div>
      <div className="welcome">
        <Icon name="handshake" />
        <h2>{say.welcomeBrokerage}</h2>
        <p className="!text-xl font-medium">{say.welcomeBrokerageDescription}</p>
      </div>
      <FormFooter>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(true)}
        >
          {say.next}
        </Button>
      </FormFooter>
    </div >
  )
}
