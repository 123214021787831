import { CircularProgressBar } from "@/components"
import { CircularProgressBarProps } from "@/components/CircularProgressBar/model"
import { useReduxState } from "@/redux"
import { useRef } from "react"

import { StatisticsStyled } from "./StatisticsStyled"

// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
// tslint:disable-next-line:no-var-requires
const Zoom = require("react-reveal/Zoom")

const StatisticItem = ({ label, ...circularProps }: {
  label: string,
} & CircularProgressBarProps) => (
  <Zoom>
    <div
      className="statistic-item"
    >
      <CircularProgressBar {...circularProps} />
      <h3>{label}</h3>
    </div>
  </Zoom>
)

export function Statistics(): JSX.Element {
  const { say } = useReduxState(state => ({
    say: state.languages.say,
  }))
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>
  return (
    <StatisticsStyled ref={ref}>
      <Fade top={true}>
        <h1>{say.realFact}</h1>
      </Fade>
      <div className="list-box">
        <StatisticItem
          label={say.satisfiedCustomers}
          iconName="award"
          count={250}
          sufix="+"
        />
        <StatisticItem
          label={say.yearsOfExperience}
          iconName="stopwatch"
          count={5}
        />
        <StatisticItem
          label={say.dealershipNetworks}
          iconName="cars"
          count={150}
          sufix="+"
        />
      </div>
    </StatisticsStyled>
  )
}