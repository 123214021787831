
import { Button, CheckboxList, DelayedSearchInput, Fieldset, Icon } from "@/components"
import { setReduxState, useReduxState } from "@/redux"

import { BROKERAGE_TABLE_HEAD_LIST } from "../constants/USER_TABLE_HEAD_LIST"
import { getUsersInitFilters } from "../functions/getUsersInitFilters"

export function UsersFilters(): JSX.Element {
  const {
    say,
    shownColumnList,
  } = useReduxState(state => ({
    say: state.languages.say,
    shownColumnList: state.users.shownColumnList,
  }))
  return (
    <div className="col w-[400px] max-w-[calc(100vw-40px)] top-[100px] h-full fixed backdrop-blur-sm border-r-2 bg-grey-900/90 border-solid border-primary-300 z-20">
      <div className="flex items-center justify-between shrink-0 px-5 h-[57px] w-full border-b border-solid border-primary-300 text-primary-300">
        <div className="flex items-center">
          <Icon name="filter" className="h-4 mr-2 text-primary-300" />
          {say.filters}
        </div>
        <Button
          iconName="trash-alt"
          isCircle={true}
          size="sm"
          shade={300}
          className="!bg-grey-850"
          variant="contained"
          color="error"
          onClick={() => setReduxState("users", { ...getUsersInitFilters(), shownColumnList })}
        />
      </div>
      <div className="p-5 overflow-auto h-[calc(100%-157px)] in-h3:text-lg in-.fieldset:mb-4 in-.input:h-10 in-h3:mb-2">
        {/* <Fieldset label={say.searchPrecision}>
          <CheckboxList
            optionList={[...SEARCH_PRECISION_LIST]}
            valueList={[searchPrecision]}
            outputMap={value => say[value]}
            onChange={newList => setReduxState("users", { searchPrecision: newList[0] || "high" })}
          />
        </Fieldset> */}
        <DelayedSearchInput
          onChange={searchField => setReduxState("users", { searchField })}
          getValue={reduxState => reduxState.users.searchField}
        />
        <Fieldset label={say.shownColumns}>
          <CheckboxList
            optionList={[...BROKERAGE_TABLE_HEAD_LIST]}
            valueList={shownColumnList}
            outputMap={value => say[value]}
            isMultiple={true}
            onChange={newList => setReduxState("users", { shownColumnList: newList })}
          />
        </Fieldset>
      </div>
    </div>
  )
}