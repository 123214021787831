import { getStorageItem } from "@/localStorage"
import { IS_DESKTOP } from "@/constants"

import { UIState, ApparenceType } from "../model"

import { getScreenBreakpoint, getScreenDimensions } from "."

export function getUiInitState(): UIState {
  const apparence: ApparenceType = getStorageItem("apparence") || { isDark: false, isAuto: true }
  const { width, height } = getScreenDimensions()

  return {
    themeColor: "primary",
    isDragHover: false,
    isShowTermsWarning: getStorageItem("isShowTermsWarning") === undefined ? true : false,
    notchPosition: undefined,
    screenWidth: getScreenBreakpoint(),
    loadingCount: 0,
    vw: width,
    vh: height,
    isDarkMode: apparence.isDark,
    isLandscape: window.innerWidth >= window.innerHeight,
    snackbar: undefined,
    isKeyboard: false,
    isFilters: IS_DESKTOP ? true : false
  }
}


