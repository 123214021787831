import { handleSocketRequest } from "@/api/functions"
import { setReduxState } from "@/redux"
export function getManyUsers(): void {
  handleSocketRequest(
    "users",
    "getMany",
    {
    }, userList => {
      setReduxState("users", { userList })
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    }
  )
}