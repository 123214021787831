import { center } from "@/ui/mixins"
import styled from "styled-components"

export const LoadingStyled = styled.div<{}>`
  z-index: 100000000;
  position: fixed;
  bottom: 24px;
  left: 0;
  width: 100%;
  touch-action: none;
  pointer-events: none;
  ${center}
  .fa-icon {
    background: white;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 25px;
    animation: infinite-rotate 1s linear infinite;
    box-shadow: 0 0 5px ${() => window.theme.grey400};
    color: ${() => window.theme.primary500};
    @keyframes infinite-rotate {
         0% { -webkit-transform: rotate(0deg); }
         100% { -webkit-transform: rotate(360deg); }
    }
  }
`
