import { IS_ANY_IOS, IS_DESKTOP, IS_INVENTORY, IS_SAFARI } from "@/constants"
import { breakpoints, center, clickable } from "@/ui/mixins"
import styled, { css } from "styled-components"

export const OurServicesStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  h1 {
    text-align: center;
    margin-bottom: 40px;
    color: white;
    text-shadow: 1px 1px 0 black;
  }
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./images/gold.jpg");
    background-position: center;
    ${() => !IS_SAFARI && !IS_ANY_IOS && css`
      background-attachment: fixed;
    `}
    background-size: cover;
    opacity: 0.7;
  }
  .list-box {
    display: grid;
    justify-content: center;
    grid-template-columns: min(calc(100vw - 40px), 350px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-rows: min(calc(100vw - 40px), 350px);
    @media screen and (min-width: ${breakpoints.sm}) {
      grid-template-columns: repeat(2, min(calc(50vw - 30px), 350px));
      grid-auto-rows: min(calc(50vw - 30px), 350px);
      ${() => IS_INVENTORY && css`
        .service-item:last-child {
          width: calc(100vw - 40px);
        }
      `}
    }
    ${() => IS_INVENTORY && css`
      @media screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: repeat(3, min(calc(33vw - 20px), 350px));
        grid-template-rows: min(calc(33vw - 20px), 350px);
        max-height: 350px;
        .service-item:last-child {
          width: 100%;
        }
      }
    `}

  }
  .service-item {
    ${clickable};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    background:  hsl(42,37%,30%, 0.3);
    backdrop-filter: blur(10px);
    ${() => {
    if (IS_DESKTOP) {
      return css`
        &:hover {
          transform: scale(1.03) !important;
          background:  hsl(42,37%,30%, 0.4);
        }
        &:active {
          background:  hsl(42,37%,30%, 0.5);
        }
      `
    } else {
      return css`
        &:active {
          transform: scale(1.03) !important;
          background:  hsl(42,37%,30%, 0.4);
        }
      `
    }
  }}
  }
  .fa-icon {
    ${center};
    height: 25%;
    width: 25%;
    border-radius: 9999px;
    margin-bottom: 20px;
    opacity: 0.9;
    background: ${() => window.theme.grey900};

    svg {
      height: 50%;
      max-height: 30px;
      ${() => (IS_SAFARI || IS_ANY_IOS) && css`
        height: 30px;
      `}
    }
  }
  .fal-car-garage {
    color: #64B5F6;
    border: 1px solid #64B5F6;
  }
  .fal-handshake-alt {
    color: #E57373;
    border: 1px solid #E57373;
  }
  .fal-sack-dollar {
    color: #81C784;
    border:1px solid #81C784;
  }
  p {
    text-align: center;
    font-weight: 200;
    color: white;
  }
  h3 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 300;
    color: white;
  }
`