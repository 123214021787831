import { AutoComplete, Button, Fieldset, Input, KeywordList, ToggleList } from "@/components"
import { simplifyString } from "@/functions"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import {
  AllBrokeragePrices,
  AllVehicleBodyStyles,
  AllVehicleDriveTrains,
  AllVehicleFuelTypes,
  BROKERAGE_PRICE_LIST,
  VEHICLE_BODY_STYLE_LIST,
  VEHICLE_BRAND_LIST,
  VEHICLE_DRIVE_TRAIN_LIST,
  VEHICLE_FUEL_TYPE_LIST
} from "@/shared"

import { incrementCreateBrokerageViewIndex } from "../functions/incrementCreateBrokerageViewIndex"

import { FormFooter } from "./FormFooter"

export function VehicleForm(): JSX.Element {
  const {
    say,
    brandField,
    brandList,
    otherBrand,
    isInvalidField,
    bodyStyle,
    fuelTypeList,
    driveTrainList,
    price
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    driveTrainList: state.createBrokerage.driveTrainList,
    fuelTypeList: state.createBrokerage.fuelTypeList,
    brandField: state.createBrokerage.brandField,
    brandList: state.createBrokerage.brandList,
    otherBrand: state.createBrokerage.otherBrand,
    bodyStyle: state.createBrokerage.bodyStyle,
    price: state.createBrokerage.price,
    bodyStyleField: state.createBrokerage.bodyStyleField,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  const priceOptionList = [...BROKERAGE_PRICE_LIST]
  const activePrice = priceOptionList.find(item => price && item[0] === price[0])
  return (
    <div>
      <Fieldset label={say.selectBodyStyleLong}>
        <ToggleList
          isError={isInvalidField && !bodyStyle}
          optionList={[...VEHICLE_BODY_STYLE_LIST]}
          outputMap={(val: AllVehicleBodyStyles) => (
            <>
              {say[val]}<img alt={val} src={"./images/" + val + ".svg"} />
            </>
          )}
          active={bodyStyle}
          onChange={(val?: AllVehicleBodyStyles) => setReduxState("createBrokerage", { bodyStyle: val })}
        />
      </Fieldset>
      <Fieldset label={say.selectFuelTypeLong}>
        <ToggleList
          isError={isInvalidField && (fuelTypeList.length < 1
            || fuelTypeList.length > 2)}
          optionList={[...VEHICLE_FUEL_TYPE_LIST]}
          outputMap={(val: AllVehicleFuelTypes) => say[val]}
          activeList={fuelTypeList}
          onChange={(val: AllVehicleFuelTypes[]) => setReduxState("createBrokerage", { fuelTypeList: val })}
        />
      </Fieldset>
      <Fieldset label={say.selectDriveTrainLong}>
        <ToggleList
          isError={isInvalidField && (driveTrainList.length < 1
            || driveTrainList.length > 2)}
          optionList={[...VEHICLE_DRIVE_TRAIN_LIST]}
          outputMap={(val: AllVehicleDriveTrains) => say[val]}
          activeList={driveTrainList}
          onChange={(val: AllVehicleDriveTrains[]) => setReduxState("createBrokerage", { driveTrainList: val })}
        />
      </Fieldset>
      <Fieldset label={say.selectBrandLong}>
        <AutoComplete
          filter={value => simplifyString(say[value]).includes(simplifyString(brandField))}
          inputProps={{
            mobileFocusLabel: say.selectBrand,
            iconName: "cars",
            isError: isInvalidField && (brandList.length < 1 || brandList.length > 3),
            value: brandField,
            placeholder: say[VEHICLE_BRAND_LIST[0]!],
            onChange: evt => setReduxState("createBrokerage", { brandField: evt.target.value })
          }}
          dropdownProps={{
            selectedValue: undefined,
            optionList: VEHICLE_BRAND_LIST,
            sort: (
              val1,
              val2,
            ) => val1 === "other" ? -1 : val2 === "other" ? 1 : say[val1].localeCompare(say[val2]),
            outputMap: value => say[value],
            onChange: value => !brandList.includes(value) && setReduxState("createBrokerage", {
              brandList: [...brandList, value],
              brandField: "",
            })
          }}
        />
      </Fieldset>
      <KeywordList
        optionList={[...brandList]}
        outputMap={key => say[key]}
        onChange={newVal => setReduxState("createBrokerage", {
          brandList: newVal,
        })}
      />
      {brandList.includes("other") && (
        <Fieldset label={say.otherBrand}>
          <Input
            mobileFocusLabel={say.otherBrand}
            isError={isInvalidField && !otherBrand}
            isTrimOnBlur={true}
            value={otherBrand}
            iconName="car"
            onChange={evt => setReduxState("createBrokerage", { otherBrand: evt.target.value })}
          />
        </Fieldset>
      )}
      <Fieldset label={say.selectPriceLong}>
        <ToggleList
          isError={isInvalidField && !price}
          optionList={[...BROKERAGE_PRICE_LIST]}
          outputMap={(val: AllBrokeragePrices) => (
            val[0] === 0 ? say.lessThan(val[1]) : val[1] === Infinity ? say.moreThan(val[0]) : `${val[0]} $ - ${val[1]} $`
          )}
          active={activePrice}
          onChange={(val: AllBrokeragePrices) => setReduxState("createBrokerage", { price: val })}
        />
      </Fieldset>
      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(false)}
        >
          {say.previous}
        </Button>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(true)}
        >
          {say.next}
        </Button>
      </FormFooter>
    </div>
  )
}
