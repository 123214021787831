import { tw } from "@/functions"
import { useReduxState } from "@/redux"

import { Icon } from ".."
import { Tooltip } from "../Tooltip"

import { FieldsetProps } from "./model"


export const Fieldset = ({
  className,
  label,
  isRequired,
  isOptional,
  children,
  moreInfo,
  id
}: FieldsetProps): JSX.Element | null => {
  const say = useReduxState(state => state.languages.say)
  return (
    <div id={id} className={tw`fieldset flex flex-col mb-8 shrink-0 ${className}`}>
      <div className="leading-tight flex flex-wrap shrink-0 mb-1 sm:mb-2.5 font-normal text-secondary-900">
        {`${label} ${isRequired ? "*" : ""} ${isOptional ? `(${say.optional})` : ""}`}
        {moreInfo && (
          <Icon name="question" className="w-6 h-6 ml-3 rounded-full in-svg:h-3 bg-grey-200 center text-grey-800">
            <Tooltip>
              {moreInfo}
            </Tooltip>
          </Icon>
        )}
      </div>
      <div className="row-middle min-h-[50px] flex-1 children:w-full not-last-children:mr-2.5">
        {children}
      </div>
    </div>
  )
}
