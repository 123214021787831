import { createBrowserHistory } from "history"
import { setReduxState } from "@/redux"

import { updateDocumentTitle } from "../functions/updateDocumentTitle"
import { RoutesState } from "../model"
export const BROWSER_HISTORY = createBrowserHistory()
updateDocumentTitle(BROWSER_HISTORY.location.pathname)

BROWSER_HISTORY.listen(location => {
  setReduxState("routes", { pathName: location.pathname as RoutesState["pathName"] })
})