import { setReduxState } from "@/redux"
import { Args, Vehicle, VehicleRequests } from "@/shared"

import { handleSocketRequest } from "../functions"



export function getManyVehicles(params: VehicleRequests["getMany"]["params"] & { isLoadingHidden?: boolean },
  callback: (vehicleList: Vehicle[]) => void
): void {
  handleSocketRequest(
    "vehicles",
    "getMany",
    {
      ...params
    },
    vehicleList => {
      callback(vehicleList as Args<typeof callback>[0])
    }, err => {
      setReduxState("ui", {
        snackbar: {
          message: err,
          severity: "error"
        }
      })
    }
  )
}