import { getReduxState } from "@/redux"
import { tw } from "@/shared"

import { AllVehicleHeadColumns } from "../model"

export function getVehicleFieldClassName(val: AllVehicleHeadColumns): string {
  const { editedTableCell } = getReduxState().vehicles
  if (val === editedTableCell || !editedTableCell) {
    return ""
  } else {
    return tw`hidden`
  }
}