import { BottomRightButton } from "./BottomRightButton"
import { UsersTable } from "./UsersTable"
import { UsersFilters } from "./UsersFilters"


export function Users(): JSX.Element {
  return (
    <div className="row">
      <UsersFilters />
      <UsersTable />
      <BottomRightButton />
    </div>
  )

}