import { setReduxState, useReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { searchVehicles } from "../Search/functions"

import { HotCategoriesStyled } from "./HotCategoriesStyled"

// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
// tslint:disable-next-line:no-var-requires
const Zoom = require("react-reveal/Zoom")

const HotItem = ({ label, onClick, image }: {
  label: string,
  onClick: () => void,
  image: string
}) => (
  <Zoom>
    <div
      className="hot-item"
      onClick={() => onClick()}
    >
      <img alt={image} src={`./images/${image}`} />
      <h3>{label}</h3>
    </div>
  </Zoom>
)

export function HotCategories(): JSX.Element {
  const { say } = useReduxState(state => ({
    say: state.languages.say,
  }))

  return (
    <HotCategoriesStyled>
      <Fade top={true}>
        <h1>{say.hotCategories}</h1>
      </Fade>
      <div className="hot-list">
        <HotItem
          label={say.sedan}
          onClick={() => {
            goToExactPath("/search")
            setReduxState("search", { bodyStyleList: ["sedan"] }, () => searchVehicles())
          }}
          image="sedan-alt.svg"
        />
        <HotItem
          label={say.hatchback}
          onClick={() => {
            goToExactPath("/search")
            setReduxState("search", { bodyStyleList: ["hatchback"] }, () => searchVehicles())
          }}
          image="hatchback-alt.svg"
        />
        <HotItem
          label={say.sport}
          onClick={() => {
            goToExactPath("/search")
            setReduxState("search", { bodyStyleList: ["sport"] }, () => searchVehicles())
          }}
          image="sport-alt.svg"
        />
        <HotItem
          label={say.suv}
          onClick={() => {
            goToExactPath("/search")
            setReduxState("search", { bodyStyleList: ["suv"] }, () => searchVehicles())
          }}
          image="suv-alt.svg"
        />
        <HotItem
          label={say.pickup}
          onClick={() => {
            goToExactPath("/search")
            setReduxState("search", { bodyStyleList: ["pickup"] }, () => searchVehicles())
          }}
          image="pickup-alt.svg"
        />
        <HotItem
          label={say.minivan}
          onClick={() => {
            goToExactPath("/search")
            setReduxState("search", { bodyStyleList: ["minivan"] }, () => searchVehicles())
          }}
          image="minivan-alt.svg"
        />
      </div>
    </HotCategoriesStyled>
  )
}