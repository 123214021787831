import { IS_DESKTOP, OS } from "@/constants"
import { Theme } from "@/ui/model"
import { css, FlattenSimpleInterpolation } from "styled-components"

import { ShadeEffectOptions } from "../styles/model"

export function rem(pixelSize: number): string {
  return `${pixelSize / 16}rem`
}
export function aspectRatio(width: number, height: number): FlattenSimpleInterpolation {
  return css`
    &::before {
      float: left;
      padding-top: ${height / width * 100}%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  `
}
export const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const clickable = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
`

export const NoScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`
export const breakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
} as const

export const noSelection = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const noScrollbar = css`
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
`

export const getNotch = (postion: "left" | "right" | "top" | "bottom"): string => (
  OS === "android" ? `var(--safe-area-inset-${postion})` : `env(safe-area-inset-${postion})`
)

export const shadeEffect = (
  color: keyof Theme | "white" | "transparent",
  type: "color" | "background" | "border-color",
  options?: ShadeEffectOptions
) => {
  const { tags, isImportant, incrementation, whiteShadeType = "grey" } = options || {} as ShadeEffectOptions
  const colorType = (color || "").replace(/[0-9]/g, "")
  const colorIntensity = (color || "").replace(/[a-z]/g, "")
  const isDecreasing: boolean = Number(colorIntensity) >= 700
  let finalIncrementation: [number, number] = incrementation || [100, 200]
  if (isDecreasing) {
    finalIncrementation = finalIncrementation.map((num: number) => -num) as [number, number]
  }
  const colorHover = (
    color === "white" || color === "transparent" ?
      `${whiteShadeType}${finalIncrementation[0]}` :
      `${colorType}${Number(colorIntensity) + finalIncrementation[0]}`
  ) as keyof Theme
  const colorActive = (
    color === "white" || color === "transparent" ?
      `${whiteShadeType}${finalIncrementation[1]}` :
      `${colorType}${Number(colorIntensity) + finalIncrementation[1]}`
  ) as keyof Theme
  const tagListFinal = tags ? tags : ["&"]
  const finalTags = tagListFinal.map((tag: string) => tag === "&" ? "&" : `& ${tag}`).join(", ")
  const tagsHover = tagListFinal.map((tag: string) => tag === "&" ? "&:hover" : `&:hover ${tag}`).join(", ")
  const tagsActive = tagListFinal.map((tag: string) => tag === "&" ? "&:active" : `&:active ${tag}`).join(", ")
  const importantTag = isImportant ? " !important" : ""
  if (IS_DESKTOP) {
    return css`
    ${clickable}
      ${finalTags} {
        ${`${type}: ${color === "white" || color === "transparent" ? color : window.theme[color]}${importantTag};`}
      }
      ${tagsHover} {
        ${`${type}: ${window.theme[colorHover]}${importantTag};`}
      }
      ${tagsActive} {
        ${`${type}: ${window.theme[colorActive]}${importantTag};`}
      }
    `
  } else {
    return css`
      ${clickable}
      ${finalTags} {
        ${`${type}: ${color === "white" || color === "transparent" ? color : window.theme[color]}${importantTag};`}
      }
      ${tagsActive} {
        ${`${type}: ${window.theme[colorHover]}${importantTag};`}
      }
    `
  }
}
