import styled from "styled-components"
import {
  rem
} from "@/ui/mixins"


export const PageStyled = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    width: calc(100% - 40px);
    max-width: 1140px;
    padding: 0 20px;
    & > div {
      padding-bottom: 20px;
      margin: 100px 0 30px 0;
    }
    h2 {
      font-size: ${rem(24)};
      padding: ${rem(20)} 0;
      color: ${() => window.theme.secondary900};
    }
    strong {
       display: flex;
      font-weight: 400;
      font-size: ${rem(18)};
      padding: ${rem(30)} 0 ${rem(10)} 0;
      color: ${() => window.theme.secondary900};
    }
    p {
      padding: ${rem(10)} 0;
      color: ${() => window.theme.grey500};
    }
    li {
      line-height: 150%;
      color: ${() => window.theme.grey500};
    }
    a {
      margin: 0 10px;
      color: ${() => window.theme.primary300};
      text-decoration: underline;
    }
    @media screen and (max-width: 600px) {
      & > div {
        width: 100vw !important;
        padding: ${rem(10)};
      }
    }
`