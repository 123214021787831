import styled from "styled-components"

export const RoutesStyled = styled.div<{}>`
  display: flex;
  flex-direction: column;
  .page-box {
    display: flex;
    flex-direction: column;
  }
`
