export const VEHICLE_BODY_STYLE_LIST = [
  "sedan",
  "minivan",
  "suv",
  "hatchback",
  "cabriolet",
  "sport",
  "pickup",
  // "offroad",
  // "minitruck",
  // "micro",
  // "van",
  // "campervan",

] as const