import { AutoComplete, Button, Fieldset, Input } from "@/components"
import { simplifyString } from "@/functions"
import { getVehicleFieldClassName } from "@/pages/Vehicles/functions/getVehicleFieldClassName"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import {
  AllVehicleBodyStyles,
  AllVehicleBrands,
  VEHICLE_BODY_STYLE_LIST,
  VEHICLE_BRAND_LIST,
  VEHICLE_MODEL_LIST_BY_VEHICLE_BRAND,
  VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND
} from "@/shared"
import moment from "moment"

import { incrementCreateVehicleViewIndex } from "../functions/incrementCreateVehicleViewIndex"

import { FormFooter } from "./FormFooter"

export function VehicleForm(): JSX.Element {
  const {
    say,
    brandField,
    brand,
    otherBrand,
    modelField,
    model,
    isInvalidField,
    year,
    yearField,
    bodyStyle,
    bodyStyleField,
    exteriorColor,
    interiorColor,
    doorCountField,
    seatingCountField,
    viewIndex,
    isSellVehicle,
    vinIdField
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    isSellVehicle: state.routes.firstPath === "sell-vehicle",
    brandField: state.createVehicle.brandField,
    brand: state.createVehicle.brand,
    otherBrand: state.createVehicle.otherBrand,
    modelField: state.createVehicle.modelField,
    model: state.createVehicle.model,
    yearField: state.createVehicle.yearField,
    year: state.createVehicle.year,
    bodyStyle: state.createVehicle.bodyStyle,
    vinIdField: state.createVehicle.vinIdField,
    exteriorColor: state.createVehicle.exteriorColor,
    interiorColor: state.createVehicle.interiorColor,
    bodyStyleField: state.createVehicle.bodyStyleField,
    doorCountField: state.createVehicle.doorCountField,
    seatingCountField: state.createVehicle.seatingCountField,
    viewIndex: state.createVehicle.viewIndex,
    editedTableCell: state.vehicles.editedTableCell,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  return (
    <div>
      <div className="horizontal-box">
        <Fieldset className={getVehicleFieldClassName("brand")} label={say.selectBrand} isRequired={true}>
          <AutoComplete
            filter={(value: AllVehicleBrands) => simplifyString(say[value]).includes(simplifyString(brandField))}
            inputProps={{
              mobileFocusLabel: say.selectBrand,
              iconName: "cars",
              isError: isInvalidField && !brand,
              value: brandField,
              placeholder: say[VEHICLE_BRAND_LIST[0]!],
              onChange: evt => setReduxState("createVehicle", { brandField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: brand,
              optionList: VEHICLE_BRAND_LIST,
              sort: (
                val1: AllVehicleBrands,
                val2: AllVehicleBrands,
              ) => val1 === "other" ? -1 : val2 === "other" ? 1 : say[val1].localeCompare(say[val2]),
              outputMap: (value: AllVehicleBrands) => say[value],
              onChange: (value: AllVehicleBrands) => value === brand ? setReduxState("createVehicle", {
                brand: value,
                brandField: say[value],
              }) : setReduxState("createVehicle", {
                brand: value,
                brandField: say[value],
                model: undefined,
                modelField: ""
              })
            }}
          />
        </Fieldset>
        {brand === "other" && (
          <Fieldset className={getVehicleFieldClassName("brand")} label={say.otherBrand}>
            <Input
              mobileFocusLabel={say.otherBrand}
              isError={isInvalidField && !otherBrand}
              isTrimOnBlur={true}
              value={otherBrand}
              iconName="car"
              onChange={evt => setReduxState("createVehicle", { otherBrand: evt.target.value })}
            />
          </Fieldset>
        )}
        {brand && (
          <Fieldset className={getVehicleFieldClassName("brand")} label={say.selectModel} isRequired={true}>
            <AutoComplete
              filter={value => simplifyString(value).includes(simplifyString(modelField))}
              inputProps={{
                mobileFocusLabel: say.selectModel,
                iconName: "car",
                isError: isInvalidField && !model,
                value: modelField,
                placeholder: VEHICLE_MODEL_LIST_BY_VEHICLE_BRAND[brand][0],
                onChange: evt => setReduxState("createVehicle", { modelField: evt.target.value })
              }}
              dropdownProps={{
                selectedValue: model,
                sort: (
                  val1: string,
                  val2: string,
                ) => VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][val1]!.localeCompare(VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][val2]!),
                optionList: VEHICLE_MODEL_LIST_BY_VEHICLE_BRAND[brand!] || [],
                outputMap: (value: string) => VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][value],
                onChange: (value: string) => (
                  setReduxState("createVehicle", {
                    model: value,
                    modelField: VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[brand][value]
                  })
                )
              }}
            />
          </Fieldset>
        )}
        <Fieldset className={getVehicleFieldClassName("year")} label={say.selectYear} isRequired={true}>
          <AutoComplete
            filter={value => simplifyString(value).includes(simplifyString(yearField))}
            inputProps={{
              mobileFocusLabel: say.selectYear,
              iconName: "calendar-alt",
              isError: isInvalidField && !year,
              value: yearField,
              placeholder: moment().subtract(5, "years").format("YYYY"),
              onChange: evt => setReduxState("createVehicle", { yearField: evt.target.value.split(/[^0-9]/).join("") })
            }}
            dropdownProps={{
              selectedValue: year,
              optionList: Array(moment().get("year") - 1972 + 1).fill(0).map((_, index) => String(index + 1972)).reverse(),
              outputMap: value => String(value),
              onChange: value => (
                setReduxState("createVehicle", {
                  year: Number(value),
                  yearField: value
                })
              )
            }}
          />
        </Fieldset>
      </div>
      <div className="horizontal-box">
        <Fieldset className={getVehicleFieldClassName("bodyStyle")} label={say.selectBodyStyle} isRequired={true}>
          <AutoComplete
            filter={(value: AllVehicleBodyStyles) => simplifyString(say[value]).includes(simplifyString(bodyStyleField))}
            inputProps={{
              mobileFocusLabel: say.selectBodyStyle,
              iconName: "car-side",
              isError: isInvalidField && !bodyStyle,
              value: bodyStyleField,
              placeholder: say[VEHICLE_BODY_STYLE_LIST[0]!],
              onChange: evt => setReduxState("createVehicle", { bodyStyleField: evt.target.value })
            }}
            dropdownProps={{
              selectedValue: bodyStyle,
              optionList: VEHICLE_BODY_STYLE_LIST,
              sort: (
                val1: AllVehicleBodyStyles,
                val2: AllVehicleBodyStyles,
              ) => say[val1].localeCompare(say[val2]),
              outputMap: (value: AllVehicleBodyStyles) => (
                <>
                  {say[value]}<img className="h-8 ml-5" alt={value} src={"./images/" + value + ".svg"} />
                </>
              ),
              onChange: (value: AllVehicleBodyStyles) => setReduxState("createVehicle", {
                bodyStyle: value,
                bodyStyleField: say[value],
              })
            }}
          />
        </Fieldset>
        <Fieldset className={getVehicleFieldClassName("exteriorColor")} label={say.selectExteriorColor} isRequired={true}>
          <Input
            mobileFocusLabel={say.selectExteriorColor}
            iconName="palette"
            isError={isInvalidField && !exteriorColor.trim()}
            value={exteriorColor}
            placeholder={say.red}
            onChange={evt => setReduxState("createVehicle", { exteriorColor: evt.target.value.charAt(0).toUpperCase() + evt.target.value.slice(1) })} />
        </Fieldset>
        <Fieldset className={getVehicleFieldClassName("interiorColor")} label={say.selectInteriorColor} isRequired={true}>
          <Input
            mobileFocusLabel={say.selectInteriorColor}
            isError={isInvalidField && !interiorColor.trim()}
            iconName="palette"
            value={interiorColor}
            placeholder={say.black}
            onChange={evt => setReduxState("createVehicle", { interiorColor: evt.target.value.charAt(0).toUpperCase() + evt.target.value.slice(1) })} />
        </Fieldset>
      </div>
      {!isSellVehicle && (
        <>
          <div className="horizontal-box">
            <Fieldset className={getVehicleFieldClassName("doors")} label={say.selectDoors}>
              <Input
                mobileFocusLabel={say.selectDoors}
                isError={!!(!isSellVehicle && doorCountField && Number(doorCountField) < 1)}
                iconName="door-closed"
                value={doorCountField}
                inputMode="numeric"
                placeholder="2"
                onChange={evt => setReduxState("createVehicle", { doorCountField: evt.target.value.split(/[^0-9]/).join("") })} />
            </Fieldset>
            <Fieldset className={getVehicleFieldClassName("seatingCount")} label={say.selectSeatingCount}>
              <Input
                mobileFocusLabel={say.selectSeatingCount}
                isError={!!(!isSellVehicle && seatingCountField && Number(seatingCountField) < 1)}
                iconName="chair-office"
                value={seatingCountField}
                inputMode="numeric"
                placeholder="5"
                onChange={evt => setReduxState("createVehicle", { seatingCountField: evt.target.value.split(/[^0-9]/).join("") })} />
            </Fieldset>
          </div>
        </>
      )}
      <Fieldset className={getVehicleFieldClassName("vinId")} label={say.selectVin} isRequired={true}>
        <Input
          mobileFocusLabel={say.selectVin}
          iconName="hashtag"
          isError={isInvalidField && vinIdField.length !== 17}
          value={vinIdField}
          placeholder="1G1AF1F57A7192174"
          onChange={evt => setReduxState("createVehicle", { vinIdField: evt.target.value.split(" ").join("").toUpperCase().slice(0, 17) })} />
      </Fieldset>
      <FormFooter>
        {viewIndex !== 0 && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => incrementCreateVehicleViewIndex(false)}
          >
            {say.previous}
          </Button>
        )}
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateVehicleViewIndex(true)}
        >
          {say.next}
        </Button>
      </FormFooter>
    </div>
  )
}
