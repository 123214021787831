export class AdjustTableWidth {
  private maxWidthList: number[] = []
  setAll(headElem: HTMLDivElement | null, bodyElem: HTMLDivElement | null): void {
    if (headElem && bodyElem) {
      const colList = Array.from(headElem!.children).map(child => {
        (child as HTMLElement).style.width = ""
        let width = (child as HTMLElement).offsetWidth + 2
        if (width > 300) {
          width = 300
        }
        return [width]
      })
      Array.from(bodyElem!.children).forEach(col => {
        const childList = Array.from(col.children)
        if (!childList.length) {
          return
        }
        childList.forEach((child, index) => {
          (child as HTMLElement).style.width = "";
          (child.firstChild as HTMLElement).style.justifyContent = ""
          let width = (child as HTMLElement).offsetWidth + 2
          if (width > 300) {
            width = 300
          }
          colList[index]?.push(width)
        })
      })
      this.maxWidthList = colList.map(col => Math.max(...col))
      Array.from(headElem!.children).forEach((child, index) => {
        (child as HTMLElement).style.width = `${this.maxWidthList[index]}px`
      })
      Array.from(bodyElem!.children).forEach(col => {
        const childList = Array.from(col.children)
        if (!childList.length) {
          return
        }
        childList.forEach((child, index) => {
          (child as HTMLElement).style.width = `${this.maxWidthList[index]}px`
          if (this.maxWidthList[index] === 300) {
            (child.firstChild as HTMLElement).style.justifyContent = "flex-start"
          }
        })
      })
    }
  }

  setOne(col: HTMLElement): void {
    const childList = Array.from(col.children)
    if (!childList.length || !this.maxWidthList.length) {
      return
    }
    childList.forEach((child, index) => {
      (child as HTMLElement).style.width = `${this.maxWidthList[index]}px`
      if (this.maxWidthList[index] === 300) {
        (child.firstChild as HTMLElement).style.justifyContent = "flex-start"
      }
    })
  }
}
