import { deleteOneBug, getManyFiles } from "@/api/requests"
import { Icon, ImageGallery, Output, TextArea } from "@/components"
import { setReduxState, useReduxState, useWillMount } from "@/redux"
import { Bug, PublicFile } from "@/shared"
import moment from "moment"
import { useState } from "react"

export function BugReportItem({
  refreshBugs,
  _id,
  imageIdList,
  operatingSystem,
  message,
  creationDate,
}: Bug & { refreshBugs: () => void }): JSX.Element {
  const { say } = useReduxState(state => ({
    say: state.languages.say
  }))
  const [imageFileList, setImageFileList] = useState<PublicFile[]>([])
  useWillMount(() => {
    getManyFiles(imageIdList, false, fileList => setImageFileList(fileList))
  })
  return (
    <div className="bug-item">
      <Output label={say.operatingSystem} value={operatingSystem} />
      <Output label={say.date} value={moment(creationDate).format(say.yearDateShort)} />
      <TextArea
        value={message}
        isReadonly={true}
      />
      {!!imageIdList.length && <ImageGallery imageList={imageFileList} />}
      <Icon
        name="trash-alt"
        onClick={() => setReduxState("popups", {
          name: "SafeAction",
          props: {
            title: say.askDeleteBug,
            action: () => { deleteOneBug(_id, () => refreshBugs()) }
          }
        })}
      />
    </div>
  )
}