import React from "react"
import { ReduxState } from "@/redux/model"
import { PageStyled } from "@/styles/PageStyled"
import { useReduxState } from "@/redux"

import en from "./en"
import fr from "./fr"

const languageMap = {
  en,
  fr
}
export function Terms(): JSX.Element {
  const { selectedLanguage } = useReduxState((state: ReduxState) => ({
    selectedLanguage: state.languages.selectedLanguage
  }))
  return (
    <PageStyled>
      <div>{languageMap[selectedLanguage] || en}</div>
    </PageStyled>
  )
}
