import { center, shadeEffect, clickable } from "@/ui/mixins"
import styled from "styled-components"

export const ToggleStyled = styled.div`
  ${center};
  ${clickable};
  height: 40px;
  padding: 0 20px;
  border-radius: 9999px;
  margin: 0 5px 5px 0;
  img {
    height: 32px;
    margin-left: 15px;
  }
  &.active {
    ${() => shadeEffect("secondary900", "background")}
    color: white;
  }
  &:not(.active) {
    color: ${() => window.theme.grey100};
    border: 1px solid ${() => window.theme.grey600};
    ${() => shadeEffect("grey750", "background", { incrementation: [50, 150] })}
  }
`