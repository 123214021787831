import { IS_DESKTOP } from "@/constants"
import { breakpoints, clickable } from "@/ui/mixins"
import styled, { css } from "styled-components"

export const HotCategoriesStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  h1 {
    text-align: center;
    color: white;
    margin-bottom: 40px;
  }
  .hot-list {
    display: grid;
    grid-template-columns: min(calc(100vw - 40px), 350px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-rows: min(calc(100vw - 40px), 200px);
    @media screen and (min-width: ${breakpoints.sm}) {
      grid-template-columns: repeat(2, min(calc(50vw - 30px), 350px));
      grid-auto-rows: min(calc(50vw - 30px), 250px);
    }
    @media screen and (min-width: ${breakpoints.md}) {
      grid-template-columns: repeat(3, min(calc(33vw - 20px), 350px));
      grid-auto-rows: min(calc(33vw - 20px), 250px);
    }
  }
  .background {
    width: 100%;
    opacity: 0.3;
  }
  .hot-item {
    ${clickable};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    ${() => {
    if (IS_DESKTOP) {
      return css`
        &:hover {
          transition: all 0.3s ease-in-out !important;
          transform: scale(1.03) !important;
          background: ${() => window.theme.secondary900}22;
        }
        &:active {
          background: ${() => window.theme.secondary900}33;
        }
      `
    } else {
      return css`
        &:active {
          transform: scale(1.015);
          background: ${() => window.theme.secondary900}22;
        }
      `
    }
  }}
  }
  img {
    width: 70%;
    margin-bottom: 20px;
    opacity: 0.9;
  }
  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color:  ${() => window.theme.secondary900};
  }
`