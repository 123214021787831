import { breakpoints, center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const FormContentStyled = styled.div`
  max-width: 1000px;
  width: 100vw;
  margin-bottom: 20px;
  padding-bottom: 20px;
  min-height: 300px;
  border-top: 4px double ${() => window.theme.secondary900};
  border-bottom: 4px double ${() => window.theme.secondary900};
  padding: 20px 20px 20px 20px;
  .view-index {
    bottom: 40px;
  }

  .welcome {
    ${center};
    flex-direction: column;
    h2, p {
      text-align: center;
    }
    h2 {
      margin-bottom: 10px;
    }
    p {
      color: ${() => window.theme.grey500};
      font-size: 18px;
      margin-bottom: 20px;
    }
    .fieldset {
      max-width: 400px !important;
    }
    .fal-handshake {
      height: 200px;
      margin-bottom: 20px;
      color: ${() => window.theme.secondary900};
    }
    .fal-money-check-edit-alt {
      height: 150px;
      margin-bottom: 20px;
      color: ${() => window.theme.secondary900};
    }
  }
  .vertical
  .input .fal-plus {
    ${center};
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    margin-right: 5px;
    flex-shrink: 0;
    ${() => shadeEffect("primary500", "background")}
    color: white;
    svg {
      height: 20px;
    }
  }
  .radio-group {
    color: ${() => window.theme.grey300};
  }
  .insurance-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div, & > button, &> img {
      margin-bottom: 20px;
      text-transform: lowercase;
    }
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      border: 1px solid ${() => window.theme.secondary200};
    }
  }

  .input {
    flex: 1;
  }
  .view-switcher {
    &> div > div {
      height: auto;
    }
    &> div > div > div {
      height: 100%;
      min-height: 50vh;
      overflow: hidden;
      padding: 20px 20px 20px 20px;
    }
  }
    .view-index {
    }
  .save-address-button,
  .current-location-button,
  .current-address-button {
    text-transform: none;
    .fa-icon {
      ${center}
      height: 25px;
      width: 25px;
      margin-right: 12px;
      padding: 7px;
      border-radius: 9999px;
      background: ${() => window.theme.primary500};
      color: white;
    }
  }
  .current-address-button{
    .fa-icon {
      background: ${() => window.theme.secondary800};
    }
  }
  .current-location-button{
    .fa-icon {
      background: ${() => window.theme.primary500};
    }
  }
  .save-address-button {
    color: ${() => window.theme.secondary800};
    .fa-icon {
      background: ${() => window.theme.secondary800};
    }
  }
  h4 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .delete-portion-button {
    ${center};
    flex: initial !important;
    height: 40px;
    width: 40px !important;
    color: white;
    padding-left: 0 !important;
    border-radius: 9999px;
    ${() => shadeEffect("primary500", "background")}
    svg {
      height: 20px;
    }
  }
  .more-prices-button {
    ${center};
    flex-direction: row-reverse;
    height: 40px;
    padding: 0 20px;
    color: ${() => window.theme.primary500};
    border-radius: 9999px;
    margin-bottom: 30px;
    ${() => shadeEffect("primary50", "background", { incrementation: [50, 150] })}
    svg {
      margin-left: 10px;
      height: 20px;
    }
  }
  .horizontal-box {
    display: flex;
    flex-direction: column;
  }
  .center-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      font-size: 14px;
    }
  }
  .footer {
    justify-content: flex-end;
    flex-direction: column;
    flex-grow: 1;
    & > div {
      justify-content: flex-end;
      display: flex;
    }
    .padding {
      flex: 1;
    }
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    border-radius: 24px;
    height: 100%;
    width: calc(100vw - 80px);
    border: 4px double ${() => window.theme.secondary900};
    .horizontal-box {
      flex-direction: row;
      align-items: center;
      &> div {
        flex: 1;
        &:not(:first-child) {
          padding-left: 10px;
        }
        &:not(:last-child) {
          padding-right: 10px;
        }
      }
    }
    .view-switcher {
      height: initial;
      &> div > div > div {
        padding: 40px;
      }
    }
    .view-index {
      position: absolute;
      bottom: 20px;
    }
    .view-index {
      bottom: 35px;
    }
  }
`