import { Button, SafeFixed, Tabs } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { goToExactPath } from "@/routes"
import { PrivateRoute } from "@/routes/components/PrivateRoute"

import { Brokerages } from "../Brokerages"
import { Users } from "../Users"
import { Vehicles } from "../Vehicles"

export function Dashboard(): JSX.Element {
  const { say, pathName, isFilters, isLess400 } = useReduxState(state => ({
    say: state.languages.say,
    pathName: state.routes.pathName,
    isFilters: state.ui.isFilters,
    isLess400: state.ui.vw! <= 440

  }))
  const optionList = [
    "brokerages",
    "vehicles",
    "users"
  ] as const
  return (
    <>
      <SafeFixed>
        <Tabs
          className={`!fixed  top-[100px] ${isFilters ? "left-[400px] w-[calc(100%-400px)]" : "left-0 w-full"}`}
          value={optionList.find(item => pathName.includes(item)) || undefined!}
          optionList={optionList}
          outputMap={val => say[val]}
          onChange={val => val && goToExactPath(`/dashboard/${val}` as `/dashboard/${typeof optionList[number]}`)}
        />
        <Button
          iconName="sliders-h"
          className={`absolute z-[200] top-[140px]  rounded-none h-[48px] ${isFilters ? isLess400 ? "left-[calc(100%-40px)]" : "left-[400px]" : "left-0"}`}
          color="primary"
          isCircle={true}
          onClick={() => setReduxState("ui", { isFilters: !isFilters })}
        />
      </SafeFixed>
      <PrivateRoute isExact={true} path="/dashboard/brokerages" component={Brokerages} />
      <PrivateRoute isExact={true} path="/dashboard/vehicles" component={Vehicles} />
      <PrivateRoute isExact={true} path="/dashboard/users" component={Users} />
    </>
  )
}