import { Table } from "@/components"
import { useReduxState } from "@/redux"

import { BROKERAGE_TABLE_HEAD_LIST } from "../constants/BROKERAGE_TABLE_HEAD_LIST"

import { LeftButtonList } from "./LeftButtonList"

export function BrokeragesTable(): JSX.Element {
  const { tableBrokerageIdList, say, outputMapByBrokerageId, shownColumnList, isFilters } = useReduxState(state => {
    return {
      say: state.languages.say,
      shownColumnList: state.brokerages.shownColumnList,
      tableBrokerageIdList: state.brokerages.tableBrokerageIdList,
      outputMapByBrokerageId: state.brokerages.outputMapByBrokerageId,
      isFilters: state.ui.isFilters,
    }
  })
  return (
    <>
      <LeftButtonList tableBrokerageIdList={tableBrokerageIdList} />
      <div className={`flex mt-[140px] ${isFilters ? "ml-[400px]" : "ml-0"}`} >
        <Table
          filter={colId => shownColumnList.includes(colId)}
          headList={BROKERAGE_TABLE_HEAD_LIST.map(key => say[key])}
          colIdList={[...BROKERAGE_TABLE_HEAD_LIST]}
          rowIdList={tableBrokerageIdList}
          bodyList={tableBrokerageIdList.map(_id => outputMapByBrokerageId[_id]!)}
        />
      </div>
    </>
  )
}