import { useReduxState } from "@/redux"
import { tw } from "@/shared"

import { Logo } from "./Logo"
import { DevKGProps } from "./model"

export function DevKG({ isDark }: DevKGProps): JSX.Element {
  const { say } = useReduxState(state => ({
    say: state.languages.say,
  }))
  return (
    <a
      className="flex items-center w-max children-div:mouse:hover:underline"
      href="https://devkg.ca"
      target="_blank"
      referrerPolicy="no-referrer"
      rel="noreferrer"
    >
      <div className={tw`mr-2 leading-loose text-center shrink-0 ${isDark ? "text-grey-300" : "text-grey-500"}`}>{say.developedBy}</div>
      <Logo isDark={isDark} />
    </a>
  )
}