import { deleteOneSession } from "@/api/requests"
import { IS_INVENTORY } from "@/constants"
import { setReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { goToExactPath } from "@/routes"
import { AllLanguages, LANGUAGE_LIST } from "@/shared"
import { Component } from "react"
import { connect, ConnectedProps } from "react-redux"

import { Icon, Select } from ".."

import { NavItem } from "./NavItem"
import { NavStyled } from "./NavStyled"
import { Palestine } from "../Palestine"
// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
export class NavNotConnected extends Component<ConnectedProps<typeof connector>, { isOpened: boolean }> {
  constructor(props: NavNotConnected["props"]) {
    super(props)
    this.state = {
      isOpened: props.isLarge ? true : false
    }
  }

  componentDidUpdate(oldProps: NavNotConnected["props"]): void {
    if (!oldProps.isLarge && this.props.isLarge && !this.state.isOpened) {
      this.setState({ isOpened: true })
    }
    if (oldProps.isLarge && !this.props.isLarge && this.state.isOpened) {
      this.setState({ isOpened: false })
    }
  }
  render(): JSX.Element {
    const { isOpened } = this.state
    const { say, isCurrentUser, isAdmin } = this.props
    const loginOptionList = isAdmin ? [
      "createVehicle",
      "createUser",
      "dashboard",
      "logout"
    ] as const : [
      "createVehicle",
      "dashboard",
      "logout"
    ] as const

    type AllLoginOptions = typeof loginOptionList[number]
    const loginActionMap: { [key in AllLoginOptions]: () => void } = {
      createVehicle: () => goToExactPath("/create-vehicle"),
      dashboard: () => goToExactPath("/dashboard/brokerages"),
      createUser: () => goToExactPath("/signin"),
      logout: () => deleteOneSession(true),
    }
    const onItemClick = (callback: () => void) => {
      callback()
      if (this.state.isOpened && !this.props.isLarge) {
        this.setState({ isOpened: false })
      }
    }
    return (
      <>
        <Palestine />
        <NavStyled>
          <Fade left={true}>
            <div className="left-box">
              <img src="/images/logo.svg" alt="logo" className="h-14" onClick={() => goToExactPath("/home")} />
            </div>
          </Fade>
          <Fade right={true}>
            <>
              <Icon name="bars" onClick={() => this.setState({ isOpened: !isOpened })} />
              {isOpened && (
                <div className="right-box">
                  <NavItem
                    label={say.home}
                    path="/home"
                    onClick={() => onItemClick(() => goToExactPath("/home"))}
                  />
                  <NavItem
                    label={say.sellVehicle}
                    path="/sell-vehicle"
                    onClick={() => onItemClick(() => goToExactPath("/sell-vehicle"))}
                  />
                  {IS_INVENTORY && (
                    <NavItem
                      label={say.inventory}
                      path="/search"
                      onClick={() => onItemClick(() => goToExactPath("/search"))}
                    />
                  )}
                  <NavItem
                    label={say.brokerageService}
                    path="/brokerage"
                    onClick={() => onItemClick(() => goToExactPath("/brokerage"))}
                  />
                  {/* <NavItem
                  label={say.aboutUs}
                  path="/about-us"
                  onClick={() => goToExactPath("/about-us")}
                /> */}
                  {!isCurrentUser && (
                    <div className="nav-icon" onClick={() => onItemClick(() => goToExactPath("/login"))}>
                      <Icon name="sign-in" />
                    </div>
                  )}
                  {isCurrentUser && (
                    <Select
                      isStyleDisabled={true}
                      className="nav-icon"
                      dropdownProps={{
                        isRightPosition: true,
                        optionList: loginOptionList,
                        outputMap: (lang: AllLoginOptions) => say[lang],
                        onChange: (value: AllLoginOptions) => onItemClick(() => loginActionMap[value]())
                      }}
                    >
                      <Icon name="user" />
                    </Select>
                  )}
                  <Select
                    isStyleDisabled={true}
                    className="nav-icon"
                    dropdownProps={{
                      isRightPosition: true,
                      optionList: [...LANGUAGE_LIST],
                      outputMap: (lang: AllLanguages) => say[lang],
                      onChange: (value: AllLanguages) => onItemClick(() => setReduxState("languages", { selectedLanguage: value }))
                    }}
                  >
                    <Icon name="globe-americas" />
                  </Select>
                </div>
              )}
            </>
          </Fade>
        </NavStyled >
      </>
    )
  }
}
const connector = connect((state: ReduxState) => ({
  say: state.languages.say,
  isCurrentUser: state.users.currentUser,
  isAdmin: state.users.currentUser?.role === "admin",
  isLarge: state.ui.vw! > 800
}))

export const Nav = connector(NavNotConnected)