import React from "react"

const en = (
  <>
    <h2>Terms & Conditions</h2>
    <p>
      The term used "Avarta Auto" in this privacy policy refers to our website https://avartasolutions.com
      unless otherwise defined in these terms and conditions
    </p>
    <p>
      By browsing Avarta Auto, these terms will be
      automatically applied to you - so you should make sure that you read them
      that you read them carefully before using the website. You are not
      allowed to copy, or modify the application, any part of the web site,
      or our trademarks in any way. You may not attempt to
      extract the source code of the website, and you may not
      attempt to translate the website into other languages, or
      make derivative versions. The website itself and all trade,
      copyright, database rights and other intellectual property rights,
      property rights related thereto are all owned by
      Avarta Auto solutions inc.
    </p>

    <p>
      Avarta Auto solutions inc. is committed to making Avarta Auto
      is as useful and effective as possible. For this reason, we
      reserve the right to make changes to Avarta Auto at any time and for any reason.
    </p>
    <p>
      Avarta Auto stores and processes personal data
      that you have provided to us, in order to provide
      our service.
    </p>
    <p>
      You should be aware that there are certain things that
      Avarta Auto solutions inc. assumes no responsibility for.
      Some features of Avarta Auto will require Avarta Auto to have an active
      active Internet connection. The connection may be Wi-Fi or
      provided by your mobile network provider, but Avarta Auto solutions inc.
      Avarta Auto solutions inc. cannot assume responsibility for the application not working at full functionality if you do not have
      access to Wi-Fi.
    </p>
    <p />
    <p>
      In the same vein, Avarta Auto solutions inc. cannot always
      take responsibility for how you use Avarta Auto, i.e.
      you must ensure that your device stays charged - if it
      and you can't turn it on to take advantage of the Service, Avarta Auto solutions inc.
      service, Avarta Auto Solutions Inc. cannot be held responsible.
      responsible.
    </p>
    <p>
      With respect to Avarta Auto solutions inc.'s liability regarding
      your use of Avarta Auto, when using it, it is important to keep in mind that
      important to keep in mind that, while we strive to ensure that it is updated and
      ensure that it is updated and correct at all times, we do
      rely on third parties to provide us with information so that
      we can make it available to you.
      Avarta Auto Solutions Inc. is not responsible for any loss,
      direct or indirect that you encounter on any third party dependent functionality in the application.
    </p>
    <p>
      À un moment donné, nous souhaiterons peut-être mettre à jour Avarta Auto
      Avarta Auto solutions inc. ne promet pas que
      il mettra toujours à jour Avarta Auto pour qu'il soit pertinent pour vous.
      Nous pouvons également souhaiter cesser de fournir Avarta Auto, et peut mettre fin à
      l'utilisation à tout moment sans préavis. Sauf indication contraire de notre part, en cas de résiliation,
      les droits et licences qui vous sont accordés dans ces conditions
      finira;
    </p>
    <p> <strong>Cycle de vie des véhicules</strong> </p>
    <p>
      Les administrateurs de Avarta Auto se donnent la discrétion d'accepter ou refuser la publication,
      modification suppression et changement d'audience d'un véhicule.
    </p>

    <p> <strong> Modifications de ces termes et conditions </strong> </p>
    <p>
      Nous pouvons mettre à jour nos conditions générales
      de temps en temps. Ainsi, il est conseillé de consulter cette page
      périodiquement pour tout changement. Nous allons
      notify you of any changes by posting the new Terms and
      Terms and Conditions on this page. These changes are effective
      immediately upon posting on this page.
    </p>
    <p> <strong>Contact Us</strong> </p>
    <p>
      If you have any questions or suggestions about
      our Terms and Conditions, please feel free to contact us through the means offered in the contact us section of our website
    </p>
    <p>
      This terms and conditions page was generated by
      <a rel="noopener noreferrer" href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank">
        Application Privacy Rule Generator
      </a>
    </p>
  </>
)

export default en
