import { SetAction } from "../../redux/model"

import { CreateBrokerageState } from "./model"



export const createBrokerage = (currentState: CreateBrokerageState, action: SetAction<"createBrokerage">) => {
  const data = { ...action.data }
  if (data.fuelTypeList && data.fuelTypeList.length > 2) {
    data.fuelTypeList.shift()
  }
  if (data.driveTrainList && data.driveTrainList.length > 2) {
    data.driveTrainList.shift()
  }
  if (data.brandList && data.brandList.length > 3) {
    data.brandList.shift()
  }
  return {
    ...currentState,
    ...data
  }
}
