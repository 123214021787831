import { Table } from "@/components"
import { getReduxState, setReduxState, useReduxState } from "@/redux"

import { VEHICLE_TABLE_HEAD_LIST } from "../constants/VEHICULE_TABLE_HEAD_LIST"
import { editVehicleStatus } from "../functions/editVehicleStatus"
import { presetVehicleForEditing } from "../functions/presetVehicleForEditing"
import { AllVehicleHeadColumns } from "../model"

import { LeftButtonList } from "./LeftButtonList"
import { toggleVehicleRequests } from "./toggleVehicleRequests"

export function VehiclesTable(): JSX.Element {
  const { tableVehicleIdList, say, outputMapByVehicleId, shownColumnList, isFilters } = useReduxState(state => {
    return {
      say: state.languages.say,
      shownColumnList: state.vehicles.shownColumnList,
      tableVehicleIdList: state.vehicles.tableVehicleIdList,
      outputMapByVehicleId: state.vehicles.outputMapByVehicleId,
      isInitView: state.vehicles.isInitView,
      isFilters: state.ui.isFilters,
    }
  })
  return (
    <>
      <LeftButtonList tableVehicleIdList={tableVehicleIdList} />
      <div className={`flex mt-[140px] ${isFilters ? "ml-[400px]" : "ml-0"}`} >
        <Table
          filter={colId => shownColumnList.includes(colId)}
          headList={VEHICLE_TABLE_HEAD_LIST.map(key => say[key])}
          colIdList={[...VEHICLE_TABLE_HEAD_LIST]}
          rowIdList={tableVehicleIdList}
          onDoubleClick={(colId, vehicleId) => {
            const vehicle = getReduxState().vehicles.vehicleMap[vehicleId]!
            if (colId === "audience") {
              setReduxState("popups", {
                name: "SafeAction", props: {
                  title: vehicle.isPublic ? say.makeVehiclePrivate : say.makeVehiclePublic,
                  buttonName: "yes",
                  action: () => toggleVehicleRequests(vehicle._id, !vehicle.isPublic)
                }
              })

            } else if (colId === "status") {
              editVehicleStatus(vehicle._id)
            } else if (colId === "creationDate" || colId === "lastEditDate" || colId === "ownership") {
              return
            } else {
              const tableColMap: { [key in AllVehicleHeadColumns]: AllVehicleHeadColumns } = {
                brand: "brand",
                model: "brand",
                year: "year",
                estimate: "estimate",
                creationDate: "creationDate",
                lastEditDate: "lastEditDate",
                comment: "comment",
                status: "status",
                bodyStyle: "bodyStyle",
                exteriorColor: "exteriorColor",
                interiorColor: "interiorColor",
                doors: "doors",
                seatingCount: "seatingCount",
                vinId: "vinId",
                equipments: "equipments",
                condition: "condition",
                distance: "distance",
                fuelType: "fuelType",
                cityFuelConsumption: "fuelType",
                highwayFuelConsumption: "fuelType",
                transmission: "fuelType",
                cylinderCount: "fuelType",
                driveTrain: "driveTrain",
                price: "price",
                audience: "audience",
                ownership: "ownership",
                firstName: "firstName",
                lastName: "lastName",
                phone: "phone",
                email: "email",
                address: "address",
              }
              presetVehicleForEditing(vehicle, tableColMap[colId])
            }
          }}
          errorMap={({ children }) => typeof children === "string" && children.includes(say.old)}
          bodyList={tableVehicleIdList.map(_id => outputMapByVehicleId[_id]!)}
        />
      </div>
    </>
  )
}