import { OptionalToUndefined } from "@/shared"
import { getStorageItem } from "@/localStorage"

import { BrokeragesState } from "../model"


export function getBrokeragesInitState(): OptionalToUndefined<BrokeragesState> {
  return {
    tableBrokerageIdList: [],
    outputMapByBrokerageId: {},
    brokerageMap: {},
    brokerageList: [],
    brokerageIdList: [],
    editedComment: { html: "", plainText: "" },
    ...getStorageItem("brokerages")
  }
}
