import { AllVehicleBrands } from "."

export const VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND: {
  [brand in AllVehicleBrands]: {
    [model: string]: string
  }
} = {
  "other": {
    "other": "Other",
  },
  "acura": {
    "clModels": "CL Models (4)",
    "22cl": " - 2.2CL",
    "23cl": " - 2.3CL",
    "30cl": " - 3.0CL",
    "32cl": " - 3.2CL",
    "ilx": "ILX",
    "integ": "Integra",
    "legend": "Legend",
    "mdx": "MDX",
    "nsx": "NSX",
    "rdx": "RDX",
    "rlModels": "RL Models (2)",
    "35rl": " - 3.5 RL",
    "rl": " - RL",
    "rsx": "RSX",
    "slx": "SLX",
    "tlModels": "TL Models (3)",
    "25tl": " - 2.5TL",
    "32tl": " - 3.2TL",
    "tl": " - TL",
    "tsx": "TSX",
    "vigor": "Vigor",
    "zdx": "ZDX",
    "acuoth": "Other Acura Models"
  },
  "alfa": {
    "alfa164": "164",
    "alfa8c": "8C Competizione",
    "alfagt": "GTV-6",
    "mil": "Milano",
    "spid": "Spider",
    "alfaoth": "Other Alfa Romeo Models"
  },
  // "amc": {
  //   "amcallian": "Alliance",
  //   "con": "Concord",
  //   "eagle": "Eagle",
  //   "amcenc": "Encore",
  //   "amcspirit": "Spirit",
  //   "amcoth": "Other AMC Models"
  // },
  "aston": {
    "db7": "DB7",
    "db9": "DB9",
    "dbs": "DBS",
    "lagonda": "Lagonda",
    "rapide": "Rapide",
    "v12vant": "V12 Vantage",
    "vantage": "V8 Vantage",
    "vanquish": "Vanquish",
    "virage": "Virage",
    "unavailast": "Other Aston Martin Models"
  },
  "audi": {
    "80": "80",
    "90": "90",
    "4000": "4000",
    "5000": "5000",
    "audi100": "100",
    "audi200": "200",
    "a3": "A3",
    "a4": "A4",
    "a5": "A5",
    "a6": "A6",
    "a7": "A7",
    "a8": "A8",
    "allrdqua": "allroad",
    "audicabri": "Cabriolet",
    "audicoupe": "Coupe",
    "q3": "Q3",
    "q5": "Q5",
    "q7": "Q7",
    "quattr": "Quattro",
    "r8": "R8",
    "rs4": "RS 4",
    "rs5": "RS 5",
    "rs6": "RS 6",
    "s4": "S4",
    "s5": "S5",
    "s6": "S6",
    "s7": "S7",
    "s8": "S8",
    "tt": "TT",
    "ttrs": "TT RS",
    "tts": "TTS",
    "v8": "V8 Quattro",
    "audoth": "Other Audi Models"
  },
  // "avanti": {
  //   "convert": "Convertible",
  //   "coupeavant": "Coupe",
  //   "sedan": "Sedan",
  //   "unavailava": "Other Avanti Models"
  // },
  "bentl": {
    "arnage": "Arnage",
    "azure": "Azure",
    "brooklands": "Brooklands",
    "bencont": "Continental",
    "corniche": "Corniche",
    "beneight": "Eight",
    "benmul": "Mulsanne",
    "benturbo": "Turbo R",
    "unavailben": "Other Bentley Models"
  },
  "bmw": {
    "1Series": "1 Series (3)",
    "128i": " - 128i",
    "135i": " - 135i",
    "135is": " - 135is",
    "3Series": "3 Series (29)",
    "318i": " - 318i",
    "318ic": " - 318iC",
    "318is": " - 318iS",
    "318ti": " - 318ti",
    "320i": " - 320i",
    "323ci": " - 323ci",
    "323i": " - 323i",
    "323is": " - 323is",
    "323it": " - 323iT",
    "325ci": " - 325Ci",
    "325e": " - 325e",
    "325es": " - 325es",
    "325i": " - 325i",
    "325is": " - 325is",
    "325ix": " - 325iX",
    "325xi": " - 325xi",
    "328ci": " - 328Ci",
    "328i": " - 328i",
    "328is": " - 328iS",
    "328xi": " - 328xi",
    "330ci": " - 330Ci",
    "330i": " - 330i",
    "330xi": " - 330xi",
    "335d": " - 335d",
    "335i": " - 335i",
    "335is": " - 335is",
    "335xi": " - 335xi",
    "active3": " - ActiveHybrid 3",
    "bmw325": " - 325",
    "5Series": "5 Series (19)",
    "524td": " - 524td",
    "525i": " - 525i",
    "525xi": " - 525xi",
    "528e": " - 528e",
    "528i": " - 528i",
    "528it": " - 528iT",
    "528xi": " - 528xi",
    "530i": " - 530i",
    "530it": " - 530iT",
    "530xi": " - 530xi",
    "533i": " - 533i",
    "535i": " - 535i",
    "535igt": " - 535i Gran Turismo",
    "535xi": " - 535xi",
    "540i": " - 540i",
    "545i": " - 545i",
    "550i": " - 550i",
    "550igt": " - 550i Gran Turismo",
    "active5": " - ActiveHybrid 5",
    "6Series": "6 Series (8)",
    "633csi": " - 633CSi",
    "635csi": " - 635CSi",
    "640i": " - 640i",
    "640igc": " - 640i Gran Coupe",
    "645ci": " - 645Ci",
    "650i": " - 650i",
    "650igc": " - 650i Gran Coupe",
    "l6": " - L6",
    "7Series": "7 Series (15)",
    "733i": " - 733i",
    "735i": " - 735i",
    "735il": " - 735iL",
    "740i": " - 740i",
    "740il": " - 740iL",
    "740li": " - 740Li",
    "745i": " - 745i",
    "745li": " - 745Li",
    "750i": " - 750i",
    "750il": " - 750iL",
    "750li": " - 750Li",
    "760i": " - 760i",
    "760li": " - 760Li",
    "active7": " - ActiveHybrid 7",
    "alpinab7": " - Alpina B7",
    "8Series": "8 Series (4)",
    "840ci": " - 840Ci",
    "850ci": " - 850Ci",
    "850csi": " - 850CSi",
    "850i": " - 850i",
    "lSeries": "L Series (1)",
    "l7": " - L7",
    "mSeries": "M Series (8)",
    "1seriesm": " - 1 Series M",
    "bmwmcoupe": " - M Coupe",
    "bmwroad": " - M Roadster",
    "m3": " - M3",
    "m5": " - M5",
    "m6": " - M6",
    "x5m": " - X5 M",
    "x6m": " - X6 M",
    "xSeries": "X Series (5)",
    "activex6": " - ActiveHybrid X6",
    "x1": " - X1",
    "x3": " - X3",
    "x5": " - X5",
    "x6": " - X6",
    "zSeries": "Z Series (3)",
    "z3": " - Z3",
    "z4": " - Z4",
    "z8": " - Z8",
    "bmwoth": "Other BMW Models"
  },
  "buick": {
    "cent": "Century",
    "elec": "Electra",
    "enclave": "Enclave",
    "buienc": "Encore",
    "lacrosse": "LaCrosse",
    "lesa": "Le Sabre",
    "lucerne": "Lucerne",
    "park": "Park Avenue",
    "rainier": "Rainier",
    "reatta": "Reatta",
    "reg": "Regal",
    "rendezvous": "Rendezvous",
    "riv": "Riviera",
    "buickroad": "Roadmaster",
    "skyh": "Skyhawk",
    "skyl": "Skylark",
    "somer": "Somerset",
    "terraza": "Terraza",
    "buverano": "Verano",
    "buoth": "Other Buick Models"
  },
  "cad": {
    "allant": "Allante",
    "ats": "ATS",
    "brough": "Brougham",
    "catera": "Catera",
    "cima": "Cimarron",
    "cts": "CTS",
    "dev": "De Ville",
    "dts": "DTS",
    "eldo": "Eldorado",
    "escala": "Escalade",
    "escalaesv": "Escalade ESV",
    "ext": "Escalade EXT",
    "flee": "Fleetwood",
    "sev": "Seville",
    "srx": "SRX",
    "sts": "STS",
    "xlr": "XLR",
    "xts": "XTS",
    "cadoth": "Other Cadillac Models"
  },
  "chev": {
    "astro": "Astro",
    "avalnch": "Avalanche",
    "aveo": "Aveo",
    "aveo5": "Aveo5",
    "berett": "Beretta",
    "blazer": "Blazer",
    "cam": "Camaro",
    "cap": "Caprice",
    "checaps": "Captiva Sport",
    "cav": "Cavalier",
    "cele": "Celebrity",
    "chevette": "Chevette",
    "citation": "Citation",
    "cobalt": "Cobalt",
    "colorado": "Colorado",
    "corsi": "Corsica",
    "corv": "Corvette",
    "cruze": "Cruze",
    "elcam": "El Camino",
    "equinox": "Equinox",
    "g15exp": "Express Van",
    "g10": "G Van",
    "hhr": "HHR",
    "chevimp": "Impala",
    "kodc4500": "Kodiak C4500",
    "lumina": "Lumina",
    "lapv": "Lumina APV",
    "luv": "LUV",
    "mali": "Malibu",
    "chvmetr": "Metro",
    "chevmont": "Monte Carlo",
    "nova": "Nova",
    "chevprizm": "Prizm",
    "chvst": "S10 Blazer",
    "s10pickup": "S10 Pickup",
    "chev150": "Silverado and other C/K1500",
    "chevc25": "Silverado and other C/K2500",
    "ch3500pu": "Silverado and other C/K3500",
    "sonic": "Sonic",
    "spark": "Spark",
    "chevspec": "Spectrum",
    "chsprint": "Sprint",
    "ssr": "SSR",
    "chevsub": "Suburban",
    "tahoe": "Tahoe",
    "tracke": "Tracker",
    "trailblz": "TrailBlazer",
    "trailbzext": "TrailBlazer EXT",
    "traverse": "Traverse",
    "uplander": "Uplander",
    "ventur": "Venture",
    "volt": "Volt",
    "cheoth": "Other Chevrolet Models"
  },
  "chry": {
    "300": "300",
    "chrys200": "200",
    "chry300": "300M",
    "aspen": "Aspen",
    "caravan": "Caravan",
    "cirrus": "Cirrus",
    "conc": "Concorde",
    "chryconq": "Conquest",
    "cordoba": "Cordoba",
    "crossfire": "Crossfire",
    "eclass": "E Class",
    "fifth": "Fifth Avenue",
    "chrygrandv": "Grand Voyager",
    "impe": "Imperial",
    "intrepid": "Intrepid",
    "chrylas": "Laser",
    "leba": "LeBaron",
    "lhs": "LHS",
    "chryneon": "Neon",
    "ny": "New Yorker",
    "newport": "Newport",
    "pacifica": "Pacifica",
    "chprowle": "Prowler",
    "ptcruis": "PT Cruiser",
    "chryseb": "Sebring",
    "chrytc": "TC by Maserati",
    "tandc": "Town &amp; Country",
    "voyager": "Voyager",
    "choth": "Other Chrysler Models"
  },
  // "daew": {
  //   "lanos": "Lanos",
  //   "leganza": "Leganza",
  //   "nubira": "Nubira",
  //   "daeoth": "Other Daewoo Models"
  // },
  // "daihat": {
  //   "char": "Charade",
  //   "rocky": "Rocky",
  //   "daihoth": "Other Daihatsu Models"
  // },
  // "datsun": {
  //   "310": "310",
  //   "510": "510",
  //   "720": "720",
  //   "810": "810",
  //   "dat200sx": "200SX",
  //   "dat210": "210",
  //   "280z": "280ZX",
  //   "300zx": "300ZX",
  //   "datmax": "Maxima",
  //   "datpu": "Pickup",
  //   "pul": "Pulsar",
  //   "datsent": "Sentra",
  //   "stan": "Stanza",
  //   "datoth": "Other Datsun Models"
  // },
  // "delorean": {
  //   "dmc12": "DMC-12"
  // },
  "dodge": {
    "400": "400",
    "dod600": "600",
    "ari": "Aries",
    "avengr": "Avenger",
    "caliber": "Caliber",
    "dodcara": "Caravan",
    "challenger": "Challenger",
    "dodchar": "Charger",
    "dodcolt": "Colt",
    "dodconq": "Conquest",
    "doddw": "D/W Truck",
    "dakota": "Dakota",
    "doddart": "Dart",
    "day": "Daytona",
    "diploma": "Diplomat",
    "durang": "Durango",
    "doddyna": "Dynasty",
    "grandcarav": "Grand Caravan",
    "intre": "Intrepid",
    "journey": "Journey",
    "lancerdodg": "Lancer",
    "magnum": "Magnum",
    "mirada": "Mirada",
    "monaco": "Monaco",
    "dodneon": "Neon",
    "nitro": "Nitro",
    "omni": "Omni",
    "raider": "Raider",
    "ram1504wd": "Ram 1500 Truck",
    "ram25002wd": "Ram 2500 Truck",
    "ram3502wd": "Ram 3500 Truck",
    "ram4500": "Ram 4500 Truck",
    "dodd50": "Ram 50 Truck",
    "cv": "RAM C/V",
    "ramsrt10": "Ram SRT-10",
    "ramvanv8": "Ram Van",
    "ramwagon": "Ram Wagon",
    "ramcgr": "Ramcharger",
    "rampage": "Rampage",
    "dodshad": "Shadow",
    "dodspir": "Spirit",
    "sprinter": "Sprinter",
    "srt4": "SRT-4",
    "stregis": "St. Regis",
    "steal": "Stealth",
    "stratu": "Stratus",
    "viper": "Viper",
    "dooth": "Other Dodge Models"
  },
  // "eagle": {
  //   "eaglemed": "Medallion",
  //   "eagleprem": "Premier",
  //   "summit": "Summit",
  //   "talon": "Talon",
  //   "vision": "Vision",
  //   "eagoth": "Other Eagle Models"
  // },
  "fer": {
    "360": "360",
    "308gtb": "308 GTB Quattrovalvole",
    "308tbi": "308 GTBI",
    "308gts": "308 GTS Quattrovalvole",
    "308tsi": "308 GTSI",
    "328gtb": "328 GTB",
    "328gts": "328 GTS",
    "348gtb": "348 GTB",
    "348gts": "348 GTS",
    "348spi": "348 Spider",
    "348tb": "348 TB",
    "348ts": "348 TS",
    "456gt": "456 GT",
    "456mgt": "456M GT",
    "458italia": "458 Italia",
    "512bbi": "512 BBi",
    "512m": "512M",
    "512tr": "512TR",
    "550m": "550 Maranello",
    "575m": "575M Maranello",
    "599gtb": "599 GTB Fiorano",
    "599gto": "599 GTO",
    "612scaglie": "612 Scaglietti",
    "fercalif": "California",
    "enzo": "Enzo",
    "f355": "F355",
    "f40": "F40",
    "f430": "F430",
    "f50": "F50",
    "ferff": "FF",
    "mond": "Mondial",
    "test": "Testarossa",
    "unavailfer": "Other Ferrari Models"
  },
  "fiat": {
    "2000": "2000 Spider",
    "fiat500": "500",
    "berton": "Bertone X1/9",
    "brava": "Brava",
    "pinin": "Pininfarina Spider",
    "strada": "Strada",
    "fiatx19": "X1/9",
    "unavailfia": "Other Fiat Models"
  },
  // "fisk": {
  //   "karma": "Karma"
  // },
  "ford": {
    "aero": "Aerostar",
    "aspire": "Aspire",
    "bron": "Bronco",
    "b2": "Bronco II",
    "focmax": "C-MAX",
    "fordclub": "Club Wagon",
    "contour": "Contour",
    "courier": "Courier",
    "crownvic": "Crown Victoria",
    "e150econ": "E-150 and Econoline 150",
    "e250econ": "E-250 and Econoline 250",
    "e350econ": "E-350 and Econoline 350",
    "edge": "Edge",
    "escape": "Escape",
    "esco": "Escort",
    "excursion": "Excursion",
    "exp": "EXP",
    "expedi": "Expedition",
    "expediel": "Expedition EL",
    "explor": "Explorer",
    "sporttrac": "Explorer Sport Trac",
    "f100": "F100",
    "f150pickup": "F150",
    "f250": "F250",
    "f350": "F350",
    "f450": "F450",
    "fairm": "Fairmont",
    "festiv": "Festiva",
    "fiesta": "Fiesta",
    "fivehundre": "Five Hundred",
    "flex": "Flex",
    "focus": "Focus",
    "freestar": "Freestar",
    "freestyle": "Freestyle",
    "fusion": "Fusion",
    "granada": "Granada",
    "gt": "GT",
    "ltd": "LTD",
    "must": "Mustang",
    "probe": "Probe",
    "ranger": "Ranger",
    "taurus": "Taurus",
    "taurusx": "Taurus X",
    "tempo": "Tempo",
    "tbird": "Thunderbird",
    "transconn": "Transit Connect",
    "windst": "Windstar",
    "fordzx2": "ZX2 Escort",
    "footh": "Other Ford Models"
  },
  // "freight": {
  //   "fresprint": "Sprinter"
  // },
  // "geoBrand": {
  //   "geometro": "Metro",
  //   "geoprizm": "Prizm",
  //   "spect": "Spectrum",
  //   "storm": "Storm",
  //   "geotrack": "Tracker",
  //   "geooth": "Other Geo Models"
  // },
  "gmc": {
    "acadia": "Acadia",
    "caballero": "Caballero",
    "canyon": "Canyon",
    "envoy": "Envoy",
    "envoyxl": "Envoy XL",
    "envoyxuv": "Envoy XUV",
    "jim": "Jimmy",
    "rallywag": "Rally Wagon",
    "gmcs15": "S15 Jimmy",
    "s15": "S15 Pickup",
    "safarigmc": "Safari",
    "gmcsavana": "Savana",
    "15sipu4wd": "Sierra C/K1500",
    "gmcc25pu": "Sierra C/K2500",
    "gmc3500pu": "Sierra C/K3500",
    "sonoma": "Sonoma",
    "sub": "Suburban",
    "gmcsyclon": "Syclone",
    "terrain": "Terrain",
    "topc4500": "TopKick C4500",
    "typh": "Typhoon",
    "gmcvandur": "Vandura",
    "yukon": "Yukon",
    "yukonxl": "Yukon XL",
    "gmcoth": "Other GMC Models"
  },
  "honda": {
    "accord": "Accord",
    "civic": "Civic",
    "crv": "CR-V",
    "crz": "CR-Z",
    "crx": "CRX",
    "crosstourModels": "Crosstour and Accord Crosstour Models (2)",
    "crosstour": " - Accord Crosstour",
    "honcross": " - Crosstour",
    "hondelsol": "Del Sol",
    "element": "Element",
    "fit": "Fit",
    "insight": "Insight",
    "odyssey": "Odyssey",
    "passpo": "Passport",
    "pilot": "Pilot",
    "pre": "Prelude",
    "ridgeline": "Ridgeline",
    "s2000": "S2000",
    "honoth": "Other Honda Models"
  },
  "amgen": {
    "hummer": "H1",
    "h2": "H2",
    "h3": "H3",
    "h3t": "H3T",
    "amgoth": "Other Hummer Models"
  },
  "hyund": {
    "accent": "Accent",
    "azera": "Azera",
    "elantr": "Elantra",
    "hyuelancpe": "Elantra Coupe",
    "elantour": "Elantra Touring",
    "entourage": "Entourage",
    "equus": "Equus",
    "hyuexcel": "Excel",
    "genesis": "Genesis",
    "genesiscpe": "Genesis Coupe",
    "santafe": "Santa Fe",
    "scoupe": "Scoupe",
    "sonata": "Sonata",
    "tiburo": "Tiburon",
    "tucson": "Tucson",
    "veloster": "Veloster",
    "veracruz": "Veracruz",
    "xg300": "XG300",
    "xg350": "XG350",
    "hyuoth": "Other Hyundai Models"
  },
  "infin": {
    "exModels": "EX Models (2)",
    "ex35": " - EX35",
    "ex37": " - EX37",
    "fxModels": "FX Models (4)",
    "fx35": " - FX35",
    "fx37": " - FX37",
    "fx45": " - FX45",
    "fx50": " - FX50",
    "gModels": "G Models (4)",
    "g20": " - G20",
    "g25": " - G25",
    "g35": " - G35",
    "g37": " - G37",
    "iModels": "I Models (2)",
    "i30": " - I30",
    "i35": " - I35",
    "jModels": "J Models (1)",
    "j30": " - J30",
    "jxModels": "JX Models (1)",
    "jx35": " - JX35",
    "mModels": "M Models (6)",
    "m30": " - M30",
    "m35": " - M35",
    "m35hybrid": " - M35h",
    "m37": " - M37",
    "m45": " - M45",
    "m56": " - M56",
    "qModels": "Q Models (1)",
    "q45": " - Q45",
    "qxModels": "QX Models (2)",
    "qx4": " - QX4",
    "qx56": " - QX56",
    "infoth": "Other Infiniti Models"
  },
  // "isu": {
  //   "amigo": "Amigo",
  //   "ascender": "Ascender",
  //   "axiom": "Axiom",
  //   "hombre": "Hombre",
  //   "i280": "i-280",
  //   "i290": "i-290",
  //   "i350": "i-350",
  //   "i370": "i-370",
  //   "isumark": "I-Mark",
  //   "isuimp": "Impulse",
  //   "oasis": "Oasis",
  //   "isupu": "Pickup",
  //   "rodeo": "Rodeo",
  //   "stylus": "Stylus",
  //   "troop": "Trooper",
  //   "trp2": "Trooper II",
  //   "vehicross": "VehiCROSS",
  //   "isuoth": "Other Isuzu Models"
  // },
  "jag": {
    "stype": "S-Type",
    "xtype": "X-Type",
    "xf": "XF",
    "xjSeries": "XJ Series (10)",
    "jagxj12": " - XJ12",
    "jagxj6": " - XJ6",
    "jagxjr": " - XJR",
    "jagxjrs": " - XJR-S",
    "jagxjs": " - XJS",
    "vanden": " - XJ Vanden Plas",
    "xj": " - XJ",
    "xj8": " - XJ8",
    "xj8l": " - XJ8 L",
    "xjsport": " - XJ Sport",
    "xkSeries": "XK Series (3)",
    "jagxk8": " - XK8",
    "xk": " - XK",
    "xkr": " - XKR",
    "jagoth": "Other Jaguar Models"
  },
  "jeep": {
    "cher": "Cherokee",
    "jeepcj": "CJ",
    "comanc": "Comanche",
    "commander": "Commander",
    "compass": "Compass",
    "jeepgrand": "Grand Cherokee",
    "grwag": "Grand Wagoneer",
    "liberty": "Liberty",
    "patriot": "Patriot",
    "jeeppu": "Pickup",
    "scramble": "Scrambler",
    "wagone": "Wagoneer",
    "wrangler": "Wrangler",
    "jeoth": "Other Jeep Models"
  },
  "kia": {
    "amanti": "Amanti",
    "borrego": "Borrego",
    "forte": "Forte",
    "fortekoup": "Forte Koup",
    "optima": "Optima",
    "rio": "Rio",
    "rio5": "Rio5",
    "rondo": "Rondo",
    "sedona": "Sedona",
    "sephia": "Sephia",
    "sorento": "Sorento",
    "soul": "Soul",
    "spectra": "Spectra",
    "spectra5": "Spectra5",
    "sporta": "Sportage",
    "kiaoth": "Other Kia Models"
  },
  "lam": {
    "avent": "Aventador",
    "count": "Countach",
    "diablo": "Diablo",
    "gallardo": "Gallardo",
    "jalpa": "Jalpa",
    "lm002": "LM002",
    "murcielago": "Murcielago",
    "unavaillam": "Other Lamborghini Models"
  },
  // "lan": {
  //   "beta": "Beta",
  //   "zagato": "Zagato",
  //   "unavaillan": "Other Lancia Models"
  // },
  "rov": {
    "defend": "Defender",
    "discov": "Discovery",
    "frelndr": "Freelander",
    "lr2": "LR2",
    "lr3": "LR3",
    "lr4": "LR4",
    "range": "Range Rover",
    "evoque": "Range Rover Evoque",
    "rangesport": "Range Rover Sport",
    "rovoth": "Other Land Rover Models"
  },
  "lexus": {
    "ctModels": "CT Models (1)",
    "ct200h": " - CT 200h",
    "esModels": "ES Models (5)",
    "es250": " - ES 250",
    "es300": " - ES 300",
    "es300h": " - ES 300h",
    "es330": " - ES 330",
    "es350": " - ES 350",
    "gsModels": "GS Models (6)",
    "gs300": " - GS 300",
    "gs350": " - GS 350",
    "gs400": " - GS 400",
    "gs430": " - GS 430",
    "gs450h": " - GS 450h",
    "gs460": " - GS 460",
    "gxModels": "GX Models (2)",
    "gx460": " - GX 460",
    "gx470": " - GX 470",
    "hsModels": "HS Models (1)",
    "hs250h": " - HS 250h",
    "isModels": "IS Models (6)",
    "is250": " - IS 250",
    "is250c": " - IS 250C",
    "is300": " - IS 300",
    "is350": " - IS 350",
    "is350c": " - IS 350C",
    "isf": " - IS F",
    "lexlfa": "LFA",
    "lsModels": "LS Models (4)",
    "ls400": " - LS 400",
    "ls430": " - LS 430",
    "ls460": " - LS 460",
    "ls600h": " - LS 600h",
    "lxModels": "LX Models (3)",
    "lx450": " - LX 450",
    "lx470": " - LX 470",
    "lx570": " - LX 570",
    "rxModels": "RX Models (5)",
    "rx300": " - RX 300",
    "rx330": " - RX 330",
    "rx350": " - RX 350",
    "rx400h": " - RX 400h",
    "rx450h": " - RX 450h",
    "scModels": "SC Models (3)",
    "sc300": " - SC 300",
    "sc400": " - SC 400",
    "sc430": " - SC 430",
    "lexoth": "Other Lexus Models"
  },
  "linc": {
    "aviator": "Aviator",
    "blkwood": "Blackwood",
    "cont": "Continental",
    "lslincoln": "LS",
    "marklt": "Mark LT",
    "mark6": "Mark VI",
    "mark7": "Mark VII",
    "mark8": "Mark VIII",
    "mks": "MKS",
    "mkt": "MKT",
    "mkx": "MKX",
    "mkz": "MKZ",
    "naviga": "Navigator",
    "navigal": "Navigator L",
    "linctc": "Town Vehicle",
    "zephyr": "Zephyr",
    "linoth": "Other Lincoln Models"
  },
  "lotus": {
    "elan": "Elan",
    "lotelise": "Elise",
    "esprit": "Esprit",
    "evora": "Evora",
    "exige": "Exige",
    "unavaillot": "Other Lotus Models"
  },
  "mas": {
    "430": "430",
    "bitrbo": "Biturbo",
    "coupemas": "Coupe",
    "gransport": "GranSport",
    "granturism": "GranTurismo",
    "qp": "Quattroporte",
    "spyder": "Spyder",
    "unavailmas": "Other Maserati Models"
  },
  "maybach": {
    "57maybach": "57",
    "62maybach": "62",
    "unavailmay": "Other Maybach Models"
  },
  "mazda": {
    "929": "929",
    "mazda323": "323",
    "mazda626": "626",
    "bSeries": "B-Series Pickup",
    "cx5": "CX-5",
    "cx7": "CX-7",
    "cx9": "CX-9",
    "glc": "GLC",
    "mazda2": "MAZDA2",
    "mazda3": "MAZDA3",
    "mazda5": "MAZDA5",
    "mazda6": "MAZDA6",
    "mazdaspd3": "MAZDASPEED3",
    "mazdaspd6": "MAZDASPEED6",
    "miata": "Miata MX5",
    "mill": "Millenia",
    "mpv": "MPV",
    "mx3": "MX3",
    "mx6": "MX6",
    "navajo": "Navajo",
    "prote": "Protege",
    "prote5": "Protege5",
    "rx7": "RX-7",
    "rx8": "RX-8",
    "tribute": "Tribute",
    "mazoth": "Other Mazda Models"
  },
  "mclaren": {
    "mp4": "MP4-12C"
  },
  "mb": {
    "190Class": "190 Class (2)",
    "190d": " - 190D",
    "190e": " - 190E",
    "240Class": "240 Class (1)",
    "240d": " - 240D",
    "300ClassEClass": "300 Class / E Class (6)",
    "300cd": " - 300CD",
    "300ce": " - 300CE",
    "300d": " - 300D",
    "300e": " - 300E",
    "300td": " - 300TD",
    "300te": " - 300TE",
    "cClass": "C Class (13)",
    "c220": " - C220",
    "c230": " - C230",
    "c240": " - C240",
    "c250": " - C250",
    "c280": " - C280",
    "c300": " - C300",
    "c320": " - C320",
    "c32amg": " - C32 AMG",
    "c350": " - C350",
    "c36amg": " - C36 AMG",
    "c43amg": " - C43 AMG",
    "c55amg": " - C55 AMG",
    "c63amg": " - C63 AMG",
    "clClass": "CL Class (6)",
    "cl500": " - CL500",
    "cl550": " - CL550",
    "cl55amg": " - CL55 AMG",
    "cl600": " - CL600",
    "cl63amg": " - CL63 AMG",
    "cl65amg": " - CL65 AMG",
    "clkClass": "CLK Class (7)",
    "clk320": " - CLK320",
    "clk350": " - CLK350",
    "clk430": " - CLK430",
    "clk500": " - CLK500",
    "clk550": " - CLK550",
    "clk55amg": " - CLK55 AMG",
    "clk63amg": " - CLK63 AMG",
    "clsClass": "CLS Class (4)",
    "cls500": " - CLS500",
    "cls550": " - CLS550",
    "cls55amg": " - CLS55 AMG",
    "cls63amg": " - CLS63 AMG",
    "eClass": "E Class (18)",
    "260e": " - 260E",
    "280ce": " - 280CE",
    "280e": " - 280E",
    "400e": " - 400E",
    "500e": " - 500E",
    "e300": " - E300",
    "e320": " - E320",
    "e320blue": " - E320 Bluetec",
    "e320cdi": " - E320 CDI",
    "e350": " - E350",
    "e350blue": " - E350 Bluetec",
    "e400": " - E400 Hybrid",
    "e420": " - E420",
    "e430": " - E430",
    "e500": " - E500",
    "e550": " - E550",
    "e55amg": " - E55 AMG",
    "e63amg": " - E63 AMG",
    "gClass": "G Class (4)",
    "g500": " - G500",
    "g550": " - G550",
    "g55amg": " - G55 AMG",
    "g63amg": " - G63 AMG",
    "glClass": "GL Class (5)",
    "gl320blue": " - GL320 Bluetec",
    "gl320cdi": " - GL320 CDI",
    "gl350blue": " - GL350 Bluetec",
    "gl450": " - GL450",
    "gl550": " - GL550",
    "glkClass": "GLK Class (1)",
    "glk350": " - GLK350",
    "mClass": "M Class (11)",
    "ml320": " - ML320",
    "ml320blue": " - ML320 Bluetec",
    "ml320cdi": " - ML320 CDI",
    "ml350": " - ML350",
    "ml350blue": " - ML350 Bluetec",
    "ml430": " - ML430",
    "ml450hy": " - ML450 Hybrid",
    "ml500": " - ML500",
    "ml550": " - ML550",
    "ml55amg": " - ML55 AMG",
    "ml63amg": " - ML63 AMG",
    "rClass": "R Class (6)",
    "r320blue": " - R320 Bluetec",
    "r320cdi": " - R320 CDI",
    "r350": " - R350",
    "r350blue": " - R350 Bluetec",
    "r500": " - R500",
    "r63amg": " - R63 AMG",
    "sClass": "S Class (30)",
    "300sd": " - 300SD",
    "300sdl": " - 300SDL",
    "300se": " - 300SE",
    "300sel": " - 300SEL",
    "350sd": " - 350SD",
    "350sdl": " - 350SDL",
    "380se": " - 380SE",
    "380sec": " - 380SEC",
    "380sel": " - 380SEL",
    "400se": " - 400SE",
    "400sel": " - 400SEL",
    "420sel": " - 420SEL",
    "500sec": " - 500SEC",
    "500sel": " - 500SEL",
    "560sec": " - 560SEC",
    "560sel": " - 560SEL",
    "600sec": " - 600SEC",
    "600sel": " - 600SEL",
    "s320": " - S320",
    "s350": " - S350",
    "s350blue": " - S350 Bluetec",
    "s400hy": " - S400 Hybrid",
    "s420": " - S420",
    "s430": " - S430",
    "s500": " - S500",
    "s550": " - S550",
    "s55amg": " - S55 AMG",
    "s600": " - S600",
    "s63amg": " - S63 AMG",
    "s65amg": " - S65 AMG",
    "slClass": "SL Class (13)",
    "300sl": " - 300SL",
    "380sl": " - 380SL",
    "380slc": " - 380SLC",
    "500sl": " - 500SL",
    "560sl": " - 560SL",
    "600sl": " - 600SL",
    "sl320": " - SL320",
    "sl500": " - SL500",
    "sl550": " - SL550",
    "sl55amg": " - SL55 AMG",
    "sl600": " - SL600",
    "sl63amg": " - SL63 AMG",
    "sl65amg": " - SL65 AMG",
    "slkClass": "SLK Class (8)",
    "slk230": " - SLK230",
    "slk250": " - SLK250",
    "slk280": " - SLK280",
    "slk300": " - SLK300",
    "slk320": " - SLK320",
    "slk32amg": " - SLK32 AMG",
    "slk350": " - SLK350",
    "slk55amg": " - SLK55 AMG",
    "slrClass": "SLR Class (1)",
    "slr": " - SLR",
    "slsClass": "SLS Class (1)",
    "slsamg": " - SLS AMG",
    "sprinterClass": "Sprinter Class (1)",
    "mbsprinter": " - Sprinter",
    "mboth": "Other Mercedes-Benz Models"
  },
  // "merc": {
  //   "capri": "Capri",
  //   "cougar": "Cougar",
  //   "mercgrand": "Grand Marquis",
  //   "lynx": "Lynx",
  //   "marauder": "Marauder",
  //   "mariner": "Mariner",
  //   "marq": "Marquis",
  //   "milan": "Milan",
  //   "montego": "Montego",
  //   "monterey": "Monterey",
  //   "mounta": "Mountaineer",
  //   "mystiq": "Mystique",
  //   "sable": "Sable",
  //   "topaz": "Topaz",
  //   "tracer": "Tracer",
  //   "villa": "Villager",
  //   "merczep": "Zephyr",
  //   "meoth": "Other Mercury Models"
  // },
  // "merkur": {
  //   "scorp": "Scorpio",
  //   "xr4ti": "XR4Ti",
  //   "meroth": "Other Merkur Models"
  // },
  "mini": {
    "cooprclubModels": "Cooper Clubman Models (2)",
    "cooperclub": " - Cooper Clubman",
    "cooprclubs": " - Cooper S Clubman",
    "coopcountryModels": "Cooper Countryman Models (2)",
    "countryman": " - Cooper Countryman",
    "countrymns": " - Cooper S Countryman",
    "coopcoupModels": "Cooper Coupe Models (2)",
    "minicoupe": " - Cooper Coupe",
    "miniscoupe": " - Cooper S Coupe",
    "cooperModels": "Cooper Models (2)",
    "cooper": " - Cooper",
    "coopers": " - Cooper S",
    "cooprroadModels": "Cooper Roadster Models (2)",
    "cooperroad": " - Cooper Roadster",
    "coopersrd": " - Cooper S Roadster"
  },
  "mit": {
    "3000gt": "3000GT",
    "cord": "Cordia",
    "diaman": "Diamante",
    "eclip": "Eclipse",
    "endeavor": "Endeavor",
    "mitexp": "Expo",
    "galant": "Galant",
    "miti": "i",
    "lancermits": "Lancer",
    "lancerevo": "Lancer Evolution",
    "mitpu": "Mighty Max",
    "mirage": "Mirage",
    "mont": "Montero",
    "montsport": "Montero Sport",
    "outlander": "Outlander",
    "outlandspt": "Outlander Sport",
    "precis": "Precis",
    "raidermits": "Raider",
    "sigma": "Sigma",
    "mitstar": "Starion",
    "tred": "Tredia",
    "mitvan": "Van",
    "mitoth": "Other Mitsubishi Models"
  },
  "nissan": {
    "nis200sx": "200SX",
    "240sx": "240SX",
    "300zxturbo": "300ZX",
    "350z": "350Z",
    "370z": "370Z",
    "altima": "Altima",
    "patharmada": "Armada",
    "axxess": "Axxess",
    "cube": "Cube",
    "fronti": "Frontier",
    "gtR": "GT-R",
    "juke": "Juke",
    "leaf": "Leaf",
    "max": "Maxima",
    "murano": "Murano",
    "muranocros": "Murano CrossCabriolet",
    "nv": "NV",
    "nx": "NX",
    "path": "Pathfinder",
    "nispu": "Pickup",
    "pulsar": "Pulsar",
    "quest": "Quest",
    "rogue": "Rogue",
    "sentra": "Sentra",
    "stanza": "Stanza",
    "titan": "Titan",
    "nisvan": "Van",
    "versa": "Versa",
    "xterra": "Xterra",
    "nissoth": "Other Nissan Models"
  },
  // "olds": {
  //   "88": "88",
  //   "98": "Ninety-Eight",
  //   "achiev": "Achieva",
  //   "alero": "Alero",
  //   "aurora": "Aurora",
  //   "brav": "Bravada",
  //   "cucr": "Custom Cruiser",
  //   "oldcus": "Cutlass",
  //   "oldcalais": "Cutlass Calais",
  //   "ciera": "Cutlass Ciera",
  //   "csupr": "Cutlass Supreme",
  //   "oldsfir": "Firenza",
  //   "intrig": "Intrigue",
  //   "omeg": "Omega",
  //   "regen": "Regency",
  //   "silho": "Silhouette",
  //   "toro": "Toronado",
  //   "oldoth": "Other Oldsmobile Models"
  // },
  // "peug": {
  //   "405": "405",
  //   "504": "504",
  //   "505": "505",
  //   "604": "604",
  //   "unavailpeu": "Other Peugeot Models"
  // },
  // "plym": {
  //   "acc": "Acclaim",
  //   "arrow": "Arrow",
  //   "breeze": "Breeze",
  //   "carave": "Caravelle",
  //   "champ": "Champ",
  //   "colt": "Colt",
  //   "plymconq": "Conquest",
  //   "granfury": "Gran Fury",
  //   "plymgranv": "Grand Voyager",
  //   "hori": "Horizon",
  //   "laser": "Laser",
  //   "neon": "Neon",
  //   "prowle": "Prowler",
  //   "reli": "Reliant",
  //   "sapporoply": "Sapporo",
  //   "scamp": "Scamp",
  //   "sundan": "Sundance",
  //   "traildust": "Trailduster",
  //   "voya": "Voyager",
  //   "plyoth": "Other Plymouth Models"
  // },
  "pont": {
    "6000": "6000",
    "t1000": "1000",
    "aztek": "Aztek",
    "bon": "Bonneville",
    "catalina": "Catalina",
    "fiero": "Fiero",
    "fbird": "Firebird",
    "g3": "G3",
    "g5": "G5",
    "g6": "G6",
    "g8": "G8",
    "grndam": "Grand Am",
    "gp": "Grand Prix",
    "gto": "GTO",
    "j2000": "J2000",
    "lemans": "Le Mans",
    "montana": "Montana",
    "parisi": "Parisienne",
    "phoenix": "Phoenix",
    "safaripont": "Safari",
    "solstice": "Solstice",
    "sunbir": "Sunbird",
    "sunfir": "Sunfire",
    "torrent": "Torrent",
    "ts": "Trans Sport",
    "vibe": "Vibe",
    "ponoth": "Other Pontiac Models"
  },
  "por": {
    "911": "911",
    "924": "924",
    "928": "928",
    "944": "944",
    "968": "968",
    "boxste": "Boxster",
    "carreragt": "Carrera GT",
    "cayenne": "Cayenne",
    "cayman": "Cayman",
    "panamera": "Panamera",
    "poroth": "Other Porsche Models"
  },
  "ram": {
    "ram1504wd": "1500",
    "ram25002wd": "2500",
    "ram3502wd": "3500",
    "ram4500": "4500"
  },
  // "ren": {
  //   "18i": "18i",
  //   "fu": "Fuego",
  //   "lecar": "Le Vehicle",
  //   "r18": "R18",
  //   "rensport": "Sportwagon",
  //   "unavailren": "Other Renault Models"
  // },
  "rr": {
    "camar": "Camargue",
    "corn": "Corniche",
    "ghost": "Ghost",
    "parkward": "Park Ward",
    "phant": "Phantom",
    "dawn": "Silver Dawn",
    "silseraph": "Silver Seraph",
    "rrspir": "Silver Spirit",
    "spur": "Silver Spur",
    "unavailrr": "Other Rolls-Royce Models"
  },
  // "saab": {
  //   "93": "9-3",
  //   "95": "9-5",
  //   "900": "900",
  //   "9000": "9000",
  //   "92x": "9-2X",
  //   "94x": "9-4X",
  //   "97x": "9-7X",
  //   "saoth": "Other Saab Models"
  // },
  // "saturn": {
  //   "astra": "Astra",
  //   "aura": "Aura",
  //   "ion": "ION",
  //   "lSeries": "L Series (3)",
  //   "l100": " - L100",
  //   "l200": " - L200",
  //   "l300": " - L300",
  //   "lssaturn": "LS",
  //   "lwSeries": "LW Series (4)",
  //   "lw": " - LW1",
  //   "lw2": " - LW2",
  //   "lw200": " - LW200",
  //   "lw300": " - LW300",
  //   "outlook": "Outlook",
  //   "relay": "Relay",
  //   "scSeries": "SC Series (2)",
  //   "sc1": " - SC1",
  //   "sc2": " - SC2",
  //   "sky": "Sky",
  //   "slSeries": "SL Series (3)",
  //   "sl": " - SL",
  //   "sl1": " - SL1",
  //   "sl2": " - SL2",
  //   "swSeries": "SW Series (2)",
  //   "sw1": " - SW1",
  //   "sw2": " - SW2",
  //   "vue": "Vue",
  //   "satoth": "Other Saturn Models"
  // },
  "scion": {
    "scifrs": "FR-S",
    "iq": "iQ",
    "tc": "tC",
    "xa": "xA",
    "xb": "xB",
    "xd": "xD"
  },
  "smart": {
    "fortwo": "fortwo",
    "smoth": "Other smart Models"
  },
  "srt": {
    "srtviper": "Viper"
  },
  // "sterl": {
  //   "825": "825",
  //   "827": "827",
  //   "unavailste": "Other Sterling Models"
  // },
  "sub": {
    "baja": "Baja",
    "brat": "Brat",
    "subbrz": "BRZ",
    "forest": "Forester",
    "imprez": "Impreza",
    "impwrx": "Impreza WRX",
    "justy": "Justy",
    "subl": "L Series",
    "legacy": "Legacy",
    "loyale": "Loyale",
    "suboutbk": "Outback",
    "svx": "SVX",
    "b9tribeca": "Tribeca",
    "xt": "XT",
    "xvcrsstrek": "XV Crosstrek",
    "suboth": "Other Subaru Models"
  },
  "suzuki": {
    "aerio": "Aerio",
    "equator": "Equator",
    "esteem": "Esteem",
    "forenza": "Forenza",
    "grandv": "Grand Vitara",
    "kizashi": "Kizashi",
    "reno": "Reno",
    "samur": "Samurai",
    "side": "Sidekick",
    "swift": "Swift",
    "sx4": "SX4",
    "verona": "Verona",
    "vitara": "Vitara",
    "x90": "X-90",
    "xl7": "XL7",
    "suzoth": "Other Suzuki Models"
  },
  "tesla": {
    "roadster": "Roadster"
  },
  "toyota": {
    "4run": "4Runner",
    "avalon": "Avalon",
    "camry": "Camry",
    "celica": "Celica",
    "corol": "Corolla",
    "corona": "Corona",
    "cress": "Cressida",
    "echo": "Echo",
    "fjcruis": "FJ Cruiser",
    "highlander": "Highlander",
    "lc": "Land Cruiser",
    "matrix": "Matrix",
    "mr2": "MR2",
    "mr2spydr": "MR2 Spyder",
    "paseo": "Paseo",
    "pickup": "Pickup",
    "previa": "Previa",
    "prius": "Prius",
    "priusc": "Prius C",
    "priusv": "Prius V",
    "rav4": "RAV4",
    "sequoia": "Sequoia",
    "sienna": "Sienna",
    "solara": "Solara",
    "starlet": "Starlet",
    "supra": "Supra",
    "t100": "T100",
    "tacoma": "Tacoma",
    "tercel": "Tercel",
    "tundra": "Tundra",
    "toyvan": "Van",
    "venza": "Venza",
    "yaris": "Yaris",
    "toyoth": "Other Toyota Models"
  },
  // "tri": {
  //   "tr7": "TR7",
  //   "tr8": "TR8",
  //   "trioth": "Other Triumph Models"
  // },
  "volks": {
    "beetle": "Beetle",
    "volkscab": "Cabrio",
    "cab": "Cabriolet",
    "cc": "CC",
    "corr": "Corrado",
    "dasher": "Dasher",
    "eos": "Eos",
    "eurovan": "Eurovan",
    "volksfox": "Fox",
    "gli": "GLI",
    "golfr": "Golf R",
    "gti": "GTI",
    "golfandrabbitmodels": "Golf and Rabbit Models (2)",
    "golf": " - Golf",
    "rabbit": " - Rabbit",
    "jet": "Jetta",
    "pass": "Passat",
    "phaeton": "Phaeton",
    "rabbitpu": "Pickup",
    "quan": "Quantum",
    "r32": "R32",
    "routan": "Routan",
    "scir": "Scirocco",
    "tiguan": "Tiguan",
    "touareg": "Touareg",
    "vanag": "Vanagon",
    "vwoth": "Other Volkswagen Models"
  },
  "volvo": {
    "240": "240",
    "260": "260",
    "740": "740",
    "760": "760",
    "780": "780",
    "850": "850",
    "940": "940",
    "960": "960",
    "c30": "C30",
    "c70": "C70",
    "s40": "S40",
    "s60": "S60",
    "s70": "S70",
    "s80": "S80",
    "s90": "S90",
    "v40": "V40",
    "v50": "V50",
    "v70": "V70",
    "v90": "V90",
    "xc60": "XC60",
    "xc": "XC70",
    "xc90": "XC90",
    "voloth": "Other Volvo Models"
  },
  // "yugo": {
  //   "gv": "GV",
  //   "gvc": "GVC",
  //   "gvl": "GVL",
  //   "gvs": "GVS",
  //   "gvx": "GVX",
  //   "yuoth": "Other Yugo Models"
  // }
}