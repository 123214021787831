import { getManyFiles, getOneVehicle } from "@/api/requests"
import { Component } from "react"
import { goToExactPath } from "@/routes"
import { getReduxState, setReduxState } from "@/redux"

import { getVehicleInitState } from "../functions"

import { VehicleContent } from "./VehicleContent"
export class Vehicle extends Component {
  componentDidMount(): void {
    const lastPath = getReduxState().routes.lastPath
    const vehicle = getReduxState().vehicles.vehicleMap[lastPath] || getReduxState().search.vehicleMap[lastPath]
    setReduxState("vehicle", {
      ...getVehicleInitState(),
      vehicle
    })
    if (lastPath === "vehicle") {
      goToExactPath("/home")
      return
    }
    if (!vehicle) {
      getOneVehicle(lastPath, newVehicle => {
        setReduxState("vehicle", { vehicle: newVehicle })
        if (newVehicle) {
          getManyFiles(newVehicle.imageIdList, false, imageList => setReduxState("vehicle", { imageList }))
        }
      })
    } else {
      getManyFiles(vehicle.imageIdList, false, imageList => setReduxState("vehicle", { imageList }))
    }
  }
  render(): JSX.Element {
    return <VehicleContent />
  }
}