import { center } from "@/ui/mixins"
import styled from "styled-components"

export const ShowTermsWarningStyled = styled.div<{}>`
  ${center}
  position: fixed;
  z-index: 100000;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: ${() => window.theme.primary500};
  h4 {
    font-weight: 400;
    line-height: 150%;
  }
  h4 , a{
    color: white;
  }
  a {
    color: ${() => window.theme.primary100};
    text-decoration: underline;
  }
`
