import { BROWSER_HISTORY } from "@/routes"

import { Icon } from "../Icon"

import { PageTopBarProps } from "./model"
import { PageTopBarStyled } from "./PageTopBarStyled"

export function PageTopBar({ label, isBackButton }: PageTopBarProps): JSX.Element {
  return (
    <PageTopBarStyled>
      {isBackButton && <Icon name="chevron-left" onClick={() => BROWSER_HISTORY.goBack()} />}
      <h4>{label}</h4>
    </PageTopBarStyled>
  )
}