import { SayKeywordStrings } from "@/shared"
import { Button, Select } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { deleteOneUser } from "@/api/requests"

import { LeftButtonListProps } from "../model"

export function LeftButtonList({ tableUserIdList }: LeftButtonListProps): JSX.Element {
  const { say, userMap, isFilters } = useReduxState(state => ({
    say: state.languages.say,
    userMap: state.users.userMap,
    isFilters: state.ui.isFilters,
  }))
  return (
    <div
      className={`sticky  mt-[140px] col ${isFilters ? "left-[400px]" : "left-0"}`}
    >
      <div className="w-10 h-12 border-b border-solid bg-grey-850 border-grey-800" />
      {tableUserIdList.map(_id => {
        const optionList: SayKeywordStrings[] = ["editPassword", "delete"]
        const user = userMap[_id]!
        return (
          <Select
            key={_id}
            isStyleDisabled={true}
            dropdownProps={{
              optionList,
              outputMap: (key: typeof optionList[number]) => say[key],
              onChange: (key: typeof optionList[number]) => {
                if (key === "editPassword") {
                  setReduxState("popups", {
                    name: "EditPassword",
                  })
                }
                if (key === "delete") {
                  setReduxState("popups", {
                    name: "SafeAction",
                    props: {
                      title: say.askDeleteUser(user.email),
                      action: () => deleteOneUser(_id)
                    }
                  })
                }
              }
            }}
          >
            <Button
              iconName="chevron-right"
              size="xs"
              isCircle={true}
              color="success"
              className="w-10 h-10 border-b border-solid rounded-none border-success-300"
            />
          </Select>
        )
      })}
    </div>
  )
}
