import { handleSocketRequest } from "@/api/functions"
import { getReduxState, setReduxState } from "@/redux"

export function deleteOneBrokerage(_id: string): void {
  handleSocketRequest(
    "brokerages",
    "deleteOne",
    {
      _id,
    }, () => {
      const { brokerageList } = getReduxState().brokerages
      const newBrokerageList = brokerageList.filter(item => item._id !== _id)
      setReduxState("brokerages", { brokerageList: newBrokerageList })
      setReduxState("ui", {
        snackbar: {
          message: "deleteBrokerageSuccess",
          severity: "success"
        }
      })
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    }
  )
  setReduxState("popups", { name: undefined })
}