import { breakpoints, center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const SearchFiltersStyled = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100vh;
  width: ${breakpoints.sm};
  max-width: 100vw;
  background: ${() => window.theme.grey900}cc;
  box-shadow: 0 5px 10px black;
  backdrop-filter: blur(20px);
  transform: translateX(-100%);
  transition: 0.2s all ease-in-out;
  & > .fal-times {
    ${center};
    z-index: 1000;
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    box-shadow: 0 0 5px black;
    ${() => shadeEffect("primary500", "background")};
    svg {
      height: 20px;
    }
  }
  & > .fal-trash-alt {
    ${center};
    z-index: 1000;
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    height: 40px;
    padding: 0 20px;
    border-radius: 9999px;
    box-shadow: 0 0 5px black;
    ${() => shadeEffect("secondary900", "background")};
    svg {
      margin-right: 15px;
      height: 20px;
    }
  }
  .horizontal-box {
    display: flex;
    width: 100%;
    .fieldset {
      width: calc(50% - 10px);
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  &.active {
    transform: translateX(0);
  }
  .fieldset {
    width: 100%;
  }
  .scrollbar-box {
    & > div:first-child {
      padding: 100px 20px 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

`