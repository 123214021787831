import { useState } from "react"
import { Class, Fieldset, Input } from "@/components"
import { useReduxState } from "@/redux"

import { DelayedSearchInputProps } from "./model"

let timeoutId: number = -1
export function DelayedSearchInput({
  getValue,
  onChange
}: DelayedSearchInputProps): JSX.Element {
  const { say, searchField } = useReduxState(state => ({
    searchField: getValue(state),
    say: state.languages.say,
  }))
  const [innerSearchField, setState] = useState(searchField)
  return (
    <Class
      props={{ innerSearchField, searchField }}
      didUpdate={oldProps => {
        if (searchField !== oldProps.searchField && innerSearchField === oldProps.innerSearchField) {
          setState(searchField)
        }
      }}
    >
      <Fieldset label={say.search}>
        <Input
          mobileFocusLabel={say.search}
          onChange={evt => {
            setState(evt.target.value)
            window.clearTimeout(timeoutId)
            timeoutId = window.setTimeout(() => {
              onChange(evt.target.value)
            }, 500)
          }}
          placeholder={say.writeSomething}
          value={innerSearchField}
          iconName="search"
        />
      </Fieldset>
    </Class>
  )
}