
import { CreateVehicleState } from "../model"

export function getCreateVehicleInitState(): CreateVehicleState {
  return {
    viewIndex: 0,
    imageList: [],
    brandField: "",
    modelField: "",
    yearField: "",
    bodyStyleField: "",
    exteriorColor: "",
    interiorColor: "",
    doorCountField: "",
    seatingCountField: "",
    equipmentField: "",
    vinIdField: "",
    equipmentList: [],
    brand: undefined,
    otherBrand: "",
    model: undefined,
    _id: undefined,
    estimateField: "",
    isMsrp: false,
    year: undefined,
    distanceField: "",
    isKmDistanceUnit: true,
    conditionField: "",
    condition: undefined,
    cylinderCountField: "",
    driveTrainField: "",
    driveTrain: undefined,
    isManualTransmission: false,
    fuelTypeField: "",
    fuelType: undefined,
    cityFuelConsumptionField: "",
    highwayFuelConsumptionField: "",

    isFullOwnership: true,
    priceField: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: undefined,
  }
}
