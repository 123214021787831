import { getReduxState, setReduxState } from "@/redux"
import { getManyFiles } from "@/api/requests"
import { goToExactPath } from "@/routes"
import { PostalAddress, Vehicle, VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND } from "@/shared"

import { AllVehicleHeadColumns } from "../model"

export function presetVehicleForEditing(vehicle: Vehicle, colId?: AllVehicleHeadColumns): void {
  const {
    languages: {
      say
    },
  } = getReduxState()
  const params = {
    ...vehicle,
  }
  setReduxState("createVehicle", {
    _id: params._id,
    brandField: say[params.brand],
    brand: params.brand,
    otherBrand: params.otherBrand,
    modelField: VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[params.brand][params.model],
    model: params.model,
    yearField: params.year.toString(),
    year: params.year,
    bodyStyleField: say[params.bodyStyle],
    bodyStyle: params.bodyStyle,
    exteriorColor: params.exteriorColor,
    interiorColor: params.interiorColor,
    doorCountField: params.doorCount === undefined ? undefined : params.doorCount.toString(),
    seatingCountField: params.seatingCount === undefined ? undefined : params.seatingCount.toString(),
    equipmentField: "",
    vinIdField: params.vinId,
    equipmentList: params.equipmentList,
    distanceField: params.distance.toString(),
    isKmDistanceUnit: params.isKmDistanceUnit,
    conditionField: say[params.condition],
    condition: params.condition,
    cityFuelConsumptionField: (params.cityFuelConsumption || "").toString(),
    highwayFuelConsumptionField: (params.highwayFuelConsumption || "").toString(),
    cylinderCountField: params.cylinderCount ? params.cylinderCount.toString() : undefined,
    driveTrainField: say[params.driveTrain],
    driveTrain: params.driveTrain,
    isManualTransmission: params.isManualTransmission,
    fuelTypeField: say[params.fuelType],
    fuelType: params.fuelType,
    priceField: params.price.toString(),
    estimateField: params.estimate === undefined ? "" : String(params.estimate?.price),
    isMsrp: params.estimate === undefined ? false : params.estimate.isMsrp,
    isFullOwnership: params.customerParams?.isFullOwnership,
    firstName: params.customerParams?.firstName,
    lastName: params.customerParams?.lastName,
    email: params.customerParams?.email,
    phone: params.customerParams?.phone,
    address: params.customerParams?.address as PostalAddress,
    viewIndex: 0,
  }, () => colId ? setReduxState({
    popups: {
      name: "EditPartialVehicle",
    },
    vehicles: {
      editedComment: params.comment || { html: "", plainText: "" },
      editedTableCell: colId
    }
  }) : setReduxState("vehicles", {
    editedTableCell: undefined
  }, () => goToExactPath("/edit-vehicle")))
  getManyFiles(params.imageIdList, false, imageList => setReduxState("createVehicle", { imageList }))
}