import { useReduxState } from "@/redux"

export function FormFooter({ children }: { children: React.ReactNode }): JSX.Element | null {
  const isDashboard = useReduxState(state => state.routes.firstPath === "dashboard")
  if (isDashboard) {
    return null
  }
  return (
    <div className="footer">
      <div className="row-box">
        {children}
      </div>
    </div>
  )
}