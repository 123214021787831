import { Table } from "@/components"
import { useReduxState } from "@/redux"

import { BROKERAGE_TABLE_HEAD_LIST } from "../constants/USER_TABLE_HEAD_LIST"

import { LeftButtonList } from "./LeftButtonList"

export function UsersTable(): JSX.Element {
  const { tableUserIdList, say, outputMapByUserId, shownColumnList, isFilters } = useReduxState(state => {
    return {
      say: state.languages.say,
      shownColumnList: state.users.shownColumnList,
      tableUserIdList: state.users.tableUserIdList,
      outputMapByUserId: state.users.outputMapByUserId,
      isFilters: state.ui.isFilters,
    }
  })
  return (
    <>
      <LeftButtonList tableUserIdList={tableUserIdList} />
      <div className={`flex mt-[140px] ${isFilters ? "ml-[400px]" : "ml-0"}`} >
        <Table
          filter={colId => shownColumnList.includes(colId)}
          headList={BROKERAGE_TABLE_HEAD_LIST.map(key => say[key])}
          colIdList={[...BROKERAGE_TABLE_HEAD_LIST]}
          rowIdList={tableUserIdList}
          bodyList={tableUserIdList.map(_id => outputMapByUserId[_id]!)}
        />
      </div>
    </>
  )
}