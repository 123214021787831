/* eslint-disable no-template-curly-in-string */
import "moment/locale/fr"

import { html } from ".."

import {
  Say
} from "./model"

export const fr: Say = {
  weSupportPalestine: html`Nous supportons la <b>Palestine!</b>`,
  userAlreadyLogged: "L'utilisateur est déjà connecté",
  userDoesntExists: "L'utilisateur n'existe pas",
  wrongPassword: "Mot de passe incorrect",
  userAlreadyExists: "L'utilisateur existe déjà",
  accessDenied: "Accès refusé",
  wrongFileSize: "Taille de fichier incorrecte",
  dataDoesntExists: "Les données demandées au serveur n'existent pas",
  wrongNewPassword: "Le nouveau mot de passe doit être entre 6 et 40 caractères",
  invalidValidator: "Requête invalide",
  invalidEmail: "Email invalide",
  corruptedData: "Les données que vous avez demandées sont corrompues",
  locationNotSupported: "Votre localisation est non supportée",
  invalidLocation: "Votre adresse est invalide",
  invalidPhone: "Téléphone invalide",
  invalidDate: "Date invalide",
  internalServerError: "Erreur de serveur interne",
  verifCodeFirst: "Premièrement, entrez le code de vérification dans vos mails",
  wrongToken: "Code de vérification incorrect",
  canada: "Canada",
  unitedStates: "États-Unis",
  alberta: "Alberta",
  britishColumbia: "Colombie-Britannique",
  manitoba: "Manitoba",
  newBrunswick: "Nouveau-Brunswick",
  newfoundlandAndLabrador: "Terre-Neuve-et-Labrador",
  northwestTerritories: "Territoires du Nord-Ouest",
  novaScotia: "Nouvelle-Écosse",
  nunavut: "Nunavut",
  ontario: "Ontario",
  princeEdwardIsland: "Île-du-Prince-Édouard",
  quebec: "Québec",
  saskatchewan: "Saskatchewan",
  yukonTerritory: "Territoire du Yukon",
  alabama: "Alabama",
  alaska: "Alaska",
  arizona: "Arizona",
  arkansas: "Arkansas",
  california: "Californie",
  colorado: "Colorado",
  connecticut: "Connecticut",
  delaware: "Delaware",
  districtOfColumbia: "District of Columbia",
  florida: "Floride",
  georgia: "Georgie",
  hawaii: "Hawaii",
  idaho: "Idaho",
  illinois: "Illinois",
  indiana: "Indiana",
  iowa: "Iowa",
  kansas: "Kansas",
  kentucky: "Kentucky",
  louisiana: "Louisiane",
  maine: "Maine",
  maryland: "Maryland",
  massachusetts: "Massachusetts",
  michigan: "Michigan",
  minnesota: "Minnesota",
  mississippi: "Mississippi",
  missouri: "Missouri",
  montana: "Montana",
  nebraska: "Nebraska",
  nevada: "Nevada",
  newHampshire: "New Hampshire",
  newJersey: "New Jersey",
  newMexico: "Nouveau Mexique",
  newYork: "New York",
  northCarolina: "Caroline du Nord",
  northDakota: "Dakota du Nord",
  ohio: "Ohio",
  oklahoma: "Oklahoma",
  oregon: "Oregon",
  pennsylvania: "Pennsylvanie",
  rhodeIsland: "Rhode Island",
  southCarolina: "Caroline du Sud",
  southDakota: "Dakota du Sud",
  tennessee: "Tennessee",
  texas: "Texas",
  utah: "Utah",
  vermont: "Vermont",
  virginia: "Virginie",
  washington: "Washington",
  westVirginia: "Virginie de L'ouest",
  wisconsin: "Wisconsin",
  wyoming: "Wyoming",
  vehicleCreateSuccess: "Véhicule créé avec succès",
  brokerageCreateSuccess: "Demande de courtage créée avec succès",
  userCreateSuccess: "Compte créé avec succès",
  address: "Adresse",
  addressPlaceholder: "Ville, adresse ou code postal",
  streetNumber: "Numéro de rue",
  streetName: "Nom de rue",
  streetNamePlaceholder: "Rue St-michel",
  postalCode: "Code postal",
  postalCodePlaceholder: "H1H",
  city: "Ville",
  cityPlaceholder: "Montréal",
  state: "État/Province",
  statePlaceholder: "Québec",
  country: "Pays",
  countryPlaceholder: "Canada",
  quickLinks: "Liens rapides",
  termsAndConditions: "Termes & conditions",
  privacyPolicy: "Politique de confidentialités",
  bugReport: "Rapport de bug",
  allRightsReserved: "Tout droits réservés",
  uploadImages: "Télécharger des images *",
  applyPolicies: "\n  En navigant sur avartasolutions.com , j'accepte les\n  <a href='https://avartasolutions.com  /terms'>Termes et conditions</a>\n  et les\n  <a href='https://avartasolutions.com  /privacy'>Politiques de confidentialités</a>.\n  ",
  ok: "Ok",
  today: "Aujourd'hui",
  sendBugReport: "Envoyer un rapport de bug",
  operatingSystem: "Système d'exploitation",
  date: "Date",
  yearDateShort: "D MMMM YYYY",
  problemDescription: "Description du problème",
  cancel: "Annuler",
  send: "Envoyer",
  editPassword: "Modifier le mot de passe",
  passwordProperties: "6 à 48 caractères sans espace",
  save: "Sauvegarder",
  oldPassword: "Ancien mot de passe",
  newPassword: "Nouveau mot de passe",
  confirmedNewPassword: "Confirmez le mot de passe",
  askDeleteBug: "Voulez-vous vraiment supprimer ce rapport de bug",
  editVehicle: "Modifier le véhicule",
  publishTheVehicle: "Envoyer",
  previous: "Précédent",
  describeVehicle: "Décrivez votre véhicule",
  uploadVehiclePhotos: "Télécharger les photos du véhicule",
  lastStep: "C'est la dernière étape!",
  next: "Suivant",
  dashboard: "Tableau de bord",
  vehicleSearchPlaceholder: "Rechercher un Véhicule...",
  audience: "Audience",
  role: "Rôle",
  status: "Status",
  forgotPassword: "Mot de passe oublié?",
  forgotPasswordHeader: "Entrez votre email pour recevoir un mot de passe temporaire.",
  email: "Email",
  emailPlaceholder: "example@email.com",
  login: "Connexion",
  loginHeader: "Connectez-vous à Avarta Auto",
  password: "Mot de passe",
  pageNotFound: "Page non répertoriée",
  createUser: "Créer un utilisateur",
  createUserHeader: "Vous receverez ensuite un mot de passe par email.",
  create: "Créer",
  passwordNotMatching: "Le mot de passe ne correspond pas",
  noInternetConnexionFound: "Aucune connexion internet trouvée",
  createBugSuccess: "Rapport de bug envoyé avec succès",
  deleteBugSuccess: "Rapport de bogue supprimé avec succès",
  deleteUserSuccess: "L'utilisateur a été supprimé avec succès",
  passwordSent: "Mot de passe envoyé par email!",
  userUpdateSuccess: "L'utilisateur a été modifié avec succès",
  passwordSuccess: "Mot de passe modifié avec succès",
  emailCopiedInClipboard: "Courriel copié dans le presse-papiers",
  pressMe: (value: number) => `cliques-moi ${value} fois`,
  pressButton5Times: "Appuyer sur le bouton 5 fois",
  yes: "Oui",
  delete: "Supprimer",
  edit: "Modifier",
  avartaAddress: "4736 Boulevard St-Jean, Suite B201",
  seeInGoogleMap: "Voir sur Google maps",
  home: "Accueil",
  sellVehicle: "Vendez votre véhicule",
  inventory: "Inventaire",
  brokerageService: "Service de courtage",
  aboutUs: "À propos",
  en: "Anglais",
  fr: "Français",
  headerTitle1: "Vous cherchez un véhicule ?",
  headerTitle2: "Vous voulez vendre votre véhicule ?",
  headerTitle3: "Véhicules sportifs",
  headerSubTitle1: "Nous avons ce qu'il vous faut !",
  headerSubTitle2: "La façon la plus simple de vendre au prix que vous voulez !",
  headerSubTitle3: "Service de courtage efficace et fiable !",
  seeMore: "Voir plus",
  hotCategories: "Catégories populaires",
  ourServices: "Nos services",
  quoteName1: "Douaa Abid",
  quoteName2: "Georges Levesque",
  quoteName3: "Rahma Mohamed",
  quote1: "Excellent service à la clientèle !  Je le recommande à tous ceux qui cherchent une voiture. Ils ont fait en sorte que j'obtienne la meilleure offre possible pour ma nouvelle voiture",
  quote2: "Très impressionné. Ils ont été extrêmement communicatifs et transparents pendant que je cherchais une voiture d'occasion pour ma fille. Ils ont livré exactement à l'heure et ont fait un travail superbe avec tout le processus. Je recommande vivement ces personnes",
  quote3: "Excellents courtiers, cette société m'a aidé à trouver la voiture parfaite. Je les recommande à 100% ; ils sont très professionnels et dévoués à leurs clients. Je ferai de nouveau affaire avec eux, c'est certain !",
  reviews: "Évaluations",
  serviceSubTitle1: "Naviguez notre inventaire pour trouver une aubaine!",
  serviceSubTitle2: "Faites confiance à notre équipe de professionnels pour qu'ils trouvent le véhicule qu'il vous faut!",
  serviceSubTitle3: "Vendez votre véhicule rapidement et en toute confiance!",
  buyAVehicle: "Acheter une voiture",
  brokerage: "Service de courtage",
  realFact: "Notre Portfolio",
  satisfiedCustomers: "Clients satisfaits",
  yearsOfExperience: "Années d'expérience",
  dealershipNetworks: "Réseaux de concessionnaires",
  acura: "Acura",
  alfa: "Alfa Romeo",
  amc: "AMC",
  aston: "Aston Martin",
  audi: "Audi",
  avanti: "Avanti",
  bentl: "Bentley",
  bmw: "BMW",
  buick: "Buick",
  cad: "Cadillac",
  chev: "Chevrolet",
  chry: "Chrysler",
  daew: "Daewoo",
  daihat: "Daihatsu",
  datsun: "Datsun",
  delorean: "DeLorean",
  dodge: "Dodge",
  eagle: "Eagle",
  fer: "Ferrari",
  fiat: "FIAT",
  fisk: "Fisker",
  ford: "Ford",
  freight: "Freightliner",
  geoBrand: "Geo",
  gmc: "GMC",
  honda: "Honda",
  amgen: "HUMMER",
  hyund: "Hyundai",
  infin: "Infiniti",
  isu: "Isuzu",
  jag: "Jaguar",
  jeep: "Jeep",
  kia: "Kia",
  lam: "Lamborghini",
  lan: "Lancia",
  rov: "Land Rover",
  lexus: "Lexus",
  linc: "Lincoln",
  lotus: "Lotus",
  mas: "Maserati",
  maybach: "Maybach",
  mazda: "Mazda",
  mclaren: "McLaren",
  mb: "Mercedes-Benz",
  merc: "Mercury",
  merkur: "Merkur",
  mini: "MINI",
  mit: "Mitsubishi",
  nissan: "Nissan",
  olds: "Oldsmobile",
  peug: "Peugeot",
  plym: "Plymouth",
  pont: "Pontiac",
  por: "Porsche",
  ram: "RAM",
  ren: "Renault",
  rr: "Rolls-Royce",
  saab: "Saab",
  saturn: "Saturn",
  scion: "Scion",
  smart: "Smart",
  srt: "SRT",
  sterl: "Sterling",
  sub: "Subaru",
  suzuki: "Suzuki",
  tesla: "Tesla",
  toyota: "Toyota",
  tri: "Triumph",
  volks: "Volkswagen",
  volvo: "Volvo",
  yugo: "Yugo",
  new: "Neuf",
  likeNew: "Comme neuf",
  used: "Usagé",
  damaged: "Endommagé",
  totalLoss: "Perte totale",
  rear: "Transmission arrière",
  front: "Traction avant",
  allWheel: "Traction intégrale",
  gaz: "Gaz",
  diesel: "Diesel",
  hybrid: "Hybride",
  electric: "Électrique",
  sedan: "Sedan",
  minivan: "Minivan",
  suv: "VUS",
  hatchback: "Hatchback",
  cabriolet: "Cabriolet",
  sport: "Sport",
  pickup: "Pickup",
  offroad: "Tout-terrain",
  minitruck: "Mini-truck",
  micro: "Micro",
  van: "Van",
  campervan: "Campeur",
  selectDistance: "Entrez la distance parcourue",
  selectCondition: "Sélectionnez l'état de la voiture",
  selectBodyStyle: "Sélectionnez la carrosserie",
  selectCylinderCount: "Entrez la quantité de cylindres",
  selectTransmission: "Sélectionnez le type de transmission",
  selectExteriorColor: "Entrez la couleur extérieure",
  selectInteriorColor: "Entrez la couleur intérieure",
  selectDriveTrain: "Sélectionnez la position de la transmission",
  selectVin: "Saisissez le numéro d'identification du véhicule (NIV)",
  selectDoors: "Sélectionnez le nombre de portes",
  selectSeatingCount: "Sélectionnez le nombre de places",
  selectFuelType: "Sélectionnez le type de carburant",
  selectCityFuelConsumption: "Consommation en ville (L/100 km)",
  selectHighwayFuelConsumption: "Consommation sur autoroute (L/100 km)",
  selectBrand: "Sélectionnez la marque",
  selectModel: "Sélectionnez le modèle",
  selectDistanceType: "Sélectionnez un type d'unité",
  selectYear: "Sélectionnez l'année",
  selectPrice: "Prix de vente souhaité",
  selectEquipments: "Sélectionnez les équipements",
  distance: "Distance parcourue",
  condition: "Condition du véhicule",
  bodyStyle: "Style de carrosserie",
  cylinderCount: "Nombre de cylindres",
  transmission: "Transmission",
  exteriorColor: "Couleur extérieure",
  interiorColor: "Couleur intérieure",
  driveTrain: "Type de transmission",
  doors: "Nombre de portes",
  doorsShort: "Portes",
  seats: "Places",
  seatingCount: "Nombre de places assises",
  fuelType: "Type de carburant",
  cityFuelConsumption: "Consommation en ville (L/100km)",
  highwayFuelConsumption: "Consommation sur autoroute (L/100km)",
  cityFuelConsumptionShort: "Consommation en ville",
  highwayFuelConsumptionShort: "Consommation sur autoroute",
  brand: "Marque",
  model: "Modèle",
  year: "Année",
  price: "Prix",
  equipments: "Équipements",
  manual: "Manuel",
  automatic: "Automatique",
  red: "Rouge",
  black: "Noir",
  airConditioning: "Air conditionné",
  leather: "Cuir",
  parkingSensors: "Capteurs de stationnement",
  cruiseControl: "Régulateur de vitesse adaptatif avec assistance au maintien dans la voie",
  carbonFiberTrim: "Garniture en fibre de carbone",
  appleCarPlayOrAndroidAuto: "Apple Véhicule play / Android Auto",
  panoramicSunroof: "Toit ouvrant panoramique",
  sunroof: "Toit ouvrant",
  remoteStarter: "Démarreur à distance",
  warrantyOrExtendedWarranty: "Garantie et/ou extension de garantie",
  sportPackage: "Pack Sport",
  digitalCluster: "Digital Cluster",
  digitalIntertainmentDisplay: "Affichage numérique de l'infotainment",
  sportsExhaust: "Échappement sport",
  blackPackage: "Pack noir",
  technicalSpecifications: "Fiche technique du véhicule",
  km: "km",
  miles: "Miles",
  selectOwnership: "Sélectionnez le type de possession",
  leasedVehicle: "Véhicule loué / financé",
  ownedVehicle: "Pleine propriété du véhicule",
  firstName: "Prénom",
  lastName: "Nom de famille",
  phone: "Numéro de téléphone",
  publishVehicle: "Publier un véhicule",
  sellVehicleSuccessDescription: "Votre véhicule est en évaluation et nous vous contacterons bientôt",
  brokerageSuccessDescription: "Votre demande de courtage est en cours de révision et nous vous contacterons bientôt",
  seeVehicleDetails: "Voir les détails du véhicule",
  makeVehiclePrivate: "Rendre le véhicule privé",
  makeVehiclePublic: "Rendre le véhicule public",
  askDeleteVehicle: (title: string) => `Etes-vous sûr de vouloir supprimer le véhicule ${title}?`,
  askDeleteBrokerage: (title: string) => `Etes-vous sûr de vouloir supprimer ce courtage avec ${title}?`,
  askDeleteUser: (email: string) => `Etes-vous sûr de vouloir supprimer cet utilisateur ${email}?`,
  logout: "Déconnexion",
  createVehicle: "Publier un véhicule",
  cylinderEngine: (count: number) => `Moteur ${count} cylindres`,
  engine: "Moteur",
  viewMore: "Voir plus de détails",
  filters: "Filtres",
  minYear: "À partir de l'année",
  maxYear: "À l'année",
  minDistance: "Distance min",
  maxDistance: "Distance max",
  minPrice: "Prix min",
  maxPrice: "Prix max",
  clearFilters: "Supprimer les filtres",
  lessThan: (price: number) => `Moins de ${price} $ / Mois`,
  moreThan: (price: number) => `${price} $ et plus`,
  contactForm: "Entrez vos informations personnelles",
  vehicleForm: "Quel type de véhicule recherchez-vous ?",
  tellYourNeeds: "Dites-nous ce dont vous avez besoin!",
  selectFuelTypeLong: "Sélectionnez le type de carburant (1 à 2 choix possibles)",
  selectDriveTrainLong: "Sélectionnez la transmission (1 à 2 choix possibles)",
  selectBrandLong: "Sélectionnez la marque (1 à 3 choix possibles)",
  selectPriceLong: "Qu'il s'agisse d'un véhicule en location ou financé, quel est votre budget mensuel ?",
  selectBodyStyleLong: "Sélectionnez le style de carrosserie (1 seul choix)",
  brokerages: "Courtages",
  vehicles: "Véhicules",
  deleteBrokerageSuccess: "Brokerage supprimé avec succès",
  no: "Non",
  undefined: "Non définit",
  priceBalance: "Solde restant du véhicule",
  welcomeBrokerage: "Bienvenue dans notre service de courtage!",
  welcomeBrokerageDescription: "Laissez nous vous trouver le véhicule qu'il vous faut!",
  welcomeSellCar: "Bienvenue sur notre plateforme pour vendre votre véhicule !",
  welcomeSellCarDescription: "Le moyen le plus simple de vendre votre véhicule au prix que vous souhaitez !",
  brokerageFirstQuestion: "Afin de parfaire nos conseils pour faciliter la recherche de votre véhicule, veuillez nous dire pourquoi nous recherchons ce véhicule ?",
  brokerageFirstPlaceholder: "EX: Pour le travail, Voiture personnelle, Voiture de sport pour le week-end, etc...",
  generalQuestion: "Question générale",
  users: "Utilisateurs",
  basic: "De base",
  admin: "Admin",
  resetPasswordSubject: "Nouveau mot de passe",
  resetPasswordTemplateTitle: html`Votre mot de passe pour <b>Avarta Solutions</b> vient d'être modifié.`,
  createUserTemplateDescription: "Vous pouvez accéder à votre compte avec votre email",
  temporaryPassword: "Mot de passe temporaire",
  enterYourInformations: "Entrez vos informations",
  askSchedule: "Quand voulez-vous que le véhicule soit livré ?",
  less1Week: "Moins d'une semaine",
  "1or2Weeks": "1 à 2 semaines",
  "2to4Weeks": "2 à 4 semaines",
  "1to3Months": "1 à 3 mois",
  scheduleType: "Délais demandés",
  afg: "Afghanistan",
  zaf: "Afrique du Sud",
  alb: "Albanie",
  dza: "Algérie",
  deu: "Allemagne",
  and: "Andorre",
  ago: "Angola",
  atg: "Antigua-et-Barbuda",
  sau: "Arabie saoudite",
  arg: "Argentine",
  arm: "Arménie",
  aus: "Australie",
  aut: "Autriche",
  aze: "Azerbaïdjan",
  bhs: "Bahamas",
  bhr: "Bahreïn",
  bgd: "Bangladesh",
  brb: "Barbade",
  blr: "Biélorussie",
  bel: "Belgique",
  blz: "Belize",
  ben: "Bénin",
  btn: "Bhoutan",
  bol: "Bolivie",
  bih: "Bosnie-Herzégovine",
  bwa: "Botswana",
  bra: "Brésil",
  brn: "Brunei",
  bgr: "Bulgarie",
  bfa: "Burkina Faso",
  bdi: "Burundi",
  khm: "Cambodge",
  cmr: "Cameroun",
  can: "Canada",
  cpv: "Cap-Vert",
  caf: "République centrafricaine",
  chl: "Chili",
  chn: "Chine",
  cyp: "Chypre",
  col: "Colombie",
  com: "Comores",
  cog: "République du Congo",
  cod: "République démocratique du Congo",
  kor: "Corée du Sud",
  prk: "Corée du Nord",
  cri: "Costa Rica",
  civ: "Côte d'Ivoire",
  hrv: "Croatie",
  cub: "Cuba",
  dnk: "Danemark",
  dji: "Djibouti",
  dom: "République dominicaine",
  dma: "Dominique",
  egy: "Égypte",
  slv: "Salvador",
  are: "Émirats arabes unis",
  ecu: "Équateur",
  eri: "Érythrée",
  esp: "Espagne",
  est: "Estonie",
  usa: "États-Unis",
  eth: "Éthiopie",
  fji: "Fidji",
  fin: "Finlande",
  fra: "France",
  gab: "Gabon",
  gmb: "Gambie",
  geo: "Géorgie",
  gha: "Ghana",
  grc: "Grèce",
  grd: "Grenade",
  gtm: "Guatemala",
  gin: "Guinée",
  gnb: "Guinée-Bissau",
  gnq: "Guinée équatoriale",
  guy: "Guyana",
  hti: "Haïti",
  hnd: "Honduras",
  hun: "Hongrie",
  ind: "Inde",
  idn: "Indonésie",
  irn: "Iran",
  irq: "Irak",
  irl: "Irlande",
  isl: "Islande",
  isr: "Israël",
  ita: "Italie",
  jam: "Jamaïque",
  jpn: "Japon",
  jor: "Jordanie",
  kaz: "Kazakhstan",
  ken: "Kenya",
  kgz: "Kirghizistan",
  kir: "Kiribati",
  kwt: "Koweït",
  lao: "Laos",
  lso: "Lesotho",
  lva: "Lettonie",
  lbn: "Liban",
  lbr: "Liberia",
  lby: "Libye",
  lie: "Liechtenstein",
  ltu: "Lituanie",
  lux: "Luxembourg",
  mkd: "Macédoine du Nord",
  mdg: "Madagascar",
  mys: "Malaisie",
  mwi: "Malawi",
  mdv: "Maldives",
  mli: "Mali",
  mlt: "Malte",
  mar: "Maroc",
  mhl: "Îles Marshall",
  mus: "Maurice",
  mrt: "Mauritanie",
  mex: "Mexique",
  fsm: "États fédérés de Micronésie",
  mda: "Moldavie",
  mco: "Monaco",
  mng: "Mongolie",
  mne: "Monténégro",
  moz: "Mozambique",
  mmr: "Birmanie",
  nam: "Namibie",
  nru: "Nauru",
  npl: "Népal",
  nic: "Nicaragua",
  ner: "Niger",
  nga: "Nigeria",
  nor: "Norvège",
  nzl: "Nouvelle-Zélande",
  omn: "Oman",
  uga: "Ouganda",
  uzb: "Ouzbékistan",
  pak: "Pakistan",
  plw: "Palaos",
  pan: "Panama",
  png: "Papouasie-Nouvelle-Guinée",
  pry: "Paraguay",
  nld: "Pays-Bas",
  per: "Pérou",
  phl: "Philippines",
  pol: "Pologne",
  prt: "Portugal",
  pse: "Palestine",
  qat: "Qatar",
  rou: "Roumanie",
  gbr: "Royaume-Uni",
  rus: "Russie",
  rwa: "Rwanda",
  kna: "Saint-Christophe-et-Niévès",
  smr: "Saint-Marin",
  vct: "Saint-Vincent-et-les-Grenadines",
  lca: "Sainte-Lucie",
  slb: "Îles Salomon",
  wsm: "Samoa",
  stp: "Sao Tomé-et-Principe",
  sen: "Sénégal",
  srb: "Serbie",
  syc: "Seychelles",
  sle: "Sierra Leone",
  sgp: "Singapour",
  svk: "Slovaquie",
  svn: "Slovénie",
  som: "Somalie",
  sdn: "Soudan",
  ssd: "Soudan du Sud",
  lka: "Sri Lanka",
  swe: "Suède",
  che: "Suisse",
  sur: "Suriname",
  swz: "Eswatini",
  syr: "Syrie",
  tjk: "Tadjikistan",
  tza: "Tanzanie",
  tcd: "Tchad",
  cze: "Tchéquie",
  tha: "Thaïlande",
  tls: "Timor oriental",
  tgo: "Togo",
  ton: "Tonga",
  tto: "Trinité-et-Tobago",
  tun: "Tunisie",
  tkm: "Turkménistan",
  tur: "Turquie",
  tuv: "Tuvalu",
  ukr: "Ukraine",
  ury: "Uruguay",
  vut: "Vanuatu",
  ven: "Venezuela",
  vnm: "Viêt Nam",
  yem: "Yémen",
  zmb: "Zambie",
  zwe: "Zimbabwe",
  invalidField: "Champ(s) invalide(s)",
  optional: "Optionnel",
  streetExt: "Extension",
  streetExtPlaceholder: "Bureau 280",
  images: "Images",
  missingField: "Champ manquant",
  vin17: "Numéro VIN < 17",
  other: "Autre",
  otherBrand: "Autre marque",
  requested: "Demandé",
  accepted: "Accepté",
  completed: "Terminé",
  inProgress: "En cours",
  discardedByTeam: "Rejeté par l'équipe",
  canceledByCustomer: "Annulé par le client",
  search: "Rechercher",
  writeSomething: "Écrivez quelque chose",
  searchPrecision: "Précision de recherche",
  shownColumns: "Colonnes affichées",
  low: "Faible",
  medium: "Moyenne",
  high: "Haute",
  estimate: "Estimé",
  msrp: "PDSF",
  creationDate: "Date de création",
  lastEditDate: "Date de modification",
  comment: "Commentaire",
  vinId: "ID du VIN",
  ownership: "Propriété",
  public: "Publique",
  private: "Privé",
  all: "Tout",
  avarta: "Avarta",
  details: "Détails",
  seeInitDetails: "Voir les info initiales",
  hideInitDetails: "Masquer les info initiales",
  old: "Ancien",
  current: "Actuel",
  editStatus: "Modifier le status",
  vehicleValueEstimate: "Estimation de la valeur du véhicule",
  msrpLong: "prix de détail suggéré par le fabricant (PDSF)",
  createForClient: "Créer pour le client",
  createForAvarta: "Créer pour avarta",
  sellVehicleTitle: "La vente de votre véhicule est maintenant plus facile !",
  sellVehicleSectionTitle: "Vendez et économisez",
  sellVehicleSectionSubTitle1: "Économisez votre argent",
  sellVehicleSectionSubTitle2: "Économisez votre temps",
  sellVehicleSectionSubText1: "Afin de réduire la taxe de vente associée, utilisez la valeur de votre véhicule actuel pour l'achat du prochain",
  sellVehicleSectionSubText2: "Notre service complet vous permettra de vous asseoir et de vous détendre pendant que nous faisons le travail",
  fillForm: "Remplir notre formulaire en conséquence",
  buyConfidence: "Achetez en toute confiance",
  brokerageDescription: "Lorsque vous faites appel aux services d'un courtier automobile, vous pouvez acheter en toute confiance, en sachant que vous êtes assisté par une personne qualifiée. Par conséquent, vous n'aurez pas à vous soucier de la possibilité de tromperie, de fraude ou d'autres problèmes qui existent actuellement dans le secteur automobile",
  brokerageSectionTitle: "Des services de courtage efficaces et fiables",
  brokerageSectionSubTitle1: "Prix exclusif",
  brokerageSectionSubTitle2: "Transparence",
  brokerageSectionSubTitle3: "Des partenaires à l'écoute",
  brokerageSectionText1: "Profitez d'un bon prix qui a été négocié pour vous",
  brokerageSectionText2: "Dites-nous ce que vous cherchez et nous vous ferons part de ce que vos voisins ont payé",
  brokerageSectionText3: "Le concessionnaire qui vous a fourni le prix exclusif répondra à toutes vos questions",
  developedBy: "Développé par",
}