import { getCreateBrokerageInitState, validateBrokerage } from "@/pages/CreateBrokerage/functions"
import { getReduxState, setReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { handleSocketRequest } from "../functions"

let isCreating: boolean = false
export function createOneBrokerage(): void {
  const { createBrokerage, languages } = getReduxState()
  const {
    brandList,
    otherBrand,
    bodyStyle,
    driveTrainList,
    fuelTypeList,
    generalQuestionField,
    price,
    firstName,
    lastName,
    phone,
    email,
    address,
    scheduleType
  } = createBrokerage
  if (isCreating) {
    return
  }
  isCreating = true
  if (validateBrokerage()) {
    isCreating = false
    return
  }

  handleSocketRequest("brokerages", "createOne", {
    brandList,
    otherBrand: brandList.includes("other") ? otherBrand : "",
    bodyStyle: bodyStyle!,
    driveTrainList,
    generalQuestion: generalQuestionField,
    fuelTypeList,
    price: price!,
    firstName,
    lastName,
    phone,
    email,
    address: address!,
    scheduleType: scheduleType!,
    language: languages.selectedLanguage
  }, data => {
    goToExactPath("/home")
    isCreating = false
    setReduxState("popups", {
      name: "LargeMessage",
      props: {
        title: getReduxState().languages.say.brokerageCreateSuccess,
        description: getReduxState().languages.say.brokerageSuccessDescription,
        iconName: "thumbs-up"
      }
    }, () => {

      setReduxState("createBrokerage", getCreateBrokerageInitState(), () => {
        setReduxState("brokerages", {
          brokerageList: [
            data,
            ...getReduxState().brokerages.brokerageList
          ]

        })
      })
    })
  }, err => {
    isCreating = false
    setReduxState("ui", { snackbar: { message: err, severity: "error" } })
  })
}