import { aspectRatio, center } from "@/ui/mixins"
import styled from "styled-components"

export const VehicleItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  background: ${() => window.theme.grey100};
  box-shadow: 0 5px 10px black;

  .img {
    display: flex;
    ${aspectRatio(1, 1)};
    touch-action: none;
    pointer-events: none;
    width: 100%;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
  }
  .fal-image  {
    ${center}
    ${aspectRatio(1, 1)};
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    background: ${() => window.theme.grey800};
    color: ${() => window.theme.grey500};
    svg {
      top: calc(50% - 20px);
      position: absolute;
      height: 40px;
    }
  }
  .content-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px;
  }

  h3 {
    flex: 1;
    font-size: 20px;
    margin-bottom: 5px;
  }
  h4 {
    font-size: 22px;
    margin-bottom: 10px;
    align-self: flex-end;
    color: ${() => window.theme.primary500};
  }
  .specs-box {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 20px;
  }

  button {
    border-radius: 5px !important;
  }
`