import { isInvalidFields } from "@/functions"
import { getReduxState } from "@/redux"

export function validateBrokerage(customViewIndex?: number): boolean {
  const reduxState = getReduxState()
  const {
    brandList,
    otherBrand,
    bodyStyle,
    driveTrainList,
    fuelTypeList,
    price,
    firstName,
    lastName,
    phone,
    email,
    generalQuestionField,
    scheduleType,
    address,
    viewIndex,
  } = reduxState.createBrokerage
  if (customViewIndex === undefined) {
    customViewIndex = viewIndex
  }
  if (customViewIndex === 1) {
    return isInvalidFields({
      generalQuestion: generalQuestionField.plainText.trim().length < 1,
      scheduleType: scheduleType === undefined
    })
  }
  if (customViewIndex === 2) {
    return isInvalidFields({
      bodyStyle: !bodyStyle,
      fuelType: fuelTypeList.length < 1
        || fuelTypeList.length > 2,
      driveTrain: driveTrainList.length < 1
        || driveTrainList.length > 2,
      brand: brandList.length < 1
        || brandList.length > 3,
      otherBrand: brandList.includes("other") && !otherBrand.length,
      price: !price

    })
  }
  if (customViewIndex === 3) {
    return isInvalidFields({
      firstName: !firstName.trim(),
      lastName: !lastName.trim(),
      email: !email.trim(),
      phone: !phone.trim(),
      address: !address
        || !address.streetNumber
        || !address.streetName
        || !address.city
        || !address.stateOrRegion

    })
  }
  else {
    return false
  }
}