import { breakpoints, center } from "@/ui/mixins"
import styled from "styled-components"

export const QuotesStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  background: #705e33;
  width: calc(100% + 20px);
  margin-left: -10px;
  box-shadow: inset 0 0 10px black;
  margin-bottom: 40px;

  h1 {
    text-align: center;
    margin-bottom: 40px;
    color: white;
  }
  a {
    margin-top: 10px;
    font-weight: 500;
    text-decoration: underline;
  }
  h5 {
    margin-top: 10px;
    color: #ffffff77;
    font-weight: 400;
  }
  .list-box {
    display: grid;
    justify-content: center;
    grid-template-columns: min(calc(100vw - 40px), 350px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-rows: auto;
    @media screen and (min-width: ${breakpoints.sm}) {
      grid-template-columns: repeat(2, min(calc(50vw - 30px), 350px));
      .quote-item:last-child {
        width: calc(100vw - 40px);
      }
    }
    @media screen and (min-width: ${breakpoints.md}) {
      grid-template-columns: repeat(3, min(calc(33vw - 20px), 350px));
      max-height: 350px;
      .quote-item:last-child {
        width: 100%;
      }
    }
  }
  .quote-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 20px;
    span {
      font-size: 28px;
      display: flex;
      color: hsl(42,25%,50%);
    }
    .quote-start {
      align-items: flex-start;
      transform: translate(-5px,-5px);

    }
    .quote-end {
      align-items: flex-end;
      transform: translate(5px, 14px);
    }

  }
  .text-box {
   display: flex;
   text-align: center;
   color: #ffffff99;

  }
  .fa-icon {
    ${center};
    height: 25%;
    width: 25%;
    border-radius: 9999px;
    margin-bottom: 20px;
    opacity: 0.9;
    background: white;

    svg {
      height: 50%;
    }
  }
  p {
    text-align: center;
    font-weight: 200;
  }
  h4 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 300;
    color: white;
  }
`