import { getCreateVehicleInitState, validateVehicle } from "@/pages/CreateVehicle/functions"
import { getReduxState, setReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { handleSocketRequest } from "../functions"

let isCreating: boolean = false
export function createOneVehicle(): void {
  const { routes, createVehicle, languages, users: { currentUser } } = getReduxState()
  const { firstPath } = routes
  const {
    brand,
    model,
    year,
    bodyStyle,
    exteriorColor,
    interiorColor,
    doorCountField,
    seatingCountField,
    equipmentList,
    distanceField,
    isKmDistanceUnit,
    condition,
    cityFuelConsumptionField,
    highwayFuelConsumptionField,
    cylinderCountField,
    driveTrain,
    isManualTransmission,
    fuelType,
    imageList,
    priceField,
    isFullOwnership,
    firstName,
    lastName,
    phone,
    email,
    address,
    vinIdField
  } = createVehicle
  if (isCreating) {
    return
  }
  isCreating = true
  if (validateVehicle()) {
    isCreating = false
    return
  }

  handleSocketRequest("vehicles", "createOne", {
    brand: brand!,
    model: model!,
    year: year!,
    bodyStyle: bodyStyle!,
    exteriorColor,
    interiorColor,
    doorCount: firstPath !== "sell-vehicle" ? Number(doorCountField) : undefined,
    seatingCount: firstPath !== "sell-vehicle" ? Number(seatingCountField) : undefined,
    equipmentList,
    distance: Number(distanceField),
    isKmDistanceUnit,
    condition: condition!,
    cityFuelConsumption: Number(cityFuelConsumptionField),
    highwayFuelConsumption: Number(highwayFuelConsumptionField),
    cylinderCount: cylinderCountField ? Number(cylinderCountField) : undefined,
    driveTrain: driveTrain!,
    isManualTransmission,
    fuelType: fuelType!,
    imageFileList: imageList,
    price: Number(priceField),
    vinId: vinIdField,
    language: languages.selectedLanguage,

    customerParams: firstPath === "sell-vehicle" ? {
      isFullOwnership,
      firstName,
      lastName,
      phone,
      email,
      address: address!,
    } : undefined
  }, vehicle => {
    if (currentUser) {
      goToExactPath("/home")
    } else {
      goToExactPath("/dashboard/vehicles")
    }
    const newVehicleList = [...getReduxState().vehicles.vehicleList, vehicle]
    setReduxState({
      popups: {
        name: currentUser ? undefined : "LargeMessage",
        props: currentUser ? undefined : {
          title: getReduxState().languages.say.vehicleCreateSuccess,
          description: getReduxState().languages.say.sellVehicleSuccessDescription,
          iconName: "thumbs-up"
        }
      },
      vehicles: { vehicleList: newVehicleList },
      createVehicle: getCreateVehicleInitState()
    })
    isCreating = false
  }, err => {
    isCreating = false
    setReduxState("ui", { snackbar: { message: err, severity: "error" } })
  })
}