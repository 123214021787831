import { center, shadeEffect, breakpoints } from "@/ui/mixins"
import styled from "styled-components"

export const FooterMapStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 700px;
  height: 200px;
  .map-box {
    width: inherit;
    height: inherit;
  }
  .button {
    ${center};
    position: absolute;
    z-index: 10;
    height: 30px;
    padding: 0 15px;
    bottom: 20px;
    right: 20px;
    font-weight: 300;
    background: hsla(0, 0%, 0%, 0.5);
    backdrop-filter: blur(3px);
    border-radius: 9999px;
    ${() => shadeEffect("secondary900", "color")}
  }
  .address-box {
    ${center};
    position: absolute;
    z-index: 10;
    height: 25px;
    padding: 0 15px;
    top: 10px;
    left: 10px;
    font-weight: 200;
    font-size: 13px;
    background: hsla(0, 0%, 0%, 0.5);
    backdrop-filter: blur(3px);
    border-radius: 9999px;
    color: ${() => window.theme.grey300};
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    .map-box {
      border-radius: 20px;
    }
  }
`