import { listToMap } from "@/shared"
import { ReduxState, SetAction } from "@/redux/model"

import { getOutputMapByVehicleId } from "./functions/getOutputMapByVehicleId"
import { getTableVehicleIdList } from "./functions/getTableVehicleIdList"
import { VehiclesState } from "./model"


export const vehicles = (currentState: VehiclesState, action: SetAction<"vehicles">, globalState?: ReduxState) => {
  const data = { ...action.data }
  if ((data.vehicleList || data.isInitView !== undefined) && globalState) {
    if (!data.vehicleList) {
      data.vehicleList = currentState.vehicleList
    }
    data.vehicleMap = listToMap(data.vehicleList)
    data.vehicleIdList = data.vehicleList.map(item => item._id)
    data.outputMapByVehicleId = getOutputMapByVehicleId(
      data.vehicleList,
      globalState.languages.say,
      data.isInitView === undefined ? currentState.isInitView : data.isInitView
    )
  }
  if (globalState) {
    data.tableVehicleIdList = getTableVehicleIdList(currentState, data, globalState)
  }
  return {
    ...currentState,
    ...data
  }
}