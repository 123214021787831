import { Remove } from "@/shared"
import moment from "moment"
import { useState } from "react"

import { Icon } from ".."

import { DatePickerSelectStyled } from "./DatePickerSelectStyled"
import { DatePickerProps } from "./model"
const DayPicker = require("react-day-picker")

export function DatePickerSelect({ value, onChange }: Remove<DatePickerProps, "children">): JSX.Element {
  const firstYearDay = moment(value)
  firstYearDay.set("months", 0)
  const [year, setYear] = useState<string>(firstYearDay.toISOString())
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(undefined)
  return (
    <DatePickerSelectStyled className={`date-picker-select ${selectedMonth ? "date" : "month"}`}>
      {!selectedMonth && (
        <div className="select-months-box">
          <div className="top-button-box">
            <div className={moment(year).isSame(moment(), "year") ? "year active" : "year"}>{moment(year).format("YYYY")}</div>
            <Icon name="chevron-left" onClick={() => {
              const newMonth = moment(year)
              newMonth.subtract(12, "months")
              setYear(newMonth.toISOString())
            }} />
            <Icon name="chevron-right" onClick={() => {
              const newMonth = moment(year)
              newMonth.add(12, "months")
              setYear(newMonth.toISOString())
            }} />
          </div>
          <div className="select-month-list">
            {Array(12).fill(0).map((_, index) => {
              const monthMoment = moment(year)
              monthMoment.add(index, "month")
              return (
                <div key={index} className={monthMoment.isSame(moment(), "month") ? "active" : ""} onClick={() => {
                  setSelectedMonth(monthMoment.toISOString())
                }}>
                  {monthMoment.format("MMM")}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {selectedMonth && (
        <DayPicker
          showOutsideDays={true}
          month={new Date(selectedMonth)}
          onDayClick={(day: any) => {
            if (day) {
              onChange(day.toISOString())
            }
          }}
        />
      )}
    </DatePickerSelectStyled>
  )
}