import { center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const FilterOutputStyled = styled.div`
  ${center};
  position: relative;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 10px;
  border-radius: 9999px;
  margin-right: 10px;
  ${() => shadeEffect("grey850", "background", { incrementation: [50, 150] })};
  color: ${() => window.theme.grey300};
  &:hover .fal-times {
    display: flex;
  }
  .fal-times {
    ${center};
    position: absolute;
    display: none;
    top: -7.5px;
    right: -7.5px;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 9999px;
    background: ${() => window.theme.error500};
    svg {
      height: 16px;
    }
  }
  .fa-icon:not(.fal-times) {
    ${center};
    height: 100%;
    &:first-child, &.center {
      margin-right: 10px;
    }
    &.right, &.center {
      margin-left: 10px;
    }
    svg {
      height: 20px;
    }
  }
  img {
    height: 20px;
    margin-left: 10px;
  }

`