export const STATE_BY_STATE_CODE = {
  canada: {
    AB: "alberta",
    BC: "britishColumbia",
    MB: "manitoba",
    NB: "newBrunswick",
    NF: "newfoundlandAndLabrador",
    NT: "northwestTerritories",
    NS: "novaScotia",
    NU: "nunavut",
    ON: "ontario",
    PE: "princeEdwardIsland",
    QC: "quebec",
    SK: "saskatchewan",
    YT: "yukonTerritory"
  },
  unitedStates: {
    AL: "alabama",
    AK: "alaska",
    AZ: "arizona",
    AR: "arkansas",
    CA: "california",
    CO: "colorado",
    CT: "connecticut",
    DE: "delaware",
    DC: "districtOfColumbia",
    FL: "florida",
    GA: "georgia",
    HI: "hawaii",
    ID: "idaho",
    IL: "illinois",
    IN: "indiana",
    IA: "iowa",
    KS: "kansas",
    KY: "kentucky",
    LA: "louisiana",
    ME: "maine",
    MD: "maryland",
    MA: "massachusetts",
    MI: "michigan",
    MN: "minnesota",
    MS: "mississippi",
    MO: "missouri",
    MT: "montana",
    NE: "nebraska",
    NV: "nevada",
    NH: "newHampshire",
    NJ: "newJersey",
    NM: "newMexico",
    NY: "newYork",
    NC: "northCarolina",
    ND: "northDakota",
    OH: "ohio",
    OK: "oklahoma",
    OR: "oregon",
    PA: "pennsylvania",
    RI: "rhodeIsland",
    SC: "southCarolina",
    SD: "southDakota",
    TN: "tennessee",
    TX: "texas",
    UT: "utah",
    VT: "vermont",
    VA: "virginia",
    WA: "washington",
    WV: "westVirginia",
    WI: "wisconsin",
    WY: "wyoming"
  }
} as const