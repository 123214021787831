import React from "react"
import { Provider } from "react-redux"
import { Router, Route } from "react-router-dom"
import "@/fonts/style.css"
import "react-perfect-scrollbar/dist/css/styles.css"
import { store } from "@/redux"
import "@/ui/theme"
import "@/styles/index.css"
import "@/localStorage"
import { createRoot } from "react-dom/client"

import { Routes } from "./routes/components/Routes"
import { BROWSER_HISTORY } from "./routes"
import { onAppStart } from "./functions"
import { ConsoleHistoryHandler } from "./classes/ConsoleHistoryHandler"
// tslint:disable-next-line:no-unused-expression
new ConsoleHistoryHandler();
(window as any).az = ConsoleHistoryHandler.getConsoleHistoryList
export class App extends React.Component {
  constructor(props: Record<string, never>) {
    super(props)
    onAppStart()
  }

  public render(): JSX.Element {
    return (
      <>
        <Provider store={store}>
          <Router history={BROWSER_HISTORY}>
            <Route path="/" component={Routes} />
          </Router>
        </Provider>
      </>
    )
  }
}
createRoot(document.getElementById("root")!).render(<App />)
