import { useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { PageStyled } from "@/styles/PageStyled"


import en from "./en"
import fr from "./fr"

const languageMap = {
  en,
  fr
}

export function Privacy(): JSX.Element {
  const { selectedLanguage } = useReduxState((state: ReduxState) => ({
    selectedLanguage: state.languages.selectedLanguage
  }))
  return (
    <PageStyled>
      <div>{languageMap[selectedLanguage] || en}</div>
    </PageStyled>
  )
}
