import { SocketIo } from "@/api/classes"
import { createOneSession } from "@/api/requests"
import { IS_CORDOVA, OS } from "@/constants"
import { detectViewportSizeChange, handleNotchPositionChange } from "@/ui/functions"

import { isIOSNotch } from "."

export function onAppStart(): void {
  SocketIo.launch().catch(() => null)
  window.addEventListener("dragover", (evt: DragEvent) => evt.preventDefault())
  window.addEventListener("drop", (evt: DragEvent) => evt.preventDefault());
  (window.visualViewport || window).addEventListener("resize", detectViewportSizeChange)
  window.addEventListener("orientationchange", detectViewportSizeChange)

  document.addEventListener("deviceready", () => {
    if (!IS_CORDOVA) {
      return
    }
    document.addEventListener("resume", () => {
      createOneSession({})
    })

    if (OS === "android" || (OS === "ios" && isIOSNotch())) {
      handleNotchPositionChange()
    }
  })
}