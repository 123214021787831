declare global {
  interface ObjectConstructor {
    // @ts-ignore
    tsEntries<U, T>(o: { [key in T]: U }): [T, U][]
    tsKeys<T>(o: T): (keyof T)[]
  }
  interface StringConstructor {
    tsIncludes<T, U extends T>(str: T, ...args: U[]): boolean
  }
}

Object.tsKeys = <Obj>(obj: Obj): (keyof Obj)[] => Object.keys(obj!) as (keyof Obj)[]
// @ts-ignore
Object.tsEntries = <U, T>(obj: { [key in T]: U }): [T, U][] => Object.entries(obj!) as unknown as [T, U][]
String.tsIncludes = <T>(...args: T[]): boolean => args.slice(1).includes(args[0]!)

export * from "./constants"
export * from "./functions"
export * from "./languages"
export * from "./requests"
export * from "./ui"

export type OptionalToUndefined<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : (T[P] | undefined);
}
export type UndefinedToOptional<T> = Partial<T> & Pick<T, {
  [P in keyof T]: T[P] extends Exclude<T[P], undefined> ? P : never
}[keyof T]>
export type AllStringFormats = "percent"
  | "positivePercent"
  | "positiveDecimalPercent"
  | "decimalPercent"
  | "positiveInteger"
  | "integer"
  | "positiveDecimal"
  | "decimal"
  | "trim"
  | "flat"
  | "price"
  | "url"
  | "positivePrice"
  | "phone"
  | "nas"
  | "dashedDate"

export type KeysOfUnion<T> = T extends T ? keyof T : never
export type ValuesOfOfUnion<T> = T extends T ? T[keyof T] : never
export type OptionalId<T> = Omit<T, "_id"> & { _id?: string }
export type Optional<T, K extends keyof T> = Remove<T, K> & { [key in K]?: T[key] }
export type SomeRequired<T, K extends keyof T> = Partial<Remove<T, K>> & { [key in K]: T[key] }
export type Args<T> = T extends (...args: infer U) => any ? U : never
export type Remove<T, K extends keyof T> = Omit<T, K>
export type RequiredKeys<T> = { [K in keyof T]-?:
  ({} extends { [P in K]: T[K] } ? never : K)
}[keyof T]

export type UnionToIntersection<U> =
  (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never

export type HtmlString = { html: string, plainText: string }
export type AllNotificationTemplates =
  | "updateCook"
  | "updateVehicle"
  | "createMessage"
  | "createPurchase"
  | "updatePurchase"
