import { useRef } from "react"

import { Class } from "../Class"

import { AdjustTableWidth } from "./AdjustTableWidth"
import { TableRow } from "./TableRow"
import { TableProps } from "./model"

export function Table<ColId extends string | number>({
  headList,
  bodyList,
  rowIdList,
  colIdList,
  filter,
  errorMap,
  onDoubleClick,
  top = 0
}: TableProps<ColId>): JSX.Element {
  const headRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  const adjustTableWidthRef = useRef(new AdjustTableWidth())
  const trueIndexList: number[] = []
  const finalHeadList = (filter ? headList.filter((_, index) => {
    const colId = colIdList[index]!
    const isTrue = filter(colId, index)
    if (isTrue) {
      trueIndexList.push(index)
    }
    return isTrue
  }) : headList)
  return (
    <Class
      didMount={() => {
        window.setTimeout(() => adjustTableWidthRef.current.setAll(headRef.current, bodyRef.current))
      }}
      didUpdate={() => {
        window.setTimeout(() => adjustTableWidthRef.current.setAll(headRef.current, bodyRef.current))
      }}
    >
      <div className="col">
        <div className="sticky z-10 row shrink-0" style={{ top }} ref={headRef}>
          {finalHeadList.map((item, index) => (
            <div key={colIdList[index]!}
              className="h-12 px-2 font-light text-center border-b border-r border-solid text-grey-500 w-max center bg-grey-850 border-grey-800"
            >
              {item}
            </div>
          ))}
        </div>
        <div className="contents" ref={bodyRef}>
          {bodyList.map((colMap, rowIndex) => (
            <TableRow
              errorMap={(colId, colIndex, children) => errorMap ? errorMap({
                colId,
                rowId: rowIdList[rowIndex]!,
                colIndex, rowIndex,
                children
              }) : false}
              key={rowIdList[rowIndex]}
              colList={colIdList.map(key => colMap[key])}
              onDoubleClick={colId => onDoubleClick && onDoubleClick(colId, rowIdList[rowIndex]!)}
              trueIndexList={trueIndexList}
              colIdList={colIdList}
              adjustTableWidthRef={adjustTableWidthRef}
            />
          ))}
        </div>
      </div>
    </Class>
  )
}