import { getStorageItem, setStorageItem } from "@/localStorage"
import { ReduxState } from "@/redux/model"

import { UsersState } from "../model"

import { filterUsers } from "./filterUsers"
import { getUsersInitFilters } from "./getUsersInitFilters"

export function getTableUserIdList(currentState: UsersState, data: Partial<UsersState>, globalState: ReduxState): string[] {
  const needTableChangeKeyMap: { [key in keyof UsersState]?: true } = {
    userList: true,
    searchPrecision: true,
    shownColumnList: true,
    searchField: true,
  }
  const dataList = Object.keys(data) as (keyof UsersState)[]
  const filterKeyMap = Object.keys(getUsersInitFilters()).reduce((map, key) => ({ ...map, [key]: true }), {})
  dataList.forEach(key => {
    const typedKey = key as keyof typeof filterKeyMap
    if (filterKeyMap[typedKey]) {
      setStorageItem("users", { ...getStorageItem("users"), [typedKey]: data[typedKey] } as any)
    }
  })
  if (dataList.find(key => needTableChangeKeyMap[key])) {
    return filterUsers({ ...currentState, ...data }, globalState).map(user => user._id)
  } else {
    return currentState.tableUserIdList
  }
}