import { breakpoints } from "@/ui/mixins"
import styled, { css } from "styled-components"

import { QuoteStyledProps } from "./model"

export const QuoteStyled = styled.div<QuoteStyledProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 140%;
  margin: 20px 0;
  .fa-icon {
    height: 20px;
    margin-right: 15px;
  }
  ${(props: QuoteStyledProps) => {
    if (props.severity === "success") {
      return css`
        background: ${() => window.theme.success50};
        border-color: ${() => window.theme.success300};
        &, .fa-icon {
          color: ${() => window.theme.success700};
        }
      `
    } else if (props.severity === "warning") {
      return css`
        background: ${() => window.theme.warning50};
        border-color: ${() => window.theme.warning300};
        &, .fa-icon {
          color: ${() => window.theme.warning800};
        }
      `
    } else if (props.severity === "error") {
      return css`
        background: ${() => window.theme.error50};
        border-color: ${() => window.theme.error300};
        &, .fa-icon {
          color: ${() => window.theme.error700};
        }
      `
    } else {
      return css`
        background: ${() => window.theme.info50};
        border-color: ${() => window.theme.info300};
        &, .fa-icon {
          color: ${() => window.theme.info700};
        }
      `
    }
  }}
  @media screen and (min-width: ${breakpoints.sm}){
    border-radius: 15px;
  }
`