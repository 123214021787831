import { handleSocketRequest } from "@/api/functions"
import { setReduxState } from "@/redux"
export function getManyReviews(): void {
  handleSocketRequest(
    "reviews",
    "getMany",
    {
    }, reviewList => {
      setReduxState("home", { reviewList })
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    }
  )
}