import { isInvalidFields } from "@/functions"
import { getReduxState } from "@/redux"

export function validateVehicle(customViewIndex?: number): boolean {
  const reduxState = getReduxState()
  const {
    createVehicle: {
      brand,
      otherBrand,
      model,
      year,
      bodyStyle,
      exteriorColor,
      interiorColor,
      doorCountField,
      seatingCountField,
      vinIdField,
      distanceField,
      condition,
      cylinderCountField,
      driveTrain,
      fuelType,

      imageList,

      priceField,

      email,
      firstName,
      lastName,
      phone,
      address,
      viewIndex
    },
    routes: {
      firstPath,
    }

  } = reduxState
  if (customViewIndex === undefined) {
    customViewIndex = viewIndex
  }
  if (firstPath === "sell-vehicle") {
    customViewIndex -= 1
  }
  const priceNum = Number(priceField)
  if (customViewIndex === 0) {
    return isInvalidFields({
      brand: !brand,
      otherBrand: brand === "other" && !otherBrand,
      model: !model,
      year: !year
        || year < 1900,
      bodyStyle: !bodyStyle,
      exteriorColor: !exteriorColor.trim(),
      interiorColor: !interiorColor.trim(),
      doors: !!(firstPath !== "sell-vehicle" && doorCountField && Number(doorCountField) < 1),
      seats: !!(firstPath !== "sell-vehicle" && seatingCountField && Number(seatingCountField) < 1),
      vin17: vinIdField.length !== 17,
    })
  }

  else if (customViewIndex === 1) {
    return isInvalidFields({
      condition: !condition,
      distance: !distanceField,
      cylinderCount: (!cylinderCountField && fuelType !== "electric" && firstPath !== "sell-vehicle"),
      driveTrain: !driveTrain
    })
  }
  else if (customViewIndex === 2) {
    return isInvalidFields({
      price: !priceField || isNaN(priceNum)
        || priceNum < 0,
      firstName: (firstPath === "sell-vehicle" && !firstName.trim()),
      lastName: (firstPath === "sell-vehicle" && !lastName.trim()),
      email: (firstPath === "sell-vehicle" && !email.trim()),
      phone: (firstPath === "sell-vehicle" && !phone.trim()),
      address: (firstPath === "sell-vehicle" && (
        !address
        || !address.streetNumber
        || !address.streetName
        || !address.city
        || !address.stateOrRegion
      )),
    })
  }
  if (customViewIndex === 3) {
    return isInvalidFields({
      images: !imageList.length,
    })
  }
  return false
}