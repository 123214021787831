import React, { SVGAttributes } from "react"
function Icon(props: SVGAttributes<{}>): JSX.Element {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        	<path d="M496 448h-16v-40c0-13.255-10.745-24-24-24h-40V208h-32v176h-64V208h-32v176h-64V208h-32v176h-64V208H96v176H56c-13.255 0-24 10.745-24 24v40H16c-8.837 0-16 7.163-16 16v8a8 8 0 0 0 8 8h496a8 8 0 0 0 8-8v-8c0-8.837-7.163-16-16-16zM64 416h384v32H64v-32zm440.267-280L271.179 34.463a48.004 48.004 0 0 0-30.358 0L7.733 136A11.999 11.999 0 0 0 0 147.216V156c0 6.627 5.373 12 12 12h20v12c0 6.627 5.373 12 12 12h424c6.627 0 12-5.373 12-12v-12h20c6.627 0 12-5.373 12-12v-8.784c0-4.982-3.077-9.445-7.733-11.216zM448 160H64v-13.606l187.943-81.871a16.004 16.004 0 0 1 8.114 0L448 146.394V160z"/>
        </svg>
    )
}

export default Icon
