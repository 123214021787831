import { handleSocketRequest } from "@/api/functions"
import { setReduxState } from "@/redux"
import { Vehicle } from "@/shared"



export function getOneVehicle(
  _id: string,
  callback: (vehicle: Vehicle | undefined) => void
): void {
  handleSocketRequest(
    "vehicles",
    "getOne",
    {
      _id,
    },
    vehicle => {
      callback(vehicle)
    }, err => {
      setReduxState("ui", {
        snackbar: {
          message: err,
          severity: "error"
        }
      })
    }
  )
}