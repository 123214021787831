import { BottomRightButton } from "./BottomRightButton"
import { BrokeragesTable } from "./BrokerageTable"
import { BrokeragesFilters } from "./BrokeragesFilters"


export function Brokerages(): JSX.Element {
  return (
    <div className="row">
      <BrokeragesFilters />
      <BrokeragesTable />
      <BottomRightButton />
    </div>
  )

}