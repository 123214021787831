import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import { IS_ANY_IOS, IS_SAFARI } from "@/constants"

import { Icon } from ".."

import { CircularProgressBarProps } from "./model"
import { ProgressCount } from "./ProgressCount"


const ProgressLeftStyled = styled.div`
  animation: left 3s linear both;
  @keyframes left{
  100%{
    transform: rotate(180deg);
  }
}`

const ProgressRightStyled = styled.div`
  animation: right 3s linear both;
  animation-delay: 3s;
  @keyframes right{
    100% {
    transform: rotate(180deg);
  }
}
`
export function CircularProgressBar({ count, iconName, sufix }: CircularProgressBarProps): JSX.Element | null {
  if (IS_SAFARI || IS_ANY_IOS) {
    return (
      <div className="w-24 h-24 border-2 border-solid rounded-full border-secondary-900 center">
        <div className="z-10 flex-col text-base font-medium rounded-full center whitespace-nowrap text-grey-300">
          <span>
            {count}
            {sufix || ""}
          </span>
          <Icon name={iconName} className="h-6 mt-1" />
        </div>
      </div>
    )
  }
  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        isVisible ? (
          <div className="circular-progress">
            <div className="overflow-hidden col h-[100px] w-[100px] relative rounded-full">
              <div className="absolute z-[6] top-[2.5px] left-[2.5px] h-[95px] w-[95px]  bg-grey-900 rounded-full" />
              <div className="center text-base whitespace-nowrap font-medium text-grey-300 flex-col absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-10">
                <span>
                  <ProgressCount count={count} />
                  {sufix || ""}
                </span>
                <Icon name={iconName} className="h-6 mt-1" />
              </div>
              <div className="circle">
                <div className="absolute w-full h-full bg-grey-300" style={{ clip: "rect(0px, 100px, 100px, 50px)" }}>
                  <ProgressLeftStyled
                    className="absolute z-[1] w-full h-full bg-secondary-900"
                    style={{ clip: "rect(0px, 50px, 100px, 0px)" }}
                  />
                </div>
                <div className="absolute z-[3] w-full h-full rotate-180 bg-grey-300" style={{ clip: "rect(0px, 100px, 100px, 50px)" }}>
                  <ProgressRightStyled
                    className="absolute w-full h-full bg-secondary-900"
                    style={{ clip: "rect(0px, 50px, 100px, 0px)" }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : <div className="w-1 h-1" />
      )}
    </VisibilitySensor>
  )
}
