import { listToMap } from "@/shared"
import { ReduxState, SetAction } from "@/redux/model"

import { getOutputMapByBrokerageId } from "./functions/getOutputMapByBrokerageId"
import { getTableBrokerageIdList } from "./functions/getTableBrokerageIdList"
import { BrokeragesState } from "./model"


export const brokerages = (currentState: BrokeragesState, action: SetAction<"brokerages">, globalState?: ReduxState) => {
  const data = { ...action.data }
  if (data.brokerageList && globalState) {
    if (!data.brokerageList) {
      data.brokerageList = currentState.brokerageList
    }
    data.brokerageMap = listToMap(data.brokerageList)
    data.brokerageIdList = data.brokerageList.map(item => item._id)
    data.outputMapByBrokerageId = getOutputMapByBrokerageId(
      data.brokerageList,
      globalState.languages.say,
    )
  }
  if (globalState) {
    data.tableBrokerageIdList = getTableBrokerageIdList(currentState, data, globalState)
  }
  return {
    ...currentState,
    ...data
  }
}