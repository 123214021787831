import { getOneFile } from "@/api/requests"
import { Button, Icon } from "@/components"
import { getReadableNumber } from "@/functions"
import { VehicleSpec } from "@/pages/Vehicle/components/VehicleSpec"
import { useReduxState, useWillMount } from "@/redux"
import { goToExactPath } from "@/routes"
import { PublicFile } from "@/shared"
import { useState } from "react"

import { VehicleItemProps } from "../model"

import { SpecStyledOverride } from "./SpecStyledOverride"
import { VehicleItemStyled } from "./VehicleItemStyled"

export function VehicleItem({ vehicle }: VehicleItemProps): JSX.Element {
  const [image, setImage] = useState<PublicFile | undefined>(undefined)
  useWillMount(() => {
    getOneFile({ _id: vehicle.imageIdList[0]!, isThumbnail: true }, file => setImage(file))
  })
  const { say } = useReduxState(state => ({
    say: state.languages.say
  }))
  const title = `${vehicle.year} ${say[vehicle.brand]} ${vehicle.model} ${say[vehicle.bodyStyle]}`
  const kmDistance = vehicle.isKmDistanceUnit ? vehicle.distance : vehicle.distance * 1.609344
  return (
    <VehicleItemStyled>
      {image && (
        <div className="img" style={{ backgroundImage: `url("${image.data}")` }} />
      )}
      {!image && <Icon name="image" />}
      <div className="content-box">
        <h3>{title}</h3>
        <h4>{getReadableNumber(vehicle.price)}$</h4>
        <div className="specs-box">
          <VehicleSpec
            styleOverride={SpecStyledOverride()}
            label={say.distance}
            value={`${getReadableNumber(kmDistance)} km`}
            iconName="tachometer-alt"
          />
          <VehicleSpec
            styleOverride={SpecStyledOverride()}
            label={say.condition}
            value={say[vehicle.condition]}
            iconName="clipboard-list-check"
          />
          {vehicle.fuelType !== "electric" && (
            <>
              <VehicleSpec
                styleOverride={SpecStyledOverride()}
                label={say.transmission}
                value={say[vehicle.isManualTransmission ? "manual" : "automatic"]}
                iconName="steering-wheel"
              />
              <VehicleSpec
                styleOverride={SpecStyledOverride()}
                label={say.driveTrain}
                value={say[vehicle.driveTrain]}
                iconName="tire"
              />
            </>
          )}
          {vehicle.fuelType !== "gaz" && (
            <VehicleSpec
              styleOverride={SpecStyledOverride()}
              label={say.fuelType}
              value={say[vehicle.fuelType]}
              iconName="gas-pump"
            />
          )}
        </div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => goToExactPath("/vehicle", vehicle._id)}
        >{say.viewMore}</Button>
      </div>
    </VehicleItemStyled>
  )
}