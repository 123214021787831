import { LanguagesState } from "@/languages/model"
import { setStorageItem } from "@/localStorage"
import { allLanguages } from "@/shared"
import moment from "moment"

import { SetAction } from "../redux/model"


export const languages = (currentState: LanguagesState, action: SetAction<"languages">) => {
  const data = { ...action.data }
  if (data.selectedLanguage) {
    moment.locale(data.selectedLanguage)

    data.say = allLanguages[data.selectedLanguage]
    setStorageItem("selectedLanguage", data.selectedLanguage)
  }
  return {
    ...currentState,
    ...data,
  }
}