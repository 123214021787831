import { getStorageItem, setStorageItem } from "@/localStorage"
import { ReduxState } from "@/redux/model"

import { VehiclesState } from "../model"

import { filterVehicles } from "./filterVehicles"
import { getVehiclesInitFilters } from "./getVehiclesInitFilters"

export function getTableVehicleIdList(currentState: VehiclesState, data: Partial<VehiclesState>, globalState: ReduxState): string[] {
  const needTableChangeKeyMap: { [key in keyof VehiclesState]?: true } = {
    vehicleList: true,
    searchPrecision: true,
    shownColumnList: true,
    searchField: true,
    status: true,
    isPublic: true,
    isInitView: true,
    ownership: true
  }
  const dataList = Object.keys(data) as (keyof VehiclesState)[]
  const filterKeyMap = Object.keys(getVehiclesInitFilters()).reduce((map, key) => ({ ...map, [key]: true }), {})
  dataList.forEach(key => {
    const typedKey = key as keyof typeof filterKeyMap
    if (filterKeyMap[typedKey]) {
      setStorageItem("vehicles", { ...getStorageItem("vehicles"), [typedKey]: data[typedKey] } as any)
    }
  })
  if (dataList.find(key => needTableChangeKeyMap[key])) {
    return filterVehicles({ ...currentState, ...data }, globalState).map(vehicle => vehicle._id)
  } else {
    return currentState.tableVehicleIdList
  }
}