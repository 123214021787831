import { useReduxState } from "@/redux"
import { Review } from "@/shared"

import { QuotesStyled } from "./QuotesStyled"

// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
// tslint:disable-next-line:no-var-requires
const Zoom = require("react-reveal/Zoom")

const QuoteItem = ({ name, comment, link, date }: Review) => {
  const say = useReduxState(state => state.languages.say)
  return (
    <Zoom>
      <div
        className="quote-item"
      >
        <h4>{name}</h4>
        <div className="text-box">
          <span className="quote-start">❝</span>
          {comment}
          <span className="quote-end">❞</span>
        </div>
        <h5>{date}</h5>
        <a href={link} className="text-[#00000050]">{say.seeInGoogleMap}</a>
      </div>
    </Zoom>
  )
}

export function Quotes(): JSX.Element {
  const { say, reviewList } = useReduxState(state => ({
    say: state.languages.say,
    reviewList: state.home.reviewList,
  }))

  return (
    <QuotesStyled>
      <Fade top={true}>
        <h1>{say.reviews}</h1>
      </Fade>
      <div className="list-box">
        {reviewList.slice(0, 3).map(item => (
          <QuoteItem
            {...item}
            key={item._id}
          />
        ))}
      </div>
    </QuotesStyled>
  )
}