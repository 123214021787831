import { setReduxState } from "@/redux"

import { handleSocketRequest } from "../functions"



export function getManyBrokerages(
): void {
  handleSocketRequest(
    "brokerages",
    "getMany",
    {
      isLoadingHidden: true
    },
    brokerageList => {
      setReduxState("brokerages", { brokerageList })
    }, err => {
      setReduxState("ui", {
        snackbar: {
          message: err,
          severity: "error"
        }
      })
    }
  )
}