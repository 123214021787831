import { ReduxState } from "@/redux/model"
import React from "react"
import { connect } from "react-redux"

export class ScrollToTopNotConnected extends React.Component<{ pathName: string }> {
  componentDidUpdate(prevProps: ScrollToTopNotConnected["props"]): void {
    if (this.props.pathName !== prevProps.pathName) {
      window.scrollTo(0, 0)
    }
  }

  render(): null {
    return null
  }
}

export const ScrollToTop = connect((state: ReduxState) => ({
  pathName: state.routes.pathName
}))(ScrollToTopNotConnected)