import { getStorageItem, setStorageItem } from "@/localStorage"
import { ReduxState } from "@/redux/model"

import { BrokeragesState } from "../model"

import { filterBrokerages } from "./filterBrokerages"
import { getBrokeragesInitFilters } from "./getBrokerageInitFilters"

export function getTableBrokerageIdList(currentState: BrokeragesState, data: Partial<BrokeragesState>, globalState: ReduxState): string[] {
  const needTableChangeKeyMap: { [key in keyof BrokeragesState]?: true } = {
    brokerageList: true,
    searchPrecision: true,
    shownColumnList: true,
    searchField: true,
  }
  const dataList = Object.keys(data) as (keyof BrokeragesState)[]
  const filterKeyMap = Object.keys(getBrokeragesInitFilters()).reduce((map, key) => ({ ...map, [key]: true }), {})
  dataList.forEach(key => {
    const typedKey = key as keyof typeof filterKeyMap
    if (filterKeyMap[typedKey]) {
      setStorageItem("brokerages", { ...getStorageItem("brokerages"), [typedKey]: data[typedKey] } as any)
    }
  })
  if (dataList.find(key => needTableChangeKeyMap[key])) {
    return filterBrokerages({ ...currentState, ...data }, globalState).map(brokerage => brokerage._id)
  } else {
    return currentState.tableBrokerageIdList
  }
}