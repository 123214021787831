
import { getStorageItem } from "@/localStorage"
import { AllLanguages, allLanguages } from "@/shared"
import moment from "moment"

import { LanguagesState } from "./model"

export function getLanguagesInitState(): LanguagesState {
  let selectedLanguage = (getStorageItem("selectedLanguage") || navigator.language.split("-")[0]!) as AllLanguages
  if (!allLanguages[selectedLanguage as AllLanguages]) {
    selectedLanguage = "en"
  }
  moment.locale(selectedLanguage)
  return {
    selectedLanguage,
    say: allLanguages[selectedLanguage],
  }

}
