import { setReduxState } from "@/redux"
import { Bug } from "@/shared"

import { handleSocketRequest } from "../functions"

export function getManyBugs(callback: (bugList: Bug[]) => void): void {
  handleSocketRequest("bugs", "getMany", { isLoadingHidden: true }, bugList => {
    callback(bugList)
  }, err => {
    setReduxState("ui", { snackbar: { message: err, severity: "error" } })
  })

}