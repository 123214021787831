import React from "react"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Icon } from "@/components"
import { createOneSession } from "@/api/requests"
import { goToExactPath } from "@/routes"
import { getReduxState, setReduxState, useReduxState } from "@/redux"
import { AllRoutes } from "@/routes/model"

import { LoginStyled } from "./LoginStyled"



export function Login(): JSX.Element {
  const { say, errorMessage, emailField, passwordField, isSownPassword } = useReduxState(state => ({
    say: state.languages.say,
    errorMessage: state.ui.snackbar?.message,
    emailField: state.login.emailField,
    passwordField: state.login.passwordField,
    isSownPassword: state.login.isSownPassword
  }))


  const login = () => {
    const { loginRedirect } = getReduxState().ui
    if (loginRedirect) {
      createOneSession({ email: emailField, password: passwordField }, () => {
        window.setTimeout(() => {
          if (loginRedirect.pathName) {
            const pathList = loginRedirect.pathName.split("/")
            const lastPath = pathList[pathList.length - 1] as string
            const firstPath = pathList[1] as AllRoutes || ""
            goToExactPath(`/${firstPath}` as `/${AllRoutes}`, lastPath)
          }
          if (loginRedirect.popup) {
            setReduxState("popups", loginRedirect.popup)
          }
          setReduxState("ui", { loginRedirect: undefined })
        }, 500)
      })
    } else {
      createOneSession({ email: emailField, password: passwordField })
    }
  }
  return (
    <LoginStyled>
      <div className="background" />
      <section>
        <h3>{say.login}</h3>
        <p>{say.loginHeader}</p>
        <TextField
          error={errorMessage === "userDoesntExists"}
          autoFocus={true}
          type="email"
          label={say.email}
          placeholder={say.emailPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="envelope" />
              </InputAdornment>
            )
          }}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setReduxState("login", { emailField: evt.target.value })}
          value={emailField}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth={true}
          onKeyUp={evt => evt.key === "Enter" && login()}
        />
        <TextField
          error={errorMessage === "wrongPassword"}
          label={say.password}
          placeholder={say.password}
          InputLabelProps={{ shrink: true }}
          value={passwordField}
          type={isSownPassword ? "text" : "password"}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setReduxState("login", { passwordField: evt.target.value })}
          onKeyUp={evt => evt.key === "Enter" && login()}

          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="lock-alt" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  name={isSownPassword ? "eye" : "eye-slash"}
                  onClick={() => setReduxState("login", { isSownPassword: !isSownPassword })}
                />
              </InputAdornment>
            )

          }}
          variant="outlined"
          fullWidth={true}
        />
        <footer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => login()}
          >{say.login}</Button>

        </footer>
        <Button color="secondary" onClick={() => goToExactPath("/forgot-password")}>{say.forgotPassword}</Button>

      </section>
    </LoginStyled>
  )
}