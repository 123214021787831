
import { Button, CheckboxList, DelayedSearchInput, Fieldset, Icon } from "@/components"
import { SEARCH_PRECISION_LIST } from "@/constants"
import { setReduxState, useReduxState } from "@/redux"
import { VEHICLE_STATUS_LIST } from "@/shared"

import { VEHICLE_TABLE_HEAD_LIST } from "../constants/VEHICULE_TABLE_HEAD_LIST"
import { getVehiclesInitFilters } from "../functions/getVehiclesInitFilters"

export function VehiclesFilters(): JSX.Element {
  const {
    say,
    shownColumnList,
    searchPrecision,
    isPublic,
    ownership,
    status,
    isInitView
  } = useReduxState(state => ({
    say: state.languages.say,
    shownColumnList: state.vehicles.shownColumnList,
    isPublic: state.vehicles.isPublic,
    isInitView: state.vehicles.isInitView,
    ownership: state.vehicles.ownership,
    status: state.vehicles.status,
    searchPrecision: state.vehicles.searchPrecision,
  }))
  return (
    <div className="col w-[400px] max-w-[calc(100vw-40px)] top-[100px] h-full fixed backdrop-blur-sm border-r-2 bg-grey-900/90 border-solid border-primary-300 z-20">
      <div className="flex items-center justify-between shrink-0 px-5 h-[57px] w-full border-b border-solid border-primary-300 text-primary-300">
        <div className="flex items-center">
          <Icon name="filter" className="h-4 mr-2 text-primary-300" />
          {say.filters}
        </div>
        <Button
          iconName="trash-alt"
          isCircle={true}
          size="sm"
          variant="outlined"
          shade={300}
          className="!bg-grey-850"
          isTransparent={true}
          color="error"
          onClick={() => setReduxState("vehicles", { ...getVehiclesInitFilters(), shownColumnList })}
        />
      </div>
      <div className="p-5 overflow-auto h-[calc(100%-157px)] in-h3:text-lg in-.fieldset:mb-4 in-.input:h-10 in-h3:mb-2">
        <Fieldset label={say.searchPrecision}>
          <CheckboxList
            optionList={[...SEARCH_PRECISION_LIST]}
            valueList={[searchPrecision]}
            outputMap={value => say[value]}
            onChange={newList => setReduxState("vehicles", { searchPrecision: newList[0] || "high" })}
          />
        </Fieldset>
        <DelayedSearchInput
          onChange={searchField => setReduxState("vehicles", { searchField })}
          getValue={reduxState => reduxState.vehicles.searchField}
        />
        <Fieldset label={say.seeInitDetails}>
          <CheckboxList
            optionList={["seeInitDetails", "hideInitDetails"] as const}
            valueList={[isInitView ? "seeInitDetails" : "hideInitDetails"] as const}
            outputMap={value => say[value]}
            onChange={newList => newList[0] && setReduxState("vehicles", {
              isInitView: newList[0] === "hideInitDetails" ? false : true
            })}
          />
        </Fieldset>
        <Fieldset label={say.audience}>
          <CheckboxList
            optionList={["all", "public", "private"] as const}
            valueList={[isPublic === undefined ? "all" : isPublic ? "public" : "private"] as const}
            outputMap={value => say[value]}
            onChange={newList => newList[0] && setReduxState("vehicles", {
              isPublic: newList[0] === "all" ? undefined : newList[0] === "private" ? false : true
            })}
          />
        </Fieldset>
        <Fieldset label={say.ownership}>
          <CheckboxList
            optionList={["all", "ownedVehicle", "leasedVehicle", "avarta"] as const}
            valueList={[ownership] as const}
            outputMap={value => say[value]}
            onChange={newList => setReduxState("vehicles", { ownership: newList[0] })}
          />
        </Fieldset>
        <Fieldset label={say.status}>
          <CheckboxList
            optionList={["all", ...VEHICLE_STATUS_LIST] as const}
            valueList={[status || "all"] as const}
            outputMap={value => say[value]}
            onChange={newList => newList[0] && setReduxState("vehicles", { status: newList[0] })}
          />
        </Fieldset>
        <Fieldset label={say.shownColumns}>
          <CheckboxList
            optionList={[...VEHICLE_TABLE_HEAD_LIST]}
            valueList={shownColumnList}
            outputMap={value => say[value]}
            isMultiple={true}
            onChange={newList => setReduxState("vehicles", { shownColumnList: newList })}
          />
        </Fieldset>
      </div>
    </div>
  )
}