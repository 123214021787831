import { createOneBrokerage } from "@/api/requests"
import { AddressField, Button, Fieldset, Input } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { formatPhone } from "@/shared"

import { incrementCreateBrokerageViewIndex } from "../functions/incrementCreateBrokerageViewIndex"

import { FormFooter } from "./FormFooter"

export function FinalForm(): JSX.Element {
  const {
    say,
    firstName,
    lastName,
    phone,
    email,
    address,
    isInvalidField,
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    firstName: state.createBrokerage.firstName,
    lastName: state.createBrokerage.lastName,
    phone: state.createBrokerage.phone,
    email: state.createBrokerage.email,
    address: state.createBrokerage.address,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))

  return (
    <div>

      <div className="horizontal-box">
        <Fieldset label={say.firstName} isRequired={true}>
          <Input
            mobileFocusLabel={say.firstName}
            iconName="user"
            isError={!firstName && isInvalidField}
            value={firstName}
            placeholder="John"
            onChange={evt => setReduxState("createBrokerage", { firstName: evt.target.value.trim() })}
          />
        </Fieldset>
        <Fieldset label={say.lastName} isRequired={true}>
          <Input
            mobileFocusLabel={say.lastName}
            iconName="user"
            isError={!lastName && isInvalidField}
            value={lastName}
            placeholder="Doe"
            onChange={evt => setReduxState("createBrokerage", { lastName: evt.target.value.trim() })}
          />
        </Fieldset>
      </div>
      <div className="horizontal-box">
        <Fieldset label={say.email} isRequired={true}>
          <Input
            mobileFocusLabel={say.email}
            iconName="envelope"
            isError={!email && isInvalidField}
            value={email}
            placeholder="john@doe.com"
            onChange={evt => setReduxState("createBrokerage", { email: evt.target.value.trim() })} />
        </Fieldset>
        <Fieldset label={say.phone} isRequired={true}>
          <Input
            mobileFocusLabel={say.phone}
            iconName="phone"
            isError={!phone && isInvalidField}
            value={phone}
            onBlur={() => setReduxState("createBrokerage", { phone: formatPhone(phone) || "" })}
            inputMode="numeric"
            placeholder="514-123-4567"
            onChange={evt => setReduxState("createBrokerage", { phone: evt.target.value.split(/[^0-9-]/).join("") })} />
        </Fieldset>
      </div>
      <AddressField
        isError={
          isInvalidField && (
            !address
            || !address.streetNumber
            || !address.streetName
            || !address.city
            || !address.stateOrRegion
          )}
        address={address}
        onAddressChange={newAddress => setReduxState("createBrokerage", { address: newAddress })}
      />

      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(false)}
        >
          {say.previous}
        </Button>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => createOneBrokerage()}
        >
          {say.send}
        </Button>
      </FormFooter>
    </div >
  )
}
