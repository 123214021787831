export const VEHICLE_BRAND_LIST = [
  "other",
  "acura",
  "alfa",
  // "amc",
  "aston",
  "audi",
  // "avanti",
  "bentl",
  "bmw",
  "buick",
  "cad",
  "chev",
  "chry",
  // "daew",
  // "daihat",
  // "datsun",
  // "delorean",
  "dodge",
  // "eagle",
  "fer",
  "fiat",
  // "fisk",
  "ford",
  // "freight",
  // "geoBrand",
  "gmc",
  "honda",
  "amgen",
  "hyund",
  "infin",
  // "isu",
  "jag",
  "jeep",
  "kia",
  "lam",
  // "lan",
  "rov",
  "lexus",
  "linc",
  "lotus",
  "mas",
  "maybach",
  "mazda",
  "mclaren",
  "mb",
  // "merc",
  // "merkur",
  "mini",
  "mit",
  "nissan",
  // "olds",
  // "peug",
  // "plym",
  "pont",
  "por",
  "ram",
  // "ren",
  "rr",
  // "saab",
  // "saturn",
  "scion",
  "smart",
  "srt",
  // "sterl",
  "sub",
  "suzuki",
  "tesla",
  "toyota",
  // "tri",
  "volks",
  "volvo",
  // "yugo"
] as
  const