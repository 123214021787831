import { Icon } from "@/components"

import { VehicleInfoItemProps } from "../model"

export function VehicleInfoItem({ title, text, iconName }: VehicleInfoItemProps): JSX.Element {
  return (
    <div className="items-center text-center col">
      <h3 className="text-2xl sm:text-3xl">{title}</h3>
      <Icon name={iconName} className="h-10 my-4 text-secondary-900" />
      <p className="max-w-[250px] text-white/70 font-medium text-base sm:text-lg">{text}</p>
    </div>
  )
}