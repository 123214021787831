import React, { SVGAttributes } from "react"
function Icon(props: SVGAttributes<{}>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 385 450"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M375.41 111.09L191.61 0 0 108.72v194.6l130.11-75.99 22.84-45.08-126.96 73.6.4-131.37 165.42-97.6 160.16 97.24 23.44-13.03zM9.44 338.06l183.8 111.09 191.61-108.73v-194.6l-137.52 76.82 11.95 25.04 99.59-56.33-.41 133.32-165.42 97.6-161.41-98-22.19 13.79z" />
        <path d="M52.92 323.85L187.71 43.64v362.01l-29.18-17.84 2.01-216.54-79.42 169.75-28.2-17.17zM331.56 326.35L195.57 43.64v362.01l30.04-18.37-2.88-216.01 80.34 171.7 28.49-16.62z" />
      </g>
    </svg>
  )
}

export default Icon