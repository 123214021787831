import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { ReduxState } from "@/redux/model"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Icon } from "@/components"
import { createOneUser } from "@/api/requests"
import { setReduxState, useReduxState } from "@/redux"

import { LoginStyled } from "../../Login/LoginStyled"


export function SignIn(): JSX.Element {
  const {
    say,
    errorMessage,
    email,
    password,
    confirmedPassword,
    isPasswordSown
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    selectedLanguage: state.languages.selectedLanguage,
    errorMessage: state.ui.snackbar?.message,
    email: state.signIn.email,
    password: state.signIn.password,
    isPasswordSown: state.signIn.isPasswordSown,
    confirmedPassword: state.signIn.confirmedPassword,
  }))

  const createUser = () => createOneUser({
    email,
    password,
    confirmedPassword,
  })
  return (
    <LoginStyled>
      <div className="background" />
      <section>
        <h3>{say.createUser}</h3>
        <TextField
          error={(errorMessage === "invalidField" && !email) || errorMessage === "userAlreadyExists"}
          autoFocus={true}
          type="email"
          label={say.email}
          placeholder={say.emailPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="envelope" />
              </InputAdornment>
            )
          }}
          onChange={evt => setReduxState("signIn", { email: evt.target.value })}
          value={email}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth={true}
          onKeyUp={evt => evt.key === "Enter" && createUser()}
        />
        <TextField
          error={errorMessage === "invalidField" && !password}
          type={isPasswordSown ? undefined : "password"}
          label={say.password}
          placeholder="••••••••"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="lock-alt" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  name={isPasswordSown ? "eye" : "eye-slash"}
                  onClick={() => setReduxState("signIn", { isPasswordSown: !isPasswordSown })}
                />
              </InputAdornment>
            )
          }}
          onChange={evt => setReduxState("signIn", { password: evt.target.value })}
          value={password}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth={true}
          onKeyUp={evt => evt.key === "Enter" && createUser()}
        />
        <TextField
          error={errorMessage === "invalidField" && !confirmedPassword}
          type={isPasswordSown ? undefined : "password"}
          label={say.confirmedNewPassword}
          placeholder="••••••••"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="lock-alt" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  name={isPasswordSown ? "eye" : "eye-slash"}
                  onClick={() => setReduxState("signIn", { isPasswordSown: !isPasswordSown })}
                />
              </InputAdornment>
            )
          }}
          onChange={evt => setReduxState("signIn", { confirmedPassword: evt.target.value })}
          value={confirmedPassword}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth={true}
          onKeyUp={evt => evt.key === "Enter" && createUser()}
        />
        <footer>
          <Button
            variant="contained"
            color="primary"
            onClick={createUser}
          >{say.create}</Button>
        </footer>
      </section>
    </LoginStyled>
  )
}

