import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { NavLink } from "react-router-dom"

import { Icon } from "../Icon"
import { DevKG } from "../DevKG"

import { FooterMap } from "./FooterMap"
import { FooterStyled } from "./FooterStyled"
// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
export function Footer(): JSX.Element | null {
  const { say, isPadding, isHidden } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    isHidden: state.routes.firstPath.startsWith("dashboard"),
    isPadding: (
      state.routes.firstPath !== "home"
      && state.routes.firstPath !== "create-vehicle"
      && state.routes.firstPath !== "sell-vehicle"
      && state.routes.firstPath !== "edit-vehicle"
      && state.routes.firstPath !== "brokerage"
    )
  }))
  if (isHidden) {
    return null
  }
  return (
    <FooterStyled>
      {isPadding && <div className="padding" />}
      <div className="top-box">
        <Fade left={true}>
          <div className="top-content">
            <DevKG isDark={true} />
            <NavLink to="terms">{say.termsAndConditions}</NavLink>
            <NavLink to="privacy">{say.privacyPolicy}</NavLink>
            <div
              className="popup-button"
              onClick={() => setReduxState("popups", { name: "BugReport" })}
            >{say.bugReport}</div>
            <a href="mailto:info@avartasolutions.com">info@avartasolutions.com</a>
            <a href="tel:5148080080">+1 (514) 808-0080</a>
          </div>
        </Fade>
        <FooterMap />
      </div>
      <div className="bottom-content">
        <Fade left={true}>
          <span>{`© 2022 Avarta Auto ${say.allRightsReserved}`} </span>
        </Fade>
        <Fade right={true}>
          <div className="icons">
            <Icon type="brands" name="facebook" onClick={() => window.open("https://www.facebook.com/AvartaSolutions", "_blank")} />
            {/* <Icon type="brands" name="twitter" /> */}
            <Icon type="brands" name="instagram" onClick={() => window.open("https://www.instagram.com/avartasolutions_/", "_blank")} />
          </div>
        </Fade>
      </div>
    </FooterStyled >
  )
}