import "moment/locale/fr"

import { html } from ".."

import { EnSay } from "."

export const en = {
  weSupportPalestine: html`We support <b>Palestine!</b>`,
  userAlreadyLogged: "User already logged",
  userDoesntExists: "User doesn't exists",
  wrongPassword: "Wrong password",
  userAlreadyExists: "User already exists",
  accessDenied: "Access denied",
  wrongFileSize: "Wrong file size",
  dataDoesntExists: "Data requested from server doesn't exists",
  wrongNewPassword: "The new password is supposed to be between 6 to 40 charaters",
  invalidValidator: "Requête invalide",
  invalidEmail: "Invalid email",
  corruptedData: "The data you requested are corrupted",
  locationNotSupported: "Your location is not supported",
  invalidLocation: "Your address is invalid",
  invalidPhone: "Invalid phone",
  invalidDate: "Invalid date",
  internalServerError: "Internal server error",
  verifCodeFirst: "First, Type-in verification code from your emails",
  wrongToken: "Wrong verification code",
  canada: "Canada",
  unitedStates: "United States",
  alberta: "Alberta",
  britishColumbia: "British Columbia",
  manitoba: "Manitoba",
  newBrunswick: "New Brunswick",
  newfoundlandAndLabrador: "Newfoundland and Labrador",
  northwestTerritories: "Northwest Territories",
  novaScotia: "Nova Scotia",
  nunavut: "Nunavut",
  ontario: "Ontario",
  princeEdwardIsland: "Prince Edward Island",
  quebec: "Quebec",
  saskatchewan: "Saskatchewan",
  yukonTerritory: "Yukon Territory",
  alabama: "Alabama",
  alaska: "Alaska",
  arizona: "Arizona",
  arkansas: "Arkansas",
  california: "California",
  colorado: "Colorado",
  connecticut: "Connecticut",
  delaware: "Delaware",
  districtOfColumbia: "District of Columbia",
  florida: "Florida",
  georgia: "Georgia",
  hawaii: "Hawaii",
  idaho: "Idaho",
  illinois: "Illinois",
  indiana: "Indiana",
  iowa: "Iowa",
  kansas: "Kansas",
  kentucky: "Kentucky",
  louisiana: "Louisiana",
  maine: "Maine",
  maryland: "Maryland",
  massachusetts: "Massachusetts",
  michigan: "Michigan",
  minnesota: "Minnesota",
  mississippi: "Mississippi",
  missouri: "Missouri",
  montana: "Montana",
  nebraska: "Nebraska",
  nevada: "Nevada",
  newHampshire: "New Hampshire",
  newJersey: "New Jersey",
  newMexico: "New Mexico",
  newYork: "New York",
  northCarolina: "North Carolina",
  northDakota: "North Dakota",
  ohio: "Ohio",
  oklahoma: "Oklahoma",
  oregon: "Oregon",
  pennsylvania: "Pennsylvania",
  rhodeIsland: "Rhode Island",
  southCarolina: "South Carolina",
  southDakota: "South Dakota",
  tennessee: "Tennessee",
  texas: "Texas",
  utah: "Utah",
  vermont: "Vermont",
  virginia: "Virginia",
  washington: "Washington",
  westVirginia: "West Virginia",
  wisconsin: "Wisconsin",
  wyoming: "Wyoming",
  vehicleCreateSuccess: "Vehicle created successfully",
  brokerageCreateSuccess: "Brokerage request created successfully",
  userCreateSuccess: "Account created successfully",
  address: "PostalAddress",
  addressPlaceholder: "City, PostalAddress or postal code",
  streetNumber: "Street number",
  streetName: "Street name",
  streetNamePlaceholder: "St-michel street",
  postalCode: "Postal code",
  postalCodePlaceholder: "H1H",
  city: "City",
  cityPlaceholder: "Montreal",
  state: "State/Province",
  statePlaceholder: "Quebec",
  country: "Country",
  countryPlaceholder: "Canada",
  quickLinks: "Quick links",
  termsAndConditions: "Terms & conditions",
  privacyPolicy: "Privacy policy",
  bugReport: "Bug report",
  allRightsReserved: "All rights reserved",
  uploadImages: "Upload images *",
  applyPolicies: "By navigating on avartasolutions.com, I accept the<a href = 'https://avartasolutions.com/ terms'>Terms and Conditions</a> and the <a href='https://avartasolutions.com/privacy'>Privacy Policies</a >.",
  ok: "Ok",
  today: "Today",
  sendBugReport: "Send an bug report",
  operatingSystem: "Operating system",
  date: "Date",
  yearDateShort: "MMMM D, YYYY",
  problemDescription: "Problem description",
  cancel: "Annuler",
  send: "Send",
  editPassword: "Edit password",
  passwordProperties: "6 to 48 characters without space",
  save: "Save",
  oldPassword: "Old password",
  newPassword: "New password",
  confirmedNewPassword: "Confirm password",
  askDeleteBug: "Do you really want to delete this bug report",
  editVehicle: "Edit vehicle",
  publishTheVehicle: "Send",
  previous: "Previous",
  describeVehicle: "Describe your vehicle",
  uploadVehiclePhotos: "Upload vehicle photos",
  lastStep: "It's the last step!",
  next: "Next",
  dashboard: "Dashboard",
  vehicleSearchPlaceholder: "Search a vehicle...",
  audience: "Audience",
  role: "Role",
  status: "Status",
  forgotPassword: "Forgot password?",
  forgotPasswordHeader: "Type-in your email to receive a temporary password.",
  email: "Email",
  emailPlaceholder: "example@email.com",
  login: "Login",
  loginHeader: "Login to Avarta Auto",
  password: "Password",
  pageNotFound: "Page not found",
  createUser: "Create user",
  createUserHeader: "You will then receive a password by email.",
  create: "Create",
  noInternetConnexionFound: "No internet connexion found",
  createBugSuccess: "Bug report sent successfully",
  deleteBugSuccess: "Bug report deleted successfully",
  passwordNotMatching: "Password does not match",
  deleteUserSuccess: "User deleted successfully",
  passwordSent: "Password sent by email!",
  userUpdateSuccess: "User edited successfully",
  passwordSuccess: "Password edited successfully",
  emailCopiedInClipboard: "Email copied in clipboard",
  pressMe: (value: number) => `Press me ${value} times`,
  pressButton5Times: "Press the button 5 times",
  yes: "Yes",
  delete: "Delete",
  edit: "Edit",
  avartaAddress: "4736 St-Jean Boulevard, Suite B201",
  seeInGoogleMap: "Voir sur Google maps",
  home: "Home",
  sellVehicle: "Sell your vehicle",
  inventory: "Inventory",
  brokerageService: "Brokerage service",
  aboutUs: "About us",
  en: "English",
  fr: "French",
  headerTitle1: "Looking for a vehicule ?",
  headerTitle2: "Want to sell your vehicle ?",
  headerTitle3: "Sport vehicles",
  headerSubTitle1: "We have what you need !",
  headerSubTitle2: "The easiest way to sell at the price you want !",
  headerSubTitle3: "Efficient and reliable brokerage service !",
  seeMore: "See more",
  hotCategories: "Hot categories",
  ourServices: "Our services",
  serviceSubTitle1: "Browse our inventory to find the best deal!",
  serviceSubTitle2: "Trust our team of professionals to find the right vehicle for you!",
  serviceSubTitle3: "Sell your vehicle quickly and with confidence!",
  reviews: "Reviews",
  quoteName1: "Douaa Abid",
  quoteName2: "Georges Levesque",
  quoteName3: "Rahma Mohamed",
  quote1: "Excellent customer service!  Would recommend if anyone is looking for a car. They made sure that I got the best possible deal for my new car.",
  quote2: "Very impressed. They were extremely communicative and transparent while I shopped for a used car for my daughter. They delivered exactly on time and did a superb job with the whole process. I highly recommend these people.",
  quote3: "Excellent brokers, this company helped me in finding the perfect car. Would recommend 100%; they are very professional and dedicated to their clients. Will do business again with them for sure!",
  buyAVehicle: "Buy a vehicle",
  brokerage: "Brokerage service",
  realFact: "Our Portfolio",
  satisfiedCustomers: "Satisfied customers",
  yearsOfExperience: "Years of experiences",
  dealershipNetworks: "Dealership networks",
  acura: "Acura",
  alfa: "Alfa Romeo",
  amc: "AMC",
  aston: "Aston Martin",
  audi: "Audi",
  avanti: "Avanti",
  bentl: "Bentley",
  bmw: "BMW",
  buick: "Buick",
  cad: "Cadillac",
  chev: "Chevrolet",
  chry: "Chrysler",
  daew: "Daewoo",
  daihat: "Daihatsu",
  datsun: "Datsun",
  delorean: "DeLorean",
  dodge: "Dodge",
  eagle: "Eagle",
  fer: "Ferrari",
  fiat: "FIAT",
  fisk: "Fisker",
  ford: "Ford",
  freight: "Freightliner",
  geoBrand: "Geo",
  gmc: "GMC",
  honda: "Honda",
  amgen: "HUMMER",
  hyund: "Hyundai",
  infin: "Infiniti",
  isu: "Isuzu",
  jag: "Jaguar",
  jeep: "Jeep",
  kia: "Kia",
  lam: "Lamborghini",
  lan: "Lancia",
  rov: "Land Rover",
  lexus: "Lexus",
  linc: "Lincoln",
  lotus: "Lotus",
  mas: "Maserati",
  maybach: "Maybach",
  mazda: "Mazda",
  mclaren: "McLaren",
  mb: "Mercedes-Benz",
  merc: "Mercury",
  merkur: "Merkur",
  mini: "MINI",
  mit: "Mitsubishi",
  nissan: "Nissan",
  olds: "Oldsmobile",
  peug: "Peugeot",
  plym: "Plymouth",
  pont: "Pontiac",
  por: "Porsche",
  ram: "RAM",
  ren: "Renault",
  rr: "Rolls-Royce",
  saab: "Saab",
  saturn: "Saturn",
  scion: "Scion",
  smart: "Smart",
  srt: "SRT",
  sterl: "Sterling",
  sub: "Subaru",
  suzuki: "Suzuki",
  tesla: "Tesla",
  toyota: "Toyota",
  tri: "Triumph",
  volks: "Volkswagen",
  volvo: "Volvo",
  yugo: "Yugo",
  new: "New",
  likeNew: "Like new",
  used: "Used",
  damaged: "Damaged",
  totalLoss: "Total loss",
  sedan: "Sedan",
  minivan: "Minivan",
  suv: "SUV",
  hatchback: "HatchBack",
  cabriolet: "Cabriolet",
  sport: "Sport",
  pickup: "Pickup",
  offroad: "Off road",
  minitruck: "Mini truck",
  micro: "Micro",
  van: "Van",
  campervan: "CamperVan",
  rear: "Rear drive",
  front: "Front drive",
  allWheel: "All wheel drive",
  gaz: "Gaz",
  diesel: "Diesel",
  hybrid: "Hybrid",
  electric: "Electric",
  selectDistance: "Type-in the driven distance",
  selectCondition: "Type-in the vehicle condition",
  selectBodyStyle: "Type-in the body style",
  selectCylinderCount: "Type-in the quantity of cylinders",
  selectTransmission: "Type-in the transmission",
  selectExteriorColor: "Type-in exterior color",
  selectInteriorColor: "Type-in interior color",
  selectDriveTrain: "Type-in the drivetrain type",
  selectVin: "Type-in the vehicle identification number (VIN)",
  selectDoors: "Type-in the amount of doors",
  selectSeatingCount: "Type-in the seating capacity",
  selectFuelType: "Type-in the fuel type",
  selectCityFuelConsumption: "City consumption (L/100km)",
  selectHighwayFuelConsumption: "Highway consumption (L/100km)",
  selectBrand: "Type-in the brand",
  selectModel: "Type-in the model",
  selectDistanceType: "Type-in the distance type",
  selectYear: "Type-in the year",
  selectPrice: "Desired Price of Sale",
  selectEquipments: "Type-in the equipments",
  distance: "Driven distance",
  condition: "Vehicle condition",
  bodyStyle: "Body style",
  cylinderCount: "Quantity of cylinders",
  transmission: "Transmission",
  exteriorColor: "Exterior color",
  interiorColor: "Interior color",
  driveTrain: "Drivetrain type",
  doors: "Amount of doors",
  doorsShort: "Doors",
  seats: "Seats",
  seatingCount: "Seating capacity",
  fuelType: "Fuel type",
  cityFuelConsumption: "City consumption (L/100km)",
  highwayFuelConsumption: "Highway consumption (L/100km)",
  cityFuelConsumptionShort: "City consumption",
  highwayFuelConsumptionShort: "Highway consumption",
  brand: "Brand",
  model: "Model",
  year: "Year",
  price: "Price",
  equipments: "Equipments",
  manual: "Manual",
  automatic: "Automatic",
  red: "Red",
  black: "Black",
  airConditioning: "Air conditioning",
  leather: "Leather",
  parkingSensors: "Parking sensors",
  cruiseControl: "Adaptive Cruise Control with Lane keep Assist",
  carbonFiberTrim: "Carbon fiber trim",
  appleCarPlayOrAndroidAuto: "Apple Vehicle play / Android Auto",
  panoramicSunroof: "Panoramic sunroof",
  sunroof: "Sunroof",
  remoteStarter: "Remote starter",
  warrantyOrExtendedWarranty: "Warranty and/or Extended warranty",
  sportPackage: "Sport package",
  digitalCluster: "Digital cluster",
  digitalIntertainmentDisplay: "Digital infotainment display",
  sportsExhaust: "Sports exhaust",
  blackPackage: "Black package",
  technicalSpecifications: "Technical specifications",
  km: "km",
  miles: "Miles",
  selectOwnership: "Type-in ownership type",
  leasedVehicle: "Leased / financed vehicle",
  ownedVehicle: "Full ownership of the vehicle",
  firstName: "First name",
  lastName: "Last name",
  phone: "Phone number",
  publishVehicle: "Publish vehicle",
  sellVehicleSuccessDescription: "Your vehicle is now in review and we will contact you soon",
  brokerageSuccessDescription: "Your brokerage request is now in review and we will contact you soon",
  seeVehicleDetails: "See vehicle details",
  makeVehiclePrivate: "Make vehicle private",
  makeVehiclePublic: "Make vehicle public",
  askDeleteVehicle: (title: string) => `are you sure you want to delete the vehicle ${title}?`,
  askDeleteBrokerage: (title: string) => `are you sure you want to delete the brokerage with ${title}`,
  askDeleteUser: (email: string) => `are you sure you want to delete the user ${email}`,
  logout: "Logout",
  createVehicle: "Publish a vehicle",
  cylinderEngine: (count: number) => `${count} cylinder engine`,
  engine: "Engine",
  viewMore: "View more details",
  filters: "Filters",
  minYear: "From year",
  maxYear: "To year",
  minDistance: "Min distance",
  maxDistance: "Max distance",
  minPrice: "Min price",
  maxPrice: "Max price",
  clearFilters: "Clear filters",
  lessThan: (price: number) => `Less than ${price} $ / Month`,
  moreThan: (price: number) => `${price} $ and more`,
  contactForm: "Type-in your contact informations",
  vehicleForm: "What type of Vehicle are you looking for?",
  tellYourNeeds: "Tell us what you need!",
  selectFuelTypeLong: "Type-in the type of fuel (1 to 2 possible choices)",
  selectDriveTrainLong: "Type-in the type of transmission (1 to 2 possible choices)",
  selectBrandLong: "Type-in the brand (1 to 3 possible choices)",
  selectPriceLong: "Whether it is a lease or financed vehicle, what is your monthly budget?",
  selectBodyStyleLong: "Type-in the body style (only 1 choice)",
  brokerages: "Courtages",
  vehicles: "Vehicles",
  deleteBrokerageSuccess: "Brokerage deleted successfully",
  no: "No",
  undefined: "Undefined",
  priceBalance: "Balance of the vehicle",
  welcomeBrokerage: "Welcome to our brokerage service!",
  welcomeBrokerageDescription: "Let us find the right vehicle for you!",
  welcomeSellCar: "Welcome to our platform to sell your vehicle!",
  welcomeSellCarDescription: "The easiest way to sell your vehicle at the price you want!",
  brokerageFirstQuestion: "In order to perfect our advice to assist your vehicle’s search, please tell us why are we looking for this vehicle?",
  brokerageFirstPlaceholder: "EX: Work purposes, Personal car, Weekend sports car, etc...",
  generalQuestion: "General question",
  users: "Users",
  basic: "Basic",
  admin: "Admin",
  resetPasswordSubject: "Password changed",
  resetPasswordTemplateTitle: html`Your password for <b>Avarta Solutions</b> has just been changed.`,
  createUserTemplateDescription: "You can access your account with your email",
  temporaryPassword: "Temporary password",
  enterYourInformations: "Enter your informations",
  askSchedule: "When would you need the vehicle delivered by?",
  less1Week: "Less than 1 week",
  "1or2Weeks": "1 to 2 weeks",
  "2to4Weeks": "2 to 4 weeks",
  "1to3Months": "1 to 3 months",
  scheduleType: "Requested deadlines",
  afg: "Afghanistan",
  alb: "Albania",
  dza: "Algeria",
  and: "Andorra",
  ago: "Angola",
  atg: "Antigua and Barbuda",
  arg: "Argentina",
  arm: "Armenia",
  aus: "Australia",
  aut: "Austria",
  aze: "Azerbaijan",
  bhs: "Bahamas",
  bhr: "Bahrain",
  bgd: "Bangladesh",
  brb: "Barbados",
  blr: "Belarus",
  bel: "Belgium",
  blz: "Belize",
  ben: "Benin",
  btn: "Bhutan",
  bol: "Bolivia (Plurinational State of)",
  bih: "Bosnia and Herzegovina",
  bwa: "Botswana",
  bra: "Brazil",
  brn: "Brunei Darussalam",
  bgr: "Bulgaria",
  bfa: "Burkina Faso",
  bdi: "Burundi",
  cpv: "Cabo Verde",
  khm: "Cambodia",
  cmr: "Cameroon",
  can: "Canada",
  caf: "Central African Republic",
  tcd: "Chad",
  chl: "Chile",
  chn: "China",
  col: "Colombia",
  com: "Comoros",
  cog: "Congo",
  cod: "Congo, Democratic Republic of the",
  cri: "Costa Rica",
  civ: "Côte d'Ivoire",
  hrv: "Croatia",
  cub: "Cuba",
  cyp: "Cyprus",
  cze: "Czechia",
  dnk: "Denmark",
  dji: "Djibouti",
  dma: "Dominica",
  dom: "Dominican Republic",
  ecu: "Ecuador",
  egy: "Egypt",
  slv: "El Salvador",
  gnq: "Equatorial Guinea",
  eri: "Eritrea",
  est: "Estonia",
  swz: "Eswatini",
  eth: "Ethiopia",
  fji: "Fiji",
  fin: "Finland",
  fra: "France",
  gab: "Gabon",
  gmb: "Gambia",
  geo: "Georgia",
  deu: "Germany",
  gha: "Ghana",
  grc: "Greece",
  grd: "Grenada",
  gtm: "Guatemala",
  gin: "Guinea",
  gnb: "Guinea-Bissau",
  guy: "Guyana",
  hti: "Haiti",
  hnd: "Honduras",
  hun: "Hungary",
  isl: "Iceland",
  ind: "India",
  idn: "Indonesia",
  irn: "Iran (Islamic Republic of)",
  irq: "Iraq",
  irl: "Ireland",
  isr: "Israel",
  ita: "Italy",
  jam: "Jamaica",
  jpn: "Japan",
  jor: "Jordan",
  kaz: "Kazakhstan",
  ken: "Kenya",
  kir: "Kiribati",
  prk: "Korea (Democratic People's Republic of)",
  kor: "Korea, Republic of",
  kwt: "Kuwait",
  kgz: "Kyrgyzstan",
  lao: "Lao People's Democratic Republic",
  lva: "Latvia",
  lbn: "Lebanon",
  lso: "Lesotho",
  lbr: "Liberia",
  lby: "Libya",
  lie: "Liechtenstein",
  ltu: "Lithuania",
  lux: "Luxembourg",
  mdg: "Madagascar",
  mwi: "Malawi",
  mys: "Malaysia",
  mdv: "Maldives",
  mli: "Mali",
  mlt: "Malta",
  mhl: "Marshall Islands",
  mrt: "Mauritania",
  mus: "Mauritius",
  mex: "Mexico",
  fsm: "Micronesia (Federated States of)",
  mda: "Moldova, Republic of",
  mco: "Monaco",
  mng: "Mongolia",
  mne: "Montenegro",
  mar: "Morocco",
  moz: "Mozambique",
  mmr: "Myanmar",
  nam: "Namibia",
  nru: "Nauru",
  npl: "Nepal",
  nld: "Netherlands",
  nzl: "New Zealand",
  nic: "Nicaragua",
  ner: "Niger",
  nga: "Nigeria",
  mkd: "North Macedonia",
  nor: "Norway",
  omn: "Oman",
  pak: "Pakistan",
  plw: "Palau",
  pan: "Panama",
  png: "Papua New Guinea",
  pry: "Paraguay",
  per: "Peru",
  phl: "Philippines",
  pol: "Poland",
  prt: "Portugal",
  pse: "Palestine",
  qat: "Qatar",
  rou: "Romania",
  rus: "Russian Federation",
  rwa: "Rwanda",
  kna: "Saint Kitts and Nevis",
  lca: "Saint Lucia",
  vct: "Saint Vincent and the Grenadines",
  wsm: "Samoa",
  smr: "San Marino",
  stp: "Sao Tome and Principe",
  sau: "Saudi Arabia",
  sen: "Senegal",
  srb: "Serbia",
  syc: "Seychelles",
  sle: "Sierra Leone",
  sgp: "Singapore",
  svk: "Slovakia",
  svn: "Slovenia",
  slb: "Solomon Islands",
  som: "Somalia",
  zaf: "South Africa",
  ssd: "South Sudan",
  esp: "Spain",
  lka: "Sri Lanka",
  sdn: "Sudan",
  sur: "Suriname",
  swe: "Sweden",
  che: "Switzerland",
  syr: "Syrian Arab Republic",
  tjk: "Tajikistan",
  tza: "Tanzania, United Republic of",
  tha: "Thailand",
  tls: "Timor-Leste",
  tgo: "Togo",
  ton: "Tonga",
  tto: "Trinidad and Tobago",
  tun: "Tunisia",
  tur: "Türkiye",
  tkm: "Turkmenistan",
  tuv: "Tuvalu",
  uga: "Uganda",
  ukr: "Ukraine",
  are: "United Arab Emirates",
  gbr: "United Kingdom of Great Britain and Northern Ireland",
  usa: "United States of America",
  ury: "Uruguay",
  uzb: "Uzbekistan",
  vut: "Vanuatu",
  ven: "Venezuela (Bolivarian Republic of)",
  vnm: "Viet Nam",
  yem: "Yemen",
  zmb: "Zambia",
  zwe: "Zimbabwe",
  invalidField: "Invalid field(s)",
  optional: "Optional",
  streetExt: "Extension",
  streetExtPlaceholder: "Office 280",
  images: "Images",
  missingField: "Missing field",
  vin17: "VIN number < 17",
  other: "Other",
  otherBrand: "Other brand",
  requested: "requested",
  accepted: "Accepted",
  completed: "Completed",
  inProgress: "In progress",
  discardedByTeam: "Discarded by team",
  canceledByCustomer: "Canceled by customer",
  search: "Search",
  writeSomething: "Write something",
  searchPrecision: "Search precision",
  shownColumns: "Shown columns",
  low: "Low",
  medium: "Medium",
  high: "High",
  estimate: "Estimate",
  msrp: "MSRP",
  creationDate: "Creation date",
  lastEditDate: "Last edit date",
  comment: "Comment",
  vinId: "VIN ID",
  ownership: "Ownership",
  public: "Public",
  private: "Private",
  all: "All",
  avarta: "Avarta",
  details: "Details",
  seeInitDetails: "See init details",
  hideInitDetails: "Hide init details",
  old: "Old",
  current: "Current",
  editStatus: "Edit status",
  vehicleValueEstimate: "Vehicle value estimate",
  msrpLong: "manufacturer’s suggested retail price (MSRP)",
  createForClient: "Create for client",
  createForAvarta: "Create for avarta",
  sellVehicleTitle: "selling your vehicle just got easier!",
  sellVehicleSectionTitle: "Sell and save",
  sellVehicleSectionSubTitle1: "Save your money",
  sellVehicleSectionSubTitle2: "Save your time",
  sellVehicleSectionSubText1: "In order to lower the associated sales tax, use the value of your current ride toward the purchase of the next one.",
  sellVehicleSectionSubText2: "Our full service will allow you to sit back and relax while we do the work.",
  fillForm: "Fill in our form accordingly",
  buyConfidence: "Buy with confidence",
  brokerageDescription: "When you employ an auto broker's services, you may buy with confidence knowing that you have a qualified supporter assisting you. Consequently, you won't need to be concerned about the possibility of deceit, fraud, or other problems now present in the vehicle business.",
  brokerageSectionTitle: "Efficient and reliable brokerage services",
  brokerageSectionSubTitle1: "Exclusive price",
  brokerageSectionSubTitle2: "Transparancy",
  brokerageSectionSubTitle3: "Partners who listen",
  brokerageSectionText1: "Take advantage of a good price that has been negotiated for you.",
  brokerageSectionText2: "Tell us what you are looking for and we will share what your neighbors have paid for.",
  brokerageSectionText3: "The dealer who provided you with the exclusive price will answer all your questions.",
  developedBy: "Developed by",
} as const

export const enSay: EnSay = en