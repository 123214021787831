import { tw } from "@/shared"

import { ToggleListProps } from "./model"
import { Toggle } from "./Toggle"
export function ToggleList({
  outputMap,
  optionList,
  onChange,
  active,
  activeList,
  isError
}: ToggleListProps): JSX.Element {
  return (
    <div className={tw`pt-1 flex flex-wrap ${isError ? tw`px-1 border border-solid border-error-300 rounded-xl` : ""}`}>
      {optionList.map((option, index) => (
        <Toggle
          key={index}
          isActive={activeList ? activeList.includes(option) : option === active}
          output={outputMap(option)}
          onChange={() => {
            if (activeList) {
              let newList = [...activeList]
              const isFound = !!activeList.find(item => item === option)
              if (isFound) {
                newList = newList.filter(item => item !== option)
              } else {
                newList.push(option)
              }
              onChange(newList)
            } else {
              if (active === option) {
                onChange(undefined)
              } else {
                onChange(option)

              }
            }
          }}
        />
      ))}
    </div>
  )
}