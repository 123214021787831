import { validateVehicle } from "@/pages/CreateVehicle/functions"
import { setReduxState } from "@/redux"
import { VehicleRequests, Vehicle } from "@/shared"

import { handleSocketRequest } from "../functions"


export function updateOneVehicle(
  query: VehicleRequests["updateOne"]["params"] & { isLoadingHidden?: boolean },
  callback: (vehicle: Vehicle) => void
): void {

  if (query.isPublic === undefined && query.status === undefined && validateVehicle()) {
    return
  }

  handleSocketRequest(
    "vehicles",
    "updateOne",
    {
      ...query,
    }, data => {
      if (callback) {
        callback(data)
      }
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    }
  )
}