import React from "react"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Icon } from "@/components"
import { forgotPassword } from "@/api/requests"
import { getReduxState, setReduxState, useReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { LoginStyled } from "../Login/LoginStyled"



export function ForgotPassword(): JSX.Element {
  const { say, errorMessage, emailField } = useReduxState(state => ({
    say: state.languages.say,
    errorMessage: state.ui.snackbar?.message,
    emailField: state.login.emailField,
  }))
  const forgotFn = () => forgotPassword({
    forgotPasswordEmail: emailField,
    language: getReduxState().languages.selectedLanguage
  })
  return (
    <LoginStyled>
      <div className="background" />
      <section>
        <h3>{say.forgotPassword}</h3>
        <p>{say.forgotPasswordHeader}</p>
        <TextField
          error={errorMessage === "userDoesntExists"}
          autoFocus={true}
          type="email"
          label={say.email}
          placeholder={say.emailPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="envelope" />
              </InputAdornment>
            )
          }}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setReduxState("login", { emailField: evt.target.value })}
          value={emailField}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth={true}
          onKeyUp={evt => evt.key === "Enter" && forgotFn()}
        />
        <footer>
          <Button color="primary" onClick={() => goToExactPath("/login")}>{say.login}</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={forgotFn}
          >{say.send}</Button>
        </footer>
      </section>
    </LoginStyled>
  )
}


