import { SayKeywordStrings } from "@/shared"
import { Button, Select } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { deleteOneVehicle } from "@/api/requests"

import { LeftButtonListProps } from "../model"
import { presetVehicleForEditing } from "../functions/presetVehicleForEditing"
import { editVehicleStatus } from "../functions/editVehicleStatus"

import { toggleVehicleRequests } from "./toggleVehicleRequests"

export function LeftButtonList({ tableVehicleIdList }: LeftButtonListProps): JSX.Element {
  const { say, vehicleMap, isFilters } = useReduxState(state => ({
    say: state.languages.say,
    vehicleMap: state.vehicles.vehicleMap,
    isFilters: state.ui.isFilters,
  }))
  return (
    <div
      className={`sticky  mt-[140px] col ${isFilters ? "left-[400px]" : "left-0"}`}
    >
      <div className="w-10 h-12 border-b border-solid bg-grey-850 border-grey-800" />
      {tableVehicleIdList.map(_id => {
        const optionList: SayKeywordStrings[] = ["delete"]
        const vehicle = vehicleMap[_id]!
        if (vehicle.isPublic) {
          optionList.unshift("makeVehiclePrivate")
        } else {
          optionList.unshift("makeVehiclePublic")

        }
        optionList.unshift("details", "edit", "comment", "editStatus")

        return (
          <Select
            key={_id}
            isStyleDisabled={true}
            dropdownProps={{
              optionList,
              outputMap: (key: typeof optionList[number]) => say[key],
              onChange: (key: typeof optionList[number]) => {
                if (key === "edit") {
                  presetVehicleForEditing(vehicle)
                } else if (key === "comment") {
                  presetVehicleForEditing(vehicle, "comment")
                } else if (key === "editStatus") {
                  editVehicleStatus(_id)
                } else if (key === "delete") {
                  setReduxState("popups", {
                    name: "SafeAction",
                    props: {
                      title: say.askDeleteVehicle(say[vehicle.brand]),
                      action: () => deleteOneVehicle(_id)
                    }
                  })
                } else if (key === "makeVehiclePublic" || key === "makeVehiclePrivate") {
                  toggleVehicleRequests(vehicle._id, !vehicle.isPublic)
                }
              }
            }}
          >
            <Button
              iconName="chevron-right"
              size="xs"
              isCircle={true}
              color="success"
              className="w-10 h-10 border-b border-solid rounded-none border-success-300"
            />
          </Select>
        )
      })}
    </div>
  )
}
