import { Fieldset, Popup, TextArea } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { editVehicle } from "@/pages/CreateVehicle/functions/editVehicle"
import { FinalForm } from "@/pages/CreateVehicle/components/FinalForm"
import { SpecsForm } from "@/pages/CreateVehicle/components/SpecsForm"
import { VehicleForm } from "@/pages/CreateVehicle/components/VehicleForm"
import { getVehicleFieldClassName } from "@/pages/Vehicles/functions/getVehicleFieldClassName"

export const EditPartialVehicle = (): JSX.Element => {
  const { say, editedComment } = useReduxState(state => ({
    say: state.languages.say,
    editedComment: state.vehicles.editedComment,
  }))
  return (
    <Popup
      title={say.editVehicle}
      isLarge={true}
      primaryButton={{
        label: say.save,
        onClick: () => editVehicle()
      }}
      secondaryButton={{
        label: say.cancel,
        onClick: () => setReduxState({
          popups: { name: undefined },
          vehicles: { editedTableCell: undefined }
        })
      }}
    >
      <VehicleForm />
      <SpecsForm />
      <FinalForm />
      <Fieldset label={say.comment} className={getVehicleFieldClassName("comment")}>
        <TextArea
          value={editedComment.html}
          onChange={(html, plainText) => setReduxState("vehicles", { editedComment: { html, plainText } })}
        />
      </Fieldset>
    </Popup>
  )
}
