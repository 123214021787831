import { isInvalidFields } from "@/functions"
import { getReduxState } from "@/redux"
import { SayKeywordStrings } from "@/shared"

import { AllVehicleHeadColumns } from "../model"

export function validatePartialVehicle(colId: AllVehicleHeadColumns): boolean {

  const reduxState = getReduxState()
  const {
    createVehicle: {
      brand,
      otherBrand,
      model,
      year,
      bodyStyle,
      exteriorColor,
      interiorColor,
      doorCountField,
      seatingCountField,
      vinIdField,
      distanceField,
      condition,
      cylinderCountField,
      driveTrain,
      fuelType,
      priceField,

      email,
      firstName,
      lastName,
      phone,
      address,
    },

  } = reduxState
  const priceNum = Number(priceField)

  const tableColMap: { [key in AllVehicleHeadColumns]?: { [key in SayKeywordStrings]?: boolean } } = {
    brand: {
      brand: !brand,
      otherBrand: brand === "other" && !otherBrand,
      model: !model,
    },
    year: {
      year: !year
        || year < 1900,
    },
    estimate: {

    },
    creationDate: {

    },
    lastEditDate: {

    },
    comment: {

    },
    status: {

    },
    bodyStyle: {
      bodyStyle: !bodyStyle,
    },
    exteriorColor: {
      exteriorColor: !exteriorColor.trim(),
    },
    interiorColor: {
      interiorColor: !interiorColor.trim(),
    },
    doors: {
      doors: !!(doorCountField && Number(doorCountField) < 1)
    },
    seatingCount: {
      seats: !!(seatingCountField && Number(seatingCountField) < 1),

    },
    vinId: {
      vin17: vinIdField.length !== 17,
    },
    condition: {
      condition: !condition,
    },
    distance: {
      distance: !distanceField,
    },
    fuelType: {
      cylinderCount: (!cylinderCountField && fuelType !== "electric"),
    },
    transmission: {

    },
    cylinderCount: {

    },
    driveTrain: {
      driveTrain: !driveTrain
    },
    price: {
      price: !priceField || isNaN(priceNum) || priceNum < 0,
    },
    firstName: {
      firstName: !firstName.trim(),
    },
    lastName: {
      lastName: !lastName.trim(),
    },
    phone: {
      phone: !phone.trim(),
    },
    email: {
      email: !email.trim(),
    },
    address: {
      address: (
        !address
        || !address.streetNumber
        || !address.streetName
        || !address.city
        || !address.stateOrRegion
      )
    },
  }
  if (tableColMap[colId]) {
    return isInvalidFields(tableColMap[colId]!)
  }
  return false
}