import { createStore, Store, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { customCombineReducers } from "@/redux/functions/customCombineReducers"
import { getReduxInitState } from "@/redux/functions/getReduxInitState"
import { languages } from "@/languages/reducer"
import { routes } from "@/routes/reducer"
import { ui } from "@/ui/reducer"
import { popups } from "@/popups/reducer"
import { createVehicle } from "@/pages/CreateVehicle/reducer"
import { signIn } from "@/pages/SignIn/reducer"
import thunk from "redux-thunk"
import { IS_DEV } from "@/constants"
import { login } from "@/pages/Login/reducer"
import { settings } from "@/Settings/reducer"
import { home } from "@/pages/Home/reducer"
import { vehicle } from "@/pages/Vehicle/reducer"
import { search } from "@/pages/Search/reducer"
import { createBrokerage } from "@/pages/CreateBrokerage/reducer"
import { vehicles } from "@/pages/Vehicles/reducer"
import { brokerages } from "@/pages/Brokerages/reducer"
import { users } from "@/pages/Users/reducer"

import { ReduxState } from "./model"

export const store: Store = createStore(
  (state: ReduxState = getReduxInitState(), action: any): ReduxState => customCombineReducers({
    languages,
    ui,
    users,
    popups,
    home,
    createVehicle,
    createBrokerage,
    settings,
    vehicles,
    brokerages,
    search,
    vehicle,
    signIn,
    routes,
    login
  }, state, action),
  IS_DEV ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk),
)