
import { tw } from "@/functions"

import { CheckBox } from "../CheckBox"

import { getOptionsAndValues } from "./getOptionsAndValues"
import { CheckboxListProps } from "./model"
import { valueListToMap } from "./valueListToMap"
export function CheckboxList<Value>({
  valueMap = {} as CheckboxListProps<Value>["valueMap"],
  valueList = getOptionsAndValues<Value>(valueMap!).valueList,
  optionList = getOptionsAndValues<Value>(valueMap!).optionList,
  outputMap,
  onChange,
  tooltipMap,
  isMultiple,
  className,
  children,
  isError,
  shade,
}: CheckboxListProps<Value>): JSX.Element {
  return (
    <div className={tw`${className} flex flex-wrap mt-2 checkbox-list children:mb-2 children:mr-2 ${isError ? " border border-error-500 border-solid pt-2 pl-2 rounded-3xl" : ""}`}>
      {optionList.map((key, index) => (
        <CheckBox
          key={index}
          shade={shade}
          isChecked={valueList.includes(key)}
          label={outputMap(key, index)}
          tooltip={tooltipMap ? tooltipMap(key, index) : undefined}
          onChange={() => {
            if (isMultiple === true) {
              let newList = [...valueList]
              if (valueList.includes(key)) {
                newList = newList.filter(item => item !== key)
                onChange(newList, valueListToMap(newList, optionList))
              } else {
                newList.push(key)
                onChange(newList, valueListToMap(newList, optionList))
              }
            } else {
              if (valueList.includes(key)) {
                onChange([], valueListToMap([], optionList))
              } else {
                onChange([key], valueListToMap([key], optionList))

              }
            }
          }}
        />
      ))}
      {children}
    </div>
  )
}