import React, { SVGAttributes } from "react"
function Icon(props: SVGAttributes<{}>): JSX.Element {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
        	<path d="M635.88 160L327.78 2.02c-4.88-2.69-10.69-2.69-15.56 0l-308.1 161c-3.87 2.15-5.26 7.02-3.11 10.88l7.78 13.99c2.15 3.86 7.02 5.25 10.88 3.1L320 34.3l300.33 153.68c3.86 2.14 8.73.76 10.87-3.1l7.79-13.99c2.15-3.87.76-8.74-3.11-10.89zm-142.5 101.74l-15.15-46.5c-10.73-32.93-41.43-55.22-76.07-55.22H237.83c-34.64 0-65.34 22.29-76.07 55.22l-15.15 46.5C117.01 273.47 96 302.22 96 336v128c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48v-16h224v16c0 26.47 21.53 48 48 48h16c26.47 0 48-21.53 48-48V336c0-33.78-21.01-62.53-50.62-74.26zm-301.19-36.59a47.888 47.888 0 0 1 45.64-33.13h164.34c20.84 0 39.18 13.32 45.64 33.13L457.86 256H182.14l10.05-30.85zM176 464c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm336 0c0 8.83-7.19 16-16 16h-16c-8.81 0-16-7.17-16-16v-16h48v16zm0-80v32H128v-80c0-26.47 21.53-48 48-48h288c26.47 0 48 21.53 48 48v48zm-72.8-71.99c-24.1 0-55.2 23.24-55.2 47.93 0 11.98 5.85 32.07 45.06 32.07l10.14-.1c25.96 0 44.8-16.8 44.8-39.95 0-23.15-18.84-39.95-44.8-39.95zm0 55.9c-3.12 0-6.63.1-10.14.1-10.53 0-21.06-.9-21.06-8.07 0-9.57 18.72-23.93 31.2-23.93s20.8 6.38 20.8 15.95-8.33 15.95-20.8 15.95zm-238.4-55.9c-25.96 0-44.8 16.8-44.8 39.95 0 23.15 18.84 39.95 44.8 39.95l10.14.1c39.21 0 45.06-20.1 45.06-32.07 0-24.69-31.1-47.93-55.2-47.93zm10.14 56c-3.51 0-7.02-.1-10.14-.1-12.48 0-20.8-6.38-20.8-15.95s8.32-15.95 20.8-15.95 31.2 14.36 31.2 23.93c0 7.17-10.53 8.07-21.06 8.07z"/>
        </svg>
    )
}

export default Icon
