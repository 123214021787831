import { handleSocketRequest } from "@/api/functions"
import { getReduxState, setReduxState } from "@/redux"

export function deleteOneUser(_id?: string): void {
  handleSocketRequest(
    "users",
    "deleteOne",
    {
      _id,
    }, () => {
      const { userList } = getReduxState().users
      const newUserList = userList.filter(userItem => userItem._id !== _id)
      setReduxState("users", { userList: newUserList })
      setReduxState("ui", {
        snackbar: {
          message: "deleteUserSuccess",
          severity: "success"
        }
      })
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    }
  )
  setReduxState("popups", { name: undefined })
}