import moment from "moment"
import { ConsoleHistory } from "@/shared"

export class ConsoleHistoryHandler {
  private static consoleHistory: ConsoleHistory[] = []
  constructor() {
    // tslint:disable-next-line:only-arrow-functions
    window.onerror = function (error: string | Event, url?: string, line?: number): false {
      ConsoleHistoryHandler.consoleHistory.push({
        type: "exception",
        date: moment().toISOString(),
        url,
        line: String(line),
        message: JSON.stringify(error),
      })
      return false
    }
    // tslint:disable-next-line:only-arrow-functions
    window.onunhandledrejection = function (evt: PromiseRejectionEvent): void {
      ConsoleHistoryHandler.consoleHistory.push({
        type: "promiseRejection",
        date: moment().toISOString(),
        message: JSON.stringify(evt.reason),
      })
    }

    const consoleTypeList = ["log", "error", "warn", "debug", "info"] as const
    function hookLogType(logType: typeof consoleTypeList[number]): typeof console[typeof logType] {
      const original = console[logType].bind(console)
      // tslint:disable-next-line:only-arrow-functions
      return function (): any {
        ConsoleHistoryHandler.consoleHistory.push({
          type: logType,
          date: moment().toISOString(),
          message: JSON.stringify(Array.from(arguments)),
        })
        original.apply(console, arguments as any)
      }
    }
    consoleTypeList.forEach(logType => {
      console[logType] = hookLogType(logType)
    })
  }
  static getConsoleHistoryList(): ConsoleHistory[] {
    return ConsoleHistoryHandler.consoleHistory
  }
}