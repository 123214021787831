import { Brokerage } from "@/shared"
import { formatString } from "@/shared"
import { ReduxState } from "@/redux/model"

import { BrokeragesState } from "../model"


export function filterBrokerages(
  newState: BrokeragesState,
  globalState: ReduxState
): Brokerage[] {
  const {
    searchField,
    searchPrecision,
  } = newState
  const {
    languages: {
      say
    }
  } = globalState
  return newState.brokerageList.filter(item => {
    const activeFilterCount = [
      searchField.trim(),
    ].filter(val => !!val).length
    let noMatchCount = 0

    if (searchField.trim()) {
      if (
        item.brandList.find(brand => formatString("flat", say[brand]).includes(formatString("flat", searchField)))
        || item.driveTrainList.find(driveTrain => formatString("flat", say[driveTrain]).includes(formatString("flat", searchField)))
        || item.fuelTypeList.find(fuelType => formatString("flat", say[fuelType]).includes(formatString("flat", searchField)))
        || formatString("flat", say[item.bodyStyle]).includes(formatString("flat", searchField))
        || formatString("flat", say[item.scheduleType]).includes(formatString("flat", searchField))
        || (item.otherBrand && formatString("flat", item.otherBrand).includes(formatString("flat", searchField)))
        || formatString("flat", item.firstName).includes(formatString("flat", searchField))
        || formatString("flat", item.lastName).includes(formatString("flat", searchField))
        || formatString("flat", item.phone).includes(formatString("flat", searchField))
        || formatString("flat", item.email).includes(formatString("flat", searchField))
        || formatString("flat", item.address.address).includes(formatString("flat", searchField))

      ) {
        return true
      } else {
        if (searchPrecision === "high") {
          return false
        } else {
          noMatchCount++
        }
      }
    }

    if (searchPrecision === "high" && noMatchCount) {
      return false
    }
    if (searchPrecision === "medium" && noMatchCount > activeFilterCount / 2) {
      return false
    } if (searchPrecision === "low" && noMatchCount >= activeFilterCount) {
      return false
    }
    return true
  })
}