import { setStorageItem } from "@/localStorage"
import { UIState } from "@/ui/model"

import { SetAction } from "../redux/model"

export const ui = (currentState: UIState, action: SetAction<"ui">) => {
  const data = action.data
  if (data.isShowTermsWarning !== undefined) {
    setStorageItem("isShowTermsWarning", data.isShowTermsWarning)
  }
  return {
    ...currentState,
    ...data
  }

}