import { getManyBugs } from "@/api/requests"
import { PageTopBar } from "@/components"
import { useReduxState, useWillMount } from "@/redux"
import { Bug } from "@/shared"
import { useState } from "react"

import { BugReportItem } from "./BugReportItem"
import { BugReportStyled } from "./BugReportStyled"

export function BugReport(): JSX.Element {
  const { say } = useReduxState(state => ({
    say: state.languages.say

  }))
  const [bugList, setBugList] = useState<Bug[]>([])
  useWillMount(() => {
    getManyBugs(newBugList => setBugList(newBugList))
  })
  return (
    <BugReportStyled>
      <PageTopBar label={say.bugReport} />

      {bugList.map(bug => (
        <BugReportItem key={bug._id} {...bug} refreshBugs={() => getManyBugs(newBugList => setBugList(newBugList))} />
      ))}
      <div className="padding" />
    </BugReportStyled>
  )
}