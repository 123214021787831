import { center, shadeEffect } from "@/ui/mixins"
import styled from "styled-components"

export const DatePickerSelectStyled = styled.div`
  position: absolute;
  &.month {
    left: calc(50% - 130px);
    bottom: -175px;
  }
  &.date {
    left: calc(50% - 140px);
    bottom: -240px;
  }
  z-index: 100;
  background: white;
  box-shadow: 0 0 10px ${() => window.theme.grey200};

  .top-button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px;
    .year {
      ${center};
      flex: 1;
      font-weight: 400;
      &.active {
        color: ${() => window.theme.primary500};
      }
    }
    .fa-icon {
        ${center};
        margin-left: 10px;
        width: 25px;
        height: 25px;
        ${() => shadeEffect("primary500", "background")}
        color: white;
        border-radius: 9999px;
        svg {
          height: 15px;

        }
    }
  }
  .select-month-list {
    margin: 10px;
    width: 240px;
    display: flex;
    flex-wrap: wrap;

    div {
      ${center};
      width: 80px;
      height: 30px;
      margin-left: 0;
      border-radius: 0;
      ${() => shadeEffect("white", "background")};
      &.active {
        color: ${() => window.theme.primary500};
      }
    }
  }
`