import { breakpoints, center, rem } from "@/ui/mixins"
import styled from "styled-components"

export const LoginStyled = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  section {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${rem(24)};
    background: white;

    &> button {
      margin-top: 20px;
      width: max-content;
      color: ${() => window.theme.secondary800};
    }
  }
  .background {
      position: absolute;
      z-index: 4;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url("./images/header3.jpg");
      background-size: cover;
      background-position: center;
    }
  .MuiInputBase-root {
    border-radius: 9999px;
  }
  .fa-icon {
    width: 16px;
  }
  .MuiTextField-root {
    margin-bottom: ${rem(20)};
  }
  .label {
    text-align: center;
  }
  button {
    height: 50px;
    border-radius: 9999px;
    box-shadow: none;
  }
  h4 {
    text-align: center;
  }
  h3 {
    text-align: center;
    margin-bottom: ${rem(16)};
    color: ${() => window.theme.primary500};
  }
  p {
    text-align: center;
    margin-bottom: ${rem(24)};
    color: ${() => window.theme.grey900};

  }
  footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    button:last-child {
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    section {
    width: calc(100% - ${rem(16)});
    max-width: 400px;
    }
    ${center}
    margin-bottom: 40px;
    left: -60px;
    width: calc(100% + 120px);

    section {
      border-radius: 8px;
      box-shadow: 0 0 4px ${() => window.theme.grey500};
    }
    button {
      margin-bottom: 0 !important;
    }
    footer {
      flex-direction: row;
    }
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    left: -100px;
    width: calc(100% + 200px);
  }
  .fal-thumbs-up {
    height: 200px;
    margin: 20px 0 40px 0;
    width: initial;
    color: ${() => window.theme.primary100};
  }
`