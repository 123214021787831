import { listToMap } from "@/shared"

import { SetAction } from "../../redux/model"

import { SearchState } from "./model"


export const search = (currentState: SearchState, action: SetAction<"search">) => {
  const data = { ...action.data }
  if (data.vehicleList) {
    data.vehicleMap = listToMap(data.vehicleList)
  }
  return {
    ...currentState,
    ...data
  }
}