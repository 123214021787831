import { Icon } from "@/components"
import { IS_INVENTORY } from "@/constants"
import { useReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { OurServicesStyled } from "./OurServicesStyled"

// tslint:disable-next-line:no-var-requires
const Fade = require("react-reveal/Fade")
// tslint:disable-next-line:no-var-requires
const Zoom = require("react-reveal/Zoom")

const ServiceItem = ({ label, onClick, icon, description }: {
  label: string,
  description: string,
  onClick: () => void,
  icon: string
}) => (
  <Zoom>
    <div
      className="service-item"
      onClick={() => onClick()}
    >
      <h3>{label}</h3>
      <Icon name={icon} />
      <p>{description}</p>
    </div>
  </Zoom>
)

export function OurServices(): JSX.Element {
  const { say } = useReduxState(state => ({
    say: state.languages.say,
  }))

  return (
    <OurServicesStyled>
      <Fade top={true}>
        <h1>{say.ourServices}</h1>
      </Fade>
      <div className="list-box">
        {IS_INVENTORY && (
          <ServiceItem
            label={say.buyAVehicle}
            description={say.serviceSubTitle1}
            onClick={() => goToExactPath("/search")}
            icon="car-garage"
          />
        )}
        <ServiceItem
          label={say.brokerage}
          description={say.serviceSubTitle2}

          onClick={() => goToExactPath("/brokerage")}
          icon="handshake-alt"
        />
        <ServiceItem
          label={say.sellVehicle}
          description={say.serviceSubTitle3}
          onClick={() => goToExactPath("/sell-vehicle")}
          icon="sack-dollar"
        />
      </div>
    </OurServicesStyled>
  )
}