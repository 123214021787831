import { breakpoints } from "@/ui/mixins"
import styled from "styled-components"

export const FormParentStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: inherit;
  margin: 0 0 0 0;
  @media screen and (min-width: ${breakpoints.sm}) {
    min-height: initial;
  }
`