import { Button, SafeFixed } from "@/components"
import { goToExactPath } from "@/routes"

export function BottomRightButton(): JSX.Element {
  return (
    <SafeFixed>
      <div className="fixed bottom-5 right-5 not-first-children:mt-2 in-button:shadow-1">
        <Button
          iconName="plus"
          size="lg"
          shade={300}
          color="success"
          isCircle={true}
          onClick={() => goToExactPath("/signin")}
        />
      </div>
    </SafeFixed>
  )
}