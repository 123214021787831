import { breakpoints } from "@/ui/mixins"
import styled from "styled-components"

export const StatisticsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  h1 {
    text-align: center;
    margin-bottom: 40px;
    color: white;
  }
  .list-box {
    display: grid;
    justify-content: center;
    grid-template-columns: min(calc(100vw - 40px), 350px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-rows: 180px;
    @media screen and (min-width: ${breakpoints.sm}) {
      grid-template-columns: repeat(2, min(calc(50vw - 30px), 350px));
      .statistic-item:last-child {
        width: calc(100vw - 40px);
      }
    }
    @media screen and (min-width: ${breakpoints.md}) {
      grid-template-columns: repeat(3, min(calc(33vw - 20px), 350px));
      max-height: 350px;
      .statistic-item:last-child {
        width: 100%;
      }
    }
  }
  .statistic-item {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }
  h3 {
    text-align: center;
    font-size: 22px;
    margin-top: 20px;
    color: white;
    font-weight: 300;
  }
`