export const VEHICLE_EQUIPMENT_LIST = [
  "airConditioning",
  "leather",
  "parkingSensors",
  "cruiseControl",
  "carbonFiberTrim",
  "appleCarPlayOrAndroidAuto",
  "panoramicSunroof",
  "sunroof",
  "remoteStarter",
  "warrantyOrExtendedWarranty",
  "sportPackage",
  "digitalCluster",
  "digitalIntertainmentDisplay",
  "sportsExhaust",
  "blackPackage",
] as const

