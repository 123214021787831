import { getReduxState, setReduxState } from "@/redux"

import { getManyBrokerages, getManyReviews, getManyUsers, getManyVehicles } from "../requests"

export function launchAllRequests(): void {
  const { currentUser } = getReduxState().users
  getManyVehicles({
    minIndex: 0,
    maxIndex: 5000,
    isLoadingHidden: true
  }, vehicleList => setReduxState("search", { vehicleList }))
  getManyReviews()
  if (!currentUser) {
    return
  }
  getManyUsers()
  getManyBrokerages()
  getManyVehicles({
    minIndex: 0,
    maxIndex: 5000,
    isLoadingHidden: true
  }, vehicleList => setReduxState("vehicles", { vehicleList }))
}