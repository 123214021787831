import { Component } from "react"
import { connect, ConnectedProps } from "react-redux"
import { Icon } from "@/components"
import { ReduxState } from "@/redux/model"
import { getReduxState, setReduxState } from "@/redux"

import { handlePageScroll } from "../functions"

import { SearchStyled } from "./SearchStyled"
import { VehicleList } from "./VehicleList"
import { SearchFilters } from "./SearchFilters"
import { FiltersOutput } from "./FiltersOutput"

export class SearchNotConnected extends Component<ConnectedProps<typeof connector>> {
  constructor(props: SearchNotConnected["props"]) {
    super(props)
    this.handlePageScroll = this.handlePageScroll.bind(this)
    window.addEventListener("scroll", this.handlePageScroll)
  }
  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.handlePageScroll)
  }
  handlePageScroll(): void {
    handlePageScroll(document.scrollingElement as any)
  }
  render(): JSX.Element {
    const { say } = this.props

    return (
      <SearchStyled>
        <div className="top-box">
          <FiltersOutput />
          <Icon
            name="sliders-h"
            onClick={() => setReduxState("search", { isFilters: !getReduxState().search.isFilters })}
          >{say.filters}</Icon>
        </div>
        <SearchFilters />
        <VehicleList />
      </SearchStyled>
    )
  }
}

const connector = connect((state: ReduxState) => ({
  vehicleList: state.vehicles.vehicleList,
  lastPath: state.routes.lastPath,
  say: state.languages.say,
}))

export const Search = connector(SearchNotConnected)