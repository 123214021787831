import { createOneVehicle } from "@/api/requests"
import { Button, ImageManager } from "@/components"
import { incrementCreateBrokerageViewIndex } from "@/pages/CreateBrokerage/functions/incrementCreateBrokerageViewIndex"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"

import { editVehicle } from "../functions/editVehicle"

import { FormFooter } from "./FormFooter"

export function ImagesForm(): JSX.Element {
  const {
    say,
    _id,
    imageList,
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    _id: state.createVehicle._id,
    imageList: state.createVehicle.imageList,
  }))
  return (
    <div>
      <ImageManager
        imageList={imageList}
        onChange={newImageList => setReduxState("createVehicle", { imageList: newImageList })}
      />
      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateBrokerageViewIndex(false)}
        >
          {say.previous}
        </Button>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => _id ? editVehicle() : createOneVehicle()}
        >
          {_id ? say.editVehicle : say.publishTheVehicle}
        </Button>
      </FormFooter>
    </div >
  )
}
