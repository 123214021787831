export const BROKERAGE_TABLE_HEAD_LIST = [
  "brand",
  "bodyStyle",
  "driveTrain",
  "fuelType",
  "price",
  "scheduleType",
  "generalQuestion",
  "comment",
  "firstName",
  "lastName",
  "phone",
  "email",
  "address",

] as const