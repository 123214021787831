import { useReduxState } from "@/redux"

import { NavItemStyled } from "./NavItemStyled"
import { NavItemProps } from "./model"
export function NavItem({ path, label, onClick }: NavItemProps): JSX.Element {
  const { firstPath } = useReduxState(state => ({
    firstPath: state.routes.firstPath
  }))

  return (
    <NavItemStyled isActive={path === `/${firstPath}`} onClick={() => onClick()}>
      {label}
    </NavItemStyled>
  )
}