import { isInvalidFields } from "@/functions"
import { getReduxState, setReduxState } from "@/redux"
import { UserRequests } from "@/shared"

import { handleSocketRequest, launchAllRequests } from "../functions"

export function createOneUser(
  user: UserRequests["createOne"]["params"] & { confirmedPassword?: string },
): void {
  if (user.password !== user.confirmedPassword) {
    setReduxState("ui", {
      snackbar: {
        message: "passwordNotMatching",
        severity: "error"
      }
    })
    return
  }
  const query = {
    email: user.email,
    password: user.password,
  }

  if (isInvalidFields({
    email: !user.email
  })) {
    return
  }
  handleSocketRequest(
    "users",
    "createOne",
    { ...query },
    data => {
      setReduxState("popups", {
        name: "LargeMessage",
        props: {
          title: getReduxState().languages.say.userCreateSuccess,
          iconName: "smile"
        }
      })
      launchAllRequests()
    }, err => {
      setReduxState("ui", { snackbar: { message: err, severity: "error" } })
    })
}