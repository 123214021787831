import { updateOneVehicle } from "@/api/requests"
import { CheckboxList, Fieldset } from "@/components"
import { isInvalidFields } from "@/functions"
import { getFromReduxState, setReduxState, useReduxState } from "@/redux"
import { VEHICLE_STATUS_LIST } from "@/shared"

export function editVehicleStatus(_id: string): void {
  const { vehicle, say } = getFromReduxState(state => ({
    say: state.languages.say,
    vehicle: state.vehicles.vehicleMap[_id]
  }))
  if (!vehicle) {
    return
  }
  setReduxState({
    vehicles: { editedStatus: vehicle.status },
    popups: {
      name: "SafeAction",
      props: {
        title: say.editStatus,
        Component: () => {
          const editedStatus = useReduxState(state => state.vehicles.editedStatus)
          return (
            <Fieldset label={say.status}>
              <CheckboxList
                optionList={VEHICLE_STATUS_LIST}
                valueList={editedStatus ? [editedStatus] : []}
                outputMap={val => say[val]}
                onChange={valueList => setReduxState("vehicles", { editedStatus: valueList[0] })}
              />
            </Fieldset>
          )
        },
        action: () => {
          const { status, vehicleList } = getFromReduxState(state => ({
            status: state.vehicles.editedStatus,
            vehicleList: state.vehicles.vehicleList,

          }))
          if (isInvalidFields({ status: !status })) {
            editVehicleStatus(_id)
          } else {
            updateOneVehicle({
              _id: vehicle._id,
              status: status!,
            }, () => setReduxState({
              popups: {
                name: undefined,
              },
              vehicles: {
                vehicleList: vehicleList.map(item => item._id === _id ? { ...item, status: status! } : item)
              }
            })
            )
          }
        }
      }
    },

  })
}