import { OS } from "@/constants"
import { getReduxState, setReduxState } from "@/redux"
import { PublicFile } from "@/shared"
import { ConsoleHistoryHandler } from "@/classes/ConsoleHistoryHandler"

import { handleSocketRequest } from "../functions"

export function createOneBug(message: string, imageFileList: PublicFile[]): void {
  const reduxState = getReduxState()
  const { deviceId } = reduxState.settings

  handleSocketRequest(
    "bugs",
    "createOne", {
    deviceId,
    operatingSystem: OS,
    userAgent: navigator.userAgent,
    consoleHistoryList: ConsoleHistoryHandler.getConsoleHistoryList(),
    message,
    imageFileList
  }, () => {
    setReduxState("ui", {
      snackbar: {
        message: "createBugSuccess",
        severity: "success"
      }
    }, () => {
      setReduxState("popups", { name: undefined })
    })
  }, err => {
    setReduxState("ui", { snackbar: { message: err, severity: "error" } })
  })

}