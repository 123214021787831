import { IS_ANY_IOS, IS_SAFARI } from "@/constants"
import { center, shadeEffect } from "@/ui/mixins"
import styled, { css } from "styled-components"

export const HomeStyled = styled.div`
min-height: 100vh;
overflow: hidden;

@media screen and (orientation: landscape) {
  margin-top: 100px;
}
${() => (IS_SAFARI || IS_ANY_IOS) && css`
    *  {
      transform-style: preserve-3d;
    }
  `}
.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100%;
  img {
    user-select: none;
  }
  .text-box {
    position: absolute;
    z-index: 100000;
    left: 60px;
  }
  h1, h2 {
    margin-bottom: 20px;
  }
  h1 {
    font-weight: 600;
    font-size: 50px;
    text-transform: uppercase;
  }
  h2 {
    font-weight: 500;
    font-size: 30px;
    top: calc(50vh + 20px);
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.header-button {
  ${center};
  width: max-content;
  height: 40px;
  border-radius: 9999px;
  padding: 0 20px;
  color: white;
  font-weight: 500;
  ${() => shadeEffect("secondary900", "background")}
}
@media screen and (orientation: portrait) {
  .slider-item {
    display: grid !important;
    img {
      object-position: right center;
    }
  }
  .text-box {
    bottom: 100px;
    justify-self: center;
    left: initial !important;
    width: max-content;
    max-width: calc(100% - 20px);
  }
  .text-box-1 {
    top: 110px;
    bottom: initial;
  }

  .img-1 {
    object-position: center !important;
  }
  h1 {
    font-size: 40px !important;
  }
  h2 {
    font-size: 24px !important;
  }
}
`