import { useReduxState } from "@/redux"

import { VehicleItem } from "./VehicleItem"

export function VehicleList(): JSX.Element {
  const {
    vehicleList,
  } = useReduxState(state => ({
    vehicleList: state.search.vehicleList,
  }))
  return (
    <div className="grid-box">
      {vehicleList.map(vehicle => <VehicleItem key={vehicle._id} vehicle={vehicle} />)}
    </div>
  )
}