export const twClasses = {
  "bg-primary-200/50": true,
  "bg-secondary-200/50": true,
  "bg-grey-200/50": true,
  "bg-success-200/50": true,
  "bg-error-200/50": true,
  "bg-warning-200/50": true,
  "bg-info-200/50": true,

  "bg-primary-250/50": true,
  "bg-secondary-250/50": true,
  "bg-grey-250/50": true,
  "bg-success-250/50": true,
  "bg-error-250/50": true,
  "bg-warning-250/50": true,
  "bg-info-250/50": true,

  "bg-primary-300/50": true,
  "bg-secondary-300/50": true,
  "bg-grey-300/50": true,
  "bg-success-300/50": true,
  "bg-error-300/50": true,
  "bg-warning-300/50": true,
  "bg-info-300/50": true,

  "bg-primary-400/50": true,
  "bg-secondary-400/50": true,
  "bg-grey-400/50": true,
  "bg-success-400/50": true,
  "bg-error-400/50": true,
  "bg-warning-400/50": true,
  "bg-info-400/50": true,

  "bg-primary-500/50": true,
  "bg-secondary-500/50": true,
  "bg-grey-500/50": true,
  "bg-success-500/50": true,
  "bg-error-500/50": true,
  "bg-warning-500/50": true,
  "bg-info-500/50": true,
}