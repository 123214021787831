import { Brokerage, Say } from "@/shared"

import { BrokeragesState } from "../model"

export function getOutputMapByBrokerageId(brokerageList: Brokerage[], say: Say): BrokeragesState["outputMapByBrokerageId"] {
  const outputMapByBrokerageId: BrokeragesState["outputMapByBrokerageId"] = {}
  brokerageList.forEach(brokerage => {
    outputMapByBrokerageId[brokerage._id] = {
      comment: brokerage.comment?.plainText,
      brand: brokerage.brandList.map(brand => brand === "other" ? `${say.other}: ${brokerage.otherBrand}` : say[brand]).join(", "),
      bodyStyle: say[brokerage.bodyStyle],
      fuelType: brokerage.fuelTypeList.map(fuelType => say[fuelType]).join(", "),
      driveTrain: brokerage.driveTrainList.map(driveTrain => say[driveTrain]).join(", "),
      price: brokerage.price[0] === 0
        ? say.lessThan(brokerage.price[1])
        : brokerage.price[1] === Infinity
          ? say.moreThan(brokerage.price[0])
          : `${brokerage.price[0]} $ - ${brokerage.price[1]} $`,
      scheduleType: say[brokerage.scheduleType],
      generalQuestion: brokerage.generalQuestion.plainText,
      firstName: brokerage.firstName,
      lastName: brokerage.lastName,
      phone: brokerage.phone,
      email: brokerage.email,
      address: brokerage.address.address,
    }
  })
  return outputMapByBrokerageId
}