import { throttle } from "@/functions"
import { getReduxState, setReduxState } from "@/redux"
import { getManyVehicles } from "@/api/requests"
import { positionValues } from "react-custom-scrollbars-2"

let isScrolledToBottom: boolean = false
function handlePageScrollNotThrottled(value: positionValues | HTMLDivElement): void {
  const elem = value as HTMLDivElement
  const minScrollBottom = elem.nodeName === "HTML" ? 800 : 400
  if (value.scrollHeight - value.clientHeight - value.scrollTop < minScrollBottom && value.scrollHeight !== value.clientHeight && !isScrolledToBottom) {
    isScrolledToBottom = true
    const { vehicleList } = getReduxState().vehicles
    const { vehicleViewCount } = getReduxState().search
    if (vehicleViewCount < vehicleList.length) {
      setReduxState("search", { vehicleViewCount: vehicleViewCount + 20 }, () => {
        isScrolledToBottom = false
      })
    }
    if (Number.isInteger((vehicleViewCount + 60) / 200) && vehicleViewCount + 60 > vehicleList.length) {
      getManyVehicles({
        minIndex: vehicleViewCount + 60,
        maxIndex: vehicleViewCount + 60 + 200 + 5000,
        isLoadingHidden: true,
      }, newVehicleList => {
        if (vehicleList.length) {
          setReduxState("vehicles", {
            vehicleList: [...vehicleList, ...newVehicleList],
          }, () => {
            isScrolledToBottom = false
          })
        }
      })
    }
  }
}
export const handlePageScroll = throttle(handlePageScrollNotThrottled, 30)