import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core"
import { AddressField, Button, CheckBox, Fieldset, Input } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"
import { formatPhone } from "@/shared"
import { getVehicleFieldClassName } from "@/pages/Vehicles/functions/getVehicleFieldClassName"

import { incrementCreateVehicleViewIndex } from "../functions/incrementCreateVehicleViewIndex"

import { FormFooter } from "./FormFooter"

export function FinalForm(): JSX.Element {
  const {
    say,
    isFullOwnership,
    priceField,
    firstName,
    lastName,
    phone,
    email,
    address,
    isInvalidField,
    estimateField,
    isMsrp,
    isContactDetails,
    isCurrentUser
  } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
    isFullOwnership: state.createVehicle.isFullOwnership,
    priceField: state.createVehicle.priceField,
    estimateField: state.createVehicle.estimateField,
    isMsrp: state.createVehicle.isMsrp,
    firstName: state.createVehicle.firstName,
    lastName: state.createVehicle.lastName,
    phone: state.createVehicle.phone,
    email: state.createVehicle.email,
    address: state.createVehicle.address,
    isCurrentUser: state.users.currentUser,
    isContactDetails: (
      state.routes.firstPath === "sell-vehicle"
      || (
        (state.routes.firstPath === "edit-vehicle" || state.popups.name === "EditPartialVehicle")
        && state.vehicles.vehicleMap[state.createVehicle._id!]?.customerParams
      )),
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  return (
    <div>
      <div className="horizontal-box">
        {isContactDetails && (
          <Fieldset className={getVehicleFieldClassName("ownership")} label={say.selectOwnership} isRequired={true}>
            <RadioGroup
              className="radio-group"
              value={isFullOwnership ? "owned" : "leased"}
              onChange={evt => setReduxState("createVehicle", { isFullOwnership: evt.target.value === "owned" ? true : false })}
            >
              <FormControlLabel value="leased" control={<Radio color="primary" />} label={say.leasedVehicle} />
              <FormControlLabel value="owned" control={<Radio color="primary" />} label={say.ownedVehicle} />
            </RadioGroup>
          </Fieldset>
        )}
        <Fieldset className={getVehicleFieldClassName("price")} label={isFullOwnership ? say.selectPrice : say.priceBalance} isRequired={true}>
          <Input
            mobileFocusLabel={isFullOwnership ? say.selectPrice : say.priceBalance}
            iconName="coin"
            isError={isInvalidField && !priceField}
            value={priceField}
            inputMode="decimal"
            placeholder="5000"
            onChange={evt => setReduxState("createVehicle", { priceField: evt.target.value.split(/[^0-9.]/).join("") })} />
        </Fieldset>
      </div>
      {isCurrentUser && (
        <div className="horizontal-box">
          <Fieldset className={getVehicleFieldClassName("estimate")} label={say.vehicleValueEstimate}>
            <Input
              mobileFocusLabel={say.vehicleValueEstimate}
              iconName="coin"
              isError={isInvalidField && !estimateField}
              value={estimateField}
              inputMode="decimal"
              placeholder="5500"
              format="positiveDecimal"
              onChange={evt => setReduxState("createVehicle", { estimateField: evt.target.value })} />
          </Fieldset>
          <Fieldset className={getVehicleFieldClassName("estimate")} label={say.msrpLong}>
            <CheckBox
              className="!w-max"
              label={say.msrp}
              isChecked={isMsrp}
              onChange={() => setReduxState("createVehicle", { isMsrp: !isMsrp })}
            />
          </Fieldset>
        </div>
      )}
      {isContactDetails && (
        <>
          <div className="horizontal-box">
            <Fieldset label={say.firstName} className={getVehicleFieldClassName("firstName")} isRequired={true}>
              <Input
                mobileFocusLabel={say.firstName}
                iconName="user"
                isError={isInvalidField && !firstName}
                value={firstName}
                placeholder="John"
                onChange={evt => setReduxState("createVehicle", { firstName: evt.target.value.trim() })}
              />
            </Fieldset>
            <Fieldset label={say.lastName} className={getVehicleFieldClassName("lastName")} isRequired={true}>
              <Input
                mobileFocusLabel={say.lastName}
                iconName="user"
                isError={isInvalidField && !lastName}
                value={lastName}
                placeholder="Doe"
                onChange={evt => setReduxState("createVehicle", { lastName: evt.target.value.trim() })}
              />
            </Fieldset>
          </div>
          <div className="horizontal-box">
            <Fieldset label={say.email} className={getVehicleFieldClassName("email")} isRequired={true}>
              <Input
                mobileFocusLabel={say.email}
                iconName="envelope"
                isError={isInvalidField && !email}
                value={email}
                placeholder="john@doe.com"
                onChange={evt => setReduxState("createVehicle", { email: evt.target.value.trim() || "" })} />
            </Fieldset>
            <Fieldset label={say.phone} className={getVehicleFieldClassName("phone")} isRequired={true}>
              <Input
                mobileFocusLabel={say.phone}
                iconName="phone"
                isError={isInvalidField && !phone}
                value={phone}
                onBlur={() => setReduxState("createVehicle", { phone: formatPhone(phone) })}
                inputMode="numeric"
                placeholder="514-123-4567"
                onChange={evt => setReduxState("createVehicle", { phone: evt.target.value.split(/[^0-9-]/).join("") })} />
            </Fieldset>
          </div>
          <div className={getVehicleFieldClassName("address")}>
            <AddressField
              address={address}
              onAddressChange={newAddress => setReduxState("createVehicle", { address: newAddress })}
            />
          </div>
        </>
      )}


      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateVehicleViewIndex(false)}
        >
          {say.previous}
        </Button>
        <div className="padding" />
        <Button
          color="primary"
          variant="contained"
          onClick={() => incrementCreateVehicleViewIndex(true)}
        >
          {say.next}
        </Button>
      </FormFooter>
    </div>
  )
}
