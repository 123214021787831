import { getFromReduxState, setReduxState } from "@/redux"

export function incrementCreateBrokerageViewIndex(isPositive: boolean): void {
  const { viewIndex, isInvalidField } = getFromReduxState(state => ({
    viewIndex: state.createBrokerage.viewIndex,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  if (isInvalidField) {
    return
  }
  document.getElementById("form")?.scrollIntoView()
  setReduxState("createBrokerage", { viewIndex: viewIndex + (isPositive ? 1 : -1) })
}