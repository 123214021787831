import { SearchState } from "../model"

export function getSearchInitState(): SearchState {
  return {
    vehicleViewCount: 50,
    vehicleList: [],
    vehicleMap: {},
    isFilters: false,
    brandField: "",
    modelField: "",
    minYearField: "",
    maxYearField: "",
    minPriceField: "",
    maxPriceField: "",
    doorCountList: [],
    seatingCountList: [],
    equipmentField: "",
    equipmentList: [],
    brand: undefined,
    model: undefined,
    bodyStyleList: [],
    minDistanceField: "",
    maxDistanceField: "",
    conditionList: [],
    driveTrainList: [],
    isManualTransmission: undefined,
    fuelTypeList: [],
  }
}
