import { Popup } from "@/components"
import { setReduxState, useReduxState } from "@/redux"
import { ReduxState } from "@/redux/model"

import { SafeActionProps } from "./model"

export function SafeAction({
  action,
  buttonName,
  description,
  Component,
  title,
}: SafeActionProps): JSX.Element {
  const { say, } = useReduxState((state: ReduxState) => ({
    say: state.languages.say,
  }))
  return (
    <Popup
      title={title}
      isLarge={true}
      closeButtonType="cancel"
      primaryButton={{
        label: say[buttonName || "yes"],
        onClick: () => {
          action()
          setReduxState("popups", { name: undefined, props: undefined })
        }
      }}
    >
      <div className="mb-5">
        {description && <h4>{description}</h4>}
        {Component && <Component />}
      </div>
    </Popup>
  )
}
