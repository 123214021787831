import { breakpoints, center } from "@/ui/mixins"
import styled from "styled-components"

import { OutputStyledProps } from "./model"

export const OutputStyled = styled.div<OutputStyledProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .label {
    ${center};
    background: ${() => window.theme.grey800};
    min-height: 40px;
    padding: 0 15px;
    flex-shrink: 0;
    font-weight: 300;
    border-radius: 11px 11px 0 0;
    color: ${() => window.theme.grey300};
    border: 1px solid ${() => window.theme.grey700};
  }
  .gallery {
    margin-top: 20px;
  }
  .output, .textarea {
    ${center};
    width: 100%;
    padding: 0 15px;
    border-radius: 0 0 11px 11px;
    min-height: 40px;
    background: ${() => window.theme.grey700};
    &, span {
      color: ${() => window.theme.grey300};
    }

  }
  .text-area-content {
    height: 100%;
  }
  .text-area-content, .DraftEditor-root, .DraftEditor-editorContainer, .public-DraftEditor-content {
    ${center}
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    flex-direction: row;

    .label {
      width: 200px;
      border-radius: 11px 0 0 11px;
    }
    .output, .textarea {
      border-radius: 0 11px 11px 0;
    }
  }
`
