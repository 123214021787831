import { SetAction } from "../../redux/model"

import { VehicleState } from "./model"


export const vehicle = (currentState: VehicleState, action: SetAction<"vehicle">) => {
  const data = { ...action.data }
  return {
    ...currentState,
    ...data
  }
}