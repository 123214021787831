import { getAllStorage, setAllStorage } from "@/localStorage"
import { getBrokeragesInitFilters } from "@/pages/Brokerages/functions/getBrokerageInitFilters"
import { getUsersInitFilters } from "@/pages/Users/functions/getUsersInitFilters"
import { getVehiclesInitFilters } from "@/pages/Vehicles/functions/getVehiclesInitFilters"
import { resetReduxState } from "@/redux"
import { goToExactPath } from "@/routes"

import { SocketIo } from "../classes"

export function deleteOneSession(isRedirect: boolean): void {
  const allStorageData = getAllStorage()
  setAllStorage({
    sessionId: undefined,
    deviceId: allStorageData.deviceId,
    apparence: allStorageData.apparence,
    isShowTermsWarning: allStorageData.isShowTermsWarning,
    currentUser: undefined,
    selectedLanguage: undefined,
    vehicles: getVehiclesInitFilters(),
    brokerages: getBrokeragesInitFilters(),
    users: getUsersInitFilters()
  })
  resetReduxState()
  SocketIo.launch().catch(() => null)
  if (isRedirect) {
    goToExactPath("/login")
  }
}