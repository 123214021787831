import { Say, PublicUser } from "@/shared"

import { UsersState } from "../model"

export function getOutputMapByUserId(userList: PublicUser[], say: Say): UsersState["outputMapByUserId"] {
  const outputMapByUserId: UsersState["outputMapByUserId"] = {}
  userList.forEach(user => {
    outputMapByUserId[user._id] = {
      email: user.email,
      role: say[user.role]
    }
  })
  return outputMapByUserId
}