import { SayKeywordStrings } from "@/shared"
import { getReduxState, setReduxState } from "@/redux"

export function isInvalidFields(
  dataList: [
    isInvalid: boolean,
    keyword: SayKeywordStrings,
    after?: string
  ][] | { [key in SayKeywordStrings]?: boolean },
  callback?: () => void,
): boolean {
  if (!(dataList instanceof Array)) {
    dataList = Object.tsEntries(dataList as { [key in SayKeywordStrings]: boolean }).map(item => [!!item[1], item[0]])
  }
  const invalidFieldList = dataList.filter(item => {
    return item[0]
  })
  const { say } = getReduxState().languages
  if (invalidFieldList.length) {
    const keywordList = invalidFieldList.map(item => `${say[item[1]]}${item[2] || ""}`)
    setReduxState("ui", {
      snackbar: {
        message: "invalidField",
        after: [...new Set(keywordList)].join(", "),
        severity: "error"
      }
    }, callback)
    return true
  } else {
    return false
  }
}