
import { tw } from "@/shared"

import { KeywordItem } from "./KeywordItem"
import { KeywordListProps } from "./model"

export function KeywordList<Value>({ onChange, outputMap, optionList, imageMap, className }: KeywordListProps<Value>): JSX.Element {
  return (
    <div
      className={tw`${className} flex flex-wrap w-full select-none keyword-list`}
    >
      {optionList.map((keyword, index) => (
        <KeywordItem
          key={index}
          onChange={onChange}
          index={index}
          optionList={optionList}
          option={keyword}
          output={outputMap(keyword)}
          image={imageMap && imageMap(keyword)}
        />
      ))}
    </div>
  )
}