import { OptionalToUndefined } from "@/shared"
import { getStorageItem } from "@/localStorage"

import { UsersState } from "../model"


export function getUsersInitState(): OptionalToUndefined<UsersState> {
  return {
    tableUserIdList: [],
    outputMapByUserId: {},
    userMap: {},
    userList: [],
    userIdList: [],
    currentUser: getStorageItem("currentUser"),
    isSessionLoaded: false,
    editedComment: { html: "", plainText: "" },
    ...getStorageItem("users")
  }
}
