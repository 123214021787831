import { Icon } from "@/components"

import { BrokerageInfoItemProps } from "../model"

export function BrokerageInfoItem({ title, text, iconName }: BrokerageInfoItemProps): JSX.Element {
  return (
    <div className="items-center text-center col">
      <h3 className="text-2xl sm:text-3xl text-black/40">{title}</h3>
      <Icon name={iconName} className="h-10 my-4 text-[hsl(42,25%,50%)]" />
      <p className="max-w-[250px] text-white/70 font-medium text-base sm:text-lg">{text}</p>
    </div>
  )
}