import { setReduxState, useReduxState } from "@/redux"
import { FormContentStyled } from "@/styles/FormContentStyled"
import { FormParentStyled } from "@/styles/FormParentStyled"
import { Class } from "@/components"

import { validateBrokerage } from "../functions"

import { VehicleForm } from "./VehicleForm"
import { FinalForm } from "./FinalForm"
import { WelcomeForm } from "./WelcomeForm"
import { FirstForm } from "./FirstForm"
import { BrokerageInfoItem } from "./BrokerageInfoItem"

export function CreateBrokerage(): JSX.Element {
  const { viewIndex, say, isInvalidField } = useReduxState(state => ({
    say: state.languages.say,
    viewIndex: state.createBrokerage.viewIndex,
    isInvalidField: state.ui.snackbar?.message === "invalidField"
  }))

  const titleList = [
    say.fillForm,
    say.tellYourNeeds,
    say.vehicleForm,
    say.contactForm,
  ] as const

  return (
    <>
      <Class props={{ viewIndex }} didUpdate={(oldProps, newProps) => (
        oldProps.viewIndex !== newProps.viewIndex
        && !isInvalidField
        && newProps.viewIndex > oldProps.viewIndex
        && validateBrokerage(oldProps.viewIndex)
        && setReduxState("createBrokerage", { viewIndex: oldProps.viewIndex })
      )} />
      <div className="relative col center mt-[100px]">
        <div className="z-10 full-absolute bg-[#202020]/80" />
        <h1 className="z-20 max-w-2xl text-3xl sm:text-5xl text-center text-secondary-900 uppercase pt-[100px] pb-10">{say.buyConfidence}</h1>
        <p
          className="z-20 max-w-[1000px] mx-5 text-lg sm:text-2xl text-justify [text-align-last:center] text-secondary-50/100 uppercase pb-[100px]"
        >{say.brokerageDescription}</p>
        <img alt="bugatti-back" className="object-cover object-center full-absolute" src="/images/bugatti-back.jpg" />
      </div>
      <div className="py-20 bg-[#705e33] center col">
        <h1 className="mb-10 text-2xl text-center uppercase sm:text-4xl">{say.brokerageSectionTitle}</h1>
        <div className="grid w-[calc(100%-40px)] grid-cols-1 sm:grid-cols-3 gap-5 max-w-[1000px]">
          <BrokerageInfoItem
            text={say.brokerageSectionText1}
            iconName="money-bill"
            title={say.brokerageSectionSubTitle1}
          />
          <BrokerageInfoItem
            iconName="hands-helping"
            text={say.brokerageSectionText2}
            title={say.brokerageSectionSubTitle2}
          />
          <BrokerageInfoItem
            iconName="assistive-listening-systems"
            text={say.brokerageSectionText3}
            title={say.brokerageSectionSubTitle3}
          />
        </div>
      </div>
      <FormParentStyled id="form">
        <div className="w-full pt-16 pb-16 text-center bg-gradient-to-b from-black/50 to-grey-900">
          <h1 className="pb-2 text-secondary-900">
            {say.buyAVehicle}
          </h1>
          <h3 className="text-secondary-50/40">
            {titleList[viewIndex]}
          </h3>
        </div>
        <FormContentStyled>
          {viewIndex === 0 && <WelcomeForm />}
          {viewIndex === 1 && <FirstForm />}
          {viewIndex === 2 && <VehicleForm />}
          {viewIndex === 3 && <FinalForm />}
        </FormContentStyled>
      </FormParentStyled>
    </>
  )
}
