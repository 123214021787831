import { useRef } from "react"
import "react-day-picker/lib/style.css"
import { formatString, tw } from "@/shared"
import { Button, Icon, SafeFixed } from "@/components"
import { IS_ANY_IOS, IS_CORDOVA, IS_DESKTOP } from "@/constants"
import { useStates, useWillMount } from "@/redux"
import { getNotch } from "@/ui/mixins"

import { OptionalParent } from "../OptionalParent"

import { InputProps } from "./model"

export function Input({
  iconName,
  onIconClick,
  children,
  onEnterKey,
  isError,
  id,
  isRightIcon,
  className,
  label,
  format,
  isTrimOnBlur,
  isChildrenOnlyWhenFocus,
  getBlurHandler,
  labelPosition = "top",
  ...inputProps
}: InputProps): JSX.Element {
  const ref = useRef<HTMLInputElement | null>(null)
  const [{
    type,
    isFocused,
  }, setState] = useStates({
    type: inputProps.type === "password" ? "password" : undefined,
    isFocused: false,
  })
  useWillMount(() => {
    if (getBlurHandler) {
      getBlurHandler(() => {
        setState({ isFocused: false })
      })
    }
  })
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)
  return (
    <OptionalParent
      isChildrenOnly={IS_DESKTOP || !isFocused}
      parent={child => {
        return (
          <SafeFixed>
            <div
              onClick={() => setState({ isFocused: false })}
              className="full-fixed px-5 pt-5 backdrop-blur-md pb-5 z-[100000000]"
              style={{
                marginTop: IS_CORDOVA && IS_ANY_IOS ? getNotch("top") : undefined,
                height: IS_CORDOVA && IS_ANY_IOS ? `calc(100% - ${getNotch("top")})` : undefined,
              }} >
              {child}
            </div>
          </SafeFixed>
        )
      }}
    >
      <div
        id={id}
        className={tw`
          input
          relative
          flex
          items-center
          h-12
          max-h-full
          rounded-full
          bg-grey-850
          transition
          duration-100
          ease-in-out
          border
          border-solid
          border-grey-750
          cursor-text
          w-full
          hover:border-secondary-900
          focus-within:!border-secondary-700
          sm:focus-within:flex-2
          ${isRightIcon ? "flex-row-reverse" : ""}
          ${isError ? "!border-error-300" : ""}
          ${className}
      `}
        onClick={evt => {
          if (!isFocused) {
            ref.current?.focus()
          } else {
            evt.stopPropagation()
          }
        }}>
        {label && labelPosition === "left" && <label className="ml-3 text-sm text-grey-500">{label}</label>}
        {iconName && (
          <Icon
            className={`h-4 cursor-text text-grey-400 ${isRightIcon ? "mr-4" : "ml-4"}`}
            name={iconName}
            onClick={evt => {
              if (onIconClick) {
                evt.stopPropagation()
                onIconClick()
              }
            }}
          />
        )}
        <input
          ref={ref}
          onKeyUp={evt => evt.key === "Enter" && onEnterKey && onEnterKey()}
          {...inputProps}
          autoFocus={isFocused || (inputProps.autoFocus && IS_DESKTOP) || undefined}
          className={`h-full bg-trans pl-5 text-sm w-full text-grey-300 placeholder:text-grey-700`}
          onFocus={evt => {
            if (!isFocused) {
              setState({ isFocused: true })
            }
            if (inputProps.onFocus) {
              inputProps.onFocus(evt)
            }
          }}
          onBlur={evt => {
            if (inputProps.onBlur) {
              inputProps.onBlur(evt)
            }
            if (isTrimOnBlur && inputProps.onChange) {
              evt.target.value = (inputProps.value as string).trim()
              inputProps.onChange(evt)
            }
          }}
          onChange={evt => {
            if (inputProps.onChange) {
              if (format) {
                evt.target.value = formatString(format, evt.target.value)
              }
              inputProps.onChange(evt)
            }
          }}
          type={type !== undefined ? type : inputProps.type}
        />
        {label && labelPosition === "right" && <label className="mr-3 text-sm text-grey-500 shrink-0">{label}</label>}
        {label && labelPosition === "top" && <label className="absolute h-5 px-3 bg-white top-[-10px] left-[16px] text-grey-800">{label}</label>}
        {type !== undefined && (
          <Icon
            className="absolute h-4 ml-4 cursor-text text-grey-400 clickable right-5"
            name={type === "password" ? "eye-slash" : "eye"}
            onClick={() => setState({ type: type === "password" ? "text" : "password" })}
          />
        )}
        {isFocused && !IS_DESKTOP && (
          <Button
            isCircle={true}
            color="error"
            size="xs"
            variant="outlined"
            shade={300}
            isTransparent={true}
            iconName="times"
            className="mr-3 !bg-grey-850"
            onClick={() => setState({ isFocused: false })}
          />
        )}
        {isChildrenOnlyWhenFocus ? isFocused ? children : undefined : children}
      </div>
    </OptionalParent>
  )
}
