import { OptionalToUndefined } from "@/shared"

import { BrokeragesState } from "../model"


export function getBrokeragesInitFilters(): OptionalToUndefined<Pick<BrokeragesState,
  | "searchField"
  | "shownColumnList"
  | "searchPrecision"
>> {
  return {
    searchField: "",
    searchPrecision: "high",
    shownColumnList: [
      "brand",
      "bodyStyle",
      "driveTrain",
      "fuelType",
      "price",
      "scheduleType",
      "generalQuestion",
      "comment",
      "firstName",
      "lastName",
      "phone",
      "email",
      "address",
    ]
  }
}