import { getFromReduxState, setReduxState } from "@/redux"

export function incrementCreateVehicleViewIndex(isPositive: boolean): void {
  const { viewIndex, isInvalidField } = getFromReduxState(state => ({
    viewIndex: state.createVehicle.viewIndex,
    isInvalidField: state.ui.snackbar?.message === "invalidField",
  }))
  if (isInvalidField) {
    return
  }
  document.getElementById("form")?.scrollIntoView()
  setReduxState("createVehicle", { viewIndex: viewIndex + (isPositive ? 1 : -1) })
}