import { Vehicle, VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND } from "@/shared"
import { formatString } from "@/shared"
import { ReduxState } from "@/redux/model"

import { VehiclesState } from "../model"


export function filterVehicles(
  newState: VehiclesState,
  globalState: ReduxState
): Vehicle[] {
  const {
    searchField,
    searchPrecision,
    status,
    ownership,
    isPublic
  } = newState
  const {
    languages: {
      say
    }
  } = globalState
  return newState.vehicleList.filter(item => {
    const activeFilterCount = [
      searchField.trim(),
      isPublic !== undefined,
      status !== "all",
      ownership !== "all"
    ].filter(val => !!val).length
    let noMatchCount = 0

    if (searchField.trim()) {
      if (
        formatString("flat", say[item.brand]).includes(formatString("flat", searchField))
        || formatString("flat", VEHICLE_MODEL_MAP_BY_VEHICLE_BRAND[item.brand][item.model]!).includes(formatString("flat", searchField))
        || item.year === Number(formatString("integer", searchField))
        || (item.comment?.plainText && formatString("flat", item.comment.plainText).includes(formatString("flat", searchField)))
        || formatString("flat", say[item.status]).includes(formatString("flat", searchField))
        || formatString("flat", say[item.bodyStyle]).includes(formatString("flat", searchField))
        || formatString("flat", item.exteriorColor).includes(formatString("flat", searchField))
        || formatString("flat", item.interiorColor).includes(formatString("flat", searchField))
        || formatString("flat", item.vinId).includes(formatString("flat", searchField))
        || formatString("flat", say[item.condition]).includes(formatString("flat", searchField))
        || formatString("flat", say[item.fuelType]).includes(formatString("flat", searchField))
        || formatString("flat", say[item.driveTrain]).includes(formatString("flat", searchField))
        || formatString("flat", item.isManualTransmission ? say.manual : say.automatic).includes(formatString("flat", searchField))
        || formatString("flat", item.isPublic ? say.public : say.private).includes(formatString("flat", searchField))
        || (item.customerParams
          && formatString("flat", item.customerParams.isFullOwnership ? say.ownedVehicle : say.leasedVehicle).includes(formatString("flat", searchField)))
        || (item.customerParams && formatString("flat", item.customerParams.firstName).includes(formatString("flat", searchField)))
        || (item.customerParams && formatString("flat", item.customerParams.lastName).includes(formatString("flat", searchField)))
        || (item.customerParams && formatString("flat", item.customerParams.phone).includes(formatString("flat", searchField)))
        || (item.customerParams && formatString("flat", item.customerParams.email).includes(formatString("flat", searchField)))
        || (item.customerParams && formatString("flat", item.customerParams.address.address).includes(formatString("flat", searchField)))
        || item.equipmentList.find(elem => formatString("flat", say[elem]).includes(formatString("flat", searchField)))

      ) {
        return true
      } else {
        if (searchPrecision === "high") {
          return false
        } else {
          noMatchCount++
        }
      }
    }

    if (isPublic !== undefined) {
      if (item.isPublic !== isPublic) {
        noMatchCount++
      }
    }
    if (status !== "all") {
      if (item.status !== status) {
        noMatchCount++
      }
    }
    if (ownership !== "all") {
      if (
        (!item.customerParams && ownership !== "avarta")
        || (item.customerParams?.isFullOwnership && ownership !== "ownedVehicle")
        || (item.customerParams && !item.customerParams.isFullOwnership && ownership !== "leasedVehicle")
      ) {
        noMatchCount++
      }
    }
    if (searchPrecision === "high" && noMatchCount) {
      return false
    }
    if (searchPrecision === "medium" && noMatchCount > activeFilterCount / 2) {
      return false
    } if (searchPrecision === "low" && noMatchCount >= activeFilterCount) {
      return false
    }
    return true
  })
}