import { ReduxState } from "@/redux/model"
import { Component } from "react"
import { connect, ConnectedProps } from "react-redux"

import { FooterMapStyled } from "./FooterMapStyled"
export class FooterMapNotConnected extends Component<ConnectedProps<typeof connector>> {
  ref: HTMLDivElement | null = null
  componentDidMount(): void {
    const lngLat: [number, number] = [-73.85037530182622, 45.488461394946256]
    const map = new window.mapboxgl.Map({
      accessToken: "pk.eyJ1IjoiYXZhcnRhc29sdXRpb25zIiwiYSI6ImNremo4NDgwdTNyYzAydXBxYWk0aXh6bWoifQ.dPDicXuUxeazXYzrXaWkgA",
      container: this.ref!,
      style: "mapbox://styles/mapbox/dark-v10",
      center: lngLat,
      attributionControl: false,
      zoom: 11
    })
    const el = document.createElement("div")
    el.className = "map-marker"
    new window.mapboxgl.Marker(el, { offset: [0, -50 / 2] })
      .setLngLat(lngLat)
      .addTo(map!)
  }
  render(): JSX.Element {
    const { say } = this.props
    return (
      <FooterMapStyled>
        <div className="address-box">{say.avartaAddress}</div>
        <div
          className="button"
          onClick={() => window.open("https://www.google.ca/maps/place/Avarta+Auto+Solutions/@45.4882846,-73.852564,17z/data=!3m2!4b1!5s0x4cc93b726e327ecd:0x310ccb32b8dd506!4m5!3m4!1s0x4cc93b186740db15:0xb84a60b4d5e334c7!8m2!3d45.488254!4d-73.8504139", "_blank")}
        >{say.seeInGoogleMap}</div>
        <div className="map-box" ref={ref => this.ref = ref} />
      </FooterMapStyled>
    )
  }
}

const connector = connect((state: ReduxState) => ({
  say: state.languages.say
}))

export const FooterMap = connector(FooterMapNotConnected)