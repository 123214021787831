import { useReduxState } from "@/redux"
import { ImageGallery } from "@/components"
import { getReadableNumber } from "@/functions"

import { VehicleContentStyled } from "./VehicleContentStyled"
import { VehicleSpec } from "./VehicleSpec"
import { VehicleSpecStyled } from "./VehicleSpecStyled"

export function VehicleContent(): JSX.Element {
  const { say, vehicle, imageList } = useReduxState(state => ({
    say: state.languages.say,
    vehicle: state.vehicle.vehicle,
    imageList: state.vehicle.imageList,
  }))
  if (!vehicle) {
    return (
      <VehicleContentStyled>
        <div className="top-box">
          <h3>—<span>$</span></h3>
        </div>
        <ImageGallery imageList={[]} />
        <div className="grid-box">
          {[...Array(8)].map((_, index) => <VehicleSpecStyled key={index} />)}
        </div>
        <div className="padding" />
      </VehicleContentStyled>
    )
  }
  const title = `${vehicle.year} ${say[vehicle.brand]} ${vehicle.model} ${say[vehicle.bodyStyle]}`
  const kmDistance = vehicle.isKmDistanceUnit ? vehicle.distance : vehicle.distance * 1.609344
  return (
    <VehicleContentStyled>
      <div className="top-box">
        {title && <h3>{title} <span>{getReadableNumber(vehicle.price)}$</span></h3>}
      </div>
      <ImageGallery imageList={imageList} />
      <div className="grid-box">
        <VehicleSpec
          label={say.distance}
          value={`${getReadableNumber(kmDistance)} km`}
          iconName="tachometer-alt"
        />
        <VehicleSpec
          label={say.condition}
          value={say[vehicle.condition]}
          iconName="clipboard-list-check"
        />
        <VehicleSpec
          label={say.fuelType}
          value={say[vehicle.fuelType]}
          iconName="gas-pump"
        />
        {vehicle.fuelType !== "electric" && (
          <>
            <VehicleSpec
              label={say.transmission}
              value={say[vehicle.isManualTransmission ? "manual" : "automatic"]}
              iconName="steering-wheel"
            />
            <VehicleSpec
              label={say.engine}
              value={vehicle.cylinderCount ? say.cylinderEngine(vehicle.cylinderCount) : say.undefined}
              iconName="cogs"
            />
            <VehicleSpec
              label={say.cityFuelConsumptionShort}
              value={`${vehicle.cityFuelConsumption} L / 100 km`}
              iconName="gas-pump"
            />
            <VehicleSpec
              label={say.highwayFuelConsumptionShort}
              value={`${vehicle.highwayFuelConsumption} L / 100 km`}
              iconName="gas-pump"
            />
            <VehicleSpec
              label={say.driveTrain}
              value={say[vehicle.driveTrain]}
              iconName="tire"
            />
          </>
        )}
        <VehicleSpec
          label={say.doors}
          value={vehicle.doorCount}
          iconName="car-side"
        />
        <VehicleSpec
          label={say.seatingCount}
          value={vehicle.seatingCount}
          iconName="chair-office"
        />
        <VehicleSpec
          label={say.exteriorColor}
          value={vehicle.exteriorColor}
          iconName="palette"
        />
        <VehicleSpec
          label={say.interiorColor}
          value={vehicle.interiorColor}
          iconName="palette"
        />
        <div className="equipment-box">
          <VehicleSpec

            label={say.equipments}
            value={vehicle.equipmentList.map(val => say[val]).join(", ")}
            iconName="list"
          />
        </div>
      </div>
    </VehicleContentStyled>
  )
}