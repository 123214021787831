import { Icon, Tooltip } from "@/components"
import { useReduxState } from "@/redux"
import { setReduxState } from "@/redux"
import { getReadableNumber } from "@/functions"

import { searchVehicles } from "../functions"

import { FilterOutputStyled } from "./FilterOutputStyled"

export function FiltersOutput(): JSX.Element {
  const {
    say,
    brand,
    model,
    equipmentList,
    minYearField,
    maxYearField,
    minDistanceField,
    maxDistanceField,
    minPriceField,
    maxPriceField,
    bodyStyleList,
    doorCountList,
    seatingCountList,
    conditionList,
    fuelTypeList,
    driveTrainList,
    isManualTransmission,
  } = useReduxState(state => ({
    say: state.languages.say,
    brand: state.search.brand,
    model: state.search.model,
    minYearField: state.search.minYearField,
    maxYearField: state.search.maxYearField,
    minPriceField: state.search.minPriceField,
    maxPriceField: state.search.maxPriceField,
    minDistanceField: state.search.minDistanceField,
    maxDistanceField: state.search.maxDistanceField,
    bodyStyleList: state.search.bodyStyleList,
    conditionList: state.search.conditionList,
    fuelTypeList: state.search.fuelTypeList,
    doorCountList: state.search.doorCountList,
    seatingCountList: state.search.seatingCountList,
    equipmentList: state.search.equipmentList,
    driveTrainList: state.search.driveTrainList,
    isManualTransmission: state.search.isManualTransmission,
    equipmentField: state.search.equipmentField,
  }))
  const filters: ({
    component: JSX.Element | string
    cancelFn: (callback: () => void) => void
  })[] = []

  if (minPriceField || maxPriceField) {
    let content = <></>
    if (!minPriceField) {
      content = <><Icon name="coin" />{`≤ ${getReadableNumber(Number(maxPriceField))} $`}</>
    } else if (!maxPriceField) {
      content = <><Icon name="coin" />{`≥ ${getReadableNumber(Number(minPriceField))} $`}</>
    } else {
      content = <>{`${getReadableNumber(Number(minPriceField))} $ ≤ `} <Icon name="coin" className="center" />{` ≥ ${getReadableNumber(Number(maxPriceField))} $`}</>
    }
    filters.push({
      component: (
        <>
          {content}
          <Tooltip>{say.price}</Tooltip>
        </>
      ),
      cancelFn: callback => setReduxState("search", { minPriceField: undefined, maxPriceField: undefined }, callback)
    })
  }

  if (minYearField || maxYearField) {
    let content = <></>
    if (!minYearField) {
      content = <><Icon name="calendar-alt" />{`≤ ${maxYearField}`}</>
    } else if (!maxYearField) {
      content = <><Icon name="calendar-alt" />{`≥ ${minYearField}`}</>
    } else {
      content = <>{`${minYearField} ≤ `} <Icon name="calendar-alt" className="center" />{` ≥ ${maxYearField}`}</>
    }
    filters.push({
      component: (
        <>
          {content}
          <Tooltip>{say.year}</Tooltip>
        </>
      ),
      cancelFn: callback => setReduxState("search", { minYearField: undefined, maxYearField: undefined }, callback)
    })
  }

  if (minDistanceField || maxDistanceField) {
    let content = <></>
    if (!minDistanceField) {
      content = <><Icon name="ruler" />{`≤ ${getReadableNumber(Number(maxDistanceField))} km`}</>
    } else if (!maxDistanceField) {
      content = <><Icon name="ruler" />{`≥ ${getReadableNumber(Number(minDistanceField))} km`}</>
    } else {
      content = <>{`${getReadableNumber(Number(minDistanceField))} km ≤ `} <Icon name="ruler" className="center" />{` ≥ ${getReadableNumber(Number(maxDistanceField))} km`}</>
    }
    filters.push({
      component: (
        <>
          {content}
          <Tooltip>{say.distance}</Tooltip>
        </>
      ),
      cancelFn: callback => setReduxState("search", { minDistanceField: undefined, maxDistanceField: undefined }, callback)
    })
  }
  equipmentList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          <Icon name="list" />
          {say[key]}
          <Tooltip>{say.equipments}</Tooltip>
        </>
      ),
      cancelFn: callback => {
        const newList = [...equipmentList]
        newList.splice(index, 1)
        setReduxState("search", { equipmentList: newList }, callback)
      }
    })
  })

  if (brand !== undefined) {
    filters.push({
      component: (
        <>
          <Icon name="cars" />
          {say[brand]}
          <Tooltip>{say.brand}</Tooltip>
        </>
      ),
      cancelFn: callback => setReduxState("search", { brand: undefined }, callback)
    })
  }


  if (model !== undefined) {
    filters.push({
      component: (
        <>
          <Icon name="car" />
          {model}
          <Tooltip>{say.model}</Tooltip>
        </>
      ),
      cancelFn: callback => setReduxState("search", { model: undefined }, callback)
    })
  }

  if (isManualTransmission !== undefined) {
    filters.push({
      component: (
        <>
          <Icon name="steering-wheel" />
          {isManualTransmission ? say.manual : say.automatic}
          <Tooltip>{say.transmission}</Tooltip>
        </>
      ),
      cancelFn: callback => setReduxState("search", { isManualTransmission: undefined }, callback)
    })
  }

  conditionList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          <Icon name="clipboard-list-check" />
          {say[key]}
          <Tooltip>{say.condition}</Tooltip>
        </>
      ),
      cancelFn: callback => {
        const newList = [...conditionList]
        newList.splice(index, 1)
        setReduxState("search", { conditionList: newList }, callback)
      }
    })
  })

  bodyStyleList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          {say[key]}
          <img alt={key} src={"./images/" + key + ".svg"} />
          <Tooltip>{say.bodyStyle}</Tooltip>
        </>
      ),
      cancelFn: callback => {
        const newList = [...bodyStyleList]
        newList.splice(index, 1)
        setReduxState("search", { bodyStyleList: newList }, callback)
      }
    })
  })

  fuelTypeList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          <Icon name="gas-pump" />
          {say[key]}
          <Tooltip>{say.fuelType}</Tooltip>
        </>
      ),
      cancelFn: callback => {
        const newList = [...fuelTypeList]
        newList.splice(index, 1)
        setReduxState("search", { fuelTypeList: newList }, callback)
      }
    })
  })

  doorCountList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          <Tooltip>{say.doors}</Tooltip>
          {key}
          <Icon name="door-closed" className="right" />
        </>
      ),
      cancelFn: callback => {
        const newList = [...doorCountList]
        newList.splice(index, 1)
        setReduxState("search", { doorCountList: newList }, callback)
      }
    })
  })
  seatingCountList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          <Tooltip>{say.seatingCount}</Tooltip>
          {key}
          <Icon name="chair-office" className="right" />
        </>
      ),
      cancelFn: callback => {
        const newList = [...seatingCountList]
        newList.splice(index, 1)
        setReduxState("search", { seatingCountList: newList }, callback)
      }
    })
  })

  driveTrainList.forEach((key, index) => {
    filters.push({
      component: (
        <>
          <Icon name="tire" />
          {say[key]}
          <Tooltip>{say.driveTrain}</Tooltip>
        </>
      ),
      cancelFn: callback => {
        const newList = [...driveTrainList]
        newList.splice(index, 1)
        setReduxState("search", { driveTrainList: newList }, callback)
      }
    })
  })

  return (
    <>
      {filters.map((filter, index) => (
        <FilterOutputStyled
          key={index} onClick={() => {
            filter.cancelFn(() => {
              searchVehicles()
            })
          }}>
          {filter.component}
          <Icon name="times" />
        </FilterOutputStyled>
      ))}
    </>
  )
}