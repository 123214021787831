import { AllColors } from "../model"

export const COLORS: AllColors = {
  "secondary": {
    "50": "#fffdeb",
    "100": "#fff8cd",
    "200": "#fff4ad",
    "300": "#feef8e",
    "400": "#fceb77",
    "500": "#f9e662",
    "600": "#edd860",
    "700": "#d8c259",
    "800": "#c3ab53",
    "900": "#a08649"
  },
  "primary": {
    "50": "#FCE2D5",
    "100": "#FCE2D5",
    "200": "#FAC0AC",
    "300": "#F19380",
    "400": "#E3695E",
    "500": "#D12E2E",
    "600": "#B3212E",
    "700": "#96172D",
    "800": "#790E2A",
    "900": "#640828",
  },
  info: {
    "100": "#C9FBEF",
    "200": "#95F8E8",
    "300": "#5EEBE0",
    "400": "#36D8D8",
    "500": "#00AFBF",
    "600": "#0089A4",
    "700": "#006789",
    "800": "#004A6E",
    "900": "#00355B",
    "t100": "rgba(0, 175, 191, 0.08)",
    "t200": "rgba(0, 175, 191, 0.16)",
    "t300": "rgba(0, 175, 191, 0.24)",
    "t400": "rgba(0, 175, 191, 0.32)",
    "t500": "rgba(0, 175, 191, 0.4)",
    "t600": "rgba(0, 175, 191, 0.6)",
  },
  success: {
    "100": "#E4F9D3",
    "200": "#C4F4AA",
    "300": "#94DE79",
    "400": "#66BE52",
    "500": "#309324",
    "600": "#1D7E1A",
    "700": "#126916",
    "800": "#0B5515",
    "900": "#064614",
    "t100": "rgba(48, 147, 36, 0.08)",
    "t200": "rgba(48, 147, 36, 0.16)",
    "t300": "rgba(48, 147, 36, 0.24)",
    "t400": "rgba(48, 147, 36, 0.32)",
    "t500": "rgba(48, 147, 36, 0.4) ",
    "t600": "rgba(48, 147, 36, 0.48)",
  },
  warning: {
    50: "#fffde7",
    100: "#fff9c4",
    200: "#fff59d",
    300: "#fff176",
    400: "#ffee58",
    500: "#ffeb3b",
    600: "#fdd835",
    700: "#fbc02d",
    800: "#f9a825",
    900: "#f57f17",
  },
  error: {
    "100": "#FCE2D5",
    "200": "#FAC0AC",
    "300": "#F19380",
    "400": "#E3695E",
    "500": "#D12E2E",
    "600": "#B3212E",
    "700": "#96172D",
    "800": "#790E2A",
    "900": "#640828",
    "t100": "rgba(209, 46, 46, 0.08)",
    "t200": "rgba(209, 46, 46, 0.16)",
    "t300": "rgba(209, 46, 46, 0.24)",
    "t400": "rgba(209, 46, 46, 0.32)",
    "t500": "rgba(209, 46, 46, 0.4)",
    "t600": "rgba(209, 46, 46, 0.48)",
  },

  grey: {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#eeeeee",
    "300": "#e0e0e0",
    "400": "#bdbdbd",
    "500": "#9e9e9e",
    "600": "#757575",
    "700": "#616161",
    "750": "#525252",
    "800": "#424242",
    "850": "#303030",
    "900": "#212121"
  },
}
