export const VEHICLE_TABLE_HEAD_LIST = [
  "year",
  "brand",
  "model",
  "vinId",
  "exteriorColor",
  "interiorColor",
  "ownership",
  "status",
  "equipments",
  "driveTrain",
  "condition",
  "fuelType",
  "price",
  "firstName",
  "lastName",
  "phone",
  "email",
  "address",
  "estimate",
  "comment",
  "distance",
  "bodyStyle",
  "doors",
  "seatingCount",
  "cityFuelConsumption",
  "highwayFuelConsumption",
  "transmission",
  "cylinderCount",
  "audience",
  "creationDate",
  "lastEditDate",

] as const