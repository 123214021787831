import { getManyVehicles } from "@/api/requests"
import { getReduxState, setReduxState } from "@/redux"

export function searchVehicles(): void {
  const {
    brand,
    model,
    minYearField,
    maxYearField,
    bodyStyleList,
    doorCountList,
    conditionList,
    seatingCountList,
    equipmentList,
    isManualTransmission,
    fuelTypeList,
    driveTrainList,
    minDistanceField,
    maxDistanceField,
    minPriceField,
    maxPriceField,
  } = getReduxState().search

  getManyVehicles({
    minIndex: 0,
    maxIndex: 5000,
    brand,
    model,
    minYear: minYearField ? Number(minYearField) : undefined,
    maxYear: maxYearField ? Number(maxYearField) : undefined,
    bodyStyleList: bodyStyleList.length ? bodyStyleList : undefined,
    doorCountList: doorCountList.length ? doorCountList : undefined,
    conditionList: conditionList.length ? conditionList : undefined,
    seatingCountList: seatingCountList.length ? seatingCountList : undefined,
    equipmentList: equipmentList.length ? equipmentList : undefined,
    fuelTypeList: fuelTypeList.length ? fuelTypeList : undefined,
    driveTrainList: driveTrainList.length ? driveTrainList : undefined,
    minDistance: minDistanceField ? Number(minDistanceField) : undefined,
    maxDistance: maxDistanceField ? Number(maxDistanceField) : undefined,
    minPrice: minPriceField ? Number(minPriceField) : undefined,
    maxPrice: maxPriceField ? Number(maxPriceField) : undefined,
    isManualTransmission,
  }, list => setReduxState("search", { vehicleList: list }))
}