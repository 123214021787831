import { getReduxState, setReduxState } from "@/redux"
import { AllCollections, AllRequests, ErrorMessages, RequestData } from "@/shared"
import moment from "moment"

import { SocketIo } from "../classes"

import { undefinedToNull } from "./undefinedToNull"

export function handleSocketRequest<Collection extends AllCollections, Path extends keyof AllRequests[Collection]>(
  collection: Collection,
  path: Path,
  {
    isLoadingHidden,
    ...data
  }: AllRequests[Collection][Path] extends RequestData<any, any, any>
    ? (AllRequests[Collection][Path]["params"] & {
      isLoadingHidden?: boolean,
    })
    : never,
  successCalback?: (
    data: AllRequests[Collection][Path] extends RequestData<any, any, any>
      ? AllRequests[Collection][Path]["result"]
      : never
  ) => void,
  errorCalback?: (data: ErrorMessages) => void,

): void {
  const finalRequest = () => {
    if (!isLoadingHidden) {
      if (!SocketIo.getSocket()?.connected) {
        setReduxState("ui", { snackbar: { message: "noInternetConnexionFound", severity: "error" } })
      }
      setReduxState("ui", { loadingCount: getReduxState().ui.loadingCount + 1 })
    }
    data = undefinedToNull(data)

    SocketIo.getSocket()!.emit(`/api/${collection}/${path as string}`, { requestDate: moment().toISOString(), data }, (
      response: {
        data?: AllRequests[Collection][Path] extends RequestData<any, any>
        ? AllRequests[Collection][Path]["result"]
        : never
        error?: ErrorMessages
      }
    ) => {
      if (!isLoadingHidden) {
        setReduxState("ui", { loadingCount: getReduxState().ui.loadingCount - 1 })
      }
      if (response.error) {
        if (errorCalback && (response.error)) {
          errorCalback(response.error!)
        }
      } else {
        if (successCalback) {
          successCalback(response.data!)
        }
      }
    })
  }
  if (!SocketIo.isReady) {
    SocketIo.pushCallback(finalRequest)
  } else if (!SocketIo.getSocket()!.connected) {
    if (!isLoadingHidden) {
      setReduxState("ui", { snackbar: { message: "noInternetConnexionFound", severity: "error" } })
    }
  } else {
    finalRequest()
  }
}
